/**
 * Version : 1.0
 * 작성일자 : 2022-03-10
 * 작성자 : 강연승
 * 설명 : 알람정보
 */
const initalState = {
  DISPLAY: false,
  TEXT: "",
  CANCLE_BTN: false,
  SUBMIT_BTN: true,
  submitEventHandler: function () {},
};

const alertInfo = (state = initalState, action) => {
  switch (action.type) {
    case "alertInfo/OPEN_ALERT":
      return {
        ...state,
        DISPLAY: true,
        TEXT: action.DATA.TEXT,
        CANCLE_BTN: false,
        submitEventHandler: () => action.DATA.submitEventHandler(),
      };
    case "alertInfo/OPEN_CONFIRM":
      return {
        ...state,
        DISPLAY: true,
        TEXT: action.DATA.TEXT,
        CANCLE_BTN: true,
        submitEventHandler: () => action.DATA.submitEventHandler(),
      };
    case "alertInfo/CLOSE":
      return {
        DISPLAY: false,
        TEXT: "",
        CANCLE_BTN: false,
        SUBMIT_BTN: true,
        submitEventHandler: function () {},
      };
    default:
      return state;
  }
};

export default alertInfo;
