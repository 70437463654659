import React, { useState } from 'react'
import EmailPopup from '../popup/emailPopup';
/**
 * Version : 1.0
 * 파일명 : EmailLogContent.js
 * 작성일자 : 2022-05-31
 * 작성자 : 권도훈
 */
function EmailLogContent(props) {
    const data = props.list.data.read();

    const [popup, setPopup] = useState(false);
    const [content, setContent] = useState({});

    return (
        <>
            <EmailPopup
                popup={popup}
                setPopup={setPopup}
                content={content}
                setContent={setContent}
            />
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr
                            className='info-email-table-tr cursor'
                            key={idx}
                            onClick={() => {
                                setContent(item);
                                setPopup(true);
                            }}
                        >
                            <td title={item.EMAIL_NUM}>
                                {item.EMAIL_NUM}
                            </td>
                            <td title={item.EMAIL_TITLE}>
                                {item.EMAIL_TITLE}
                            </td>
                            <td title={item.EMAIL_SENDER_NM}>
                                {item.EMAIL_SENDER_NM}
                            </td>
                            <td title={item.EMAIL_SENDER}>
                                {item.EMAIL_SENDER}
                            </td>
                            <td title={item.SEND_DTTM}>
                                {item.SEND_DTTM}
                            </td>
                            <td title={item.CREATE_DTTM}>
                                {item.CREATE_DTTM}
                            </td>
                            <td title={item.CREATE_IP}>
                                {item.CREATE_IP}
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan="7">
                            메일 발송 내역이 없습니다.
                        </td>
                    </tr>
            }
        </>
    )
}

export default EmailLogContent
