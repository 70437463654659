import React from 'react'
/**
 * Version : 1.0
 * 파일명 : LicenseListContent.js
 * 작성일자 : 2022-05-03
 * 작성자 : 권도훈
 */
function LicenseListContent(props) {
    const data = props.list.data.read();

    return (
        <>
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr key={idx}>
                            <td>{item.Subscribe_Dt}</td>
                            <td>{item.EXPIRED_DT}</td>
                            <td>{item.LICENSE_TYPE}</td>
                            <td className='info-licensecode' title={item.LICENSE_CODE}>
                                {item.LICENSE_CODE}
                            </td>
                            <td>{item.GROUP_NM}</td>
                            <td>{item.RES_COUNT}</td>
                            <td>{item.Use_YN === "Y" ? "사용" : "미사용"}</td>
                            <td>{item.SUBSCRIBE_YN === "Y" ? "구독" : "구독해지"}</td>
                            <td>{item.Create_Dttm}</td>
                            <td>{item.Create_IP}</td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan="10">
                            회의이용권이 없습니다.
                        </td>
                    </tr>
            }
        </>
    )
}

export default LicenseListContent
