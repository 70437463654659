import React from 'react'
/**
 * Version : 1.0
 * 파일명 : RecordList.js
 * 작성일자 : 2022-05-23
 * 작성자 : 권도훈
 */
function RecordList(props) {
    const data = props.record.data.read();

    return (
        <>
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr
                            className='rec-content-tr'
                            key={idx}
                        >
                            <td
                                className='rec-content-td text-center'
                                onClick={e => e.stopPropagation()}
                            >
                                <input
                                    type='checkbox'
                                    className='cursor'
                                    checked={props.chkList.includes(item.REC_NUM)}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.setChkList([...props.chkList, item.REC_NUM]);
                                        } else {
                                            props.setChkList(props.chkList.filter(code => code !== item.REC_NUM));
                                        }
                                    }}
                                />
                            </td>
                            <td className='rec-content-td' title={item.REC_NUM}>
                                <span>
                                    {item.REC_NUM}
                                </span>
                            </td>
                            <td className='rec-content-td' title={item.SCHEDULE_NUM}>
                                <span>
                                    {item.SCHEDULE_NUM}
                                </span>
                            </td>
                            <td className='rec-content-td' title={item.USER_NM}>
                                <span>
                                    {item.USER_NM}
                                </span>
                            </td>
                            <td className='rec-content-td' title={item.REC_TITLE}>
                                <span>
                                    {item.REC_TITLE}
                                </span>
                            </td>
                            <td className='rec-content-td' title={item.RECDAY}>
                                <span>
                                    {item.RECDAY}
                                </span>
                            </td>
                            <td className='rec-content-td text-center' title={item.STHM}>
                                {item.STHM}
                            </td>
                            <td className='rec-content-td text-center' title={item.EDHM}>
                                {item.EDHM}
                            </td>
                            <td className='rec-content-td text-center' title={item.DURTM}>
                                {item.DURTM}
                            </td>
                            <td className='rec-content-td' title={item.GROUP_NM}>
                                <span>
                                    {item.GROUP_NM}
                                </span>
                            </td>
                            <td className='rec-content-td text-center'>
                                <span>
                                    {item.DOWNLOAD_CNT}
                                </span>
                            </td>
                            <td className='rec-content-td text-center'>
                                <span>
                                    {item.DEL_YN}
                                </span>
                            </td>
                            <td className='rec-content-td' title={item.CREATE_DTTM}>
                                <span>
                                    {item.CREATE_DTTM}
                                </span>
                            </td>
                            <td className='rec-content-td' title={item.CREATE_IP}>
                                <span>
                                    {item.CREATE_IP}
                                </span>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td
                            colSpan='14'
                            className='rec-content-empty text-center'
                        >
                            <span>회의영상이 없습니다.</span>
                        </td>
                    </tr>
            }
        </>
    )
}

export default RecordList
