import React, { useState } from 'react'
import PaylogPopup from '../popup/paylogPopup';
import PaylogStatus from '../popup/paylogStatus';
/**
 * Version : 1.0
 * 파일명 : PaylogContent.js
 * 작성일자 : 2022-05-02
 * 작성자 : 권도훈
 */
function PaylogContent(props) {
    const data = props.list.data.read();

    //state
    const [detail, setDetail] = useState(false);
    const [orderNum, setOrderNum] = useState("");
    const [statusParams, setStatusParams] = useState({
        status: "",
        orderNum: "",
        msg: ""
    });

    //가격 포맷
    const amountFormat = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원";
    }
    return (
        <>
            <PaylogPopup
                isShow={detail}
                setIsShow={setDetail}
                orderNum={orderNum}
                setOrderNum={setOrderNum}
                userNum={props.userNum}
            />

            <PaylogStatus
                statusParams={statusParams}
                setStatusParams={setStatusParams}
                userNum={props.userNum}
                setIsSearch={props.setIsSearch}
            />

            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr key={idx}>
                            <td>
                                {
                                    item.STATUS === "DONE"
                                        ? item.APPROVED_AT
                                        : item.CREATE_DT
                                }
                            </td>
                            <td>{amountFormat(item.TOTAL_AMOUNT)}</td>
                            <td>{item.METHOD_TYPE}</td>
                            <td
                                className='cursor'
                                onClick={() => {
                                    setStatusParams({
                                        status: item.STATUS,
                                        orderNum: item.ORDER_NUM,
                                        msg: item.FAIL_MSG
                                    });
                                }}
                            >
                                {
                                    item.STATUS === "DONE"
                                        ? "결제성공"
                                        : item.STATUS === "FAIL"
                                            ? "결제실패"
                                            : "결제취소"
                                }
                            </td>
                            <td>
                                {
                                    item.METHOD_TYPE === "카드"
                                        ? item.COMPANY + "(" + item.CARD_TYPE + "/" + item.OWNER_TYPE + ")(" + item.CARD_NUMBER + ")"
                                        : item.BANK
                                }
                            </td>
                            <td>
                                {item.ORDER_NAME}
                            </td>
                            <td>
                                {
                                    item.STATUS === "DONE" && item.METHOD_NUM !== ""
                                        ?
                                        <button
                                            className='info-content-table-btn cursor'
                                            onClick={() => {
                                                const url = "https://pebbling.co.kr"
                                                window.open(url + "/recipt?ordernum=" + item.ORDER_NUM)
                                            }}
                                        >
                                            보기
                                        </button>
                                        :
                                        <></>
                                }
                            </td>
                            <td>
                                {
                                    item.STATUS === "DONE" && item.METHOD_NUM !== ""
                                        ?
                                        <button
                                            className='info-content-table-btn cursor'
                                            onClick={() => {
                                                setOrderNum(item.ORDER_NUM);
                                                setDetail(true);
                                            }}
                                        >
                                            보기
                                        </button>
                                        :
                                        <></>
                                }
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan="8">
                            결제내역이 없습니다.
                        </td>
                    </tr>
            }
        </>
    )
}

export default PaylogContent
