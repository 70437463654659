import React, { Suspense, useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "css/editorreact.css";
import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';
import { fetchAxiosEvent } from '../axiosEvent';
import htmlToDraft from 'html-to-draftjs';
import Loading from 'component/common/loading';
import axios from 'axios';
import { closeAlert, openAlert, openConfirm } from 'actions';
import { useDispatch } from 'react-redux';
/**
 * Version : 1.0
 * 파일명 : FaqPopup.js
 * 작성일자 : 2022-05-19
 * 작성자 : 권도훈
 */
function EditorComponent(props) {
    const data = props.result.data.read();
    const linkifyPlugin = createLinkDetectionPlugin();
    const editorRef = useRef();
    const [init, setInit] = useState(data.ANSWER_CONTENT !== undefined ? true : false);
    const [showMoreFile, setShowMoreFile] = useState(false);

    useEffect(() => {
        if (init) {
            if (data.ANSWER_CONTENT !== undefined) {
                const blocksFromHtml = htmlToDraft(data.ANSWER_CONTENT);
                const { contentBlocks, entitiyMap } = blocksFromHtml;
                const contentstate = ContentState.createFromBlockArray(contentBlocks, entitiyMap);
                props.setEditorState(EditorState.createWithContent(contentstate));
            }

            setInit(false);
        }
    }, [data, data.ANSWER_CONTENT, init, props]);

    return (
        <>
            <div
                className={
                    showMoreFile
                        ? 'info-popup-faq-line file open cursor'
                        : 'info-popup-faq-line file cursor'
                }
                onClick={() => setShowMoreFile(!showMoreFile)}
            >
                <div className='info-popup-faq-file-title'>
                    첨부파일(
                    <font>
                        {
                            data.FILE_INFO !== undefined
                                ?
                                data.FILE_INFO.length + "개"
                                :
                                "0개"
                        }
                    </font>
                    )

                    <img
                        className={
                            showMoreFile
                                ? "info-popup-faq-file-title-icon open"
                                : "info-popup-faq-file-title-icon"
                        }
                        src='/img/icon/common/picker_down.png'
                        alt=''
                    />
                </div>

                <div
                    className={
                        showMoreFile
                            ? 'info-popup-faq-file-area open'
                            : 'info-popup-faq-file-area'
                    }
                >
                    {
                        data.FILE_INFO !== undefined && data.FILE_INFO.length > 0
                            ?
                            data.FILE_INFO.map((file, idx) => (
                                <div key={idx}>
                                    <a
                                        className={
                                            showMoreFile
                                                ? 'info-popup-faq-file-info open'
                                                : 'info-popup-faq-file-info'
                                        }
                                        href={file.FILE_URL}
                                        download={file.ORI_FILE_NM}
                                        onClick={e => e.stopPropagation()}
                                    >
                                        {file.ORI_FILE_NM}
                                    </a>
                                </div>
                            ))
                            :
                            <></>
                    }
                </div>
            </div>

            <Editor
                ref={editorRef}
                wrapperClassName="wrapper-class"
                editorClassName={
                    showMoreFile
                        ? "info-popup-faq-editor open scrollbar"
                        : "info-popup-faq-editor scrollbar"
                }
                toolbarClassName="toolbar-class"
                toolbar={{
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: {
                    },
                    history: { inDropdown: false },
                }}
                placeholder="내용을 작성해주세요."
                localization={{
                    locale: 'ko',
                }}
                editorState={props.editorState}
                onEditorStateChange={props.onEditorStateChange}
                plugins={[linkifyPlugin]}
            />
        </>
    );
};

function FaqPopup(props) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const editorToHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    /**
     * dispatch
     */
    const dispatch = useDispatch();

    const [init, setInit] = useState(true);
    const [data, setData] = useState({});
    const [answer, setAnswer] = useState({});
    const [content, setContent] = useState("");

    const [showMoreFile, setShowMoreFile] = useState(false);

    useEffect(() => {
        if (init && props.popup) {
            setData(props.data);
            setAnswer(
                fetchAxiosEvent("GET",
                    "/user/admin/faq/answer",
                    {
                        config: {
                            params: {
                                SUPPORT_NUM: props.data.SUPPORT_NUM
                            }
                        }
                    }
                )
            );
            setInit(false);
        }
    }, [init, props.data, props.popup, props.userNum]);

    const convertHTML = (value) => {
        return (
            <div
                className='info-popup-faq-content scrollbar'
                dangerouslySetInnerHTML={{ __html: value }}
            />
        )
    }
    //close event
    const closeEventHanlder = () => {
        props.setPopup(false);
        setAnswer("");
        setEditorState(EditorState.createEmpty());
        setContent("");
        setShowMoreFile(false);
        setInit(true);
    }

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState || '');
        setContent(editorToHtml);
    };
    //save event
    const saveEventHandler = (supportNum) => {
        const data = {

        }
        const config = {
            params: {
                SUPPORT_NUM: supportNum,
                ANSWER_CONTENT: content
            }
        }

        axios.post("/user/admin/faq/answer", data, config)
            .then(res => {
                if (res.status === 200) {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg success'>
                                    알림!
                                </div>
                                <div className='info-msg'>
                                    저장됐습니다.
                                </div>
                            </>,
                        submitEventHandler: () => {
                            closeEventHanlder();
                            props.setIsSearch(true);
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                }
            })
            .catch(() => {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                알림!
                            </div>
                            <div className='info-msg'>
                                저장을 실패했습니다.
                            </div>
                        </>,
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            })
    }

    ReactModal.setAppElement("body");

    return (
        <ReactModal
            isOpen={props.popup}
            className="info-popup-faq"
            overlayClassName="popupOverlay"
            animationType="fade"
            transparent={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => closeEventHanlder()}
        >
            <div className='info-popup-faq-inner'>
                <img
                    className='info-popup-status-close cursor'
                    src='/img/icon/common/close_black.png'
                    alt=''
                    onClick={() => closeEventHanlder()}
                />
                <p className='info-popup-suggest-title'>
                    고객 FAQ
                </p>

                <div className='info-popup-faq-container'>
                    <div className='info-popup-faq-line'>
                        제목 : {data.SUPPORT_TITLE}
                    </div>

                    <div
                        className={
                            showMoreFile
                                ? 'info-popup-faq-line file open cursor'
                                : 'info-popup-faq-line file cursor'
                        }
                        onClick={() => setShowMoreFile(!showMoreFile)}
                    >
                        <div className='info-popup-faq-file-title'>
                            첨부파일(
                            <font>
                                {
                                    data.FILE_INFO !== undefined
                                        ?
                                        data.FILE_INFO.length + "개"
                                        :
                                        "0개"
                                }
                            </font>
                            )

                            <img
                                className={
                                    showMoreFile
                                        ? "info-popup-faq-file-title-icon open"
                                        : "info-popup-faq-file-title-icon"
                                }
                                src='/img/icon/common/picker_down.png'
                                alt=''
                            />
                        </div>

                        <div
                            className={
                                showMoreFile
                                    ? 'info-popup-faq-file-area open'
                                    : 'info-popup-faq-file-area'
                            }
                        >
                            {
                                data.FILE_INFO !== undefined && data.FILE_INFO.length > 0
                                    ?
                                    data.FILE_INFO.map((file, idx) => (
                                        <div key={idx}>
                                            <a
                                                className='info-popup-faq-file-info'
                                                href={file.FILE_URL}
                                                download={file.ORI_FILE_NM}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                {file.ORI_FILE_NM}
                                            </a>
                                        </div>
                                    ))
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                    {convertHTML(data.SUPPORT_CONTENT, "CONTENT")}
                </div>

                <div className='info-popup-faq-container editor'>
                    <div className='info-popup-faq-line'>
                        답변
                    </div>

                    <Suspense
                        fallback={
                            <div className="info-content-body-loading">
                                <Loading />
                            </div>
                        }
                    >
                        {
                            !init && props.popup
                                ?
                                <EditorComponent
                                    result={answer}
                                    editorState={editorState}
                                    setEditorState={setEditorState}
                                    onEditorStateChange={onEditorStateChange}
                                />
                                :
                                <></>
                        }
                    </Suspense>
                </div>

                <div className='info-popup-faq-btn-area'>
                    <button
                        className='cursor'
                        onClick={() => saveEventHandler(data.SUPPORT_NUM)}
                    >
                        등 록
                    </button>
                </div>

            </div>
        </ReactModal >
    )
}

export default FaqPopup
