/**
 * Version : 1.0
 * 작성일자 : 2022-03-10
 * 작성자 : 강연승
 * 설명 : 페이징
 */
const initalState = {
  ROW_COUNT: 10,
  PAGE_COUNT: 0,
  TOTAL: 0,
};

const pagingInfo = (state = initalState, action) => {
  switch (action.type) {
    case "pagingInfo/UPDATE":
      return {
        ROW_COUNT: action.DATA.ROW_COUNT,
        PAGE_COUNT: action.DATA.PAGE_COUNT,
        TOTAL: action.DATA.TOTAL,
      };
    default:
      return state;
  }
};

export default pagingInfo;
