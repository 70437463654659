import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import "css/landing.css";
import { Cookies } from "react-cookie";
import axios from "axios";
/**
 * Version : 1.0
 * 작성일자 : 2022-02-16
 * 작성자 : 권도훈
 * 설명 : Landing
 * 수정일자 : 2022-03-03
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
 * 수정일자 : 2022-05-27
 * 수정자 : 강연승
 * 수정내역 : 로그인시 이동 페이지 변경
 */
function Landing() {
  const [ID, setID] = useState("");
  const [PW, setPW] = useState("");

  const handleInputPW = e => {
    setPW(e.target.value);
  };
  const handleInputID = e => {
    setID(e.target.value);
  };

  const history = useHistory();

  var cookies = useMemo(() => {
    return new Cookies();
  }, []);

  const createToken = () => {
    const config = {
      params: {
        Request_ID: ID,
      },
    };

    axios.post("/user/token", null, config).then(res => {
      if (res.status === 200) {
        cookies.set(process.env.REACT_APP_COOKIE_AUTH, res.data.AUTH, {
          path: "/",
          maxAge: 60 * 60,
        });
        cookies.set(process.env.REACT_APP_COOKIE_REFRESH, res.data.REFRESH, {
          path: "/",
          maxAge: 24 * 60 * 60 * 30,
        });

        history.push("/app/main");
      }
    });
  };

  /**
   * LOGIN
   */

  const goLogin = () => {
    if (ID === "") {
      alert("아이디가 비었습니다.");
      return false;
    } else if (PW === "") {
      alert("비밀번호가 비었습니다.");
      return false;
    }

    const params = {
      Request_ID: ID,
      Request_PW: PW,
    };
    console.log(params);
    axios
      .post("/user/login", params, null)
      .then(res => {
        if (res.status === 200) {
          cookies.set(process.env.REACT_APP_COOKIE_TYPE, "ADMIN", {
            path: "/",
            maxAge: 60 * 60,
          });
          createToken();

        }
      })
      .catch(() => {
        alert("아이디 혹은 비밀번호가 틀렸습니다.");
      });
  };
  return (
    <div
      className="ld_div"
      style={{
        backgroundImage: "url(/img/login_background.png)",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <section className="ld_section">
        <article className="ld_article">
          <img src="/img/logo.png" alt="LOGO" />

          <div className="ld_login_div">
            <div className="ld_login_id">
              <font>아이디</font>
              <input
                type="text"
                value={ID}
                onChange={handleInputID}
                autoFocus
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    goLogin();
                  }
                }}
              />
            </div>

            <div className="ld_login_pw">
              <font>비밀번호</font>
              <input
                type="password"
                value={PW}
                onChange={handleInputPW}
                onKeyPress={e => {
                  if (e.key === "Enter") {
                    goLogin();
                  }
                }}
              />
            </div>

            <button className="ld_login_btn" onClick={() => goLogin()}>
              로그인
            </button>
          </div>
        </article>
      </section>
    </div>
  );
}

export default Landing;
