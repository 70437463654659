import React from "react";
import ReactModal from "react-modal";
import "css/schedule.css";
/**
 * Version : 1.0
 * 작성일자 : 2022-03-10
 * 작성자 : 강연승
 * 설명 : 예약 관리 - 예약현황 상세 팝업
 */
function ScheduleDetailPopup(props) {
  /**
   * url 설정
   */
  let server_url =
    "https://lgbhh6lxo8.execute-api.ap-northeast-2.amazonaws.com/admin";

  const fileInfoList = fileInfo => {
    const scheduleFileList = fileInfo.split("|");
    let rawfileInfo = [];
    let fileInfoList = [];

    for (let i = 0; i < scheduleFileList.length; i++) {
      rawfileInfo = scheduleFileList[i].split(";");
      const fileInfo = {
        fileNum: rawfileInfo[0],
        fileNm: rawfileInfo[1],
        fileURL: rawfileInfo[2],
        fileDownNm: rawfileInfo[3],
        fileSize: rawfileInfo[4],
      };
      fileInfoList.push(fileInfo);
    }
    return fileInfoList;
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="scheduleDetailPopup" //모달 ClassName
      overlayClassName="scheduleDetailPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="popup_background">
        <div className="popup_header">
          <div className="popup_close_btn">
            <img
              src="/img/icon/common/close_black.png"
              alt="close"
              onClick={props.close}
            />
          </div>
          <div className="platformImg">
            {props.data.API_TYPE === "CD0501" ? (
              <img src="/img/icon/schedule/webex.png" alt="webex" />
            ) : (
              <img src="/img/icon/schedule/zoom.png" alt="zoom" />
            )}
          </div>
        </div>
        <div className="popup_body">
          <div className="scheduleTitle">{props.data.TITLE}</div>
          {props.data.REC_YN === "Y" ? (
            <div className="scheduleRecord">
              회의 시작과 동시에 녹화를 진행합니다.
            </div>
          ) : null}
          <div className="popup_contents">
            <div className="popup_contents_div">
              <div className="contentsLabel">
                <div className="middot" />
                <div className="labelText">호스트</div>
              </div>
              <div className="contents hostInfo">
                <div className="hostNM">{props.data.HOST_NM}</div>
                <div className="hostEmail">{props.data.HOST_ID}</div>
              </div>
            </div>

            {props.data.GROUP_YN === "Y" ? (
              <div className="popup_contents_div">
                <div className="contentsLabel">
                  <div className="middot" />
                  <div className="labelText">그룹 명</div>
                </div>
                <div className="contents">{props.data.GROUP_NM}</div>
              </div>
            ) : null}

            <div className="popup_contents_div">
              <div className="contentsLabel">
                <div className="middot" />
                <div className="labelText">시작일시</div>
              </div>
              <div className="contents">
                {props.data.END_YN === "Y"
                  ? props.data.HISTORY_START_TIME.replace("T", " ")
                  : props.data.START_TIME.replace("T", " ")}
              </div>
            </div>

            <div className="popup_contents_div">
              <div className="contentsLabel">
                <div className="middot" />
                <div className="labelText">종료일시</div>
              </div>
              <div className="contents">
                {props.data.END_YN === "Y"
                  ? props.data.HISTORY_END_TIME.replace("T", " ")
                  : props.data.END_TIME.replace("T", " ")}
              </div>
            </div>

            <div className="popup_contents_div">
              <div className="contentsLabel">
                <div className="middot" />
                <div className="labelText">내용</div>
              </div>
              <div
                className={
                  props.data.CONTENTS === ""
                    ? "arrContentsEmpty"
                    : "arrContents scrollbar"
                }
              >
                {props.data.CONTENTS === "" ? "-" : props.data.CONTENTS}
              </div>
            </div>

            {Array.isArray(props.data.PARTICIPANTS) &&
            props.data.PARTICIPANTS.length > 0 ? (
              <div className="popup_contents_div">
                <div className="contentsLabel">
                  <div className="middot" />
                  <div className="labelText">참석자</div>
                </div>
                <div className="arrContents scrollbar">
                  {props.data.PARTICIPANTS.map(data => (
                    <div key={data.EMAIL} className="participants">
                      {(props.data.END_YN === "Y" && data.NAME === "") ||
                      (props.data.END_YN === "N" && data.PRTCP_NM === "") ? (
                        <div className="partiEmail">{data.EMAIL}</div>
                      ) : (
                        <>
                          <div
                            className="partiNM"
                            title={
                              props.data.END_YN === "Y"
                                ? data.NAME
                                : data.PRTCP_NM
                            }
                          >
                            {props.data.END_YN === "Y"
                              ? data.NAME
                              : data.PRTCP_NM}
                          </div>
                          <div className="partiEmail" title={data.EMAIL}>
                            {data.EMAIL}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}

            {props.data.FILE_INFO !== "" &&
            props.data.FILE_INFO !== undefined ? (
              <div className="popup_contents_div">
                <div className="contentsLabel">
                  <div className="middot" />
                  <div className="labelText">첨부파일</div>
                </div>
                <div className="arrContents scrollbar">
                  {fileInfoList(props.data.FILE_INFO).map(data => (
                    <div
                      key={data.fileNum}
                      className="fileInfo"
                      onClick={() =>
                        window.open(
                          server_url +
                            "/file/downloadFile?filename=" +
                            data.fileDownNm +
                            "&downfilename=" +
                            data.fileNm,
                          "_blank"
                        )
                      }
                    >
                      <div className="fileNM">{data.fileNm}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default ScheduleDetailPopup;
