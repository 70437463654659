import { closeAlert, openAlert, updatePagingInfo } from 'actions';
import axios from 'axios';
import "css/question.css";
import Editorreact from 'component/common/editorreact';
import Paging from 'component/common/paging';
import Questionpopupread from 'component/support/questionpopupread';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import SearchTextInputSupport from 'component/support/searchTextinputsupport';
import SearchSelectBox from 'component/common/searchSelectBox';

function Question() {

  /**
 * Version : 1.0
 * 작성일자 : 2022-04-12
 * 작성자 : 정지윤
 * 설명 : 문의사항 답변 등록/수정 화면
 */

        /**
   * REDUX
   */
         const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
         /**
          * DISPATCH
          */
         const dispatch = useDispatch();
    // const [inputmember, setinputmember] = useState('');
    const [inputid, setinputid] = useState('');
    const [inputname, setinputname] = useState('');
    const [inputcategory, setinputcategory] = useState('');
    const [inputanswer, setinputanswer] = useState('');
    const [inputstartdate, setinputstartdate] = useState('');
    const [inputenddate, setinputenddate] = useState('');
   
    // const handleInputmember = (e) =>{
    //     setinputmember(e.target.value);
    //   }
    const handleInputid = (e)=>{
        setinputid(e.target.value);
    }
    const handleInputname = (e)=>{
        setinputname(e.target.value);
    }
      const handleInputcategory = (e) =>{
        setinputcategory(e.target.value);
      }
      const handleInputanswer = (e) =>{
        setinputanswer(e.target.value);
      }
      const handleInputstartdate = (e) =>{
        setinputstartdate(e.target.value);
      }
      const handleInputenddate = (e) =>{
        setinputenddate(e.target.value);
      }


        const [selectType, setSelectType] = useState("");  
        const typeList = [
            { value: "", label: "문의유형" },
            { value: 'CD1101', label: "비회원" },
            { value: "CD1103", label: "회원정보" },
            { value: 'CD1104', label: "불편/개선" },
            { value: "CD1105", label: "오류" },
            { value: 'CD1106', label: "기타문의" },
          ];
          const [selectType2, setSelectType2] = useState("");  
          const typeList2 = [
            { value: "", label: "답변여부" },
            { value: 'Y', label: "YES" },
            { value: "N", label: "NO" },
            ];
      

      const [searchOption, setSearchOption] = useState({
        text: "" });
      const [searchOptionname, setSearchOptionname] = useState({
          text: "" });


      const[questionlist, setquestionlist] = useState([]);
      const [totalquestion, settotalquestion] = useState([]);
      const[questionlistexcel, setquestionlistexcel] = useState([]);

      const getquestionList = useCallback((inputid,inputname,inputcategory,inputanswer,inputstartdate,inputenddate)=>{
        let startdate = '';
        if(inputstartdate !== undefined &&inputstartdate !== ''){
          startdate = inputstartdate+'T00:00:00'
        }
        else{
          startdate= null;
        }
        let enddate = '';
        if(inputenddate !== undefined&&inputenddate !== ''){
          enddate = inputenddate+'T23:59:59'
        }
        else{
          enddate= null;
        }
        let idinput = '';
        if(inputid !== undefined &&inputid !== ''){
            idinput = inputid
          }
          else{
            idinput= null;
          }
          let nameinput = '';
          if(inputname !== undefined&&inputname !== ''){
            nameinput = inputname
          }
          else{
            nameinput = null;
          }

          let categoryinput = '';
          if(inputcategory !== undefined&&inputcategory !== ''){
            categoryinput = inputcategory
          }
          else{
            categoryinput = null;
          }

          let answerinput = '';
          if(inputanswer !== undefined&&inputanswer !== ''){
            answerinput = inputanswer
          }
          else{
            answerinput = null;
          }
        
        

        let config = {
            params : {
                // member : inputmember,
                input_id : idinput,
                input_name : nameinput,
                starttime : startdate,
                endtime : enddate,
                category : categoryinput,
                answer : answerinput,
                skip : PAGING_STATE.PAGE_COUNT,
                amount : PAGING_STATE.ROW_COUNT,
            }
        }
        axios.get("/support/getQuestionList", config).then((res) =>
        {
            if(res.status===200){
                setquestionlist(res.data)
            }
        })




      },[PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT])

      const countQuestion = useCallback((inputid,inputname,inputcategory,inputanswer,inputstartdate,inputenddate) =>{
          let startdate = '';
          if(inputstartdate !== undefined &&inputstartdate !== ''){
            startdate = inputstartdate+'T00:00:00'
          }
          else{
            startdate= null;
          }
          let enddate = '';
          if(inputenddate !== undefined&&inputenddate !== ''){
            enddate = inputenddate+'T23:59:59'
          }
          else{
            enddate= null;
          }
          let idinput = '';
         if(inputid !== undefined &&inputid !== ''){
            idinput = inputid
          }
          else{
            idinput= null;
          }
          let nameinput = '';
          if(inputname !== undefined&&inputname !== ''){
            nameinput = inputname
          }
          else{
            nameinput = null;
          }
          let categoryinput = '';
          if(inputcategory !== undefined&&inputcategory !== ''){
            categoryinput = inputcategory
          }
          else{
            categoryinput = null;
          }
          let answerinput = '';
          if(inputanswer !== undefined&&inputanswer !== ''){
            answerinput = inputanswer
          }
          else{
            answerinput = null;
          }
        let config = {
            params : {
                // member : inputmember,
                input_id : idinput,
                input_name : nameinput,
                starttime : startdate,
                endtime : enddate,
                category : categoryinput,
                answer : answerinput,
            }
        }
        axios.get("/support/countQuestion", config).then((res) =>{
            if(res.status ===200){
              settotalquestion(res.data)
                const paging_obj={
                    ROW_COUNT: PAGING_STATE.ROW_COUNT,
                    PAGE_COUNT: 0,
                    TOTAL: res.data
                };
                dispatch(updatePagingInfo(paging_obj))
            }
        })
      },[dispatch])


      const getquestionListexcel = useCallback((inputid,inputname,inputcategory,inputanswer,inputstartdate,inputenddate)=>{
        let startdate = '';
        if(inputstartdate !== undefined &&inputstartdate !== ''){
          startdate = inputstartdate+'T00:00:00'
        }
        else{
          startdate= null;
        }
        let enddate = '';
        if(inputenddate !== undefined&&inputenddate !== ''){
          enddate = inputenddate+'T23:59:59'
        }
        else{
          enddate= null;
        }
        let idinput = '';
        if(inputid !== undefined &&inputid !== ''){
            idinput = inputid
          }
          else{
            idinput= null;
          }
          let nameinput = '';
          if(inputname !== undefined&&inputname !== ''){
            nameinput = inputname
          }
          else{
            nameinput = null;
          }

          let categoryinput = '';
          if(inputcategory !== undefined&&inputcategory !== ''){
            categoryinput = inputcategory
          }
          else{
            categoryinput = null;
          }

          let answerinput = '';
          if(inputanswer !== undefined&&inputanswer !== ''){
            answerinput = inputanswer
          }
          else{
            answerinput = null;
          }
        
        

        let config = {
            params : {
                // member : inputmember,
                input_id : idinput,
                input_name : nameinput,
                starttime : startdate,
                endtime : enddate,
                category : categoryinput,
                answer : answerinput,
                skip : 0,
                amount : totalquestion
            }
        }
        axios.get("/support/getQuestionList", config).then((res) =>
        {
            if(res.status===200){
                setquestionlistexcel(res.data)
            }
        })




      },[totalquestion])
      useEffect(() =>{
        getquestionListexcel(searchOption.text,searchOptionname.text,selectType,selectType2,inputstartdate,inputenddate)
      },[getquestionListexcel])

      useEffect(() =>{
        getquestionList(searchOption.text,searchOptionname.text,selectType,selectType2,inputstartdate,inputenddate)
      },[getquestionList])
      useEffect(() =>{
        countQuestion(searchOption.text,searchOptionname.text,selectType,selectType2,inputstartdate,inputenddate)
      },[countQuestion])

      const onClickSearch = (inputid,inputname,inputcategory,inputanswer,inputstartdate,inputenddate) =>{
        if(inputstartdate > inputenddate){
          const obj = {
            TEXT:  <>
            <div className='info-msg err'>
                오류!
            </div>
            <div className='info-msg'>
                종료 일자는 시작 일자보다
            </div>
            <div className='info-msg'>
                이후의 날짜로 설정해주세요!
            </div>
        </>,
            submitEventHandler: () => {dispatch(closeAlert()); }
          };
          dispatch(openAlert(obj));
        }else{
          getquestionList(inputid,inputname,inputcategory,inputanswer,inputstartdate,inputenddate);
          countQuestion(inputid,inputname,inputcategory,inputanswer,inputstartdate,inputenddate)
        }

          }

  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [questioninfo, setquestioninfo] = useState({});
  const [qusetiontype, setquestiontype] = useState('');
  const openDetailPopup = (data,type) => {
    setIsOpenDetail(true);
    setquestioninfo(data);
    setquestiontype(type)
  };
  const closeDetailPopup = () => {
    setIsOpenDetail(false);
    getquestionList(inputid,inputname,selectType,selectType2,inputstartdate,inputenddate)
    countQuestion(inputid,inputname,selectType,selectType2,inputstartdate,inputenddate)
  };  


  const downloadExcel = () =>{
    let arr=[];
  
    for(let i = 0; i<questionlistexcel.length; i++){
      if(questionlistexcel[i].USER_Nm ===undefined){
        questionlistexcel[i].USER_Nm = '비회원'
      }
      if(questionlistexcel[i].PHONE_NUM===undefined){
        questionlistexcel[i].PHONE_NUM = ''
      }  
      if(questionlistexcel[i].EMAIL_ADDRESS===undefined){
        questionlistexcel[i].EMAIL_ADDRESS = ''
      }
      if(questionlistexcel[i].User_ID === undefined){
       questionlistexcel[i].User_ID = ''
      }
      if(questionlistexcel[i].SUPPORT_TITLE === undefined){
        questionlistexcel[i].SUPPORT_TITLE = ''
       }
       if(questionlistexcel[i].USER_NUM === undefined){
        questionlistexcel[i].USER_NUM = ''
       }
      arr.push({
        EMAIL_ADDRESS : questionlistexcel[i].EMAIL_ADDRESS,
        PHONE_NUM : questionlistexcel[i].PHONE_NUM,
        USER_NUM : questionlistexcel[i].USER_NUM,
        USER_Nm : questionlistexcel[i].USER_Nm,
        SUPPORT_TYPE : questionlistexcel[i].SUPPORT_TYPE,
        SUPPORT_TITLE : questionlistexcel[i].SUPPORT_TITLE,
        SUPPORT_PROGRESS : questionlistexcel[i].SUPPORT_PROGRESS,

        User_ID : questionlistexcel[i].User_ID,

        CREATE_DTTM : questionlistexcel[i].CREATE_DTTM.replace("T", " "),
        CREATE_IP : questionlistexcel[i].CREATE_IP
      })
    }
    
    const data = arr;
    const config = {
      responseType: "blob"
    };
    axios.post("/support/excel/Question", data, config)
    .then(res => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], {type : res.headers["content-type"]})
      );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "PEBBLE_문의사항.xlsx");
        document.body.appendChild(link);
        link.click();
    })
  
  
  }



  return (
    <div>

{isOpenDetail ? (
        <Questionpopupread
          open={isOpenDetail}
          close={closeDetailPopup}
          data={questioninfo}
          questiontype = {qusetiontype}
        />
      ) : null}
        <div className='Question_header'>
      <div className='questionmaintitle'>문의사항</div>
      <div className='questionsubtitle'>{"고객센터 > 문의사항(회원/비회원)"}</div>
      </div>

        <div className='Question_Search'>

                {/* <div className='Question_Search_member' onChange={handleInputmember} value={inputmember}>
                    <select className='Question_Select_member'>
                    <option value=''>회원구분</option>
                    <option value='Y'>회원</option>
                    <option value='N'>비회원</option>
                    </select>
                </div> */}


                <div className='Question_Searchbar' onKeyDown={e=>{
              if(e.key ==="Enter"){
                onClickSearch(searchOption.text,searchOptionname.text,selectType,selectType2,inputstartdate,inputenddate)
              }
            }}>
                <SearchTextInputSupport
                    label="아이디를 입력해주세요"
                    type="long"
                    value={searchOption.text}
                    setValue={value => setSearchOption({ ...searchOption, text: value })}
                      // setPage={setPage}
                      // page={page}
                      // setIsSearch={setIsSearch}
                />
                </div>
                <div className='Question_Searchbar'  onKeyDown={e=>{
              if(e.key ==="Enter"){
                onClickSearch(searchOption.text,searchOptionname.text,selectType,selectType2,inputstartdate,inputenddate)
              }
            }}>
                <SearchTextInputSupport
                    label="사용자명을 입력해주세요"
                    type="long"
                    value={searchOptionname.text}
                    setValue={value => setSearchOptionname({ ...searchOptionname, text: value })}
                />
                </div>
                <div className='Question_Search_category' onChange={handleInputcategory} value={inputcategory}>
                <SearchSelectBox
                    label="문의유형"
                    type="short"
                    list={typeList}
                    value={selectType}
                    setValue={value => setSelectType(value)}
                  />    
                </div>

                <div className='Question_Search_answer'>
                <SearchSelectBox
                    label="답변여부"
                    type="short"
                    list={typeList2}
                    value={selectType2}
                    setValue={value => setSelectType2(value)}
                  />
                </div>

                <div className='Question_Search_startdate'>
                <input type='date' className='Question_startdate_input' onChange={handleInputstartdate} value={inputstartdate}></input>
                </div>

                <div className='Question_Search_enddate'>
                <input type='date' className='Question_enddate_input' onChange={handleInputenddate} value={inputenddate}></input>
                </div>

                <div className='Question_Search_button'>
                <div className='Question_button_tosearch cursor' onClick={()=>onClickSearch(searchOption.text,searchOptionname.text,selectType,selectType2,inputstartdate,inputenddate)}>검색</div>
                </div>
        </div>
        <div className='Notice_function'>
          <div></div>
          <div className='downloadbutton cursor' onClick={() => downloadExcel()}>
             <img type='image' alt='다운로드버튼' className='downloadbuttonimage' src='/img/icon\common/exceldown.png'></img>
                <span className='downloadbuttontext'>엑셀 다운로드</span>
             </div> 
        </div>
        <section className='question_table'>
        <table>
              <thead>
                <tr className='tablehead'>
                  <th className='questionno' >NO</th>
                  <th className='questionmember'>회원구분</th>
                  <th className='questionemail'>사용자 아이디(이메일)</th>
                  <th className='questionphone'>연락처</th>
                  <th className='questionmember_no'>사용자 번호</th>
                  <th className='questionname'>사용자 명</th>
                  <th className='questiontype'>문의유형</th>
                  <th className='questiontitle'>제목</th>
                  <th className='questionanswer'>답변 여부</th>
                  <th className='questionwritedate'>생성일시</th>
                  <th className='questionwriteip'>생성자IP</th>
                </tr>
              </thead>

              <tbody>          
                  {questionlist.map((data,index) =>(
                    <tr key={data.Support_NUM} className='tablebody'>
                    <td className='questionno'>
                    {PAGING_STATE.PAGE_COUNT + index + 1}
                    </td>
                  <th className='questionmember'>{data.SUPPORT_TYPE === 'CD1101' ? <div>비회원</div> :<div>회원</div>}</th>
                  {data.SUPPORT_TYPE === 'CD1101' ? 
                   <th className='questionemail cursor' onClick={()=> openDetailPopup(data,1)}> <div>{data.EMAIL_ADDRESS}</div></th>
                 
                  :
                  <th className='questionemail cursor' onClick={()=> openDetailPopup(data,0)}> <div>{data.User_ID}</div></th>
                  }
              
                  <th className='questionphone'>{data.PHONE_NUM}</th>
                  <th className='questionmember_no'>{data.USER_NUM}</th>
                  <th className='questionname'> {data.USER_Nm}</th>
                  <th className='questiontype'>{data.SUPPORT_TYPE === 'CD1101' ? <div>비회원 문의</div> :
                  data.SUPPORT_TYPE === 'CD1103' ? <div>회원가입/탈퇴, 정보변경</div> :
                  data.SUPPORT_TYPE === 'CD1104' ? <div>불편/개선</div> :
                  data.SUPPORT_TYPE === 'CD1105' ? <div>오류</div> :
                                                   <div>기타문의</div> 
                  }</th>
                  <th className='questiontitle'>{data.SUPPORT_TITLE}</th>
                  <th className='questionanswer'>{data.SUPPORT_PROGRESS === 'CD1201' ?<div>미답변</div> : <div>답변완료</div>}</th>
                  <th className='questionwritedate'>{data.CREATE_DTTM.replace("T", " ")}</th>
                  <th className='questionwriteip'>{data.CREATE_IP}</th>
                  </tr>  
               )
                  )}
                
              </tbody>
     </table>

        </section>
        <div style={{
              marginTop : "27px",
              marginBottom : "46px"
            }}>
            <Paging />
            </div>
        
</div>
  )
}

export default Question