import React from 'react'
/**
 * Version : 1.0
 * 작성일자 : 2022-02-16
 * 작성자 : 권도훈
 * 설명 : Statistics
 */
function Statistics() {
    return (
        <section className='ct_section'>
            차트
        </section>
    )
}

export default Statistics
