import React, { useCallback, useEffect, useRef, useState } from 'react'
import ReactModal from "react-modal";
import { useDispatch } from 'react-redux';
import { closeAlert, openAlert, openConfirm } from 'actions';
import axios from 'axios';
import Loading from 'component/common/loading';
/**
 * Version : 1.0
 * 파일명 : EditUserInfo.js
 * 작성일자 : 2022-05-16
 * 작성자 : 권도훈
 * 설명 : 사용자 정보 수정
 */
function EditUserInfo(props) {
    ReactModal.setAppElement("body");
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    /**
     * state
     */
    const [isFocus, setIsFocus] = useState(true);
    const [data, setData] = useState({
        CREATE_DTTM: "",
        DEL_YN: "",
        EXPIRED_POPUP: "",
        FILE_NUM: "",
        FIRST_LOGIN_YN: "",
        LANGUAGE: "",
        OUTSTANDING_YN: "",
        PLATFORM_CODE: "",
        TRIAL_POPUP: "",
        UNLOCODE: "",
        USER_ID: "",
        USER_NM: "",
        USER_NUM: "",
        USER_TYPE: "",
        USE_YN: "Y"
    });
    const [isEdit, setIsEdit] = useState(false);
    const [img, setImg] = useState({
        src: "",
        name: "",
        file: "",
        size: ""
    });
    const [loading, setLoading] = useState(false);
    //timezone state
    const [timezonePopup, setTimezonePopup] = useState(false)
    const [userTimeZone, setUserTimeZone] = useState({
        CONTINENT: "",
        CITY: ""
    });
    const [continent, setContinent] = useState([]);
    const [city, setCity] = useState([]);
    //login type state
    const [loginTypePopup, setLoginTypePopup] = useState(false);
    /**
     * useRef
     */
    const nameRef = useRef();
    const languageRef = useRef();
    const platformRef = useRef();

    const loginType = ["PEBBLE", "NAVER", "KAKAO", "GOOGLE"];

    const closeTimezonePopup = useCallback(() => {
        if (userTimeZone.CITY === "") {
            setUserTimeZone({
                CONTINENT: "",
                CITY: ""
            });
        }
        setTimezonePopup(false);
    }, [userTimeZone.CITY]);

    const closeLoginTypePopup = useCallback(() => {
        setLoginTypePopup(false);
    }, []);
    /**
     * useEffect
     */
    //init
    useEffect(() => {
        if (props.isOpen) {
            setImg({
                src: "",
                name: "",
                file: "",
                size: ""
            });
            setIsFocus(true);
            setIsEdit(false);
            setData(props.data)
        }
    }, [props.data, props.isOpen]);
    //focus
    useEffect(() => {
        if (isEdit && isFocus) {
            nameRef.current.focus();
            setIsFocus(false);
        }
    }, [isEdit, isFocus]);

    useEffect(() => {
        if (timezonePopup) {
            if (userTimeZone.CONTINENT === "") {
                setUserTimeZone({
                    ...userTimeZone,
                    CONTINENT: "Africa"
                })
            }

            window.addEventListener("click", closeTimezonePopup);
            return () => {
                window.removeEventListener("click", closeTimezonePopup);
            };
        }
    }, [closeTimezonePopup, timezonePopup, userTimeZone]);

    useEffect(() => {
        if (loginTypePopup) {
            window.addEventListener("click", closeLoginTypePopup);
            return () => {
                window.removeEventListener("click", closeLoginTypePopup);
            };
        }
    }, [closeLoginTypePopup, loginTypePopup]);
    /**
     * event
     */
    //closeEventHandler
    const closeHandler = () => {
        setIsEdit(!isEdit);
        setTimezonePopup(false);
        setUserTimeZone({
            CONTINENT: "",
            CITY: ""
        });
        setLoginTypePopup(false);
        props.setIsOpen(false);
    }
    //deleteEventHandler
    const deleteEventHandler = (requestType) => {
        const config = {
            params: {
                REQUEST_TYPE: requestType,
                USER_NUM: props.userNum
            }
        }

        setLoading(true);

        axios.delete("/user/admin/info", config)
            .then(res => {
                setLoading(false);
                if (res.status === 200) {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg success'>
                                    알림!
                                </div>
                                <div className='info-msg'>
                                    변경사항이 저장됐습니다.
                                </div>
                            </>,
                        submitEventHandler: () => {
                            props.setIsEditUserInfo(true);
                            closeHandler();
                            setLoading(false);
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                }
            })
            .catch(() => {
                setLoading(false);
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                알림!
                            </div>
                            <div className='info-msg'>
                                저장을 실패했습니다.
                            </div>
                        </>,
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            })
    }
    //imgUploadHandler
    const imgUploadHandler = e => {
        for (var i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i].type.replace(/\/.*/, "") !== "image") {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                알림!
                            </div>
                            <div className='info-msg'>
                                올릴 수 없는 파일 형식입니다!
                            </div>
                        </>,
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
                return;
            }

            if (e.target.files[i]) {
                let reader = new FileReader();

                reader.readAsDataURL(e.target.files[i]);

                reader.onloadend = () => {
                    const base64 = reader.result;
                    if (base64) {
                        var base64Sub = base64.toString();
                        var strBase64 = base64Sub.split("base64,");

                        let img = new Image();
                        img.src = reader.result;

                        let name = e.target.files[0].name;
                        let file = strBase64[1];
                        let size = e.target.files[0].size;

                        img.onload = function () {
                            setImg(
                                {
                                    src: img.src,
                                    name: name,
                                    file: file,
                                    size: size,
                                }
                            );
                        };
                    }

                    e.target.value = null;
                };
            }
        }
    };
    //platform convertor
    const platformConvert = () => {
        const currentPlatform = platformRef.current.value.toUpperCase();

        switch (currentPlatform) {
            case "WEBEX": return "CD0501";
            case "ZOOM": return "CD0502";
            case "CHIME": return "CD0503";
            case "PEXIP": return "CD0504";
            default: return "";
        }
    }
    //saveEvnet
    const saveEventHandler = () => {
        const formData = new FormData();

        let userInfo = {
            USER_NUM: props.userNum,
            USER_NM: nameRef.current.value,
            USER_TYPE: data.USER_TYPE,
            USER_TIMEZONE:
                userTimeZone.CONTINENT !== ""
                    ? userTimeZone.CONTINENT + "/" + userTimeZone.CITY
                    : data.UNLOCODE,
            USER_LANGUAGE: languageRef.current.value,
            USER_PLATFORM: platformConvert(),
            USER_IMG: [{
                file: img.file,
                name: img.name,
                size: img.size
            }]
        };

        formData.append("userInfo", JSON.stringify(userInfo));

        setLoading(true);

        axios.put("/user/admin/info", formData)
            .then(res => {
                if (res.status === 200) {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg success'>
                                    알림!
                                </div>
                                <div className='info-msg'>
                                    변경사항이 저장됐습니다.
                                </div>
                            </>,
                        submitEventHandler: () => {
                            props.setIsEditUserInfo(true);
                            closeHandler();
                            setLoading(false);
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                }
            })
            .catch(() => {
                setLoading(false);
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                알림!
                            </div>
                            <div className='info-msg'>
                                저장을 실패했습니다.
                            </div>
                        </>,
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            })
    }

    const getContinentList = () => {
        axios.get("/profile/continent")
            .then(res => {
                if (res.status === 200) {
                    setContinent(res.data);
                }
            })
            .catch(() => {
            })
    };

    const getCityList = () => {
        axios.get("/profile/city")
            .then(res => {
                if (res.status === 200) {
                    setCity(res.data);
                }
            })
            .catch(() => {
            })
    };

    return (
        <ReactModal
            isOpen={props.isOpen}
            className="info-popup-userinfo"
            overlayClassName="popupOverlay"
            animationType="fade"
            transparent={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => closeHandler()}
        >
            <div className='info-popup-userinfo-inner'>
                {
                    loading
                        ?
                        <Loading />
                        :
                        <>
                            <img
                                className='info-popup-userinfo-close cursor'
                                src='/img/icon/common/close_black.png'
                                alt=''
                                onClick={() => closeHandler()}
                            />

                            <div className='info-popup-userinfo-img-area'>
                                {
                                    data.FILE_NUM === "" && img.src === ""
                                        ?
                                        <div
                                            className={
                                                isEdit
                                                    ? 'info-content-img cursor'
                                                    : 'info-content-img'
                                            }
                                            onClick={
                                                () => {
                                                    if (isEdit) {
                                                        document.getElementById("profile_sidebar_btn_imgUpload").click();
                                                    }
                                                }
                                            }
                                        >
                                            NO IMAGE
                                        </div>
                                        :
                                        <img
                                            className={
                                                isEdit
                                                    ? 'info-content-img cursor'
                                                    : 'info-content-img'
                                            }
                                            onClick={
                                                () => {
                                                    if (isEdit) {
                                                        document.getElementById("profile_sidebar_btn_imgUpload").click();
                                                    }
                                                }
                                            }
                                            src={
                                                isEdit
                                                    ? img.src
                                                    : data.FILE_NUM
                                            }
                                            alt=''
                                        />
                                }

                                <label
                                    className='info-popup-userinfo-input-img'
                                    htmlFor="imgupload"
                                    id="profile_sidebar_btn_imgUpload"
                                />

                                <input
                                    className='info-popup-userinfo-input-img'
                                    type="file"
                                    accept="image/*"
                                    id='imgupload'
                                    onChange={imgUploadHandler}
                                />

                                <div className='info-popup-userinfo-btn-area'>
                                    {
                                        isEdit
                                            ?
                                            <>
                                                <button
                                                    className='info-popup-userinfo-del-btn cursor'
                                                    onClick={() => {
                                                        const obj = {
                                                            TEXT:
                                                                <>
                                                                    <div className='info-msg err'>
                                                                        알림!
                                                                    </div>
                                                                    <div className='info-msg'>
                                                                        해당 사용자를 탈퇴처리 하시겠습니까?
                                                                    </div>
                                                                </>,
                                                            submitEventHandler: () => {
                                                                deleteEventHandler("DELETE");
                                                                dispatch(closeAlert());
                                                            },
                                                        };
                                                        dispatch(openConfirm(obj));
                                                    }}
                                                    disabled={
                                                        data.DEL_YN === 'Y' && data.USE_YN === 'N'
                                                            ? true
                                                            : false
                                                    }
                                                >
                                                    탈 퇴
                                                </button>

                                                <button
                                                    className='info-popup-userinfo-del-btn cursor'
                                                    onClick={() => {
                                                        const obj = {
                                                            TEXT:
                                                                <>
                                                                    <div className='info-msg err'>
                                                                        알림!
                                                                    </div>
                                                                    <div className='info-msg'>
                                                                        해당 사용자를 휴면처리 하시겠습니까?
                                                                    </div>
                                                                </>,
                                                            submitEventHandler: () => {
                                                                deleteEventHandler("DORMANT");
                                                                dispatch(closeAlert());
                                                            },
                                                        };
                                                        dispatch(openConfirm(obj));
                                                    }}
                                                    disabled={
                                                        data.USE_YN === 'N'
                                                            ? true
                                                            : false
                                                    }

                                                >
                                                    휴 면
                                                </button>

                                                <button
                                                    className='info-popup-userinfo-cancle-btn cursor'
                                                    onClick={() => {
                                                        setTimezonePopup(false);
                                                        setUserTimeZone({
                                                            CONTINENT: "",
                                                            CITY: ""
                                                        });
                                                        setLoginTypePopup(false);
                                                        setIsEdit(!isEdit);
                                                    }}
                                                >
                                                    취 소
                                                </button>
                                            </>

                                            :
                                            <></>
                                    }

                                    <button
                                        className='info-popup-userinfo-edit-btn cursor'
                                        onClick={() => {
                                            if (isEdit) {
                                                const obj = {
                                                    TEXT:
                                                        <>
                                                            <div className='info-msg err'>
                                                                알림!
                                                            </div>
                                                            <div className='info-msg'>
                                                                변경사항을 저장하시겠습니까?
                                                            </div>
                                                        </>,
                                                    submitEventHandler: () => {
                                                        saveEventHandler();
                                                        dispatch(closeAlert());
                                                    },
                                                };
                                                dispatch(openConfirm(obj));
                                            } else {
                                                getContinentList();
                                                getCityList();
                                                setImg({
                                                    ...img,
                                                    src: data.FILE_NUM
                                                });
                                                setIsEdit(true);
                                                setIsFocus(true);
                                            }
                                        }}
                                    >
                                        {
                                            isEdit
                                                ?
                                                "저 장"
                                                :
                                                "수 정"
                                        }
                                    </button>
                                </div>
                            </div>

                            <table className='info-popup-userinfo-table'>
                                <tbody>
                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>
                                                사용자 고유번호
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {data.USER_NUM}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>사용자 아이디</div>
                                        </td>

                                        <td>
                                            <div>{data.USER_ID}</div>
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>가입 일시</div>
                                        </td>

                                        <td>
                                            <div>{data.CREATE_DTTM}</div>
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>사용자 명</div>
                                        </td>

                                        <td>
                                            {
                                                isEdit
                                                    ?
                                                    <input
                                                        type="text"
                                                        defaultValue={data.USER_NM}
                                                        ref={nameRef}
                                                        placeholder="사용자 이름을 입력해주세요."
                                                    />
                                                    :
                                                    <div>{data.USER_NM}</div>
                                            }
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>가입방식</div>
                                        </td>

                                        <td>
                                            <div>
                                                {data.USER_TYPE}
                                            </div>
                                            {/* {
                                                isEdit
                                                    ?
                                                    <div
                                                        className='info-popup-userinfo-timezone cursor'
                                                        onClick={() => setLoginTypePopup(true)}
                                                    >
                                                        {data.USER_TYPE}
                                                        {
                                                            loginTypePopup
                                                                ?
                                                                <div
                                                                    className="info-popup-loginType"
                                                                    onClick={e => e.stopPropagation()}
                                                                >
                                                                    <div className='scrollbar'>
                                                                        {
                                                                            loginType.map((data, idx) => (
                                                                                <div
                                                                                    className='info-popup-timezone-item cursor'
                                                                                    key={idx}
                                                                                    onClick={() => {
                                                                                        setLoginTypePopup(false);
                                                                                    }}
                                                                                >
                                                                                    {data}
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        {data.USER_TYPE}
                                                    </div>
                                            } */}
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>타임존</div>
                                        </td>

                                        <td>
                                            {
                                                isEdit
                                                    ?
                                                    <div
                                                        className='info-popup-userinfo-timezone cursor'
                                                        onClick={() => {
                                                            setTimezonePopup(true);
                                                        }}
                                                    >
                                                        {
                                                            userTimeZone.CITY !== "" && userTimeZone.CONTINENT !== ""
                                                                ? userTimeZone.CONTINENT + "/" + userTimeZone.CITY
                                                                : data.UNLOCODE
                                                        }
                                                        {
                                                            timezonePopup
                                                                ?
                                                                <div
                                                                    className="info-popup-timezone"
                                                                    onClick={e => e.stopPropagation()}
                                                                >
                                                                    <div className='scrollbar'>
                                                                        {
                                                                            continent.map((data, idx) => {
                                                                                if (data.CONTINENT !== userTimeZone.CONTINENT) {
                                                                                    return (
                                                                                        <div
                                                                                            className='info-popup-timezone-item cursor'
                                                                                            key={"CONTINENT_LIST_" + idx}
                                                                                            onClick={() => {
                                                                                                setUserTimeZone({
                                                                                                    CONTINENT: data.CONTINENT,
                                                                                                    CITY: ""
                                                                                                });
                                                                                            }}
                                                                                        >
                                                                                            {data.CONTINENT}
                                                                                        </div>
                                                                                    )
                                                                                } else {
                                                                                    return null
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>

                                                                    <div className='scrollbar'>
                                                                        {
                                                                            city.map((data, idx) => {
                                                                                if (data.CONTINENT === userTimeZone.CONTINENT) {
                                                                                    return (
                                                                                        <div
                                                                                            className='info-popup-timezone-item cursor'
                                                                                            key={"CITY_LIST_" + idx}
                                                                                            onClick={() => {
                                                                                                setUserTimeZone({
                                                                                                    ...userTimeZone,
                                                                                                    CITY: data.CITY
                                                                                                });
                                                                                                setTimezonePopup(false);
                                                                                            }}
                                                                                        >
                                                                                            {data.CITY}
                                                                                        </div>
                                                                                    )
                                                                                } else {
                                                                                    return null
                                                                                }
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                    :
                                                    <div>{data.UNLOCODE}</div>
                                            }
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>언어</div>
                                        </td>

                                        <td>
                                            {
                                                isEdit
                                                    ?
                                                    <input
                                                        type="text"
                                                        defaultValue={data.LANGUAGE}
                                                        ref={languageRef}
                                                        placeholder="사용자 언어를 입력해주세요."
                                                    />
                                                    :
                                                    <div>{data.LANGUAGE}</div>
                                            }
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>첫 로그인 여부</div>
                                        </td>

                                        <td>
                                            <div>
                                                {
                                                    data.FIRST_LOGIN_YN === 'Y'
                                                        ? "미접속"
                                                        : "접속 완료"
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>이용권 팝업 읽음 여부</div>
                                        </td>

                                        <td>
                                            <div>
                                                {
                                                    data.EXPIRED_POPUP === 'Y'
                                                        ? "미확인"
                                                        : "확인 완료"
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>미 결제 여부</div>
                                        </td>

                                        <td>
                                            <div>
                                                {
                                                    data.OUTSTANDING_YN === 'Y'
                                                        ?
                                                        "결제 완료"
                                                        :
                                                        "결제 실패"
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>기본 화상플랫폼</div>
                                        </td>

                                        <td>
                                            {
                                                isEdit
                                                    ?
                                                    <input
                                                        type="text"
                                                        defaultValue={data.PLATFORM_CODE}
                                                        ref={platformRef}
                                                        placeholder="사용자 기본 화상플랫폼을 입력해주세요."
                                                    />
                                                    :
                                                    <div>{data.PLATFORM_CODE}</div>
                                            }
                                        </td>
                                    </tr>

                                    <tr className="info-popup-userinfo-tr">
                                        <td>
                                            <div>휴면 및 탈퇴 여부</div>
                                        </td>

                                        <td>
                                            <div>
                                                {
                                                    data.USE_YN === 'Y'
                                                        ? "이용 중"
                                                        :
                                                        data.DEL_YN === 'N'
                                                            ?
                                                            "휴면"
                                                            :
                                                            "탈퇴"
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </>
                }
            </div>
        </ReactModal >
    )
}

export default EditUserInfo