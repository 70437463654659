import { display } from '@mui/system';
import { closeAlert, openAlert, openConfirm } from 'actions';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

function Licenseaccountpopup(props) {

    const dispatch = useDispatch();

    const [use_yn, setuse_yn] = useState(''); 

    const handleuse_yn = (e) =>{
        setuse_yn(e.target.value);
      }

      const updateuse = useCallback((num, data)=>{
        let config = {
          params : {
            target : num,
            data : data,
          }
        }
        
        axios.put("/license/admin/UpdateAccount", null, config).then((res) =>{
          if(res.status === 200){
                console.log("수정되었습니다.")
          }
        })
      },[])

  const onclicksubmit = () => {
    if(use_yn === ''){
      props.close();
    }else{
      const obj = {
        TEXT: "수정되었습니다.",
        submitEventHandler: () => {dispatch(closeAlert());setuse_yn(''); props.close();}
      };
      dispatch(openAlert(obj));
      updateuse(props.num, use_yn);

    }
      return;
  }


  const onclickdelete = () =>{
    let config = {
      params : {
        target : props.num,
      }
    }

    const obj = {
      TEXT: "삭제하시겠습니까 ?",
      submitEventHandler: () => {
        axios.put("/license/admin/deleteAccount", null, config).then((res) =>
        {
            if(res.status === 200){
              const obj = {
                TEXT: "삭제되었습니다.",
                submitEventHandler: () => {dispatch(closeAlert());props.close(); }
              };
              dispatch(openAlert(obj));
            }
        }        
        )
        dispatch(closeAlert());
      },
    };
    dispatch(openConfirm(obj));
  }
  const clickclose = () =>{
    setuse_yn('') 
    props.close();

  }


  return (
    <ReactModal
    isOpen={props.open} //모달 Open(Boolean)
    
    className="licenseaccountPopup" //모달 ClassName
    overlayClassName="licenseaccountPopup_Overlay" //모달오버레이 ClassName
    onRequestClose={() => {
        setuse_yn('')
      props.close();

    }}
    shouldCloseOnEsc={true}
    animationType="fade"
    transparent={true}
    closeTimeoutMS={200}
  >   
        <div style={{textAlign : "right"}}>
    <img  className='cursor'
          style={{marginRight : "10px",
                  marginTop : "10px"}}
          src="/img/icon/common/close_black.png"
          alt=''
          onClick={() => clickclose() }/>
      </div>
      <div className='licenseaccountbody'>
          <div  className='licenseaccountID'>
        <div className='licenseaccounttitle'>아이디 :</div>
        <div> {props.id}</div>
          </div>

          <div className='licenseaccounttype'>
        <div className='licenseaccounttitle'>플랫폼 : </div>
        <div> {props.type === 'CD0501' ?  
                          <div>Webex</div>:
                             <div>Zoom</div> }</div>
          </div>

          <div style={{display : "flex"}}>
            <div className= 'licenseaccounttitleuse'>사용여부 :</div>
          <div>
          <input type='radio' className='exposeo' name='Expose_YN_CLICK' 
          style={props.use ==='Y' && use_yn===''? {display:"none"} : use_yn==='Y'? {display:"none"} : {marginLeft:"0px"}}
          value="Y" onChange={handleuse_yn} checked={props.use ==='Y' && use_yn===''? true : use_yn==='Y'? true : false}/>
          <label className='exposeo'><img style={{marginLeft:"1px"}} alt='' src={props.use ==='Y' && use_yn===''? '/img/icon/common/change.png' : use_yn==='Y'? '/img/icon/common/change.png' : ''} />사용</label>
          </div>
          <div style={{marginLeft:"10px"}}>
          <input type='radio'className='exposeno' name='Expose_YN_CLICK'
          style={props.use ==='N' && use_yn===''? {display:"none"} : use_yn==='N'? {display:"none"} : {marginLeft:"0px"}}          
          value="N" onChange={handleuse_yn} checked={props.use ==='N' && use_yn===''? true : use_yn==='N'? true : false}/>
          <label className='exposeno'><img alt='' src={props.use ==='N' && use_yn===''? '/img/icon/common/change.png' : use_yn==='N'? '/img/icon/common/change.png' : ''} />사용하지 않음</label>
          </div>
          </div>
      </div>

      <div style={{display : "flex "}}>
        <div className='licenseaccountsubmit cursor' onClick={() =>onclicksubmit()}>
            수정
        </div>
        <div className='licenseaccountdelete cursor'onClick={() => onclickdelete()}>
             삭제 
        </div>
      </div>
  </ReactModal>
  )
}

export default Licenseaccountpopup