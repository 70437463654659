import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "css/group.css";
import axios from "axios";
import Loading from "component/common/loading";
// import GroupPopupPaging from "component/group/groupPopupPaging";
/**
 * Version : 1.0
 * 작성일자 : 2022-05-16
 * 작성자 : 강연승
 * 설명 : 그룹 관리 - 그룹 미팅호스트 현황 상세 팝업
 */
function GroupHostPopup(props) {
  useEffect(() => {
    if (props.open) {
      getGroupMeetingHostTotal(props.groupInfo.groupNum);
      getGroupMeetingHost(props.groupInfo.groupNum);
    }
  }, [props.open]);

  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    ROW_COUNT: 5,
    PAGE_COUNT: 0,
    TOTAL: 0,
  });
  const [meetingHostList, setMeetingHostList] = useState([]);

  /**
   * 그룹 현황 조회
   */
  const getGroupMeetingHostTotal = groupNum => {
    let param = {
      GROUP_NUM: groupNum,
    };
    axios
      .get("/group/admin/groupMeetingHostCnt", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          const paging_obj = {
            ...pageInfo,
            TOTAL: res.data.TOTAL,
          };
          setPageInfo(paging_obj);
        }
      })
      .catch(() => {});
  };
  /**
   * 그룹 현황 조회
   */
  const getGroupMeetingHost = groupNum => {
    setLoading(true);
    let param = {
      GROUP_NUM: groupNum,
      ROW_COUNT: pageInfo.ROW_COUNT,
      PAGE_COUNT: pageInfo.PAGE_COUNT,
    };
    axios
      .get("/group/admin/groupMeetingHostList", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          setMeetingHostList(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getGroupMeetingHost(props.groupInfo.groupNum);
  }, [pageInfo.ROW_COUNT, pageInfo.PAGE_COUNT]);

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="groupMeetingHostPopup" //모달 ClassName
      overlayClassName="groupMeetingHostPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close("HOST");
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="popup_background">
        <div className="popup_header">
          <div className="popup_close_btn">
            <img
              src="/img/icon/common/close_black.png"
              alt="close"
              onClick={() => props.close("HOST")}
            />
          </div>
          <div className="popup_title">그룹 호스트 현황</div>
        </div>
        <div className="popup_body">
          <div className="groupInfo">
            <div className="label_groupNum">그룹 번호 (그룹명) : </div>
            <div className="value_groupNum">
              {props.groupInfo.groupNum + " (" + props.groupInfo.groupNm + ")"}
            </div>
          </div>
          <div className="group_list scrollbar">
            <table className="group_table">
              <colgroup>
                <col width="2%" />
                <col width="10%" />
                <col width="13%" />
                <col width="10%" />
                <col width="11%" />
                <col width="3%" />
                <col width="3%" />
                <col width="20%" />
                <col width="28%" />
              </colgroup>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>사용자번호</th>
                  <th>사용자 아이디</th>
                  <th>호스트 명</th>
                  <th>가입일자</th>
                  <th style={{ textAlign: "center" }}>사용여부</th>
                  <th style={{ textAlign: "center" }}>거절여부</th>
                  <th>생성일시</th>
                  <th>생성자IP</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="9" style={{ textAlign: "center" }}>
                      <Loading />
                    </td>
                  </tr>
                ) : Array.isArray(meetingHostList) &&
                  meetingHostList.length > 0 ? (
                  meetingHostList.map((data, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {pageInfo.PAGE_COUNT + index + 1}
                      </td>
                      <td>{data.USER_NUM}</td>
                      <td className="groupList_userMail" title={data.USER_MAIL}>
                        {data.USER_MAIL}
                      </td>
                      <td className="groupList_hostNm" title={data.HOST_NM}>
                        {data.HOST_NM}
                      </td>
                      <td>{data.JOIN_DT}</td>
                      <td style={{ textAlign: "center" }}>{data.USE_YN}</td>
                      <td style={{ textAlign: "center" }}>{data.DENY_YN}</td>
                      <td>{data.CREATE_DT.replace("T", " ")}</td>
                      <td>{data.CREATE_IP}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" style={{ textAlign: "center" }}>
                      조회된 데이터가 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {/* {pageInfo.TOTAL === 0 ? null : (
              <div className="group_paging">
                <GroupPopupPaging
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                />
              </div>
            )} */}
          </div>
          <div
            className="popup_btn_confirm"
            onClick={() => props.close("HOST")}
          >
            확인
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default GroupHostPopup;
