import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "css/common.css";
import Landing from "./landing/landing";
import App from "./app/app";
import axios from "axios";
import { Cookies } from "react-cookie";
import Alert from "component/common/alert";
/**
 * Version : 1.0
 * 작성일자 : 2022-02-16
 * 작성자 : 권도훈
 * 설명 : Pebble 기본 파일
 * 수정일자 : 2022-03-03
 * 수정자 : 권도훈
 * 수정내역 : UI 수정
 */
/**
 * Version : 1.0
 * 작성일자 : 2022-02-21
 * 작성자 : 정지윤
 * 설명 : URL,Axios 설정 추가
 */
function Pebble() {
  /**
   * Cookie
   */
  const cookies = new Cookies();
  /**
   * url 설정
   */
  let front_url = "";
  let server_url = "";

  if (process.env.NODE_ENV === "development") {
    front_url =
      process.env.REACT_APP_FRONT_URL + process.env.REACT_APP_FRONT_PORT;
    server_url =
      process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
  } else {
    front_url = process.env.REACT_APP_FRONT_URL;
    server_url = process.env.REACT_APP_SERVER_URL;
  }

  const refreshAuthToken = () => {
    cookies.set(process.env.REACT_APP_COOKIE_AUTH, "refresh", {
      path: "/",
      maxAge: 24 * 60 * 60 * 30,
    });

    const config = {
      params: {
        Request_Token: cookies.get(process.env.REACT_APP_COOKIE_REFRESH),
      },
    };

    axios
      .put("/user/token", null, config)
      .then(res => {
        cookies.remove(process.env.REACT_APP_COOKIE_AUTH);

        if (res.status === 200) {
          cookies.set(process.env.REACT_APP_COOKIE_AUTH, res.data.AUTH, {
            path: "/",
            maxAge: 60 * 60,
          });
          cookies.set(process.env.REACT_APP_COOKIE_TYPE, res.data.TYPE, {
            path: "/",
            maxAge: 60 * 60,
          });

          if (res.data.REFRESH !== undefined) {
            cookies.set(process.env.REACT_APP_COOKIE_REFRESH, res.data.REFRESH, {
              path: "/",
              maxAge: 24 * 60 * 60 * 30,
            });
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };




  /**
   * Axios 설정
   */
  axios.defaults.baseURL = front_url;
  axios.defaults.withCredentials = false;
  axios.interceptors.request.use(
    function (config) {
      if (config.params == null) {
        config.params = {};
      }

      config.url = server_url + config.url;
      config.params.REFRESH = cookies.get(process.env.REACT_APP_COOKIE_REFRESH);
      config.params.AUTHORIZATION = cookies.get(process.env.REACT_APP_COOKIE_AUTH);
      config.params.TYPE = cookies.get(process.env.REACT_APP_COOKIE_TYPE);

      if (
        config.url === server_url + "/user/kakao" ||
        config.url === server_url + "/user/naver" ||
        config.url === server_url + "/user/google" ||
        config.url === server_url + "/user/login" ||
        config.url === server_url + "/user/logout" ||
        config.url === server_url + "/user/member" ||
        config.url === server_url + "/schedule/sendPasswordMail" ||
        config.url === server_url + "/schedule/sendAuthMail" ||
        config.url === server_url + "/user/checkUseableAccount" ||
        config.url === server_url + "/user/checkAuthCode" ||
        config.url === server_url + "/user/error" ||
        config.url === server_url + "/user/token" ||
        config.url === server_url + "/payment/product" ||
        config.url === server_url + "/schedule/reDirectZoomHostUrl" ||
        config.url === server_url + "/support" ||
        config.url === server_url + "/support/gusetsup" ||
        config.url === server_url + "/support/sendguestMail" ||
        config.url === server_url + "/payment/recipt" ||
        config.url === server_url + "/support/loginadmin" ||
        config.url === server_url + "/user/tokenAdmin" ||
        config.url === server_url + "/support/getlogininfo"



      ) {
        return config;
      }

      if (cookies.get(process.env.REACT_APP_COOKIE_AUTH) === undefined) {
        if (cookies.get(process.env.REACT_APP_COOKIE_REFRESH) !== undefined) {
          refreshAuthToken();
          return;
        } else {
          window.location.href = "/";

          if (cookies.get(process.env.REACT_APP_COOKIE_AUTO_LOGIN) !== undefined) {
            cookies.remove(process.env.REACT_APP_COOKIE_AUTO_LOGIN, { path: "/" });
          }
          if (cookies.get(process.env.REACT_APP_COOKIE_TYPE) !== undefined) {
            cookies.remove(process.env.REACT_APP_COOKIE_TYPE, { path: "/" });
          }
          return;
        }
      } else {
        return config;
      }


    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      if (response.headers.check_token !== undefined) {
        if (response.headers.check_token === "FAIL") {
          cookies.remove(process.env.REACT_APP_COOKIE_TYPE, { path: "/" });
          cookies.remove(process.env.REACT_APP_COOKIE_AUTH, { path: "/" });
          cookies.remove(process.env.REACT_APP_COOKIE_REFRESH, { path: "/" });
          window.location.href = "/";
        }
      }

      if (response.headers.refresh_authorization !== undefined) {
        cookies.remove(process.env.REACT_APP_COOKIE_AUTH, { path: "/" });
        cookies.set(process.env.REACT_APP_COOKIE_AUTH, response.headers.refresh_authorization, {
          path: "/",
          maxAge: 60 * 60,
        });
      }

      if (response.headers.update_refreshtoken !== undefined) {
        cookies.remove(process.env.REACT_APP_COOKIE_REFRESH, { path: "/" });
        cookies.set(process.env.REACT_APP_COOKIE_REFRESH, response.headers.update_refreshtoken, {
          maxAge: 24 * 60 * 60 * 30,
          path: "/",
        });
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  return (
    <>
      <Alert />
      <Router>
        <Switch>
          <Route exact path="/">
            <Landing />
          </Route>

          <Route path="/app">
            <App />
          </Route>

          <Route>
            <div>EMPTY</div>
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default Pebble;