import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux';
import { closeAlert, openAlert } from 'actions';
/**
 * Version : 1.0
 * 파일명 : PaylogPopup.js
 * 작성일자 : 2022-05-17
 * 작성자 : 권도훈
 * 설명 : 상품 결제 팝업
 */
function PaylogStatus(props) {
    /**
     * dispatch
     */
    const dispatch = useDispatch();

    const [init, setInit] = useState(true);
    const [status, setStatus] = useState("");
    const [orderNum, setOrderNum] = useState("");
    const [failMsg, setFailMsg] = useState("");

    useEffect(() => {
        if (props.statusParams.status !== "" && init) {
            setStatus(props.statusParams.status);
            setOrderNum(props.statusParams.orderNum);
            setFailMsg(props.statusParams.msg);
            setInit(false);
        }
    }, [init, props.statusParams.msg, props.statusParams.orderNum, props.statusParams.status]);

    //저장 이벤트
    const saveEventHandler = async () => {
        const config = {
            params: {
                USER_NUM: props.userNum,
                ORDER_NUM: orderNum,
                STATUS_VALUE: status,
                FAIL_MSG: failMsg
            }
        };

        axios.post("/payment/status", null, config)
            .then(res => {
                if (res.status === 200) {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg success'>
                                    성공!
                                </div>
                                <div className='info-msg'>
                                    변경을 변경이 완료됐습니다.
                                </div>
                            </>,
                        submitEventHandler: () => {
                            closeEventHanlder();
                            props.setIsSearch(true);
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                }
            })
            .catch(() => {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                알림!
                            </div>
                            <div className='info-msg'>
                                변경을 실패했습니다.
                            </div>
                        </>,
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            })
    }
    //close event
    const closeEventHanlder = () => {
        props.setStatusParams({
            status: "",
            orderNum: "",
            msg: ""
        });
        setInit(true);
    }

    return (
        <ReactModal
            isOpen={props.statusParams.status !== "" ? true : false}
            className="info-popup-status"
            overlayClassName="popupOverlay"
            animationType="fade"
            transparent={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => closeEventHanlder()}
        >
            <div className='info-popup-status-inner'>
                <img
                    className='info-popup-status-close cursor'
                    src='/img/icon/common/close_black.png'
                    alt=''
                    onClick={() => closeEventHanlder()}
                />

                <p>결제 상태 수정</p>

                <section className='info-popup-status-sec'>
                    <div>
                        <div>
                            결제 상태
                        </div>

                        <div>
                            <input
                                type="radio"
                                defaultChecked={status === "DONE"}
                                onClick={() => setStatus("DONE")}
                                name="status"
                                className="cursor"
                            />
                            <label>결제 성공</label>
                            <input
                                type="radio"
                                defaultChecked={status === "FAIL"}
                                onClick={() => setStatus("FAIL")}
                                name="status"
                                className="cursor"
                            />
                            <label>결제 실패</label>
                            <input
                                type="radio"
                                defaultChecked={status === "CANCLE"}
                                onClick={() => setStatus("CANCLE")}
                                name="status"
                                className="cursor"
                            />
                            <label>결제 취소</label>
                        </div>
                    </div>

                    <div>
                        <div>
                            결제 실패 사유
                        </div>

                        <div>
                            <input
                                readOnly={status === "DONE"}
                                type="text"
                                placeholder='결제 실패 사유를 입력해주세요.'
                                value={failMsg}
                                onChange={e => setFailMsg(e.target.value)}
                            />
                        </div>
                    </div>
                </section>

                <div className='info-popup-status-btn-area'>
                    <button
                        className='cursor'
                        onClick={() => saveEventHandler()}
                    >
                        저장
                    </button>
                </div>
            </div>
        </ReactModal>
    )
}

export default PaylogStatus
