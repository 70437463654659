import React from 'react'
/**
 * Version : 1.0
 * 파일명 : PaymentContent.js
 * 작성일자 : 2022-05-04
 * 작성자 : 권도훈
 */
function PaymentContent(props) {
    const data = props.list.data.read();

    return (
        <>
            {
                data.status === undefined
                    ?
                    <tr>
                        <td>{data.PAYMENT_TYPE}</td>
                        {
                            data.PAYMENT_TYPE === "CARD"
                                ?
                                <>
                                    <td>{data.CARD_NAME}</td>
                                    <td>{data.CARD_COMPANY}</td>
                                    {/* {data.CARD_ISSUE_COMPANY} */}
                                    <td>{data.BILLING_YN === "Y" ? "법인" : "개인/개인법인"}</td>
                                    <td>{data.CARD_NUM}</td>
                                </>
                                :
                                <>
                                    <td>{data.ACCOUNT_NAME}</td>
                                    <td>{data.BANK}</td>
                                    <td>개인</td>
                                    <td>{data.ACCOUNT_NUM}</td>
                                </>
                        }
                        <td>{data.REGISTER_DT}</td>
                        <td>{data.CREATE_DTTM}</td>
                        <td>{data.CREATE_IP}</td>
                    </tr>
                    :
                    <tr>
                        <td colSpan="8">
                            결제수단 정보가 없습니다.
                        </td>
                    </tr>
            }
        </>
    )
}

export default PaymentContent
