import React, { useState, useEffect } from "react";
import "css/meetingStatistics.css";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "actions";
import axios from "axios";
import moment from "moment";
import MeetingMonthlyChart from "component/statistics/meetingMonthlyChart";
import SearchSelectBox from "component/common/searchSelectBox";

/**
 * Version : 1.0
 * 작성일자 : 2022-05-27
 * 작성자 : 강연승
 * 설명 : 통계관리 - 매출통계
 */
function MeetingStatistics() {
  /**
   * useDispatch
   */
  const dispatch = useDispatch();
  /**
   * STATE
   */
  const [meetingMonthlyChart, setMeetingMonthlyChart] = useState([]);
  const [meetingList, setMeetingList] = useState([]);
  const [dateRange, setDateRange] = useState([
    { num: 1, value: "2022-05", label: "2022.05" },
    { num: 2, value: "2022-06", label: "2022.06" },
    { num: 3, value: "2022-07", label: "2022.07" },
    { num: 4, value: "2022-08", label: "2022.08" },
    { num: 5, value: "2022-09", label: "2022.09" },
    { num: 6, value: "2022-10", label: "2022.10" },
    { num: 7, value: "2022-11", label: "2022.11" },
    { num: 8, value: "2022-12", label: "2022.12" },
    { num: 9, value: "2023-01", label: "2023.01" },
    { num: 10, value: "2023-02", label: "2023.02" },
    { num: 11, value: "2023-03", label: "2023.03" },
    { num: 12, value: "2023-04", label: "2023.04" },
    { num: 13, value: "2023-05", label: "2023.05" },
  ]);

  const [searchText, setSearchText] = useState({
    GROUP_NM: "",
    API_TYPE: "",
  });

  const [searchDateRange, setSearchDateRange] = useState({
    START: dateRange[0].value,
    END: dateRange.find(
      i =>
        i.value ===
        new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1 < 10
            ? "0" + (new Date().getMonth() + 1)
            : new Date().getMonth() + 1)
    ).value,
  });

  const getMeetingStatistics = (start, end, option) => {
    if (
      dateRange.find(i => i.value === start).num >
      dateRange.find(i => i.value === end).num
    ) {
      const obj = {
        TEXT: (
          <>
            <div className="info-msg err">오류!</div>
            <div className="info-msg">
              종료 날짜가 시작 날짜보다 더 빠릅니다.
            </div>
            <div className="info-msg">조회 기간을 수정해주세요.</div>
          </>
        ),
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    }
    if (
      dateRange.find(i => i.value === end).num -
        dateRange.find(i => i.value === start).num >
      11
    ) {
      const obj = {
        TEXT: (
          <>
            <div className="info-msg err">오류!</div>
            <div className="info-msg">
              시작 날짜로부터 최대 12개월까지 조회가 가능합니다.
            </div>
            <div className="info-msg">조회 기간을 수정해주세요.</div>
          </>
        ),
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    }
    let url = "/statistics/meeting";
    let params = {
      SEARCH_START: start + "-01T00:00:00",
      SEARCH_END: end + "-" + moment(end).daysInMonth() + "T23:59:59",
    };
    if (option.API_TYPE !== "") {
      params = {
        ...params,
        API_TYPE: option.API_TYPE,
      };
    }
    if (option.GROUP_NM !== "") {
      params = {
        ...params,
        GROUP_NM: option.GROUP_NM,
      };
    }
    axios.get(url, { params: params }).then(res => {
      if (res.status === 200) {
        setMeetingMonthlyChart(res.data.CHART);
        setMeetingList(res.data.LIST);
      }
    });
  };

  useEffect(() => {
    getMeetingStatistics(
      searchDateRange.START,
      searchDateRange.END,
      searchText
    );
    let startDay = new Date("2022-05-01");
    let today = new Date();
    let diff = today - startDay;
    let currDay = 24 * 60 * 60 * 1000;
    let currMonth = currDay * 30;
    let monthDiff = parseInt(diff / currMonth);
    let tmpArr = [];
    for (let i = 0; i <= monthDiff; i++) {
      let tmpObj = {
        num: i + 1,
        value: moment(
          new Date(new Date().setMonth(startDay.getMonth() + i))
        ).format("YYYY-MM"),
        label: moment(
          new Date(new Date().setMonth(startDay.getMonth() + i))
        ).format("YYYY.MM"),
      };
      tmpArr.push(tmpObj);
    }
    setDateRange(tmpArr);
  }, []);

  /**
   * 검색 버튼
   */
  const onSearch = () => {
    getMeetingStatistics(
      searchDateRange.START,
      searchDateRange.END,
      searchText
    );
  };

  const platformList = [
    { value: "", label: "전체" },
    { value: "CD0501", label: "Webex" },
    { value: "CD0502", label: "Zoom" },
  ];

  return (
    <>
      <div className="meetingStatistics_section">
        <div className="meetingStatistics_title">
          <font className="mainTitle">회의통계</font>
          <font className="subTitle">{"통계관리 > 회의통계"}</font>
        </div>
        <div className="meetingStatistics_searchArea">
          {/* <input
            readOnly
            type="text"
            name="START"
            className="meetingStatistics_searchDateRange"
            placeholder="검색 년 월"
            value={searchDateRange.START}
            onChange={onChange}
          /> */}

          <SearchSelectBox
            label="검색 년 월"
            type="long"
            list={dateRange}
            value={searchDateRange.START}
            setValue={value =>
              setSearchDateRange({ ...searchDateRange, START: value })
            }
          />

          <font className="meetingStatistics_dateRange">~</font>

          <SearchSelectBox
            label="검색 년 월"
            type="long"
            list={dateRange}
            value={searchDateRange.END}
            setValue={value =>
              setSearchDateRange({ ...searchDateRange, END: value })
            }
          />

          {/* <input
            readOnly
            type="text"
            name="END"
            className="meetingStatistics_searchDateRange"
            placeholder="검색 년 월"
            value={searchDateRange.END}
            onChange={onChange}
          /> */}

          <SearchSelectBox
            label="종류"
            type="short"
            list={platformList}
            value={searchText.API_TYPE}
            setValue={value =>
              setSearchText({ ...searchText, API_TYPE: value })
            }
          />

          <input
            type="text"
            name="GROUP_NM"
            className="meetingStatistics_searchDateRange"
            placeholder="그룹명"
            value={searchText.GROUP_NM}
            onChange={e =>
              setSearchText({ ...searchText, GROUP_NM: e.target.value })
            }
          />

          <button className="meetingStatistics_searchBtn" onClick={onSearch}>
            검색
          </button>
        </div>
        <div className="meetingStatistics_chartArea">
          <MeetingMonthlyChart meetingMonthlyChart={meetingMonthlyChart} />
        </div>
        <div className="meetingStatistics_excelBtnArea">
          <div className="meetingStatistics_excelDownBtn">
            <img
              className="meetingStatistics_excelIcon"
              src="/img/icon/common/exceldown.png"
              alt="excel"
            />
            <font
              className="meetingStatistics_excelDownText"
              // onClick={() =>
              //   downloadExcel(
              //     searchDate,
              //   )
              // }
            >
              엑셀 다운로드
            </font>
          </div>
        </div>
        <div className="meetingStatistics_meetingList scrollbar">
          <table className="meetingList_table">
            <colgroup>
              <col width="3%" />
              <col width="10%" />
              <col width="10%" />
              <col width="10%" />
              <col width="6%" />
              <col width="15%" />
              <col width="15%" />
              <col width="10%" />
              <col width="16%" />
              <col width="5%" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>NO</th>
                <th>예약 번호</th>
                <th>시작일시</th>
                <th>종료일시</th>
                <th>총 회의 시간</th>
                <th>회의 제목</th>
                <th>그룹 명</th>
                <th>미팅 호스트 명</th>
                <th>미팅호스트 아이디</th>
                <th>화상서비스</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(meetingList) && meetingList.length > 0 ? (
                meetingList.map((data, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td>{data.SCHEDULE_NUM}</td>
                    <td>{data.START_TIME}</td>
                    <td>{data.END_TIME}</td>
                    <td>{data.HISTORY_DURATION + "분"}</td>
                    <td className="meetingList_title" title={data.TITLE}>
                      {data.TITLE}
                    </td>
                    <td
                      className="meetingList_meetingStatisticsNm"
                      title={data.GROUP_NM}
                    >
                      {data.GROUP_NM}
                    </td>
                    <td className="meetingList_hostNm" title={data.HOST_NM}>
                      {data.HOST_NM}
                    </td>
                    <td className="meetingList_hostId" title={data.HOST_ID}>
                      {data.HOST_ID}
                    </td>
                    <td>
                      {data.API_TYPE === "CD0501"
                        ? "Webex"
                        : data.API_TYPE === "CD0502"
                        ? "Zoom"
                        : data.API_TYPE === "CD0503"
                        ? "Chime"
                        : "Pexip"}
                    </td>
                  </tr>
                ))
              ) : meetingList.length === 0 ? (
                <tr>
                  <td colSpan="10" style={{ textAlign: "center" }}>
                    조회된 데이터가 없습니다.
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default MeetingStatistics;
