import React, { forwardRef, useCallback, useEffect, useState } from "react";
import Picker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { getMonth, getYear } from "date-fns";
import "css/schedule.css";
import "css/datepicker.css";
/**
 * Version : 1.0
 * 파일명 : RecordDatepicker.js
 * 작성일자 : 2022-05-24
 * 작성자 : 권도훈
 */
function RecordDatepicker(props) {
    /**
     * useState
     */
    //display text
    const [inputText, setInputText] = useState(new Date());
    //isDisplay
    const [yearView, setYearView] = useState(false);
    const [monthView, setMonthView] = useState(false);
    //range
    const [years, setYears] = useState([]);
    const [months, setMonths] = useState([]);
    //min Value
    const [minDate, setMinDate] = useState(new Date());
    //mouse over
    const [isOver, setIsOver] = useState(false);
    /**
     * useEffect
     */
    //set Range
    useEffect(() => {
        setYears(range(getYear(new Date()) - 10, getYear(new Date()), 1));
        setMonths(["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"]);
    }, []);
    //set Value
    useEffect(() => {
        if (props.type === "START") {
            if (props.date.START_TIME === "") {
                setInputText("");
            } else {
                setInputText(new Date(props.date.START_TIME));
            }
        } else if (props.type === "END") {
            if (props.date.START_TIME !== "") {
                setMinDate(new Date(props.date.START_TIME));
            }
            if (props.date.END_TIME === "") {
                setInputText("");
            } else {
                setInputText(new Date(props.date.END_TIME));
            }
        }
    }, [props]);
    /**
     * component
     */
    //custom display
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <div
            className={
                value !== "" && !isOver
                    ? "datepicker-display focus center cursor"
                    : value !== "" && isOver
                        ? "datepicker-display focus over center cursor"
                        : value === "" && isOver
                            ? "datepicker-display over center cursor"
                            : "datepicker-display center cursor"
            }
            onClick={() => {
                setYearView(false);
                setMonthView(false);
                onClick();
            }}
            onMouseOver={() => setIsOver(true)}
            onMouseLeave={() => setIsOver(false)}
        >
            <span
                className={
                    value !== "" && !isOver
                        ? "datepicker-display-span focus cursor"
                        : value !== "" && isOver
                            ? "datepicker-display-span focus over cursor"
                            : "datepicker-display-span cursor"
                }
            >
                {props.label}
            </span>

            <input
                className={
                    value !== "" && !isOver
                        ? "datepicker-display-input focus cursor"
                        : value !== "" && isOver
                            ? "datepicker-display-input focus over cursor"
                            : value === "" && isOver
                                ? "datepicker-display-input over cursor"
                                : "datepicker-display-input cursor"
                }
                type="text"
                placeholder={props.label}
                value={value}
                readOnly
            />
        </div>
    ));
    /**
     * function
     */
    //range
    const range = (sYear, eYear, step) => {
        let array = [];
        for (let i = eYear; i > sYear; --i) {
            if (!(i % step)) {
                array.push(i);
            }
        }
        return array;
    };

    return (
        <Picker
            dateFormat="yyyy년 MM월 dd일"
            selected={inputText}
            // minDate={props.type === "END" ? minDate : ""}
            onChange={date => {
                let year = date.getFullYear();
                let mon =
                    date.getMonth() + 1 < 10
                        ? "0" + (date.getMonth() + 1)
                        : date.getMonth() + 1;
                let dt = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                let dateStr = year + "-" + mon + "-" + dt;
                setInputText(new Date(dateStr));

                if (props.type === "START") {
                    props.setDate({
                        ...props.date,
                        START: dateStr
                    });
                } else {
                    props.setDate({
                        ...props.date,
                        END: dateStr
                    });
                }
            }}
            className="pebble_datepicker"
            locale={ko}
            placeholderText="날짜 선택"
            customInput={<CustomInput />}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div className="datepicker-hd">
                    <img
                        className="cursor"
                        onClick={decreaseMonth}
                        disabled={prevMonthButtonDisabled}
                        src="/img/icon/common/left.png"
                        alt=""
                    />

                    <div
                        className="datepicker-range year center cursor"
                        onClick={() => {
                            setMonthView(false);
                            setYearView(!yearView);
                        }}
                    >
                        <div className="datepicker-range-inner">
                            <span className="datepicker-range-span">
                                {getYear(date) + "년"}
                            </span>

                            <img
                                className={
                                    yearView
                                        ? "datepicker-range-img up"
                                        : "datepicker-range-img"
                                }
                                src="/img/icon/common/picker_down.png"
                                alt=""
                            />
                        </div>

                        <div
                            className={
                                yearView
                                    ? "datepicker-range-dropbox open react-datepicker-custom-selectbox scrollbar"
                                    : "datepicker-range-dropbox"
                            }
                        >
                            {
                                years.map((option, idx) => (
                                    <div
                                        className="cursor center"
                                        key={idx}
                                        onClick={() => changeYear(option)}
                                    >
                                        <div>
                                            {option}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <div
                        className="datepicker-range month center cursor"
                        onClick={() => {
                            setYearView(false);
                            setMonthView(!monthView);
                        }}
                    >
                        <div className="datepicker-range-inner">
                            <span className="datepicker-range-span">
                                {months[getMonth(date)]}
                            </span>

                            <img
                                className={
                                    monthView
                                        ? "datepicker-range-img up"
                                        : "datepicker-range-img"
                                }
                                src="/img/icon/common/picker_down.png"
                                alt=""
                            />
                        </div>

                        <div
                            className={
                                monthView
                                    ? "datepicker-range-dropbox open react-datepicker-custom-selectbox scrollbar"
                                    : "datepicker-range-dropbox"
                            }
                        >
                            {
                                months.map((option, idx) => (
                                    <div
                                        className="cursor center"
                                        key={idx}
                                        onClick={() => changeMonth(idx)}
                                    >
                                        <div>
                                            {option}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>

                    <img
                        className="cursor"
                        onClick={increaseMonth}
                        disabled={nextMonthButtonDisabled}
                        src="/img/icon/common/right.png"
                        alt=">"
                    />
                </div>
            )}
        />
    );
}

export default RecordDatepicker;