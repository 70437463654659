import Loading from "component/common/loading";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { fetchAxiosEvent } from "../axiosEvent";
import PaylogDetailList from "../content/paylogDetailList";
import PaylogDetailPaging from "../content/paylogDetailPaging";
/**
 * Version : 1.0
 * 파일명 : PaylogPopup.js
 * 작성일자 : 2022-05-17
 * 작성자 : 권도훈
 * 설명 : 상품 결제 팝업
 */
function PaylogPopup(props) {
    const orderNum = props.orderNum;
    const userNum = props.userNum;
    /**
     * state
     */
    const [init, setInit] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [data, setData] = useState();
    const [total, setTotal] = useState();
    const [page, setPage] = useState({
        ROW_COUNT: 10,
        PAGE_COUNT: 0
    });
    //목록 조회
    const getList = useCallback(() => {
        const obj = {
            config: {
                params: {
                    ORDER_NUM: orderNum,
                    ROW_COUNT: page.ROW_COUNT,
                    PAGE_COUNT: page.PAGE_COUNT,
                    USER_NUM: userNum
                }
            }
        };

        setData(fetchAxiosEvent(
            "GET",
            "/payment/detail",
            obj
        ));
    }, [orderNum, page.PAGE_COUNT, page.ROW_COUNT, userNum]);
    //토탈 조회
    const getTotal = useCallback(() => {
        const obj = {
            config: {
                params: {
                    ORDER_NUM: orderNum,
                    USER_NUM: userNum
                }
            }
        };

        setTotal(fetchAxiosEvent(
            "GET",
            "/payment/detail/total",
            obj
        ));
    }, [orderNum, userNum]);

    //초기화
    useEffect(() => {
        if (init && props.isShow) {
            getTotal();
            getList();
            setInit(false);
        } else if (isSearch) {
            getList();
            setIsSearch(false);
        }
    }, [getList, getTotal, init, isSearch, page.PAGE_COUNT, page.ROW_COUNT, props.isShow, props.orderNum, props.userNum]);
    //닫기 이벤트
    const closePopup = () => {
        props.setOrderNum("");
        props.setIsShow(false);
        setInit(true);
        setPage({
            ROW_COUNT: 10,
            PAGE_COUNT: 0
        });
    }

    ReactModal.setAppElement("body");

    return (
        <ReactModal
            isOpen={props.isShow}
            className="pf-paydetail-popup"
            overlayClassName="popupOverlay"
            animationType="fade"
            transparent={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => closePopup()}
        >
            <div className="pf-paydetail-container">
                <div className="pf-paydetail-header">
                    <p>
                        결제 상세 내역
                    </p>

                    <img
                        className="cursor"
                        src='/img/icon/common/close.png'
                        alt="X"
                        onClick={() => closePopup()}
                    />
                </div>

                <Suspense
                    fallback={
                        <div className="pf-paydetail-loading">
                            <Loading />
                        </div>
                    }
                >
                    <PaylogDetailList
                        list={data}
                        total={total}
                    />

                    <div className="pf-paydetail-paging">
                        <PaylogDetailPaging
                            page={page}
                            setPage={setPage}
                            total={total}
                            setSearch={setIsSearch}
                        />
                    </div>
                </Suspense>
            </div>
        </ReactModal>
    )
}

export default PaylogPopup
