import React, { useState, useEffect, useCallback } from "react";
import "css/schedule.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert, openAlert, updatePagingInfo } from "actions/index";
import Paging from "component/common/paging";
import DatePicker from "component/common/datePicker";
import ScheduleDetailPopup from "component/schedule/scheduleDetailPopup";
import Loading from "component/common/loading";
import SearchSelectBox from "component/common/searchSelectBox";
import SearchTextInputSupport from "component/support/searchTextinputsupport";
/**
 * Version : 1.0
 * 작성일자 : 2022-03-10
 * 작성자 : 강연승
 * 설명 : 예약 관리 - 예약현황
 */
function Schedule() {
  /**
   * REDUX
   */
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);

  const [searchDate, setSearchDate] = useState({
    START_TIME: "",
    END_TIME: "",
  });
  const [scheduleList, setScheduleList] = useState([]);


/*검색 타입 */

const [searchOption, setSearchOption] = useState({
  text: "" });

  const [selectType, setSelectType] = useState("");

  const typeList = [
    { value: "", label: "구분" },
    { value: "SEARCH_GROUP_NM", label: "그룹 이름" },
    { value: "SEARCH_TITLE", label: "회의 제목" },
    { value: "SEARCH_HOST_ID", label: "아이디" },
    { value: "SEARCH_SCHEDULE_NUM", label: "회의 번호" },
  ];

     /**
   * Set Schedule Total
   */
      const getScheduleTotal = useCallback(
        (text, date,type) => {
          let param = {};
          if(type !==""){
            param = {
              ...param,
              SEARCH_TYPE : type
      
            }
          }
          if(text !== "" && text!==undefined){
            param = {
              ...param,
              INPUT_TEXT : text
            }
          }
          if (date.START_TIME !== "" && date.END_TIME !== "") {
            param = {
              ...param,
              START_TIME: date.START_TIME + "T00:00:00",
              END_TIME: date.END_TIME + "T23:59:59",
            };
          }
          axios
            .get("/schedule/admin/scheduleCnt", {
              params: param,
            
            })
            .then(res => {
              if (res.status === 200) {
                const paging_obj = {
                  ROW_COUNT: PAGING_STATE.ROW_COUNT,
                  PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
                  TOTAL: res.data.TOTAL,
                };
                dispatch(updatePagingInfo(paging_obj));
              }
            })
            .catch(() => {});
        },
        [dispatch]
      );
    


 
  /**
   * 회의 리스트 조회
   */
  const getScheduleList = useCallback(
    (text, date, type) => {
      setLoading(true);
      let param = {
        ROW_COUNT: PAGING_STATE.ROW_COUNT,
        PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
      };
      if(type !==""){
        param = {
          ...param,
          SEARCH_TYPE : type
  
        }
      }
      if(text !== "" && text!==undefined){
        param = {
          ...param,
          INPUT_TEXT : text
        }
      }
      if (date.START_TIME !== "" && date.END_TIME !== "") {
        param = {
          ...param,
          START_TIME: date.START_TIME + "T00:00:00",
          END_TIME: date.END_TIME + "T23:59:59",
        };
      }
      axios
        .get("/schedule/admin/scheduleList", {
          params: param,
        })
        .then(res => {
          if (res.status === 200) {
            setScheduleList(res.data);
           
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT]
  );
  /**
   * useEffect
   */
  useEffect(() => {
    getScheduleTotal(searchOption.text, searchDate, selectType);
  }, [getScheduleTotal]);

  useEffect(()=>{
    getScheduleList(searchOption.text, searchDate,selectType );
  },[getScheduleList])
  /**
   * 검색 버튼
   */
  const onSearch = (text,date,type) => {
    if(date.START_TIME > date.END_TIME){
      const obj = {
        TEXT: <>
        <div className='info-msg err'>
            오류!
        </div>
        <div className='info-msg'>
            종료 일자는 시작 일자보다
        </div>
        <div className='info-msg'>
            이후의 날짜로 설정해주세요!
        </div>
    </>,
        submitEventHandler: () => {dispatch(closeAlert()); }
      };
      dispatch(openAlert(obj));
    }else{
      getScheduleList(text, date, type);
      getScheduleTotal(text,date, type);
    }

  };

  /**
   * 예약현황 상세보기
   */
  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [scheduleDetailInfo, setScheduleDetailInfo] = useState({});
  const openDetailPopup = data => {
    setIsOpenDetail(true);
    setScheduleDetailInfo(data);
  };
  const closeDetailPopup = () => {
    setIsOpenDetail(false);
  };
  /**
   * 엑셀 다운로드
   */
  const downloadExcel = (text, date, type) => {
    let param = {};
    if(type !==""){
      param = {
        ...param,
        SEARCH_TYPE : type

      }
    }
    if(text !== "" && text!==undefined){
      param = {
        ...param,
        INPUT_TEXT : text
      }
    }
    if (date.START_TIME !== "" && date.END_TIME !== "") {
      param = {
        ...param,
        START_TIME: date.START_TIME + "T00:00:00",
        END_TIME: date.END_TIME + "T23:59:59",
      };
    }

    const config = {
      params: param,
      responseType: "blob",
    };
    axios.post("/schedule/admin/scheduleList/excel", null, config).then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Pebble_예약관리_예약현황.xlsx");
        document.body.appendChild(link);
        link.click();
      }
    });
  };
  return (
    <>
      {loading ? (
        <div
          style={{
            display: loading ? "block" : "none",
            zIndex: "997",
            position: "absolute",
            width: "calc(100% - 285px)",
            height: "calc(100% - 91px)",
          }}
        >
          <Loading />
        </div>
      ) : null}
      <div className="schedule_section">
        {isOpenDetail ? (
          <ScheduleDetailPopup
            open={isOpenDetail}
            close={closeDetailPopup}
            data={scheduleDetailInfo}
          />
        ) : null}

        <div className="schedule_title">
          <font className="mainTitle">예약현황</font>
          <font className="subTitle">{"예약관리 > 예약현황"}</font>
        </div>
        <div className="schedule_searchArea">
        <SearchTextInputSupport
                    label="검색어를 입력하세요"
                    type="long"
                    value={searchOption.text}
                    setValue={value => setSearchOption({ ...searchOption, text: value })}
                />

          
          <SearchSelectBox
                    label="카테고리"
                    type="short"
                    list={typeList}
                    value={selectType}
                    setValue={value => setSelectType(value)}
                  />   
          {/* <input
            type="text"
            name="GROUP_NM"
            className="schedule_searchInput schedule_groupNm"
            placeholder="그룹명"
            value={searchText.GROUP_NM}
            onChange={onChange}
          />
          <input
            type="text"
            name="HOST_ID"
            className="schedule_searchInput schedule_meetingHost"
            placeholder="미팅 호스트"
            value={searchText.HOST_ID}
            onChange={onChange}
          />
          <input
            type="text"
            name="SCHEDULE_NUM"
            className="schedule_searchInput schedule_scheduleNum"
            placeholder="예약 번호"
            value={searchText.SCHEDULE_NUM}
            onChange={onChange}
          /> */}
          <DatePicker
            type="START"
            date={searchDate}
            setDate={newValue => {
              setSearchDate({
                ...searchDate,
                START_TIME: newValue.START,
              });
            }}
          />
          <font className="schedule_dateRange">~</font>
          <DatePicker
            type="END"
            date={searchDate}
            setDate={newValue => {
              setSearchDate({
                ...searchDate,
                END_TIME: newValue.END,
              });
            }}
          />
  
          <button className="schedule_searchBtn" onClick={()=>onSearch(searchOption.text, searchDate,selectType)}>
            검색
          </button>
        </div>
        <div className="schedule_excelBtnArea">
          <div className="schedule_excelDownBtn">
            <img
              className="schedule_excelIcon"
              src="/img/icon/common/exceldown.png"
              alt="excel"
            />
            <font
              className="schedule_excelDownText"
              onClick={() => downloadExcel(searchOption.text, searchDate, selectType)}
            >
              엑셀 다운로드
            </font>
          </div>
        </div>
        <div className="schedule_list">
          <table className="schedule_table">
            <colgroup>
              <col width="40px" />
              <col width="130px" />
              <col width="158px" />
              <col width="158px" />
              <col width="71px" />
              <col width="72px" />
              <col width="138px" />
              <col width="120px" />
              <col width="154px" />
              <col width="58px" />
              <col width="64px" />
              <col width="160px" />
              <col width="235px" />
            </colgroup>
            <thead>
              <tr>
                <th>NO</th>
                <th>예약 번호</th>
                <th>시작일시</th>
                <th>종료일시</th>
                <th>회의시간</th>
                <th>진행여부</th>
                <th>회의 제목</th>
                <th>그룹 명</th>
                <th>미팅 호스트</th>
                <th style={{ paddingLeft: "5px" }}>초대인원</th>
                <th>상세보기</th>
                <th>생성일시</th>
                <th>생성자IP</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(scheduleList) && scheduleList.length > 0 ? (
                scheduleList.map((data, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      {PAGING_STATE.PAGE_COUNT + index + 1}
                    </td>
                    <td>{data.SCHEDULE_NUM}</td>
                    <td>{data.START_TIME.replace("T", " ")}</td>
                    <td>{data.END_TIME.replace("T", " ")}</td>
                    <td>{data.DURATION}분</td>
                    <td style={{ textAlign: "center" }}>{data.END_YN}</td>
                    <td title={data.TITLE} className="scheduleList_title">
                      {data.TITLE}
                    </td>
                    <td title={data.GROUP_NM} className="scheduleList_groupNM">
                      {data.GROUP_YN === "Y" ? data.GROUP_NM : "-"}
                    </td>
                    <td title={data.HOST_ID} className="scheduleList_hostID">
                      {data.HOST_ID}
                    </td>
                    <td style={{ textAlign: "center" }}>{data.PARTI_CNT}명</td>
                    <td style={{ textAlign: "center", padding: "6px 11px" }}>
                      <div
                        className="schedule_detail_btn"
                        onClick={() => openDetailPopup(data)}
                      >
                        보기
                      </div>
                    </td>
                    <td>{data.CREATE_DT.replace("T", " ")}</td>
                    <td>{data.CREATE_IP}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    조회된 데이터가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="schedule_paging">
            <Paging />
          </div>
        </div>
      </div>
    </>
  );
}

export default Schedule;
