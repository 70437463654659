import React from "react";
import { NavLink } from "react-router-dom";
import "css/menu.css";
import { useDispatch } from "react-redux";
import { updatePagingInfo } from "actions/index";
import { useState } from "react";
import { height } from "@mui/system";
/**
 * Version : 1.0
 * 작성일자 : 2022-03-03
 * 작성자 : 권도훈
 * 설명 : Menu
 * 수정일자 : 2022-05-25
 * 작성자 : 권도훈
 * 설명 : 결제관리 메뉴 추가
 */
function Menu() {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();

  const setPagingInfo = () => {
    const paging_obj = {
      ROW_COUNT: 10,
      PAGE_COUNT: 0,
      TOTAL: 0,
    };
    dispatch(updatePagingInfo(paging_obj));
  };

  const [menuuser, setmenuuser] = useState("open");
  const [menulicense, setmenulicense] = useState("open");
  const [menureserve, setmenureserve] = useState("open");
  const [menuitem, setmenuitem] = useState("open");
  const [menuvideo, setmenuvideo] = useState("open");
  const [menugroup, setmenugroup] = useState("open");
  const [menusupport, setmenusupport] = useState("open");
  const [menupay, setmenupay] = useState("open");
  const [menulicenseid, setmenulicenseid] = useState("open");
  const [menustatistics, setmenustatistics] = useState("open");

  const onclickmenuopen = type => {
    if (type === "user") {
      if (menuuser === "open") {
        setmenuuser("");
      } else {
        setmenuuser("open");
      }
    }
    if (type === "license") {
      if (menulicense === "open") {
        setmenulicense("");
      } else {
        setmenulicense("open");
      }
    }
    if (type === "reserve") {
      if (menureserve === "open") {
        setmenureserve("");
      } else {
        setmenureserve("open");
      }
    }
    if (type === "item") {
      if (menuitem === "open") {
        setmenuitem("");
      } else {
        setmenuitem("open");
      }
    }
    if (type === "video") {
      if (menuvideo === "open") {
        setmenuvideo("");
      } else {
        setmenuvideo("open");
      }
    }
    if (type === "group") {
      if (menugroup === "open") {
        setmenugroup("");
      } else {
        setmenugroup("open");
      }
    }
    if (type === "support") {
      if (menusupport === "open") {
        setmenusupport("");
      } else {
        setmenusupport("open");
      }
    }
    if (type === "pay") {
      if (menupay === "open") {
        setmenupay("");
      } else {
        setmenupay("open");
      }
    }
    if (type === "licenseid") {
      if (menulicenseid === "open") {
        setmenulicenseid("");
      } else {
        setmenulicenseid("open");
      }
    }
    if (type === "statistics") {
      if (menustatistics === "open") {
        setmenustatistics("");
      } else {
        setmenustatistics("open");
      }
    }
  };

  const onclickallmenu = type => {
    if (type === "open") {
      setmenuuser("");
      setmenulicense("");
      setmenureserve("");
      setmenuitem("");
      setmenuvideo("");
      setmenugroup("");
      setmenusupport("");
      setmenupay("");
      setmenulicenseid("");
      setmenustatistics("");
    } else {
      setmenuuser("open");
      setmenulicense("open");
      setmenureserve("open");
      setmenuitem("open");
      setmenuvideo("open");
      setmenugroup("open");
      setmenusupport("open");
      setmenupay("open");
      setmenulicenseid("open");
      setmenustatistics("open");
    }
  };
  const [mouseOverType, setMouseOverType] = useState("");
  const [mouseOverStatus, setMouseOverStatus] = useState(false);
  const mouseOver = (type, status) => {
    setMouseOverType(type);
    setMouseOverStatus(status);
  };
  const mouseOut = (type, status) => {
    setMouseOverType(type);
    setMouseOverStatus(status);
  };
  return (
    <nav className="menu-nav scrollbar">
      <div className="hoverbox">
        <div className="hovertext">
          {mouseOverType === "open" && mouseOverStatus ? (
            <div className="hovertext">전체열기</div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="hovertext">
          {mouseOverType === "close" && mouseOverStatus ? (
            <div className="hovertext">전체닫기</div>
          ) : (
            <div></div>
          )}{" "}
        </div>
      </div>

      <div className="menucontrollbutton">
        <div
          className="menucontrollopenbutton cursor"
          onClick={() => onclickallmenu("open")}
          onMouseOver={() => mouseOver("open", true)}
          onMouseOut={() => mouseOut("open", false)}
        >
          +
        </div>
        <div
          className="menucontrollclosebutton cursor"
          onClick={() => onclickallmenu("close")}
          onMouseOver={() => mouseOver("close", true)}
          onMouseOut={() => mouseOut("close", false)}
        >
          -
        </div>
      </div>

      {menuuser === "open" ? (
        <section className="menu-section menu_closed">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("user")}
          >
            회원 관리
          </label>
          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/user"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px", height: "0px" }} />
            <img
              src="/img/icon/menu/user.png"
              alt="user"
              style={{ marginRight: "17px" }}
            />
            <div className="closedmenu">회원관리</div>
          </NavLink>
          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/userlog"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/connect.png"
              alt="connect"
              style={{ marginRight: "16px" }}
            />
            <div className="closedmenu">접속현황</div>
          </NavLink>{" "}
        </section>
      ) : (
        <section className="menu-section menu_adminuser">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("user")}
          >
            회원 관리
          </label>
          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/user"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/user.png"
              alt="user"
              style={{ marginRight: "17px" }}
            />
            <div>회원관리</div>
          </NavLink>
          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/userlog"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/connect.png"
              alt="connect"
              style={{ marginRight: "16px" }}
            />
            <div>접속현황</div>
          </NavLink>
        </section>
      )}

      {menulicense === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label cursor "
            onClick={() => onclickmenuopen("license")}
          >
            회의 이용권
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/meetingPass"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/connect.png"
              alt="pay"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">회의이용권 관리</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_adimlicense">
          <label
            className="menu-section-label cursor "
            onClick={() => onclickmenuopen("license")}
          >
            회의 이용권
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/meetingPass"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/connect.png"
              alt="pay"
              style={{ marginRight: "15px" }}
            />
            <div>회의이용권 관리</div>
          </NavLink>
        </section>
      )}

      {menureserve === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label cursor "
            onClick={() => onclickmenuopen("reserve")}
          >
            예약 관리
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/schedule"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/reserve.png"
              alt="reserve"
              style={{ marginRight: "16px" }}
            />
            <div className="closedmenu">예약현황</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_adminreserve">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("reserve")}
          >
            예약 관리
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/schedule"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/reserve.png"
              alt="reserve"
              style={{ marginRight: "16px" }}
            />
            <div>예약현황</div>
          </NavLink>
        </section>
      )}

      {menuitem === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("item")}
          >
            상품 관리
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/product"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/product.png"
              alt="product"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">상품관리</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_adminitem">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("item")}
          >
            상품 관리
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/product"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/product.png"
              alt="product"
              style={{ marginRight: "15px" }}
            />
            <div>상품관리</div>
          </NavLink>
        </section>
      )}

      {menuvideo === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("video")}
          >
            회의영상 관리
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/record"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/video.png"
              alt="video"
              style={{ marginRight: "16px" }}
            />
            <div className="closedmenu">회의영상현황</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_adminvideo">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("video")}
          >
            회의영상 관리
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/record"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/video.png"
              alt="video"
              style={{ marginRight: "16px" }}
            />
            <div>회의영상현황</div>
          </NavLink>
        </section>
      )}

      {menugroup === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("group")}
          >
            그룹 관리
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/group"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">그룹현황</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_admingroup">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("group")}
          >
            그룹 관리
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/group"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>그룹현황</div>
          </NavLink>
        </section>
      )}

      {menusupport === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("support")}
          >
            고객센터
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/notice"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">공지사항</div>
          </NavLink>
          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/question"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">문의사항</div>
          </NavLink>
          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/suggestion"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">고객의 소리</div>
          </NavLink>
          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/faq"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">FAQ</div>
          </NavLink>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/info"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">회원통합정보</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_adminsupport">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("support")}
          >
            고객센터
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/notice"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>공지사항</div>
          </NavLink>
          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/question"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>문의사항</div>
          </NavLink>
          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/suggestion"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>고객의 소리</div>
          </NavLink>
          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/faq"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>FAQ</div>
          </NavLink>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/info"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/group.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>회원통합정보</div>
          </NavLink>
        </section>
      )}

      {menupay === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("pay")}
          >
            결제 관리
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/payment"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/product.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">결제수단</div>
          </NavLink>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/recurring"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/product.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">정기결제</div>
          </NavLink>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/paylog"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/product.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">결제내역</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_adminpay">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("pay")}
          >
            결제 관리
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/payment"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/product.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>결제수단</div>
          </NavLink>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/recurring"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/product.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>정기결제</div>
          </NavLink>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/paylog"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/product.png"
              alt="group"
              style={{ marginRight: "15px" }}
            />
            <div>결제내역</div>
          </NavLink>
        </section>
      )}

      {menulicenseid === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("licenseid")}
          >
            계정관리
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/licenseaccount"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/pay.png"
              alt="pay"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">계정관리</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_adminlicenseid">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("licenseid")}
          >
            계정관리
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/licenseaccount"
            activeClassName="menu-active"
            onClick={setPagingInfo}
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/pay.png"
              alt="pay"
              style={{ marginRight: "15px" }}
            />
            <div>계정관리</div>
          </NavLink>
        </section>
      )}

      {menustatistics === "open" ? (
        <section className="menu-section  menu_closed">
          <label
            className="menu-section-label lastmenu cursor"
            onClick={() => onclickmenuopen("statistics")}
          >
            통계 관리
          </label>

          <NavLink
            className="cursor menu-incative closedmenu"
            exact
            to="/app/salesStatistics"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/chart.png"
              alt="chart"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">매출통계</div>
          </NavLink>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/meetingStatistics"
            activeClassName="menu-active"
            style={{ display: "none" }}
          >
            <div style={{ width: "60px" }} />
            <img
              className="closedmenu"
              src="/img/icon/menu/chart.png"
              alt="chart"
              style={{ marginRight: "15px" }}
            />
            <div className="closedmenu">회의통계</div>
          </NavLink>
        </section>
      ) : (
        <section className="menu-section menu_adminstatistics">
          <label
            className="menu-section-label cursor"
            onClick={() => onclickmenuopen("statistics")}
          >
            통계 관리
          </label>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/salesStatistics"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/chart.png"
              alt="chart"
              style={{ marginRight: "15px" }}
            />
            <div>매출통계</div>
          </NavLink>

          <NavLink
            className="cursor menu-incative"
            exact
            to="/app/meetingStatistics"
            activeClassName="menu-active"
          >
            <div style={{ width: "60px" }} />
            <img
              src="/img/icon/menu/chart.png"
              alt="chart"
              style={{ marginRight: "15px" }}
            />
            <div>회의통계</div>
          </NavLink>
        </section>
      )}
    </nav>
  );
}

export default Menu;
