import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import ReactModal from 'react-modal';
import "css/suggestionpopup.css";

function Suggestionpopup(props) {

      /**
 * Version : 1.0
 * 작성일자 : 2022-04-12
 * 작성자 : 정지윤
 * 설명 : 고객의 소리 모달화면
 */

    let server_url = "";
    if (process.env.NODE_ENV === "development") {
       server_url =
         process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
     } else {
       server_url = process.env.REACT_APP_SERVER_URL;
     }


    const [getsug, setgetsug] = useState(''); 
    const [getsugimg, setgetsugimg] = useState([]);

    let support_num = props.Support_NUM;


    const getSuggestion = useCallback(() =>{

        let config={
            params : {
                sup_num : support_num
            }
          }
          if(support_num === ''){

          }else{
            axios.get("/support/getSuggestion", config)
            .then((res)=>{
                if(res.status===200){
                    setgetsugimg(res.data.Suggestionimg)
                   setgetsug(res.data.Suggestiondata);
                
                }
            })
          }

        
    }, [support_num])
    useEffect(() =>{
        getSuggestion()
    },[getSuggestion])

    const  convertHTML = (value)=>{

        return <div dangerouslySetInnerHTML={ {__html: value} }>
        </div>
      }


      const clickclose= ()=>{
        props.close();
      }

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      
      className="supportDetailPopup" //모달 ClassName
      overlayClassName="supportDetailPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >

<div style={{textAlign : "right"}}>
  <img  className='cursor'
        style={{marginRight : "10px",
                marginTop : "10px"}}
        src="/img/icon/common/close_black.png"
        alt=''
        onClick={() => clickclose() }/>
    </div>
    <div className='suggestionpopupbody'>
        <div style={{
            fontSize : "20px",
            marginBottom : "10px"
        }}>고객의 소리</div>
 {getsug === '' ? <div></div>
               :
               <div>

               <div style={{
                   display : "flex"
               }}>
                   <div className='suggestionholdtxt'>고객 번호 :</div>
                   <div>{getsug.USER_NUM}</div>

               </div>

               <div style={{
                   display : "flex"
               }}>
                   <div className='suggestionholdtxt'>고객 아이디 :</div>
                   <div>{getsug.User_ID}</div>

               </div>

               <div style={{
                   display : "flex"
               }}>
                   <div className='suggestionholdtxt'>고객 닉네임 :</div>
                   <div>{getsug.USER_Nm}</div>

               </div>

               <div style={{
                   marginTop : "40px"
               }}>
                   <div style={{fontSize : "20px"}}>내용</div>
                   <div className='m_question_content'>{convertHTML(getsug.Support_Content)}</div>

               </div>
               <div>

               </div>
               <div style={{
  
               }}>
                   
                   {getsugimg.length <1 ? <div></div> : <div>
                                    <div>첨부파일</div>

                                  {getsugimg.map((data) =>(
                                    <div key={data.FILE_NM}>
                                        <div className=' cursor'onClick={() => window.open(
                                                                    server_url +
                                                                      "/file/downloadFile?filename=" +
                                                                      data.FILE_NM +
                                                                      "&downfilename=" +
                                                                      data.ORI_FILE_NM,
                                                                    "_blank"
                                                                  )
                                        }
                                       > {data.ORI_FILE_NM}</div>
                                    </div>
                                ))}
                                </div>
                   }
                  
               </div>
           </div>
               }     


    </div>
    </ReactModal>
  )
}

export default Suggestionpopup