import React, { useState } from 'react'
import SchedulePopup from '../popup/schedulePopup';
/**
 * Version : 1.0
 * 파일명 : ScheduleListContent.js
 * 작성일자 : 2022-05-04
 * 작성자 : 권도훈
 */
function ScheduleListContent(props) {
    const data = props.list.data.read();

    const [popup, setPopup] = useState(false);
    const [content, setContent] = useState({});

    return (
        <>
            <SchedulePopup
                popup={popup}
                setPopup={setPopup}
                content={content}
                setContent={setContent}
            />
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr key={idx}>
                            <td>{item.SCHEDULE_NUM}</td>
                            <td>{item.START_TIME}</td>
                            <td>{item.END_TIME}</td>
                            <td>{item.HISTORY_START_TIME}</td>
                            <td>{item.HISTORY_END_TIME}</td>
                            <td>{item.HISTORY_DURATION}</td>
                            <td className='info-ellipsis schedule-title' title={item.TITLE}>
                                {item.TITLE}
                            </td>
                            <td>{item.GROUP_NM}</td>
                            <td>{item.REC_YN}</td>
                            <td>{item.INVITE_COUNT}</td>
                            <td>{item.HISTORY_COUNT}</td>
                            <td>{item.MEETING_KEY}</td>
                            <td>{item.CREATE_DT}</td>
                            <td className='info-ellipsis schedule-create-ip' title={item.CREATE_IP}>
                                {item.CREATE_IP}
                            </td>
                            <td>
                                <button
                                    className='info-content-table-btn cursor'
                                    onClick={() => {
                                        setContent(item);
                                        setPopup(true);
                                    }}
                                >
                                    보기
                                </button>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan="15">
                            예약 내역이 없습니다.
                        </td>
                    </tr>
            }
        </>
    )
}

export default ScheduleListContent
