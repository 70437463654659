import React from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";

/**
 * Version : 1.0
 * 작성일자 : 2022-05-27
 * 작성자 : 강연승
 * 설명 : 회의 차트 대시보드
 * 수정일자 : 2022-05-27
 * 작성자 : 강연승
 * 설명 : 대시보드 페이지 추가
 */
function MeetingChart(props) {
  return (
    <Bar
      data={{
        labels: props.resMeetingCntList.map(data => data.day),
        datasets: [
          {
            label: "예약된 회의 수",
            data: props.resMeetingCntList.map(data => data.cnt),
            backgroundColor: "#ffb423",
            stack: "Stack 0",
          },
          {
            label: "완료된 회의 수",
            data: props.endMeetingCntList.map(data => data.cnt),
            backgroundColor: "#7f84fb",
            stack: "Stack 1",
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: context => {
                let label = context.dataset.label + "" || "";

                return context.parsed.y !== null
                  ? label + ": " + context.parsed.y + "회"
                  : null;
              },
            },
          },
        },
        maintainAspectRatio: false,
        barThickness: "38",
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            // ticks: {
            //   stepSize: 5,
            // },
          },
        },
      }}
      height="300"
    />
  );
}

export default MeetingChart;
