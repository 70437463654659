import React, { useEffect, useState, useRef } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import "css/editorreact.css";
import { ContentState, convertToRaw, EditorState, Entity } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { SliderValueLabel } from '@mui/material';
import htmlToDraft from 'html-to-draftjs';
import createLinkDetectionPlugin from 'draft-js-link-detection-plugin';
function Editorreact({content, writedata, open}) {

   
  // .editor {
  //   height: 500px !important;
  //   border: 1px solid #f1f1f1 !important;
  //   padding: 5px !important;
  //   border-radius: 2px !important;
  // }

  // useState로 상태관리하기 초기값은 EditorState.createEmpty()
  // EditorState의 비어있는 ContentState 기본 구성으로 새 개체를 반환 => 이렇게 안하면 상태 값을 나중에 변경할 수 없음.
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const editorRef = useRef();

  const linkDetectionPlugin = createLinkDetectionPlugin();
const plugins = [linkDetectionPlugin];

const linkifyPlugin = createLinkDetectionPlugin();
  const onEditorStateChange = (editorState) => {
    
    setEditorState(editorState || '');

    writedata(editorToHtml || '');
    console.log(content);

  };

  const HtmlToeditor = content
 if(content !== ''){
   open= 3;
 }
  useEffect(() =>{
    const blocksFromHtml = htmlToDraft(HtmlToeditor);
    if(blocksFromHtml){
      const {contentBlocks, entitiyMap} = blocksFromHtml;
      const contentstate = ContentState.createFromBlockArray(contentBlocks, entitiyMap);
      const editorState = EditorState.createWithContent(contentstate);
      setEditorState(editorState);
    }
  },[open])



  const editorToHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  useEffect(() => {
    console.log(editorRef.current);
  }, [editorRef])
  

  return (
      <div className='editorheader'>
        <Editor
        ref={editorRef}
          // 에디터와 툴바 모두에 적용되는 클래스
          wrapperClassName="wrapper-class"
          // 에디터 주변에 적용된 클래스
          editorClassName="editor"
          // 툴바 주위에 적용된 클래스
          toolbarClassName="toolbar-class"
          // 툴바 설정
          toolbar={{
            // inDropdown: 해당 항목과 관련된 항목을 드롭다운으로 나타낼것인지
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: {       
             },
            history: { inDropdown: false },
          }} 
          placeholder="내용을 작성해주세요."
          // 한국어 설정
          localization={{
            locale: 'ko',
          }}
          // 초기값 설정
          editorState={editorState}
          // 에디터의 값이 변경될 때마다 onEditorStateChange 호출
          onEditorStateChange={onEditorStateChange}

          onContentStateChange={data => console.log(draftToHtml(data))}

          plugins={[linkifyPlugin]}
        >
        <div ></div>

        </Editor>
    </div >
  )
}

export default Editorreact