import React, { useState, useEffect } from "react";
import ReactModal from "react-modal";
import "css/meetingPass.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions/index";
import { CircularProgress } from "@material-ui/core";
import UserSearchPopup from "./userSearchPopup";

function MeetingPassPopup(props) {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [signInfo, setSignInfo] = useState({
    USER_NM: "",
    USER_NUM: "",
    USER_ID: "",
    GROUP_NUM: "",
    GROUP_NM: "",
    EXPIRED_DT: "",
    LOCK_YN: "N",
    PRODUCT_COUNT: 0,
    PRODUCT_CODE: "PD0104",
    PAY_NUM: "",
  });
  const [showUserSearchPopup, setShowUserSearchPopup] = useState(false);
  useEffect(() => {
    if (props.type === "EDIT") {
      setSignInfo({
        ...signInfo,
        USER_NUM: props.licenseInfo.USER_NUM,
        USER_ID: props.licenseInfo.USER_ID,
        USER_NM: props.licenseInfo.USER_NM,
        USER_LICENSE_NUM: props.licenseInfo.USER_LICENSE_NUM,
        EXPIRED_DT: props.licenseInfo.EXPIRED_DT,
        LICENSE_CODE: props.licenseInfo.LICENSE_CODE,
      });
    }
  }, [props.type]);

  const insertMeetingPass = () => {
    setLoading(true);
    let url = "/license/admin/insertMeetingPass";
    let param = signInfo;
    axios
      .post(url, null, { params: param })
      .then((res) => {
        if (res.status === 200) {
          props.close(false);
          setLoading(false);
          const obj = {
            TITLE: "알림",
            TEXT: "회의 이용권이 생성되었습니다.",
            submitEventHandler: () => {
              props.reload();
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        } else {
          props.close(false);
          setLoading(false);
          const obj = {
            TITLE: "알림",
            TEXT: "회의 이용권이 생성에 실패하였습니다.",
            submitEventHandler: () => {
              props.reload();
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(() => {
        props.close(false);
        setLoading(false);
        const obj = {
          TEXT: "회의 이용권 생성 중 오류가 발생했습니다.",
          submitEventHandler: () => {
            props.reload();
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      });
  };
  const updateMeetingPass = () => {
    setLoading(true);
    let url = "/license/admin/updateMeetingPass";
    let param = signInfo;
    axios
      .put(url, null, { params: param })
      .then((res) => {
        if (res.status === 200) {
          props.close(false);
          setLoading(false);
          const obj = {
            TEXT: "회의 이용권이 수정되었습니다.",
            submitEventHandler: () => {
              props.reload();
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        } else {
          props.close(false);
          setLoading(false);
          const obj = {
            TEXT: "회의 이용권이 수정에 실패하였습니다.",
            submitEventHandler: () => {
              props.reload();
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(() => {
        props.close(false);
        setLoading(false);
        const obj = {
          TEXT: "회의 이용권 수정 중 오류가 발생했습니다.",
          submitEventHandler: () => {
            props.reload();
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
      });
  };
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="mp-cu-popup" //모달 ClassName
      overlayClassName="mp-cu-popup-overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close(false);
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      {showUserSearchPopup ? (
        <UserSearchPopup
          open={showUserSearchPopup}
          close={() => setShowUserSearchPopup(false)}
          signInfo={signInfo}
          setSignInfo={setSignInfo}
        />
      ) : null}
      <div className="mp-cu-popup-bg">
        <div className="mp-cu-popup-header">
          {props.type === "EDIT" ? "회의이용권 수정" : "회의이용권 등록"}
        </div>
        <div className="mp-cu-popup-body">
          <div className="mp-cu-userArea">
            <div className="mp-cu-input-label">이용자</div>
            <input
              className="mp-cu-input-user"
              type="text"
              readOnly
              value={signInfo.USER_NM}
              onChange={(e) => {
                setSignInfo({ ...signInfo, USER_NM: e.target.value });
              }}
            />
            <button
              className="mp-cu-btn-search"
              onClick={() => setShowUserSearchPopup(true)}
            >
              검색
            </button>
          </div>
          {/* <div className="mp-cu-groupArea">
            <div className="mp-cu-input-label">그룹</div>
            <input
              className="mp-cu-input-group"
              type="text"
              disabled
              name="GROUP_NUM"
              value={signInfo.GROUP_NM}
              onChange={e => {
                setSignInfo({ ...signInfo, GROUP_NM: e.target.value });
              }}
            />
          </div> */}
          <div className="mp-cu-dateArea">
            <div className="mp-cu-input-label">종료일자</div>
            <div>
              <input
                className="mp-cu-input-date"
                type="date"
                name="EXPIRED_DT"
                value={signInfo.EXPIRED_DT}
                onChange={(e) => {
                  setSignInfo({ ...signInfo, EXPIRED_DT: e.target.value });
                }}
              />
            </div>
          </div>
          {props.type === "EDIT" ? null : (
            <div className="mp-cu-cntArea">
              <div className="mp-cu-input-label">수량</div>
              <div>
                <input
                  className="mp-cu-input-productCnt"
                  min="0"
                  type="number"
                  name="PRODUCT_COUNT"
                  value={
                    signInfo.PRODUCT_COUNT < 0 ? 0 : signInfo.PRODUCT_COUNT
                  }
                  onChange={(e) => {
                    setSignInfo({ ...signInfo, PRODUCT_COUNT: e.target.value });
                  }}
                />
              </div>
            </div>
          )}
          <div className="mp-cu-activeCheckArea">
            <div className="mp-cu-input-label">활성화 여부</div>
            <div className="mp-cu-radioBox">
              <input
                type="radio"
                name="LOCK_YN"
                value="N"
                checked={signInfo.LOCK_YN === "N"}
                onChange={(e) => {
                  setSignInfo({ ...signInfo, LOCK_YN: e.target.value });
                }}
              />
              <span>활성화</span>
              <input
                type="radio"
                name="LOCK_YN"
                value="Y"
                checked={signInfo.LOCK_YN === "Y"}
                onChange={(e) => {
                  setSignInfo({ ...signInfo, LOCK_YN: e.target.value });
                }}
              />
              <span>비활성화</span>
            </div>
          </div>
        </div>
        <div className="mp-cu-popup-footer">
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <>
              {props.type === "EDIT" ? (
                <button
                  className={
                    signInfo.EXPIRED_DT === "" || signInfo.USER_NUM === ""
                      ? "mp-cu-btn-sign"
                      : "mp-cu-btn-sign actvieSign"
                  }
                  disabled={
                    signInfo.EXPIRED_DT === "" || signInfo.USER_NUM === ""
                  }
                  onClick={() => updateMeetingPass()}
                >
                  수정
                </button>
              ) : (
                <button
                  className={
                    signInfo.EXPIRED_DT === "" ||
                    signInfo.USER_NUM === "" ||
                    signInfo.PRODUCT_COUNT < 1
                      ? "mp-cu-btn-sign"
                      : "mp-cu-btn-sign actvieSign"
                  }
                  disabled={
                    signInfo.EXPIRED_DT === "" ||
                    signInfo.USER_NUM === "" ||
                    signInfo.PRODUCT_COUNT < 1
                  }
                  onClick={() => insertMeetingPass()}
                >
                  등록
                </button>
              )}
              <button
                className="mp-cu-btn-cancel"
                onClick={() => {
                  props.close(false);
                }}
              >
                취소
              </button>
            </>
          )}
        </div>
      </div>
    </ReactModal>
  );
}

export default MeetingPassPopup;
