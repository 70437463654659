import React, { Suspense, useCallback, useEffect, useState } from 'react'
import 'css/pay/paylog.css';
import { fetchAxiosEvent } from 'component/info/axiosEvent';
import { useDispatch } from 'react-redux';
import { closeAlert, openAlert, openConfirm } from 'actions';
import Loading from 'component/common/loading';
import Paging from 'component/info/content/paging';
import axios from 'axios';
import DatePicker from "component/record/recordDatepicker";
import { CircularProgress } from "@material-ui/core";
import SearchTextInput from 'component/common/searchTextInput';
import SearchSelectBox from 'component/common/searchSelectBox';
import RecurringList from 'component/pay/recurring/recurringList';
/**
 * Version : 1.0
 * 파일명 : Recurring.js
 * 작성일자 : 2022-05-26
 * 작성자 : 권도훈
 */
function Recurring() {
    /**
     * useDispatch
     */
    const dispatch = useDispatch();
    /**
     * useState
     */
    //초기화
    const [init, setInit] = useState(true);
    //검색
    const [isSearch, setIsSearch] = useState(false);
    const [isPaging, setIsPaging] = useState(false);
    const [page, setPage] = useState({
        ROW_COUNT: 10,
        PAGE_COUNT: 0
    });
    const [searchOption, setSearchOption] = useState({
        USER_TEXT: "",
        PAYMENT_TYPE: "",
        USE_YN: ""
    });
    const [date, setDate] = useState({
        START_TIME: "",
        END_TIME: ""
    });
    //데이터
    const [recurring, setRecurring] = useState();
    const [total, setTotal] = useState(0);
    //체크박스
    const [chkList, setChkList] = useState([]);
    //엑셀
    const [isDownload, setIsDownload] = useState(false);
    //selectbox
    const paymentTypeList = [
        { value: "", label: "전체" },
        { value: "CARD", label: "카드" },
        { value: "TRANSFER", label: "계좌" }
    ];
    const useYnList = [
        { value: "", label: "전체" },
        { value: "Y", label: "사용" },
        { value: "N", label: "미사용" }
    ];
    /**
     * useCallbak
     */
    //토탈 조회
    const getRecurringListTotal = useCallback(() => {
        setTotal(
            fetchAxiosEvent(
                "GET",
                "/payment/recurring/list/total",
                {
                    config: {
                        params: {
                            USER_TEXT: searchOption.USER_TEXT,
                            PAYMENT_TYPE: searchOption.PAYMENT_TYPE,
                            USE_YN: searchOption.USE_YN,
                            START_DTTM:
                                date.START_TIME !== ""
                                    ? date.START_TIME + " 00:00:00"
                                    : "",
                            END_DTTM:
                                date.END_TIME !== ""
                                    ? date.END_TIME + " 23:59:59"
                                    : ""
                        }
                    }
                }
            )
        )
    }, [date.END_TIME, date.START_TIME, searchOption.PAYMENT_TYPE, searchOption.USER_TEXT, searchOption.USE_YN]);
    //목록 조회
    const getRecurringList = useCallback(() => {
        setRecurring(
            fetchAxiosEvent(
                "GET",
                "/payment/recurring/list",
                {
                    config: {
                        params: {
                            ROW_COUNT: page.ROW_COUNT,
                            PAGE_COUNT: page.PAGE_COUNT,
                            USER_TEXT: searchOption.USER_TEXT,
                            PAYMENT_TYPE: searchOption.PAYMENT_TYPE,
                            USE_YN: searchOption.USE_YN,
                            START_DTTM:
                                date.START_TIME !== ""
                                    ? date.START_TIME + " 00:00:00"
                                    : "",
                            END_DTTM:
                                date.END_TIME !== ""
                                    ? date.END_TIME + " 23:59:59"
                                    : ""
                        }
                    }
                }
            )
        )
    }, [date.END_TIME, date.START_TIME, page.PAGE_COUNT, page.ROW_COUNT, searchOption.PAYMENT_TYPE, searchOption.USER_TEXT, searchOption.USE_YN]);
    /**
     * useEffect
     */
    //초기화
    useEffect(() => {
        if (init) {
            getRecurringListTotal();
            getRecurringList();
            setInit(false);
        }
    }, [getRecurringList, getRecurringListTotal, init]);
    //검색
    useEffect(() => {
        if (isSearch) {
            if (date.START_TIME !== "" || date.END_TIME !== "") {
                if (date.START_TIME === "" || date.END_TIME === "") {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg err'>
                                    오류!
                                </div>
                                <div className='info-msg'>
                                    검색 날짜를 모두 설정해주세요!
                                </div>
                            </>,
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                    setIsSearch(false);
                    return;
                } else if (date.END_TIME < date.START_TIME) {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg err'>
                                    오류!
                                </div>
                                <div className='info-msg'>
                                    종료 일자는 시작 일자보다
                                </div>
                                <div className='info-msg'>
                                    이후의 날짜로 설정해주세요!
                                </div>
                            </>,
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                    setIsSearch(false);
                    return;
                }
            }
            getRecurringListTotal();
            getRecurringList();
            setChkList([]);
            setIsSearch(false);
        }
    }, [date.END_TIME, date.START_TIME, dispatch, getRecurringList, getRecurringListTotal, isSearch]);
    //페이징
    useEffect(() => {
        if (isPaging) {
            getRecurringList();
            setIsPaging(false);
        }
    }, [getRecurringList, isPaging]);
    /**
     * function
     */
    //checkbox click
    const clickChkEventHandler = e => {
        if (e.target.checked) {
            let userNumList = [];

            recurring.data.read().forEach(el => {
                if (!chkList.includes(el.USER_NUM + "/" + el.ORDER_NUM)) {
                    userNumList.push(el.USER_NUM + "/" + el.ORDER_NUM);
                }
            });

            setChkList(userNumList);

        } else {
            if (chkList.length < total.data.read()) {
                let isCheckedAll = true;
                let userNumList = [];

                recurring.data.read().forEach(el => {
                    if (!chkList.includes(el.USER_NUM + "/" + el.ORDER_NUM)) {
                        isCheckedAll = false;
                        userNumList.push(el.USER_NUM + "/" + el.ORDER_NUM);
                    }
                });

                if (isCheckedAll) {
                    setChkList([]);
                } else {
                    userNumList = userNumList.concat(chkList);
                    setChkList(userNumList);
                }

            } else {
                setChkList([]);
            }
        }
    }
    //excel download EventHandler
    const downloadExcelEventHandler = () => {
        setIsDownload(true);

        const data = {
            USER_NUM_LIST: chkList
        }

        const config = {
            responseType: "blob",
            params: {
                REQUEST_TYPE: chkList.length > 0 ? "CHECK_LIST" : "ALL",
                USER_TEXT: searchOption.USER_TEXT,
                PAYMENT_TYPE: searchOption.PAYMENT_TYPE,
                USE_YN: searchOption.USE_YN,
                START_DTTM:
                    date.START_TIME !== ""
                        ? date.START_TIME + " 00:00:00"
                        : "",
                END_DTTM:
                    date.END_TIME !== ""
                        ? date.END_TIME + " 23:59:59"
                        : ""
            }
        }

        axios.post("/payment/recurring/list/excel", data, config)
            .then(res => {
                const url = window.URL.createObjectURL(
                    new Blob([res.data], { type: res.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "PEBBLE_정기결제목록.xlsx");
                document.body.appendChild(link);
                link.click();
                setIsDownload(false);
            })
            .catch(() => {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                오류!
                            </div>
                            <div className='info-msg'>
                                오류가 발생했습니다.
                            </div>
                        </>,
                    submitEventHandler: () => {
                        setIsDownload(false);
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            });
    }

    return (
        <div className='payment-container'>
            <section className='payment-header drag-none'>
                <span className='payment-header-title'>
                    정기결제현황
                </span>

                <span className='payment-header-dir'>
                    결제관리<font>&gt;</font>정기결제현황
                </span>
            </section>

            <div className='payment-search-area'>
                <SearchTextInput
                    label="사용자 아이디 또는 이름"
                    type="long"
                    value={searchOption.USER_TEXT}
                    setValue={value => setSearchOption({ ...searchOption, USER_TEXT: value })}
                    setPage={setPage}
                    page={page}
                    setIsSearch={setIsSearch}
                />

                <SearchSelectBox
                    label="종류"
                    type="short"
                    list={paymentTypeList}
                    value={searchOption.PAYMENT_TYPE}
                    setValue={value => setSearchOption({ ...searchOption, PAYMENT_TYPE: value })}
                    setPage={setPage}
                    page={page}
                    setIsSearch={setIsSearch}
                />

                <SearchSelectBox
                    label="사용여부"
                    type="short"
                    list={useYnList}
                    value={searchOption.USE_YN}
                    setValue={value => setSearchOption({ ...searchOption, USE_YN: value })}
                    setPage={setPage}
                    page={page}
                    setIsSearch={setIsSearch}
                />

                <div className='payment-search-date center'>
                    <DatePicker
                        label="등록일자"
                        type="START"
                        date={date}
                        setDate={result => {
                            setDate({
                                ...date,
                                START_TIME: result.START
                            });
                        }}
                    />
                    <span>~</span>
                    <DatePicker
                        label="등록일자"
                        type="END"
                        date={date}
                        setDate={result => {
                            setDate({
                                ...date,
                                END_TIME: result.END
                            });
                        }}
                    />
                </div>

                <button
                    className='payment-search-btn btn blue cursor'
                    onClick={() => {
                        setPage({
                            ...page,
                            PAGE_COUNT: 0
                        });
                        setIsSearch(true);
                    }}
                >
                    검색
                </button>
            </div>

            <div className='payment-content'>
                <div className='payment-content-btn-area'>
                    <div className='payment-content-btn-area-inner'>
                        <div
                            className={
                                !isDownload
                                    ? 'payment-content-btn btn lightgrey cursor center'
                                    : 'payment-content-btn btn lightgrey drag-none center'
                            }
                            onClick={() => {
                                if (!isDownload) {
                                    const obj = {
                                        TEXT:
                                            <>
                                                <div className='info-msg success'>
                                                    알림!
                                                </div>
                                                <div className='info-msg'>
                                                    엑셀파일을 다운로드 하시겠습니까?
                                                </div>
                                            </>,
                                        submitEventHandler: () => {
                                            downloadExcelEventHandler();
                                            dispatch(closeAlert());
                                        },
                                    };
                                    dispatch(openConfirm(obj));
                                }
                            }}
                        >
                            {
                                isDownload
                                    ?
                                    <CircularProgress size={18} />
                                    :
                                    <>
                                        <img
                                            src='/img/icon/common/exceldown.png'
                                            alt=''
                                        />
                                        <span>엑셀 다운로드</span>
                                    </>
                            }
                        </div>
                    </div>
                </div>

                <div className='payment-content-table-area'>
                    <table className='payment-content-table'>
                        <colgroup>
                            <col width="3%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="6%" />
                            <col width="6%" />
                            <col width="8%" />
                            <col width="5%" />
                            <col width="6%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="8%" />
                            <col width="8%" />
                            <col width="10%" />
                        </colgroup>

                        <thead>
                            <tr>
                                <th className='payment-content-th'>
                                    <input
                                        type="checkbox"
                                        className='cursor'
                                        checked={chkList.length > 0}
                                        onChange={e => clickChkEventHandler(e)}
                                    />
                                </th>
                                <th className='payment-content-th left'>
                                    <span>
                                        사용자 번호
                                    </span>
                                </th>
                                <th className='payment-content-th left'>
                                    <span>
                                        사용자 아이디
                                    </span>
                                </th>
                                <th className='payment-content-th left'>
                                    <span>
                                        사용자 명
                                    </span>
                                </th>
                                <th className='payment-content-th left'>
                                    <span>
                                        결제예정일
                                    </span>
                                </th>
                                <th className='payment-content-th'>
                                    결제성공여부
                                </th>
                                <th className='payment-content-th'>
                                    상품금액
                                </th>
                                <th className='payment-content-th'>
                                    상품수량
                                </th>
                                <th className='payment-content-th'>
                                    결제수단타입
                                </th>
                                <th className='payment-content-th'>
                                    사용여부
                                </th>
                                <th className='payment-content-th'>
                                    빌링키여부
                                </th>
                                <th className='payment-content-th left'>
                                    <span>
                                        등록일
                                    </span>
                                </th>
                                <th className='payment-content-th left'>
                                    <span>
                                        생성일시
                                    </span>
                                </th>
                                <th className='payment-content-th left'>
                                    <span>
                                        생성자IP
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody className='payment-content-tbody'>
                            <Suspense
                                fallback={
                                    <tr className='payment-loding-tr'>
                                        <td colSpan='14'>
                                            <Loading />
                                        </td>
                                    </tr>
                                }
                            >
                                {
                                    !init
                                        ?
                                        <RecurringList
                                            list={recurring}
                                            chkList={chkList}
                                            setChkList={setChkList}
                                        />
                                        :
                                        <></>
                                }
                            </Suspense>
                        </tbody>
                    </table>

                    <div className='payment-content-paging'>
                        <Suspense fallback={<></>}>
                            {
                                !init
                                    ?
                                    <Paging
                                        setIsSearch={setIsPaging}
                                        page={page}
                                        setPage={setPage}
                                        TOTAL={total}
                                    />
                                    :
                                    <></>
                            }
                        </Suspense>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Recurring