// import Alert from "component/tag/modal/alert";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePagingInfo, openAlert, closeAlert } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : paging.js
 * 작성일자 : 2022-01-13
 * 작성자 : 권도훈
 * 설명 : 공통 페이징 div REDUX
 * 수정일자 : 2022-05-19
 * 작성자 : 강연승
 * 설명 : 페이지 갯수 10 이상 페이징 오류 수정
 */
function Paging() {
  /**
   * REDUCER
   */
  const REDUCER_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();

  const totalPage = Math.ceil(REDUCER_STATE.TOTAL / REDUCER_STATE.ROW_COUNT);
  //초기값
  const [showingNum, setShowingNum] = useState({
    start: 1,
    end: REDUCER_STATE.ROW_COUNT,
  });

  useEffect(() => {
    const lessThanFive = totalPage <= REDUCER_STATE.ROW_COUNT;
    lessThanFive
      ? setShowingNum(prev => ({
          ...prev,
          start: 1,
          end: totalPage,
        }))
      : setShowingNum(prev => ({
          ...prev,
          start: 1,
          end: REDUCER_STATE.ROW_COUNT,
        }));
  }, [REDUCER_STATE.TOTAL]);

  //왼쪽 화살표가 눌렸을 때
  const changePageNumbersBackward = () => {
    REDUCER_STATE.PAGE_COUNT / 10 + 1 > REDUCER_STATE.ROW_COUNT &&
      setShowingNum(prev => arrowHandler(prev, -1, totalPage));
  };

  //오른쪽 화살표가 눌렸을 때
  const changePageNumberForward = () => {
    showingNum.end <= REDUCER_STATE.TOTAL &&
      setShowingNum(prev => arrowHandler(prev, 1, totalPage));
  };

  const arrowHandler = (prev, sign, total) => {
    const PAGES_PER_LIST = 10;
    const nextIndex = prev.end + PAGES_PER_LIST;
    let res;
    if (sign === 1) {
      res = nextIndex > total ? total : nextIndex;
    } else if (sign === -1) {
      res =
        prev.end - prev.start < 9
          ? prev.start + 9 - PAGES_PER_LIST
          : prev.end - PAGES_PER_LIST;
    }
    return { ...prev, start: prev.start + PAGES_PER_LIST * sign, end: res };
  };

  const getEmptyArray = (start, end) => {
    let tmpArr = [];
    for (let i = start; i <= end; i++) {
      tmpArr.push(i);
    }
    return tmpArr;
  };

  const pages = getEmptyArray(showingNum.start, showingNum.end);

  /**
   * 페이징
   */
  const changePage = type => {
    if (type === "prev") {
      if (REDUCER_STATE.PAGE_COUNT - 10 < 0 || REDUCER_STATE.TOTAL <= 10) {
        const obj = {
          TEXT: "첫 페이지입니다.",
          submitEventHandler: () => {
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
        return;
      } else if (REDUCER_STATE.PAGE_COUNT >= 10) {
        let diff = REDUCER_STATE.PAGE_COUNT - 10;
        if ((REDUCER_STATE.PAGE_COUNT / 10) % 10 === 0) {
          changePageNumbersBackward();
        }
        if (diff < 10) {
          const paging_obj = {
            ROW_COUNT: REDUCER_STATE.ROW_COUNT,
            PAGE_COUNT: 0,
            TOTAL: REDUCER_STATE.TOTAL,
          };
          dispatch(updatePagingInfo(paging_obj));
        } else {
          const paging_obj = {
            ROW_COUNT: REDUCER_STATE.ROW_COUNT,
            PAGE_COUNT: REDUCER_STATE.PAGE_COUNT - 10,
            TOTAL: REDUCER_STATE.TOTAL,
          };
          dispatch(updatePagingInfo(paging_obj));
        }
      }
    } else if (type === "next") {
      if (
        REDUCER_STATE.PAGE_COUNT + 10 >= REDUCER_STATE.TOTAL ||
        REDUCER_STATE.ROW_COUNT === REDUCER_STATE.TOTAL
      ) {
        const obj = {
          TEXT: "마지막 페이지입니다.",
          submitEventHandler: () => {
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
        return;
      } else if (REDUCER_STATE.PAGE_COUNT < REDUCER_STATE.TOTAL) {
        let diff = REDUCER_STATE.PAGE_COUNT + 10;
        if (((REDUCER_STATE.PAGE_COUNT + 10) / 10) % 10 === 0) {
          changePageNumberForward();
        }
        if (diff > REDUCER_STATE.TOTAL) {
          const paging_obj = {
            ROW_COUNT: REDUCER_STATE.ROW_COUNT,
            PAGE_COUNT:
              REDUCER_STATE.TOTAL -
              (REDUCER_STATE.TOTAL - REDUCER_STATE.PAGE_COUNT),
            TOTAL: REDUCER_STATE.TOTAL,
          };
          dispatch(updatePagingInfo(paging_obj));
        } else {
          const paging_obj = {
            ROW_COUNT: REDUCER_STATE.ROW_COUNT,
            PAGE_COUNT: REDUCER_STATE.PAGE_COUNT + 10,
            TOTAL: REDUCER_STATE.TOTAL,
          };
          dispatch(updatePagingInfo(paging_obj));
        }
      }
    }
  };
  /**
   * 첫 페이지로 이동
   */
  const goFirstPage = () => {
    if (REDUCER_STATE.PAGE_COUNT - 10 < 0 || REDUCER_STATE.TOTAL <= 10) {
      const obj = {
        TEXT: "첫 페이지입니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (REDUCER_STATE.PAGE_COUNT >= 10) {
      setShowingNum({
        start: 1,
        end: REDUCER_STATE.ROW_COUNT,
      });
      const paging_obj = {
        ROW_COUNT: REDUCER_STATE.ROW_COUNT,
        PAGE_COUNT: 0,
        TOTAL: REDUCER_STATE.TOTAL,
      };
      dispatch(updatePagingInfo(paging_obj));
    }
  };
  /**
   * 마지막 페이지로 이동
   */
  const goLastPage = () => {
    if (
      REDUCER_STATE.PAGE_COUNT + 10 >= REDUCER_STATE.TOTAL ||
      REDUCER_STATE.ROW_COUNT === REDUCER_STATE.TOTAL
    ) {
      const obj = {
        TEXT: "마지막 페이지입니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (REDUCER_STATE.PAGE_COUNT < REDUCER_STATE.TOTAL) {
      const diff = REDUCER_STATE.TOTAL % 10;
      if (diff === 0) {
        const paging_obj = {
          ROW_COUNT: REDUCER_STATE.ROW_COUNT,
          PAGE_COUNT: REDUCER_STATE.TOTAL - 10,
          TOTAL: REDUCER_STATE.TOTAL,
        };
        dispatch(updatePagingInfo(paging_obj));
      } else {
        if (totalPage / REDUCER_STATE.ROW_COUNT !== 1) {
          setShowingNum({
            start: Math.floor(totalPage / REDUCER_STATE.ROW_COUNT) * 10 + 1,
            end: totalPage,
          });
        }
        const paging_obj = {
          ROW_COUNT: REDUCER_STATE.ROW_COUNT,
          PAGE_COUNT: REDUCER_STATE.TOTAL - diff,
          TOTAL: REDUCER_STATE.TOTAL,
        };
        dispatch(updatePagingInfo(paging_obj));
      }
    }
  };
  /**
   * 선택한 페이지로 이동
   */
  const goPageNumber = num => {
    const paging_obj = {
      ROW_COUNT: REDUCER_STATE.ROW_COUNT,
      PAGE_COUNT: num * 10,
      TOTAL: REDUCER_STATE.TOTAL,
    };
    dispatch(updatePagingInfo(paging_obj));
  };

  return (
    <div className="paging">
      <img
        className="cursor first_btn"
        src="/img/icon/common/first.png"
        alt="<<"
        onClick={() => goFirstPage()}
      />
      <img
        className="cursor prev_btn"
        src="/img/icon/common/prev.png"
        alt="<"
        onClick={() => changePage("prev")}
      />
      <div className="pageNumDiv">
        {pages.map(page => (
          <div
            key={"page_" + page}
            onClick={() => goPageNumber(page - 1)}
            className={
              REDUCER_STATE.PAGE_COUNT / 10 + 1 === page
                ? "pageText pageNow"
                : "pageText"
            }
          >
            {page}
          </div>
        ))}
      </div>
      <img
        className="cursor next_btn"
        src="/img/icon/common/next.png"
        alt=">"
        onClick={() => changePage("next")}
      />
      <img
        className="cursor end_btn"
        src="/img/icon/common/end.png"
        alt=">>"
        onClick={() => goLastPage()}
      />
    </div>
  );
}

export default Paging;
