import { SystemUpdateOutlined } from '@material-ui/icons';
import { inputAdornmentClasses } from '@mui/material';
import { closeAlert, openAlert, updatePagingInfo } from 'actions';
import "css/suggestion.css";
import axios from 'axios';
import Paging from 'component/common/paging';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Suggestionpopup from 'component/support/suggestionpopup';
import SearchTextInputSupport from 'component/support/searchTextinputsupport';

function Suggestion() {
  /**
 * Version : 1.0
 * 작성일자 : 2022-04-12
 * 작성자 : 정지윤
 * 설명 : 고객의 소리 등록/수정 화면
 */

        /**
   * REDUX
   */
    const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
         /**
          * DISPATCH
          */
         const dispatch = useDispatch();
         const [opennumber, setopennumber] = useState('');
         const [isOpenDetail, setIsOpenDetail] = useState(false);
         const openDetailPopup = (support_num) => {

           setIsOpenDetail(true);
           setopennumber(support_num)
          
         };
         const closeDetailPopup = () => {
    
           setIsOpenDetail(false);
           getsuggestionlist(searchOption.text,searchOptionname.text,inputstartdate,inputenddate)
           countsuggestion(searchOption.text,searchOptionname.text,inputstartdate,inputenddate)
         };

         const [searchOption, setSearchOption] = useState({
          text: "" });
        const [searchOptionname, setSearchOptionname] = useState({
            text: "" });

    const [inputstartdate, setinputstartdate] = useState('');
    const [inputenddate, setinputenddate] = useState('');

    const [suggestionlist, setsuggestionlist] = useState([]);
    
    const [suggestionexcel, setsuggestionexcel] = useState([]);
    const [suggestioncount, setsuggestioncount] = useState([]);

    const handleinputstartdate = (e) =>{
        setinputstartdate(e.target.value);
    }
    const handleinputenddate = (e) =>{
        setinputenddate(e.target.value);
    }

    const getsuggestionlist = useCallback((inputid,inputname,inputstartdate,inputenddate) =>{
        let startdate = '';
        if(inputstartdate !== undefined &&inputstartdate !== ''){
          startdate = inputstartdate+'T00:00:00'
        }
        else{
          startdate= null;
        }
        let enddate = '';
        if(inputenddate !== undefined&&inputenddate !== ''){
          enddate = inputenddate+'T23:59:59'
        }
        else{
          enddate= null;
        }
        let config ={
          params : {
            input_id : inputid,
            input_name : inputname,
            starttime : startdate,
            endtime : enddate,
            skip : PAGING_STATE.PAGE_COUNT,
            amount : PAGING_STATE.ROW_COUNT,
          }
        }
        axios.get("/support/SuggestionList",config).then((res)=> 
        {
            if(res.status===200){

                setsuggestionlist(res.data)

            }
        })
        
    },[PAGING_STATE.PAGE_COUNT,PAGING_STATE.ROW_COUNT]);

    const getsuggestionlistexcel = useCallback((inputid,inputname,inputstartdate,inputenddate) =>{
      let startdate = '';
      if(inputstartdate !== undefined &&inputstartdate !== ''){
        startdate = inputstartdate+'T00:00:00'
      }
      else{
        startdate= null;
      }
      let enddate = '';
      if(inputenddate !== undefined&&inputenddate !== ''){
        enddate = inputenddate+'T23:59:59'
      }
      else{
        enddate= null;
      }
      let config ={
        params : {
          input_id : inputid,
          input_name : inputname,
          starttime : startdate,
          endtime : enddate,
          skip : 0,
          amount : suggestioncount,
        }
      }
      axios.get("/support/SuggestionList",config).then((res)=> 
      {
          if(res.status===200){
              setsuggestionexcel(res.data)

          }
      })
      
  },[suggestioncount]);



    useEffect(()=>{
        getsuggestionlist(searchOption.text,searchOptionname.text,inputstartdate,inputenddate);
        },[getsuggestionlist])
        useEffect(()=>{
          getsuggestionlistexcel(searchOption.text,searchOptionname.text,inputstartdate,inputenddate);
          },[getsuggestionlistexcel])

    const countsuggestion = useCallback((inputid,inputname,inputstartdate,inputenddate)=>{
        let startdate = '';
        if(inputstartdate !== undefined &&inputstartdate !== ''){
          startdate = inputstartdate+'T00:00:00'
        }
        else{
          startdate= null;
        }
        let enddate = '';
        if(inputenddate !== undefined&&inputenddate !== ''){
          enddate = inputenddate+'T23:59:59'
        }
        else{
          enddate= null;
        }
  
  
        let config ={
          params : {
            input_id : inputid,
            input_name : inputname,
            starttime : startdate,
            endtime : enddate
          }
        }
        axios.get("/support/countSuggestion",config).then((res)=>{
            if(res.status===200){
              setsuggestioncount(res.data)
                const paging_obj={
                    ROW_COUNT: PAGING_STATE.ROW_COUNT,
                    PAGE_COUNT: 0,
                    TOTAL: res.data
                };
                dispatch(updatePagingInfo(paging_obj))
            }
        })
    },[PAGING_STATE.ROW_COUNT,dispatch]);



    useEffect(()=>{
        countsuggestion(searchOption.text,searchOptionname.text,inputstartdate,inputenddate)
    },[countsuggestion])


    const onClicksearch = (inputid,inputname,inputstartdate,inputenddate) =>{
      if(inputstartdate > inputenddate){
        const obj = {
          TEXT:  <>
          <div className='info-msg err'>
              오류!
          </div>
          <div className='info-msg'>
              종료 일자는 시작 일자보다
          </div>
          <div className='info-msg'>
              이후의 날짜로 설정해주세요!
          </div>
      </>,
          submitEventHandler: () => {dispatch(closeAlert()); }
        };
        dispatch(openAlert(obj));
      }else{
        getsuggestionlist(inputid,inputname,inputstartdate,inputenddate)
        countsuggestion(inputid,inputname,inputstartdate,inputenddate)
      }
    }



    const downloadExcel = () =>{
      let arr=[];
    
      for(let i = 0; i<suggestionexcel.length; i++){
        arr.push({
          Support_NUM : suggestionexcel[i].Support_NUM,
          USER_NUM : suggestionexcel[i].USER_NUM,
          USER_Nm : suggestionexcel[i].USER_Nm,
          Create_DTTM : suggestionexcel[i].CREATE_DTTM.replace("T", " "),
          Create_IP : suggestionexcel[i].CREATE_IP,
        })
      }

      
      const data = arr;

      const config = {
        responseType: "blob"
      };

      axios.post("/support/excel/Suggestion", data, config)
      .then(res => {

        const url = window.URL.createObjectURL(
          new Blob([res.data], {type : res.headers["content-type"]})
        );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "PEBBLE_고객의 소리.xlsx");
          document.body.appendChild(link);
          link.click();
      })
    
    
    }




  return (

    <div>
          {isOpenDetail ? (
      <Suggestionpopup
        open={isOpenDetail}
        close={closeDetailPopup}
        Support_NUM={opennumber}
      />
    ) : null}
      
      <div className='suggestion_header'>
      <div className= 'suggestionMaintitile'>고객의 소리</div>
      <div className= 'suggestionsubtitile'>{"고객센터 > 고객의 소리"}</div>

      </div>
        <div className='suggestion_search'> 
                <div  onKeyDown={e=>{
              if(e.key ==="Enter"){
                onClicksearch(searchOption.text,searchOptionname.text,inputstartdate,inputenddate)
              }
            }}>
                <SearchTextInputSupport
                    label="아이디를 입력해주세요"
                    type="long"
                    value={searchOption.text}
                    setValue={value => setSearchOption({ ...searchOption, text: value })}
                      // setPage={setPage}
                      // page={page}
                      // setIsSearch={setIsSearch}
                />
                </div>
                <div>
                <SearchTextInputSupport
                    label="사용자명을 입력해주세요"
                    type="long"
                    value={searchOptionname.text}
                    setValue={value => setSearchOptionname({ ...searchOptionname, text: value })}
                />
                </div>
                <div>
                <input type='date' className='Notice_startdate_input' onChange={handleinputstartdate} value={inputstartdate}></input>   
                </div>
                <div>
                <input type='date' className='Notice_enddate_input' onChange={handleinputenddate} value={inputenddate}></input>
                </div>
                <div>
                    <div className='suggestion_search_button cursor' onClick={()=>onClicksearch(searchOption.text,searchOptionname.text, inputstartdate, inputenddate)}> 검색</div>
                </div>

        </div>
      <div className='Notice_function'>
        <div></div>
        <div className='downloadbutton cursor' onClick={() => downloadExcel()}>
             <img type='image' alt='다운로드버튼' className='downloadbuttonimage' src='/img/icon\common/exceldown.png'></img>
                <span className='downloadbuttontext'>엑셀 다운로드</span>
             </div> 
        </div>
        <div style={{height: "555px"}}>
        <table className='suggestiontable'>
              <thead>
                <tr className='tablehead'>
                  <th className='suggestionindexno' >NO</th>
                  <th className='suggestionmember'>사용자 번호</th>
                  <th className='suggestionid'>사용자 아이디</th>
                  <th className='suggestionname'>사용자 명</th>
                  <th className='suggestionwritedate'>생성 일시</th>
                  <th className='suggestionip'>생성자IP</th>

                </tr>
              </thead>

              <tbody>          
                  {suggestionlist.map((data,index) =>(
                    <tr key={index} className='tablebody'>
                    <td className='suggestionindexno'>
                    {PAGING_STATE.PAGE_COUNT + index + 1}
                    </td>
                    
                  <td className='suggestionmember cursor' onClick={()=>openDetailPopup(data.Support_NUM)}>{data.USER_NUM}</td>
                 <td className='suggestionid'>{data.User_ID}</td>
                     <td className='suggestionname'>{data.USER_Nm}</td>
                    <td className='suggestionwritedate'>{data.CREATE_DTTM.replace("T", " ")}</td>
                    <td className='suggestionip'>{data.CREATE_IP}</td>
                  </tr>  
               )
                  )}
                
              </tbody>
     </table>
        </div>
        <div style={{
              marginTop : "27px",
              marginBottom : "46px"
            }}>
            <Paging />
            </div>

              


    </div>
  )
}

export default Suggestion