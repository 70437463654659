import React, { useEffect, useRef, useState } from 'react'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux';
import { closeAlert, openAlert, openConfirm } from 'actions';
import axios from 'axios';
import Loading from 'component/common/loading';
/**
 * Version : 1.0
 * 파일명 : ProductPopup.js
 * 작성일자 : 2022-05-20
 * 작성자 : 권도훈
 */
function ProductPopup(props) {
    ReactModal.setAppElement("body");
    /**
     * useDispatch
     */
    const dispatch = useDispatch();
    /**
     * state
     */
    //초기화
    const [init, setInit] = useState(true);
    //로딩
    const [loading, setLoading] = useState(false);
    //입력값
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [amount, setAmount] = useState("");
    const [discount, setDiscount] = useState("");
    const [duration, setDuration] = useState("");
    const [useYN, setUseYN] = useState("Y");
    const [description, setDescription] = useState("");
    /**
     * useRef
     */
    //입력창
    const nameRef = useRef();
    const amountRef = useRef();
    const discountRef = useRef();
    const durationRef = useRef();
    const descriptionRef = useRef();
    /**
     * useEffect
     */
    //초기화
    useEffect(() => {
        if (init && props.isOpen) {
            if (props.popupType === "CREATE") {
                setCode("PD01");
                setName("");
                setAmount("");
                setDiscount("");
                setDuration("");
                setUseYN("Y");
                setDescription("");
            } else {
                setCode(props.data.PRODUCT_CODE);
                setName(props.data.PRODUCT_NAME);
                setAmount(props.data.PRODUCT_PRICE);
                setDiscount(props.data.PRODUCT_DISCOUNT);
                setDuration(props.data.PRODUCT_DATE);
                setUseYN(props.data.VIEW_YN);
                setDescription(props.data.PRODUCT_COMMENT);
            }

            setInit(false);
        }

    }, [init, props.data, props.isOpen, props.popupType]);
    /**
     * function
     */
    //closeEvent
    const closeEventHandler = () => {
        props.setIsOpen(false);
        setInit(true);
    }
    //숫자 포맷
    const numberFormat = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    //값 확인
    const checkInputValue = () => {
        if (name === "" ||
            amount === "" ||
            discount === "" ||
            duration === "" ||
            description === ""
        ) {
            return false;
        } else {
            return true;
        }
    }
    //saveEvent
    const saveEventHandler = () => {
        const config = {
            params: {
                PRODUCT_TYPE: code,      //상품타입
                PRODUCT_NAME: name,
                PRODUCT_PRICE: amount.replaceAll(",", ""),
                PRODUCT_DISCOUNT: discount.replaceAll(",", ""),
                PRODUCT_COMMENT: description,
                PRODUCT_DATE: duration.replaceAll(",", ""),
                VIEW_YN: useYN
            }
        }

        setLoading(true);

        if (props.popupType === "CREATE") {
            axios.post("/payment/product", null, config)
                .then(res => {
                    setLoading(false);

                    if (res.status === 200) {
                        closeEventHandler();
                        const obj = {
                            TEXT:
                                <>
                                    <div className='info-msg success'>
                                        알림!
                                    </div>
                                    <div className='info-msg'>
                                        등록이 완료됐습니다.
                                    </div>
                                </>,
                            submitEventHandler: () => {
                                props.setPage({
                                    ...props.page,
                                    PAGE_COUNT: 0
                                });
                                props.setInit(true);
                                dispatch(closeAlert());
                            },
                        };
                        dispatch(openAlert(obj));
                    }
                })
                .catch(() => {
                    setLoading(false);
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg err'>
                                    알림!
                                </div>
                                <div className='info-msg'>
                                    등록을 실패했습니다.
                                </div>
                            </>,
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                })
        } else {
            axios.put("/payment/product", null, config)
                .then(res => {
                    setLoading(false);

                    if (res.status === 200) {
                        closeEventHandler();
                        const obj = {
                            TEXT:
                                <>
                                    <div className='info-msg success'>
                                        알림!
                                    </div>
                                    <div className='info-msg'>
                                        수정이 완료됐습니다.
                                    </div>
                                </>,
                            submitEventHandler: () => {
                                props.setPage({
                                    ...props.page,
                                    PAGE_COUNT: 0
                                });
                                props.setInit(true);
                                dispatch(closeAlert());
                            },
                        };
                        dispatch(openAlert(obj));
                    }
                })
                .catch(() => {
                    setLoading(false);
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg err'>
                                    알림!
                                </div>
                                <div className='info-msg'>
                                    수정을 실패했습니다.
                                </div>
                            </>,
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                })
        }


    }


    return (
        <ReactModal
            isOpen={props.isOpen}
            className="pd-popup-add"
            overlayClassName="popupOverlay"
            animationType="fade"
            transparent={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => closeEventHandler()}
        >
            <span className='pd-popup-add-close cursor center'>
                <img
                    src="/img/icon/common/close_black.png"
                    alt=''
                    onClick={() => closeEventHandler()}
                />
            </span>

            <div className='pd-popup-add-inner'>
                {
                    loading
                        ?
                        <Loading />
                        :
                        <>
                            <div className='pd-popup-add-title'>
                                <span>
                                    {
                                        props.popupType === "CREATE"
                                            ? "상품 등록"
                                            : "상품 수정"
                                    }
                                </span>
                            </div>

                            <div className='pd-popup-add-item'>
                                <span>
                                    상품명
                                </span>

                                <input
                                    className={
                                        name !== ""
                                            ? 'pd-popup-add-input text active'
                                            : 'pd-popup-add-input text'
                                    }
                                    type="text"
                                    placeholder='등록하실 상품명을 입력해주세요.'
                                    ref={nameRef}
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    onKeyDown={
                                        e => {
                                            if (e.key === "Enter") {
                                                amountRef.current.focus();
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className='pd-popup-add-item'>
                                <span>
                                    상품 가격
                                </span>

                                <div>
                                    <input
                                        className={
                                            amount !== ""
                                                ? 'pd-popup-add-input num active'
                                                : 'pd-popup-add-input num'
                                        }
                                        type="text"
                                        placeholder='등록하실 상품 가격을 입력해주세요.'
                                        ref={amountRef}
                                        value={numberFormat(amount)}
                                        onChange={e => {
                                            const amount = e.target.value.replaceAll(",", "").replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');;
                                            setAmount(amount)
                                        }}
                                        onKeyDown={
                                            e => {
                                                if (e.key === "Enter") {
                                                    discountRef.current.focus();
                                                }
                                            }
                                        }
                                    />

                                    <span className='pd-popup-add-input-span'>
                                        원
                                    </span>
                                </div>
                            </div>

                            <div className='pd-popup-add-item'>
                                <span>
                                    할인 금액
                                </span>

                                <div>
                                    <input
                                        className={
                                            discount !== ""
                                                ? 'pd-popup-add-input num active'
                                                : 'pd-popup-add-input num'
                                        }
                                        type="text"
                                        placeholder='등록하실 할인 금액을 입력해주세요.'
                                        ref={discountRef}
                                        value={numberFormat(discount)}
                                        onChange={e => {
                                            const discount = e.target.value.replaceAll(",", "").replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');;
                                            setDiscount(discount)
                                        }}
                                        onKeyDown={
                                            e => {
                                                if (e.key === "Enter") {
                                                    durationRef.current.focus();
                                                }
                                            }
                                        }
                                    />

                                    <span className='pd-popup-add-input-span'>
                                        원
                                    </span>
                                </div>
                            </div>

                            <div className='pd-popup-add-item'>
                                <span>
                                    적용 기간
                                </span>

                                <div>
                                    <input
                                        className={
                                            duration !== ""
                                                ? 'pd-popup-add-input num active'
                                                : 'pd-popup-add-input num'
                                        }
                                        type="text"
                                        placeholder='등록하실 적용 기간을 입력해주세요.'
                                        ref={durationRef}
                                        value={numberFormat(duration)}
                                        onChange={e => {
                                            const duration = e.target.value.replaceAll(",", "").replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');;
                                            setDuration(duration)
                                        }}
                                        onKeyDown={
                                            e => {
                                                if (e.key === "Enter") {
                                                    descriptionRef.current.focus();
                                                }
                                            }
                                        }
                                    />

                                    <span className='pd-popup-add-input-span'>
                                        일
                                    </span>
                                </div>
                            </div>

                            <div className='pd-popup-add-item'>
                                <span>
                                    사용 여부
                                </span>

                                <div className='pd-popup-add-ckb-area center'>
                                    <div className='pd-popup-add-ckb'>
                                        <input
                                            className='cursor'
                                            type="checkbox"
                                            checked={useYN === "Y" ? true : false}
                                            onChange={
                                                () => {
                                                    if (useYN !== "Y") {
                                                        setUseYN("Y");
                                                    }
                                                }
                                            }
                                        />
                                        <span>
                                            Yes
                                        </span>
                                    </div>

                                    <div className='pd-popup-add-ckb'>
                                        <input
                                            type="checkbox"
                                            checked={useYN === "N" ? true : false}
                                            className='cursor'
                                            onChange={
                                                () => {
                                                    if (useYN !== "N") {
                                                        setUseYN("N");
                                                    }
                                                }
                                            }
                                        />
                                        <span>
                                            No
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='pd-popup-add-item'>
                                <span>
                                    설명
                                </span>

                                <input
                                    className={
                                        description !== ""
                                            ? 'pd-popup-add-input text active'
                                            : 'pd-popup-add-input text'
                                    }
                                    type="text"
                                    placeholder='등록하실 상품의 설명을 입력해주세요.'
                                    ref={descriptionRef}
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                    onKeyDown={
                                        e => {
                                            if (e.key === "Enter") {
                                                if (checkInputValue()) {
                                                    const obj = {
                                                        TEXT:
                                                            <>
                                                                <div className='info-msg success'>
                                                                    저장!
                                                                </div>
                                                                <div className='info-msg'>
                                                                    {
                                                                        props.popupType === "CREATE"
                                                                            ?
                                                                            "상품을 등록하시겠습니까?"
                                                                            :
                                                                            "상품을 수정하시겠습니까?"
                                                                    }
                                                                </div>
                                                            </>,
                                                        submitEventHandler: () => {
                                                            saveEventHandler();
                                                            dispatch(closeAlert());
                                                        },
                                                    };
                                                    dispatch(openConfirm(obj));
                                                } else {
                                                    const obj = {
                                                        TEXT:
                                                            <>
                                                                <div className='info-msg err'>
                                                                    알림!
                                                                </div>
                                                                <div className='info-msg'>
                                                                    비어있는 값을 채워주세요.
                                                                </div>
                                                            </>,
                                                        submitEventHandler: () => {
                                                            dispatch(closeAlert());
                                                        },
                                                    };
                                                    dispatch(openAlert(obj));
                                                }
                                            }
                                        }
                                    }
                                />
                            </div>

                            <div className='pd-popup-add-btn-area'>
                                <button
                                    className='cursor btn grey'
                                    onClick={() => closeEventHandler()}
                                >
                                    취소
                                </button>

                                <button
                                    className='cursor btn blue'
                                    onClick={() => {
                                        if (checkInputValue()) {
                                            const obj = {
                                                TEXT:
                                                    <>
                                                        <div className='info-msg success'>
                                                            저장!
                                                        </div>
                                                        <div className='info-msg'>
                                                            {
                                                                props.popupType === "CREATE"
                                                                    ?
                                                                    "상품을 등록하시겠습니까?"
                                                                    :
                                                                    "상품을 수정하시겠습니까?"
                                                            }
                                                        </div>
                                                    </>,
                                                submitEventHandler: () => {
                                                    saveEventHandler();
                                                    dispatch(closeAlert());
                                                },
                                            };
                                            dispatch(openConfirm(obj));
                                        } else {
                                            const obj = {
                                                TEXT:
                                                    <>
                                                        <div className='info-msg err'>
                                                            알림!
                                                        </div>
                                                        <div className='info-msg'>
                                                            비어있는 값을 채워주세요.
                                                        </div>
                                                    </>,
                                                submitEventHandler: () => {
                                                    dispatch(closeAlert());
                                                },
                                            };
                                            dispatch(openAlert(obj));
                                        }
                                    }}
                                >
                                    {
                                        props.popupType === "CREATE"
                                            ?
                                            "등록"
                                            :
                                            "수정"
                                    }
                                </button>
                            </div>
                        </>
                }
            </div>

        </ReactModal>

    )
}

export default ProductPopup
