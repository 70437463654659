/**
 * Version : 1.0
 * 작성일자 : 2022-02-16
 * 작성자 : 권도훈
 * 설명 : action 정보
 */

/**
 * 페이징 정보 업데이트
 */
export const updatePagingInfo = data => {
  return {
    type: "pagingInfo/UPDATE",
    DATA: data,
  };
};

/**
 * Open Alert
 */
export const openAlert = data => {
  return {
    type: "alertInfo/OPEN_ALERT",
    DATA: data,
  };
};
/**
 * Open Confirm
 */
export const openConfirm = data => {
  return {
    type: "alertInfo/OPEN_CONFIRM",
    DATA: data,
  };
};
/**
 * Close Alert
 */
export const closeAlert = () => {
  return {
    type: "alertInfo/CLOSE",
  };
};
