import { closeAlert, openAlert, openConfirm, updatePagingInfo } from 'actions';
import axios from 'axios';
import Paging from 'component/common/paging';
import Faqwritepopup from 'component/support/faqwritepopup';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "css/faq.css";
import SearchTextInputSupport from 'component/support/searchTextinputsupport';
import SearchSelectBox from 'component/common/searchSelectBox';

function Faq() {
/**
 * Version : 1.0
 * 작성일자 : 2022-04-12
 * 작성자 : 정지윤
 * 설명 : FAQ 등록/수정 화면
 */
        /**
   * REDUX
   */
         const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
         /**
          * DISPATCH
          */
         const dispatch = useDispatch();


    const [inputkeyword, setinputkeyword] = useState('');
    const [selectexpose, setselectexpose] = useState('');
    const [selectcategory, setselectcategory] = useState('');
    const [inputstartdate, setinputstartdate] = useState('');
    const [inputenddate, setinputenddate] = useState('');

    const [isOpenDetail, setIsOpenDetail] = useState(false);
    const [opennumber, setopennumber] = useState('');
    const [opentype, setopentype] = useState('');
    const [searchOption, setSearchOption] = useState({
      text: "" });

    const handleInputkeyword = (e) =>{
        setinputkeyword(e.target.value);
      }
      const handleInputexpose = (e) =>{
        setselectexpose(e.target.value);
      }
      const handleInputcategory = (e) =>{
        setselectcategory(e.target.value);
      }
      const handleInputstartdate = (e) =>{
        setinputstartdate(e.target.value);
      }
      
      const handleInputenddate = (e) =>{
        setinputenddate(e.target.value);
      }

      const[FAQlist, setFAQList] = useState([]);
      const[FAQlistexcel, setFAQListexcel] = useState([]);

      const typeList = [
        { value: "", label: "노출여부" },
        { value: 'Y', label: "YES" },
        { value: "N", label: "NO" },
      ];


  const [selectType, setSelectType] = useState("");

      const getFAQList = useCallback((inputkeyword,selectexpose,selectcategory,inputstartdate,inputenddate) =>{

        let startdate = '';
        if(inputstartdate !== undefined &&inputstartdate !== ''){
          startdate = inputstartdate+'T00:00:00'
        }
        else{
          startdate= null;
        }
        let enddate = '';
        if(inputenddate !== undefined&&inputenddate !== ''){
          enddate = inputenddate+'T23:59:59'
        }
        else{
          enddate= null;
        }
        
        let category = '';
        if(selectcategory !== undefined&&selectcategory !== ''){
            category = selectcategory;
        }else{
            category=null;
        }

        let config = {
          params : {
            content : inputkeyword,
            expose_YN : selectexpose,
            category : category,
            starttime : startdate,
            endtime : enddate,
            skip : PAGING_STATE.PAGE_COUNT,
            amount : PAGING_STATE.ROW_COUNT,
          }
  
        }
        
        axios
        .get("/support/FAQList", config).then((res) =>
        {
          if(res.status===200){
            setFAQList(res.data)
  
          }
        })
  
  
      },[PAGING_STATE.PAGE_COUNT,PAGING_STATE.ROW_COUNT]
      );
      useEffect(() =>{
        getFAQList(searchOption.text,selectType,selectcategory,inputstartdate,inputenddate)
      },[getFAQList])


const [FAQtotal, setFAQtotal] = useState('');
const countFAQ = useCallback((inputkeyword,selectexpose,selectcategory,inputstartdate,inputenddate)=>{

  let startdate = '';
  if(inputstartdate !== undefined &&inputstartdate !== ''){
    startdate = inputstartdate+'T00:00:00'
  }
  else{
    startdate= null;
  }
  let enddate = '';
  if(inputenddate !== undefined&&inputenddate !== ''){
    enddate = inputenddate+'T23:59:59'
  }
  else{
    enddate= null;
  }


  let config ={
    params : {
      content : inputkeyword,
      expose_YN : selectexpose,
      Notice_Type : selectcategory,
      starttime : startdate,
      endtime : enddate
    }
  }
  axios.get("/support/countFAQ",config)
  .then((res)=>
  {
    if(res.status===200){
      setFAQtotal(res.data)
      const paging_obj = {
        ROW_COUNT: PAGING_STATE.ROW_COUNT,
        PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
        TOTAL: res.data
      };
      dispatch(updatePagingInfo(paging_obj));
    }
  })

},[dispatch]);

useEffect(()=>{
  countFAQ(searchOption.text,selectType,selectcategory,inputstartdate,inputenddate);
},[countFAQ])





      const [check, setcheck] = useState([]);
            const handleInputcheck = (checked, id) =>{
            if(checked){
                setcheck([...check, id])   
        }   else{
                setcheck(check.filter(el => el !== id));
        }
        };
        const handlecheckall = (checked) =>{
            if(checked){
                const idArray = [];
                FAQlist.forEach((el) => idArray.push(el.Notice_Num));    
                setcheck(idArray); 
            }  else{
                setcheck([]);
            }
        };

      const postall = FAQlist.length;

      const onClickSearch= (inputkeyword,selectexpose,selectcategory,inputstartdate,inputenddate) =>{
        if(inputstartdate > inputenddate){
          const obj = {
            TEXT: <>
            <div className='info-msg err'>
                오류!
            </div>
            <div className='info-msg'>
                종료 일자는 시작 일자보다
            </div>
            <div className='info-msg'>
                이후의 날짜로 설정해주세요!
            </div>
        </>,
            submitEventHandler: () => {dispatch(closeAlert()); }
          };
          dispatch(openAlert(obj));
        }else{
        getFAQList(inputkeyword,selectexpose,selectcategory,inputstartdate,inputenddate)
        countFAQ(inputkeyword,selectexpose,selectcategory,inputstartdate,inputenddate)
        }
      }


      const deletefaq = () =>{
        if (check.length <1){
            alert("삭제 대상을 선택해주세요")
          }else{
            let NOTICE_NUM = "";
            for(let i = 0; i<check.length; i++){
             NOTICE_NUM = NOTICE_NUM+ check[i] + ",";
            }
            const config = {
              params : {
                FAQ_NUM : NOTICE_NUM
              }
            }

            const obj = {
              TEXT: "삭제하시겠습니까 ?",
              submitEventHandler: () => {
                axios.post("/support/DeleteFAQ", null, config).then((res)=>{
                  if(res.status === 200){
                   const obj = {
                     TEXT: "삭제되었습니다.",
                     submitEventHandler: () => {dispatch(closeAlert());}
                   };
                   dispatch(openAlert(obj));
                   getFAQList();
                   countFAQ()
                  }
                })
              },
            };
            dispatch(openConfirm(obj));
   
          }
      }
      const getFAQListexcel = useCallback((inputkeyword,selectexpose,selectcategory,inputstartdate,inputenddate) =>{

        let startdate = '';
        if(inputstartdate !== undefined &&inputstartdate !== ''){
          startdate = inputstartdate+'T00:00:00'
        }
        else{
          startdate= null;
        }
        let enddate = '';
        if(inputenddate !== undefined&&inputenddate !== ''){
          enddate = inputenddate+'T23:59:59'
        }
        else{
          enddate= null;
        }
        
        let category = '';
        if(selectcategory !== undefined&&selectcategory !== ''){
            category = selectcategory;
        }else{
            category=null;
        }

        let config = {
          params : {
            content : inputkeyword,
            expose_YN : selectexpose,
            category : category,
            starttime : startdate,
            endtime : enddate,
            skip : 0,
            amount : FAQtotal
          }
  
        }
        
        axios
        .get("/support/FAQList", config).then((res) =>
        {
          if(res.status===200){
            setFAQListexcel(res.data)

          }
        })
  
  
      },[FAQtotal]
      );
      useEffect(() =>{
        getFAQListexcel(searchOption.text,selectType,selectcategory,inputstartdate,inputenddate)
      },[getFAQListexcel])

      const downloadExcel = () =>{
        let arr=[];
      
        for(let i = 0; i<FAQlistexcel.length; i++){

            
          arr.push({
            Notice_Title : FAQlistexcel[i].Notice_Title,
            Notice_Clickcount : FAQlistexcel[i].Notice_Clickcount,
            Notice_Expose_YN : FAQlistexcel[i].Notice_Expose_YN,
            Notice_Type : FAQlistexcel[i].Notice_Type,
            Create_Dttm : FAQlistexcel[i].Create_Dttm.replace("T", " "),
            Create_IP : FAQlistexcel[i].Create_IP,
          })
        }
        
        const data = arr;
        const config = {
          responseType: "blob"
        };
        axios.post("/support/excel/FAQ", data, config)
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], {type : res.headers["content-type"]})
          );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "PEBBLE_FAQ.xlsx");
            document.body.appendChild(link);
            link.click();
        })
      
      
      }


      const openDetailPopup = (faq_num, number) => {
        setIsOpenDetail(true);
        setopennumber(faq_num);
        setopentype(number)
       
      };
  
  
      const closeDetailPopup = () => {
  
          setIsOpenDetail(false);
          getFAQList(searchOption.text,selectType,selectcategory,inputstartdate,inputenddate)
          countFAQ(searchOption.text,selectType,selectcategory,inputstartdate,inputenddate)
        };
   
      

  return (
    <div>
             {isOpenDetail ? (
        <Faqwritepopup
          open={isOpenDetail}
          close={closeDetailPopup}
          faq_num={opennumber}
          opentype = {opentype}
        />
      ) : null}
        <div className='FAQ_header'>
            <div className= 'FAQMaintitile'>FAQ</div>
            <div className= 'FAQsubtitile'>{"고객센터 > FAQ"}</div>
        </div>

        <div className='FAQ_Search' style={{display : "flex"}}>
              <div className='FAQ_Searchbar'  onKeyDown={e=>{
              if(e.key ==="Enter"){
                onClickSearch(searchOption.text,selectType,selectcategory,inputstartdate,inputenddate)
              }
            }}>
                <SearchTextInputSupport
                    label="제목을 입력해주세요"
                    type="long"
                    value={searchOption.text}
                    setValue={value => setSearchOption({ ...searchOption, text: value })}
                      // setPage={setPage}
                      // page={page}
                      // setIsSearch={setIsSearch}
                />
</div>

<div className='FAQ_Search_Expose' onChange={handleInputexpose} value={selectexpose}>
<SearchSelectBox
                    label="노출여부"
                    type="short"
                    list={typeList}
                    value={selectType}
                    setValue={value => setSelectType(value)}
                  />   
</div>

{/* <div className='FAQ_Select_Category'>
<select className='FAQ_Select_Category' onChange={handleInputcategory} value={selectcategory}>
     <option value=''>FAQ유형</option>
     <option value='CD1302'>결제</option>
      <option value='CD1303'>예약</option>
      <option value='CD1304'>가입/탈퇴</option>
</select>
</div> */}

<div className='FAQ_Search_startdate'>
  <input type='date' className='FAQ_startdate_input' onChange={handleInputstartdate} value={inputstartdate}></input>
</div>

<div className='FAQ_Search_enddate'>
<input type='date' className='FAQ_enddate_input' onChange={handleInputenddate} value={inputenddate}></input>
</div>

<div className='FAQ_Search_button cursor' >
  <div className='FAQ_button_tosearch cursor' onClick={()=>onClickSearch(searchOption.text,selectType,selectcategory,inputstartdate,inputenddate)}>검색</div>
</div>



</div>

<div className='Notice_function'>
        <div className='Notice_function2'><div className='faq_insert cursor' onClick={()=>openDetailPopup(0,0)}>등록</div> 
        <div className='Notice_delete cursor' onClick={() =>deletefaq()}>삭제</div></div>
        <div className='downloadbutton cursor' onClick={() => downloadExcel()}>
             <img type='image' alt='다운로드버튼' className='downloadbuttonimage' src='/img/icon\common/exceldown.png'></img>
                <span className='downloadbuttontext'>엑셀 다운로드</span>
             </div>     
       </div> 



       <section className='ct_table noticetable'>
        <table className='faq_tablemain'>
              <thead>
                <tr className='noticetablehead'>
                <th className='checkboxnotice'><input type='checkbox' className='checkall'
                 onChange={e => {
                  handlecheckall(e.currentTarget.checked);
               }}
               checked ={check.length === postall ? true : false}
                ></input></th>
                  <th className='noticeno' >NO</th>
                  <th className='noticetitle'>제목</th>
                  <th className='noticeclickcount'>조회수</th>
                  <th className='noticeexpose'>노출여부</th>
                  <th className='noticehold'>질문 유형</th>
                  <th className='noticewritedate'>등록일시</th>
                  <th className='writedate2'>생성일시</th>
                  <th className='noticeIP'>생성자IP</th>
                </tr>
              </thead>

              <tbody>          
                  {FAQlist.map((data,index) =>(
                    <tr key={index} className='noticetablebody'>
                    <td className='checkbox'>
                      <input type='checkbox' className='check' id={data.Notice_Num} 
                   
                     onChange={e => {
                      handleInputcheck(e.currentTarget.checked, data.Notice_Num);
                    }}
                   
                    checked={check.includes(data.Notice_Num) ? true : false}
                    ></input></td>
                    <td className='noticeno'>
                    {PAGING_STATE.PAGE_COUNT + index + 1}
                    </td>
                    <td className='noticetitle cursor'
                      onClick={()=>openDetailPopup(data.Notice_Num,1)}>{data.Notice_Title}</td>
                    <td className='noticeclickcount'>{data.Notice_Clickcount}</td>
                    <td className='noticeexpose'>{data.Notice_Expose_YN}</td>
                    <td className='noticehold'>{data.Notice_Type}</td>
                    <td className='noticewritedate'>{data.Create_Dttm.replace("T", " ")}</td>
                    <td className='writedate2'>{data.Create_Dttm.replace("T", " ")}</td>
                    <td className='noticeIP'>{data.Create_IP}</td>
                  </tr>  
                  )
                  )}
                
              </tbody>
            </table>
      </section>
      <div style={{
              marginTop : "27px",
              marginBottom : "46px"
            }}>
            <Paging />
            </div>




    </div>
  )
}

export default Faq