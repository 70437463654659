import React from 'react'
/**
 * Version : 1.0
 * 파일명 : InfoContentNavgitor.js
 * 작성일자 : 2022-04-27
 * 작성자 : 권도훈
 */
function InfoContentNavgitor(props) {
    return (
        <nav className='info-content-nav'>
            <ol>
                <li
                    className={
                        props.type === "LICENSE"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("LICENSE");
                        props.setIsChangeType(true);
                    }}
                >
                    회의 이용권
                </li>
                <li
                    className={
                        props.type === "PAYLOG"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("PAYLOG");
                        props.setIsChangeType(true);
                    }}
                >
                    결제내역
                </li>
                <li
                    className={
                        props.type === "HISTORY"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("HISTORY");
                        props.setIsChangeType(true);
                    }}
                >
                    로그인 히스토리
                </li>
                <li
                    className={
                        props.type === "FAQ"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("FAQ");
                        props.setIsChangeType(true);
                    }}
                >
                    문의사항
                </li>
                <li
                    className={
                        props.type === "SUGGEST"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("SUGGEST");
                        props.setIsChangeType(true);
                    }}
                >
                    고객의 소리
                </li>
                <li
                    className={
                        props.type === "PAYMENT"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("PAYMENT");
                        props.setIsChangeType(true);
                    }}
                >
                    등록 결제수단
                </li>
                <li
                    className={
                        props.type === "PASSWORD"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("PASSWORD");
                        props.setIsChangeType(true);
                    }}
                >
                    비밀번호 변경이력
                </li>
                <li
                    className={
                        props.type === "SCHEDULE"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("SCHEDULE");
                        props.setIsChangeType(true);
                    }}
                >
                    예약 히스토리
                </li>
                <li
                    className={
                        props.type === "DELETE"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("DELETE");
                        props.setIsChangeType(true);
                    }}
                >
                    탈퇴 설문조사
                </li>
                <li
                    className={
                        props.type === "MAIL"
                            ? "active cursor"
                            : "cursor"
                    }
                    onClick={() => {
                        props.setType("MAIL");
                        props.setIsChangeType(true);
                    }}
                >
                    메일 발송내역
                </li>
            </ol>
        </nav>
    )
}

export default InfoContentNavgitor
