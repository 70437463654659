import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import "css/questionpopup.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "actions";
import Editorreact from "component/common/editorreact";
  /**
 * Version : 1.0
 * 작성일자 : 2022-04-12
 * 작성자 : 정지윤
 * 설명 : 문의사항 답변 등록/수정 모달화면
 */
function Questionpopupread(props) {


       /**
      * DISPATCH
      */
        const dispatch = useDispatch();
        let server_url = "";
        if (process.env.NODE_ENV === "development") {
         server_url =
           process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
       } else {
         server_url = process.env.REACT_APP_SERVER_URL;
       }

       const [filelistQ, setfilelistQ] = useState([]);

       const getfilelistQ = useCallback(()=>{
        let config = {
          params : {
            SUPPORT_NUM : props.data.Support_NUM
          }
        }
        axios.get("/support/getQuestionfiles",config).then((res)=>{
          if(res.status===200){
            setfilelistQ(res.data);
            
          }
        })
       },[props.data.Support_NUM])

       useEffect(() =>{
         getfilelistQ()
       },[getfilelistQ])
/* ================================================================================================================================================ */
const [writecontent, setwritecontent] = useState('');
const [writeexpose, setwirteexpose] = useState('CD1201');
const [upload, setupload] =useState('');
const [AnswerNum, setAnswerNUM] = useState('');
const handlewriteexpose = (e) =>{
  setwirteexpose(e.target.value);
}
const handlewritcontent = (e) =>{
  setwritecontent (e.target.value);
}

const writedata = (content) =>{
  setwritecontent(content);
}

const [totalFiles, setTotalFiles] = useState([]);
const [oldFiles, setOldFiles] = useState([]);
const [fileInfoList, setfileInfoList] = useState([]);

useEffect(() => {
  if (
    fileInfoList !== undefined &&
    fileInfoList !== null &&
    fileInfoList !== ""
  ) {
    let tmp = [];
    for (let i = 0; i < fileInfoList.length; i++) {
      tmp.push({
        FILE_NM: fileInfoList[i].FILE_NM,
        ORI_FILE_NM: fileInfoList[i].ORI_FILE_NM,
        type: "old",
        fileSize: fileInfoList[i].FILE_SIZE,
      });
    }
    setOldFiles(oldFiles.concat(tmp));
    setTotalFiles(totalFiles.concat(tmp));
  }
}, [fileInfoList]);

const [newFileList, setNewFileList] = useState([]);
const onChangeFile = e => {
  let tmpArr = [];
  let totalTmp = [];
  let scheduleFileTotalSize = 0;
  for (let i = 0; i < oldFiles.length; i++) {
    scheduleFileTotalSize += parseInt(oldFiles[i].fileSize);
  }
  for (let i = 0; i < newFileList.length; i++) {
    scheduleFileTotalSize += newFileList[i].size;
  }
  for (let i = 0; i < e.target.files.length; i++) {
    let sizeTotal = scheduleFileTotalSize;
    if (newFileList.find(file => file.name === e.target.files[i].name)) {
      const obj = {
        TEXT:  "이미 선택된 파일입니다.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    if (oldFiles.find(file => file.ORI_FILE_NM === e.target.files[i].name)) {
      const obj = {
        TEXT:  "이미 선택된 파일입니다.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    if (e.target.files[i].size > 10485760) {
      const obj = {
        TEXT:  "10MB 이하의 파일을 첨부해주세요.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    if (sizeTotal + e.target.files[i].size > 10485760) {
      const obj = {
        TEXT: "파일 크기 합계가 10MB 이상입니다. 사이즈를 줄여주세요",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    scheduleFileTotalSize += e.target.files[i].size;
    tmpArr.push(e.target.files[i]);
    totalTmp.push({ FILE_NM: e.target.files[i].name, type: "new" });
  }
  setNewFileList(newFileList.concat(tmpArr));
  setTotalFiles(totalFiles.concat(totalTmp));
  e.target.value = "";
};
const onRemoveFile = (fileNM, type) => {
  if (type === "old") {
    setOldFiles(oldFiles.filter(list => list.FILE_NM !== fileNM));
  } else if (type === "new") {
    setNewFileList(newFileList.filter(list => list.name !== fileNM));
  }
  setTotalFiles(totalFiles.filter(list => list.FILE_NM !== fileNM));
};


const gettingQuestion = useCallback(()=>{
  if(props.questiontype === 1){

return;
}else{
  setfileInfoList([]);
  setTotalFiles([]);
  let config = {
    params: {
      SUPPORT_NUM : props.data.Support_NUM
    }
  }
  axios.get("/support/getAnswer", config).then((res)=>{
    if(res.status===200){

      if(res.data.Answerdata ===null){
        setupload('1');
        return;
      }else{
        setwritecontent(res.data.Answerdata.ANSWER_CONTENT)
        setfileInfoList(res.data.Answerfiles)
        setAnswerNUM(res.data.Answerdata.ANSWER_NUM)
        return;
      }
    
    }

  })
}
},[props.data.Support_NUM])

useEffect(() =>{
  gettingQuestion()
}, [gettingQuestion])


const onClicksubmit = (content, expose) =>{

  const formData = new FormData();

  if (Array.isArray(newFileList) && newFileList.length > 0) {
    for (let i = 0; i < newFileList.length; i++) {
      formData.append("files", newFileList[i]);
    }

  }
  let parameter = {
    ANSWER_CONTENT : content,
    SUPPORT_NUM :  props.data.Support_NUM
  }

  formData.append("params", JSON.stringify(parameter))
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  };

  axios
  .post("/support/Answer", formData, config)
  .then(response =>{
    if (response.status===200){
    }
  })

  let datas = {
    params : {
      SUPPORT_NUM :  props.data.Support_NUM,
      SUPPORT_PROGRESS  : expose 
    }
  }
  axios.put("/support/updateprogress",null,datas).then((res) =>{
    if(res.status === 200){
    }
  })

  const obj = {
    TEXT: "답변이 등록되었습니다.",
    submitEventHandler: () =>{dispatch(closeAlert()); props.close();}
  };
  dispatch(openAlert(obj));

  

}
const onClickUpdate = ( content, expose) =>{
  const formData = new FormData();
  let parameter = {
    Notice_Content : content,
    SUPPORT_NUM :  props.data.Support_NUM,
    ANSWER_NUM : AnswerNum
  }

  if (Array.isArray(newFileList) && newFileList.length > 0) {
    for (let i = 0; i < newFileList.length; i++) {
      formData.append("files", newFileList[i]);
    }

  }
  if (Array.isArray(oldFiles) && oldFiles.length > 0) {
    parameter = {
      ...parameter,
      file_info: oldFiles,
    };
  } else if (oldFiles.length === 0) {
    if (fileInfoList === undefined) {
      parameter = {
        ...parameter,
        file_info: null,
      };
    } else if (fileInfoList !== undefined && fileInfoList.length > 0) {
      parameter = {
        ...parameter,
        file_info: [],
      };
    }
  }

  formData.append("params", JSON.stringify(parameter))

  
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  };

  axios.put("/support/updateAnswer", formData, config)
  .then(response =>{
    if (response.status===200){

    }
  })

  let datas = {
    params : {
      SUPPORT_NUM : props.data.Support_NUM,
      SUPPORT_PROGRESS  : expose 
    }
  }

  axios.put("/support/updateprogress",null,datas).then((res) =>{
    if(res.status === 200){
    }
  })

  const obj = {
    TEXT: "답변이 수정되었습니다.",
    submitEventHandler: () => {dispatch(closeAlert()); props.close();}
  };
  dispatch(openAlert(obj));

}

const  convertHTML = (value)=>{

  return <div dangerouslySetInnerHTML={ {__html: value} }>
  </div>
}
const clickclose = () =>{
  props.close();
}


  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="questionDetailPopup scrollbar" //모달 ClassName
      overlayClassName="questionDetailPopup_Overlay scrollbar" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="questionpopupbody scrollbar">
      <div style={{textAlign : "right"}}>
    <img  className='cursor'
          style={{marginRight : "10px",
                  marginTop : "10px"}}
          src="/img/icon/common/close_black.png"
          alt=''
          onClick={() => clickclose() }/>
      </div>
      
      <div className="w_question_title ">문의사항 상세보기</div>
     {props.questiontype === 1 
    ?
      <div className="g_question_guest"> 
        <div className="g_question_email">
          <div className="g_question_holdtxt">사용자 메일주소 :</div> <div>{props.data.EMAIL_ADDRESS}</div>
        </div>
        <div className="g_question_phonenum">
        <div className="g_question_holdtxt">사용자 전화번호 :</div><div>{props.data.PHONE_NUM}</div>
        </div>
        <div className="g_question_writedate">
          <div className="g_question_holdtxt">문의날짜 :</div><div>{props.data.CREATE_DTTM.replace("T", " ")}</div>
        </div>
        <div className="g_question_content">
        <div>문의내용</div><div className="g_question_content_r">{props.data.SUPPORT_CONTENT}</div>
        </div>

      </div>
    :
    <div className="m_question_all">
    <div>
      <div className="m_question_usernum"> 
    <div className="qusetion_textfix">사용자 번호 :</div><div>{props.data.USER_NUM}</div>
    </div>
    <div className="m_question_id">
      <div className="qusetion_textfix">사용자 아이디 :</div> <div>{props.data.User_ID}</div>
    </div>
    <div className="m_question_name">
      <div  className="qusetion_textfix">사용자 명 :</div> <div>{props.data.USER_Nm}</div>
    </div>
    <div className="m_question_writedate">
      <div className="qusetion_textfix">문의날짜 :</div><div>{props.data.CREATE_DTTM.replace("T", " ")}</div>
    </div>
    <div className="m_question_title">
    <div className="qusetion_textfix">제목 :</div><div>{props.data.SUPPORT_TITLE}</div>
    </div>
    <div >
    <div>문의내용</div><div className="m_question_content">{convertHTML(props.data.SUPPORT_CONTENT)}</div>
    </div>


    <div>
     
      
    {filelistQ.length<1 ? <div></div> : 
                               <div> <div>첨부파일</div>   
                              <div style={{
                                  overflowY : "scroll",
                                  height : "50px",
                  
                                }}>{filelistQ.map((data) =>(
                                 <div key={data.FILE_NM}
                                 
                                 onClick={() =>
                                  window.open(
                                    server_url +
                                      "/file/downloadFile?filename=" +
                                      data.FILE_NM +
                                      "&downfilename=" +
                                      data.ORI_FILE_NM,
                                    "_blank"
                                  )}>
                                  {data.ORI_FILE_NM}
                                  </div>
      ))}</div></div>}

    </div>
    <div className='question_answerYN'>
          <div >답변 상태</div>
          <input type='radio' className='exposeo' name='Expose_YN_CLICK' value="CD1202" onChange={handlewriteexpose} checked={writeexpose === 'CD1202'}/><label className='exposeo'>답변</label>
          <input type='radio'className='exposeno' name='Expose_YN_CLICK' value="CD1201" onChange={handlewriteexpose} checked={writeexpose === 'CD1201'}/><label className='exposeno'>미답변</label>
        </div>
  </div>
                                    <div>

                      <div>
                      <Editorreact content={writecontent} writedata={writedata} open = {1}/>
                    </div>


                                <div>
                                <div className='notice_file'>
                                <div className='notice_file_head'>
                                  <div className='notice_fontblack'>파일 목록</div> 
                                  </div>
                                  <div className='notice_input_file'>
                                  <div className='notice_input_fileinfo'>
                                  {Array.isArray(totalFiles) && totalFiles.length > 0 ? (
                                    <div className="notice_fileListscrollbar"
                                    style={{
                                      overflowY : "scroll",
                                      height : "50px",
                      
                                    }}>
                                        {totalFiles.map(data => (
                                          <div key={data.FILE_NM} className="notice_img_test"
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                                                }}>
                                            <font
                                              style={{
                                                textOverflow: "ellipsis",
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                                                color: "#3E3E4F",
                                              }}
                                            >
                                              {data.type === "old" ? 
                                              <div onClick={() =>
                                                window.open(
                                                  server_url +
                                                    "/file/downloadFile?filename=" +
                                                    data.FILE_NM +
                                                    "&downfilename=" +
                                                    data.ORI_FILE_NM,
                                                  "_blank"
                                                )}>
                                                {data.ORI_FILE_NM}
                                                </div> 
                                              : data.FILE_NM}
                                            </font>
                                            <img
                                              className="cursor"
                                              src="/img/icon/common/remove.png"
                                              alt="remove"
                                              style={{
                                                verticalAlign: "top",
                                                float: "right",
                                                marginRight: "8px",
                                              }}
                                              onClick={() => onRemoveFile(data.FILE_NM, data.type )}
                                            />
                                          </div>
                                        ))}
                                    
                                    </div>
                                  ) :(
                                    <div className="notice_inputFileplaceholder">
                                    10MB이내 파일만 가능합니다.
                                    </div>
                                  )
                                  }

                                    </div> 
                                  <div className='notice_inputfilebtn'>
                                  <label htmlFor="inputFile" className='noticeimagebtn cursor'>파일첨부</label>
                                  <input type='file' 
                                    id="inputFile" 
                                    className='noticeinputfilemain'
                                    placeholder='등록'
                                    // accept="image/png, image/jpg, image/bmp, image/gif" 
                                    style={{display:"none"}}
                                    onChange={onChangeFile}
                                    name="file"
                                    multiple />
                                  </div>



                                        </div>
                                      </div>
                                    </div>

                                                <div className="notice_submit_button">
                            {upload.length === 1  ?
                            <div className="notice_submit cursor"  onClick={()=>onClicksubmit(writecontent, writeexpose)}>
                            답변 등록
                          </div>: 
                          <div className="notice_submit cursor" onClick={()=>onClickUpdate(writecontent, writeexpose)}>
                          답변 수정           
                        </div>}
                      </div>         
                               </div>

  </div>
    }
      </div>
    </ReactModal>
  );
}

export default Questionpopupread;
