import React, { useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { useHistory } from "react-router";
import "css/app.css";
import Menu from "component/common/menu";
import Main from "page/main/main";
import Statistics from "page/statistics/statistics";
import SalesStatistics from "page/statistics/salesStatistics";
import MeetingStatistics from "page/statistics/meetingStatistics";
import User from "page/user/user";
import Userlog from "page/user/userlog";
import PayLog from "page/license/paylog";
import Schedule from "page/schedule/schedule";
import Group from "page/group/group";

import Suggestion from "page/support/suggestion";
import Notice from "page/support/notice";
import Question from "page/support/question";
import Faq from "page/support/faq";
import Info from "page/info/info";
import axios from "axios";
import { Cookies } from "react-cookie";
import Product from "page/product/product";
import Record from "page/record/record";
import Payment from "page/pay/payment";
import Recurring from "page/pay/recurring";
import MeetingPass from "page/license/meetingPass";
import Paylog from "page/pay/paylog";
import Licenseaccount from "page/licenseaccount/licenseaccount";

/**
 * Version : 1.0
 * 작성일자 : 2022-02-16
 * 작성자 : 권도훈
 * 설명 : App
 * 수정일자 : 2022-09-02
 * 작성자 : 강연승
 * 설명 : sobridge 홈페이지 링크 수정
 */
function App() {
  var cookies = useMemo(() => {
    return new Cookies();
  }, []);

  /**
   * history
   */
  const history = useHistory();

  const goLogout = () => {
    axios.post("/user/logout", null).then(res => {
      if (res.status === 200) {
        alert("로그아웃 되었습니다.");
        cookies.remove(process.env.REACT_APP_COOKIE_REFRESH, { path: "/" });
        cookies.remove(process.env.REACT_APP_COOKIE_AUTH, { path: "/" });
        cookies.remove(process.env.REACT_APP_COOKIE_TYPE, { path: "/" });

        window.location.href = "/";
      }
    });
  };

  return (
    <div className="app-div">
      <section className="app-section">
        <div className="app-sidebar">
          <div
            className="app-sidebar-logo"
            onClick={() =>
              history.push({
                pathname: "/app/main",
              })
            }
          >
            <img src="/img/logo.png" alt="logo" />
          </div>

          <Menu />
        </div>

        <div className="app-container">
          <nav className="app-container-header">
            <div
              className="cursor"
              style={{
                background: "#EE6C4D",
                boxShadow: "0px 6px 15px #96969629",
                width: "48px",
                height: "48px",
                borderRadius: "999px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "20px",
              }}
              onClick={() => goLogout()}
            >
              <img alt="로그아웃" src="/img/icon/menu/logout.png" />
            </div>

            <a href="https://www.sobridge.kr" target="_blank">
              <img
                alt="홈페이지"
                src="/img/icon/menu/home.png"
                style={{ marginRight: "9px" }}
              />
              So-Bridge 홈페이지
            </a>
          </nav>

          <div className="app-container-content scrollbar">
            <>
              <Switch>
                <Route exact path="/app/main">
                  <Main />
                </Route>
                <Route exact path="/app/user">
                  <User />
                </Route>

                <Route exact path="/app/userlog">
                  <Userlog />
                </Route>

                {/* <Route exact path="/app/license">
                  <PayLog />
                </Route> */}

                <Route exact path="/app/meetingPass">
                  <MeetingPass />
                </Route>

                <Route exact path="/app/schedule">
                  <Schedule />
                </Route>

                <Route exact path="/app/product">
                  <Product />
                </Route>

                <Route exact path="/app/record">
                  <Record />
                </Route>

                <Route exact path="/app/group">
                  <Group />
                </Route>

                <Route exact path="/app/payment">
                  <Payment />
                </Route>

                <Route exact path="/app/recurring">
                  <Recurring />
                </Route>

                <Route exact path="/app/paylog">
                  <Paylog />
                </Route>

                <Route exact path="/app/Notice">
                  <Notice />
                </Route>

                <Route exact path="/app/suggestion">
                  <Suggestion />
                </Route>
                <Route exact path="/app/question">
                  <Question />
                </Route>
                <Route exact path="/app/faq">
                  <Faq />
                </Route>

                <Route exact path="/app/info">
                  <Info />
                </Route>

                <Route exact path="/app/licenseaccount">
                  <Licenseaccount />
                </Route>

                <Route exact path="/app/salesStatistics">
                  <SalesStatistics />
                </Route>
                <Route exact path="/app/meetingStatistics">
                  <MeetingStatistics />
                </Route>
              </Switch>
            </>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
