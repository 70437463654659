import React from "react";
import { useDispatch } from "react-redux";
import { openAlert, closeAlert } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : Paging.js
 * 작성일자 : 2022-05-02
 * 작성자 : 권도훈
 */
function Paging(props) {
    const total = props.TOTAL.data.read();
    /**
     * DISPATCH
     */
    const dispatch = useDispatch();
    /**
     * 페이징
     */
    const changePage = type => {
        if (type === "prev") {
            if (props.page.PAGE_COUNT - 10 < 0 || total <= 10) {
                const obj = {
                    TEXT: "첫 페이지입니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
                return;
            } else if (props.page.PAGE_COUNT >= 10) {
                let diff = props.page.PAGE_COUNT - 10;

                if (diff < 10) {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: 0,
                    });
                } else {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.page.PAGE_COUNT - 10,
                    });
                }

                props.setIsSearch(true);
            }

        } else if (type === "next") {
            if (
                props.page.PAGE_COUNT + 10 >= total ||
                props.page.ROW_COUNT === total
            ) {
                const obj = {
                    TEXT: "마지막 페이지입니다.",
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
                return;
            } else if (props.page.PAGE_COUNT < total) {
                let diff = props.page.PAGE_COUNT + 10;

                if (diff > total) {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT:
                            total -
                            (total - props.page.PAGE_COUNT),
                    });
                } else {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.page.PAGE_COUNT + 10,
                    });
                }

                props.setIsSearch(true);
            }
        }
    };
    /**
     * 첫 페이지로 이동
     */
    const goFirstPage = () => {
        if (props.page.PAGE_COUNT - 10 < 0 || total <= 10) {
            const obj = {
                TEXT: "첫 페이지입니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                },
            };
            dispatch(openAlert(obj));
            return;
        } else if (props.page.PAGE_COUNT >= 10) {
            props.setPage({
                ...props.page,
                PAGE_COUNT: 0,
            });
            props.setIsSearch(true);
        }
    };
    /**
     * 마지막 페이지로 이동
     */
    const goLastPage = () => {
        if (
            props.page.PAGE_COUNT + 10 >= total ||
            props.page.ROW_COUNT === total
        ) {
            const obj = {
                TEXT: "마지막 페이지입니다.",
                submitEventHandler: () => {
                    dispatch(closeAlert());
                },
            };
            dispatch(openAlert(obj));
            return;
        } else if (props.page.PAGE_COUNT < total) {
            const diff = total % 10;

            if (diff === 0) {
                props.setPage({
                    ...props.page,
                    PAGE_COUNT: total - 10
                });
            } else {
                props.setPage({
                    ...props.page,
                    PAGE_COUNT: total - diff,
                });
            }

            props.setIsSearch(true);
        }
    };
    //페이지 카운트 포맷
    const pageCountFormat = () => {
        let pageArr = [];

        const maxPageCount = Math.ceil(total / props.page.ROW_COUNT);
        const currentPageCount = Math.floor(props.page.PAGE_COUNT / props.page.ROW_COUNT);
        const currentMaxPageCount = Math.floor(currentPageCount / 10);
        const nextMinPageCount = Math.floor(currentPageCount / 10);

        let currentMinPageCount = 0;

        if (currentMaxPageCount === nextMinPageCount) {
            currentMinPageCount = nextMinPageCount * props.page.ROW_COUNT;
        } else {
            currentMinPageCount = currentMaxPageCount * props.page.ROW_COUNT;
        }

        for (let i = currentMinPageCount; i < maxPageCount; i++) {
            pageArr.push({
                isActive: i === currentPageCount ? true : false,
                count: i + 1
            });

            if (pageArr.length >= 10) {
                return pageArr;
            }
        }

        return pageArr;
    }

    return (
        <>
            {
                total > 0
                    ?
                    <div className="info-paging">
                        <img
                            className="cursor first_btn"
                            src="/img/icon/common/first.png"
                            alt="<<"
                            onClick={() => goFirstPage()}
                        />
                        <img
                            className="cursor prev_btn"
                            src="/img/icon/common/prev.png"
                            alt="<"
                            onClick={() => changePage("prev")}
                        />

                        <div>
                            {
                                pageCountFormat().map((data, idx) => (
                                    <div
                                        key={idx}
                                        className={
                                            data.isActive
                                                ? "info-paging-num current-page drag-none"
                                                : "info-paging-num cursor"
                                        }

                                        onClick={() => {
                                            if (data.isActive) {
                                                return;
                                            }

                                            props.setPage({
                                                ...props.page,
                                                PAGE_COUNT:
                                                    data.count - 1 > 0
                                                        ? (data.count - 1) * props.page.ROW_COUNT
                                                        : 0
                                            });
                                            props.setIsSearch(true);
                                        }}
                                    >
                                        {data.count}
                                    </div>
                                ))
                            }
                        </div>

                        < img
                            className="cursor next_btn"
                            src="/img/icon/common/next.png"
                            alt=">"
                            onClick={() => changePage("next")
                            }
                        />
                        < img
                            className="cursor end_btn"
                            src="/img/icon/common/end.png"
                            alt=">>"
                            onClick={() => goLastPage()}
                        />
                    </div>
                    :
                    <></>
            }
        </>
    );
}

export default Paging;
