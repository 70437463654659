import React, { useState, useEffect, useCallback } from "react";
import "css/group.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert, openAlert, updatePagingInfo } from "actions/index";
import Paging from "component/common/paging";
import DatePicker from "component/common/datePicker";
import Loading from "component/common/loading";
import GroupMeetingPassPopup from "component/group/groupMeetingPassPopup";
import GroupHostPopup from "component/group/groupHostPopup";
import SearchSelectBox from "component/common/searchSelectBox";
/**
 * Version : 1.0
 * 작성일자 : 2022-05-09
 * 작성자 : 강연승
 * 설명 : 그룹관리 - 그룹현황
 */
function Group() {
  /**
   * REDUX
   */
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState({
    GROUP_NM: "",
    GROUP_EXPLN: "",
    OWNER_NM: "",
  });
  const [searchDate, setSearchDate] = useState({
    START_TIME: "",
    END_TIME: "",
  });

  const [category, setCategory] = useState("");
  const [useYN, setUseYN] = useState("");
  const [favoriteYN, setFavoriteYN] = useState("");
  const [groupList, setGroupList] = useState([]);

  //selectbox
  const groupThemaList = [
    { value: "", label: "전체" },
    { value: "CD0402", label: "기업" },
    { value: "CD0403", label: "학교" },
    { value: "CD0404", label: "학원" },
    { value: "CD0405", label: "대학교" },
    { value: "CD0406", label: "친목" },
  ];
  const useYnList = [
    { value: "", label: "전체" },
    { value: "Y", label: "Y" },
    { value: "N", label: "N" },
  ];
  const favoriteYnList = [
    { value: "", label: "전체" },
    { value: "Y", label: "Y" },
    { value: "N", label: "N" },
  ];

  /**
   * 검색값 onChange
   */
  const onChange = e => {
    const { value, name } = e.target;
    setSearchText({
      ...searchText,
      [name]: value,
    });
  };

  /**
   * Set Group Total
   */
  const getGroupTotal = useCallback(
    (text, date, thema, use, favorite) => {
      let param = {};
      if (text.GROUP_NM !== "") {
        param = {
          ...param,
          SEARCH_GROUP_NM: text.GROUP_NM,
        };
      }
      if (text.GROUP_EXPLN !== "") {
        param = {
          ...param,
          SEARCH_GROUP_EXPLN: text.GROUP_EXPLN,
        };
      }
      if (text.OWNER_NM !== "") {
        param = {
          ...param,
          SEARCH_OWNER_NM: text.OWNER_NM,
        };
      }
      if (thema !== "") {
        param = {
          ...param,
          SEARCH_CATEGORY: thema,
        };
      }
      if (use !== "") {
        param = {
          ...param,
          SEARCH_DELETE_YN: use,
        };
      }
      if (favorite !== "") {
        param = {
          ...param,
          SEARCH_FAVORITE_YN: favorite,
        };
      }
      if (date.START_TIME !== "" && date.END_TIME !== "") {
        param = {
          ...param,
          START_TIME: date.START_TIME + "T00:00:00",
          END_TIME: date.END_TIME + "T23:59:59",
        };
      }
      axios
        .get("/group/admin/groupCnt", {
          params: param,
        })
        .then(res => {
          if (res.status === 200) {
            const paging_obj = {
              ROW_COUNT: PAGING_STATE.ROW_COUNT,
              PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
              TOTAL: res.data.TOTAL,
            };
            dispatch(updatePagingInfo(paging_obj));
          }
        })
        .catch(() => {});
    },
    [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT, dispatch]
  );

  /**
   * 그룹 현황 조회
   */
  const getGroupList = useCallback(
    (text, date, thema, use, favorite) => {
      setLoading(true);
      let param = {
        ROW_COUNT: PAGING_STATE.ROW_COUNT,
        PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
      };
      if (text.GROUP_NM !== "") {
        param = {
          ...param,
          SEARCH_GROUP_NM: text.GROUP_NM,
        };
      }
      if (text.GROUP_EXPLN !== "") {
        param = {
          ...param,
          SEARCH_GROUP_EXPLN: text.GROUP_EXPLN,
        };
      }
      if (text.OWNER_NM !== "") {
        param = {
          ...param,
          SEARCH_OWNER_NM: text.OWNER_NM,
        };
      }
      if (thema !== "") {
        param = {
          ...param,
          SEARCH_CATEGORY: thema,
        };
      }
      if (use !== "") {
        param = {
          ...param,
          SEARCH_DELETE_YN: use,
        };
      }
      if (favorite !== "") {
        param = {
          ...param,
          SEARCH_FAVORITE_YN: favorite,
        };
      }
      if (date.START_TIME !== "" && date.END_TIME !== "") {
        param = {
          ...param,
          START_TIME: date.START_TIME + "T00:00:00",
          END_TIME: date.END_TIME + "T23:59:59",
        };
      }
      axios
        .get("/group/admin/groupList", {
          params: param,
        })
        .then(res => {
          if (res.status === 200) {
            setGroupList(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT]
  );

  /**
   * useEffect
   */
  useEffect(() => {
    getGroupTotal(searchText, searchDate, category, useYN, favoriteYN);
    getGroupList(searchText, searchDate, category, useYN, favoriteYN);
  }, [getGroupTotal, getGroupList]);
  /**
   * 검색 버튼
   */
  const onSearch = () => {
    if (searchDate.START_TIME > searchDate.END_TIME) {
      const obj = {
        TEXT: (
          <>
            <div className="info-msg err">오류!</div>
            <div className="info-msg">종료 일자는 시작 일자보다</div>
            <div className="info-msg">이후의 날짜로 설정해주세요!</div>
          </>
        ),
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    } else {
      getGroupTotal(searchText, searchDate, category, useYN, favoriteYN);
      getGroupList(searchText, searchDate, category, useYN, favoriteYN);
    }
  };

  const groupCategory = code => {
    switch (code) {
      case "CD0402":
        return "기업";
      case "CD0403":
        return "학교";
      case "CD0404":
        return "학원";
      case "CD0405":
        return "대학교";
      case "CD0406":
        return "친목";
      default:
        return "기업";
    }
  };

  /**
   * 그룹 미팅 호스트 현황 상세보기
   */
  const [isOpenHostDetail, setIsOpenHostDetail] = useState(false);
  const [isOpenPassDetail, setIsOpenPassDetail] = useState(false);
  const [groupInfo, setGroupInfo] = useState({
    groupNum: "",
    groupNm: "",
  });

  const openDetailPopup = (type, num, nm) => {
    let data = {
      groupNum: num,
      groupNm: nm,
    };
    setGroupInfo(data);
    if (type === "HOST") {
      setIsOpenHostDetail(true);
    } else if (type === "PASS") {
      setIsOpenPassDetail(true);
    }
  };

  const closeDetailPopup = type => {
    if (type === "HOST") {
      setIsOpenHostDetail(false);
    } else if (type === "PASS") {
      setIsOpenPassDetail(false);
    }
  };

  /**
   * 엑셀 다운로드
   */
  const downloadExcel = (text, date, thema, use, favorite) => {
    let param = {};
    if (text.GROUP_NM !== "") {
      param = {
        ...param,
        SEARCH_GROUP_NM: text.GROUP_NM,
      };
    }
    if (text.GROUP_EXPLN !== "") {
      param = {
        ...param,
        SEARCH_GROUP_EXPLN: text.GROUP_EXPLN,
      };
    }
    if (text.OWNER_NM !== "") {
      param = {
        ...param,
        SEARCH_OWNER_NM: text.OWNER_NM,
      };
    }
    if (thema !== "") {
      param = {
        ...param,
        SEARCH_CATEGORY: thema,
      };
    }
    if (use !== "") {
      param = {
        ...param,
        SEARCH_DELETE_YN: use,
      };
    }
    if (favorite !== "") {
      param = {
        ...param,
        SEARCH_FAVORITE_YN: favorite,
      };
    }
    if (date.START_TIME !== "" && date.END_TIME !== "") {
      param = {
        ...param,
        START_TIME: date.START_TIME + "T00:00:00",
        END_TIME: date.END_TIME + "T23:59:59",
      };
    }

    const config = {
      params: param,
      responseType: "blob",
    };
    axios.post("/group/admin/groupList/excel", null, config).then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Pebble_그룹관리_그룹현황.xlsx");
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: loading ? "block" : "none",
            zIndex: "997",
            position: "absolute",
            width: "calc(100% - 285px)",
            height: "calc(100% - 91px)",
          }}
        >
          <Loading />
        </div>
      ) : null}
      <div className="group_section">
        {isOpenHostDetail ? (
          <GroupHostPopup
            open={isOpenHostDetail}
            close={closeDetailPopup}
            groupInfo={groupInfo}
          />
        ) : null}

        {isOpenPassDetail ? (
          <GroupMeetingPassPopup
            open={isOpenPassDetail}
            close={closeDetailPopup}
            groupInfo={groupInfo}
          />
        ) : null}

        <div className="group_title">
          <font className="mainTitle">그룹현황</font>
          <font className="subTitle">{"그룹관리 > 그룹현황"}</font>
        </div>
        <div className="group_searchArea">
          <input
            type="text"
            name="GROUP_NM"
            className="group_searchInput groupNM"
            placeholder="그룹명"
            value={searchText.GROUP_NM}
            onChange={onChange}
          />
          <input
            type="text"
            name="GROUP_EXPLN"
            className="group_searchInput groupNM"
            placeholder="그룹 설명"
            value={searchText.GROUP_EXPLN}
            onChange={onChange}
          />
          <input
            type="text"
            name="OWNER_NM"
            className="group_searchInput groupOwnerNM"
            placeholder="오너명"
            value={searchText.OWNER_NM}
            onChange={onChange}
          />

          <SearchSelectBox
            label="카테고리"
            type="short"
            list={groupThemaList}
            value={category}
            setValue={value => setCategory(value)}
          />

          <SearchSelectBox
            label="사용여부"
            type="short"
            list={useYnList}
            value={useYN}
            setValue={value => setUseYN(value)}
          />

          <SearchSelectBox
            label="즐겨찾기"
            type="short"
            list={favoriteYnList}
            value={favoriteYN}
            setValue={value => setFavoriteYN(value)}
          />

          <DatePicker
            type="START"
            date={searchDate}
            setDate={newValue => {
              setSearchDate({
                ...searchDate,
                START_TIME: newValue.START,
              });
            }}
          />
          <font className="group_dateRange">~</font>
          <DatePicker
            type="END"
            date={searchDate}
            setDate={newValue => {
              setSearchDate({
                ...searchDate,
                END_TIME: newValue.END,
              });
            }}
          />
          <button className="group_searchBtn" onClick={onSearch}>
            검색
          </button>
        </div>
        <div className="group_excelBtnArea">
          <div className="group_excelDownBtn">
            <img
              className="group_excelIcon"
              src="/img/icon/common/exceldown.png"
              alt="excel"
            />
            <font
              className="group_excelDownText"
              onClick={() =>
                downloadExcel(
                  searchText,
                  searchDate,
                  category,
                  useYN,
                  favoriteYN
                )
              }
            >
              엑셀 다운로드
            </font>
          </div>
        </div>
        <div className="group_list">
          <table className="group_table">
            <colgroup>
              <col width="40px" />
              <col width="130px" />
              <col width="202px" />
              <col width="153px" />
              <col width="143px" />
              <col width="102px" />
              <col width="100px" />
              <col width="100px" />
              <col width="80px" />
              <col width="100px" />
              <col width="180px" />
              <col width="240px" />
            </colgroup>
            <thead>
              <tr>
                <th>NO</th>
                <th>그룹 번호</th>
                <th>그룹 명</th>
                <th>오너 명</th>
                <th>그룹 설명</th>
                <th>카테고리 명</th>
                <th style={{ textAlign: "center" }}>호스트 수</th>
                <th style={{ textAlign: "center" }}>이용권 수</th>
                <th style={{ textAlign: "center" }}>사용 여부</th>
                <th style={{ textAlign: "center" }}>즐겨찾기 여부</th>
                <th>생성일시</th>
                <th>생성자IP</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(groupList) && groupList.length > 0 ? (
                groupList.map((data, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      {PAGING_STATE.PAGE_COUNT + index + 1}
                    </td>
                    <td>{data.GROUP_NUM}</td>
                    <td className="groupList_groupNM" title={data.GROUP_NM}>
                      {data.GROUP_NM}
                    </td>
                    <td className="groupList_ownerNM" title={data.OWNER_NM}>
                      {data.OWNER_NM}
                    </td>
                    <td
                      className="groupList_groupExpln"
                      title={data.GROUP_EXPLN}
                    >
                      {data.GROUP_EXPLN}
                    </td>
                    <td>{groupCategory(data.CTGRY_CD)}</td>
                    <td style={{ textAlign: "center", padding: "6px 11px" }}>
                      <div
                        className="group_detail_btn"
                        onClick={() =>
                          openDetailPopup("HOST", data.GROUP_NUM, data.GROUP_NM)
                        }
                      >
                        {data.HOST_CNT}
                      </div>
                    </td>
                    <td style={{ textAlign: "center", padding: "6px 11px" }}>
                      <div
                        className="group_detail_btn"
                        onClick={() =>
                          openDetailPopup("PASS", data.GROUP_NUM, data.GROUP_NM)
                        }
                      >
                        {data.GROUP_LICENSE_CNT}
                      </div>
                    </td>
                    <td style={{ textAlign: "center" }}>{data.USE_YN}</td>
                    <td style={{ textAlign: "center" }}>{data.FAVORITE_YN}</td>
                    <td>{data.CREATE_DT.replace("T", " ")}</td>
                    <td>{data.CREATE_IP}</td>
                  </tr>
                ))
              ) : groupList.length === 0 ? (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    조회된 데이터가 없습니다.
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>

          <div className="group_paging">
            <Paging />
          </div>
        </div>
      </div>
    </>
  );
}

export default Group;
