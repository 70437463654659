import React from 'react'
/**
 * Version : 1.0
 * 파일명 : PaylogDetailList.js
 * 작성일자 : 2022-05-17
 * 작성자 : 권도훈
 * 설명 : 결제 내역 상세보기 회의이용권 목록
 */
function PaylogDetailList(props) {
    const list = props.list.data.read();
    const count = props.total.data.read();

    //회의이용권 포맷
    const licenseFormat = license => {
        if (license !== undefined) {
            const licenseArr = license.split("-");
            return licenseArr[0].replace(/(.{6})/g, "$1-");
        } else {
            return "-";
        }
    };

    return (
        <>
            <section className="pf-paydetail-section">
                <div className="pf-paydetail-section-inner">
                    <div className="pf-paydetail-section-item">
                        <div>
                            <span className="pf-paydetail-section-line" />
                            <p>다음 결제일</p>
                        </div>

                        <p>{list.PAY_DY}</p>
                    </div>

                    <div className="pf-paydetail-section-item">
                        <div>
                            <span className="pf-paydetail-section-line" />
                            <p>이용권 구매 수량</p>
                        </div>

                        <p>{count.TOTAL}개</p>
                    </div>
                </div>
            </section>

            <div>
                <div className="pf-paydetail-list">
                    <div className="pf-paydetail-list-header">
                        구매 이용권 코드
                    </div>

                    <div className="pf-paydetail-list-content">
                        {
                            list.LICENSE_LIST.map((data, idx) => (
                                <div
                                    className={
                                        data.SUBSCRIBE_YN === "Y"
                                            ? "pf-paydetail-list-content-item"
                                            : "pf-paydetail-list-content-item line"
                                    }
                                    key={idx}
                                >
                                    {licenseFormat(data.LICENSE_CODE)}

                                    {
                                        data.SUBSCRIBE_YN === "N"
                                            ?
                                            <font className="pf-paydetail-list-content-comment">
                                                구독해지완료
                                            </font>
                                            : <></>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaylogDetailList
