import Loading from 'component/common/loading';
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { fetchAxiosEvent } from './axiosEvent';
import FaqListContent from './content/faqListContent';
import Paging from './content/paging';
import PaymentContent from './content/paymentContent';
/**
 * Version : 1.0
 * 파일명 : Payment.js
 * 작성일자 : 2022-05-04
 * 작성자 : 권도훈
 */
function Payment(props) {
    const [init, setInit] = useState(false);
    const [list, setList] = useState(
        fetchAxiosEvent(
            "GET",
            "/payment/admin",
            {
                config: {
                    params: {
                        USER_NUM: props.userNum
                    }
                }
            }
        )
    );

    const getPayment = useCallback(() => {
        const obj = {
            config: {
                params: {
                    USER_NUM: props.userNum
                }
            }
        }
        setList(fetchAxiosEvent("GET", "/payment/admin", obj));
    }, [props.userNum]);

    useEffect(() => {
        if (init) {
            getPayment();
            setInit(false);
        }
    }, [getPayment, init]);

    return (
        <div className='info-content-inner table'>
            <table className='info-content-table'>
                <colgroup>
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="10%" />
                    <col width="15%" />
                    <col width="15%" />
                    <col width="20%" />
                </colgroup>

                <thead>
                    <tr>
                        <th>카드 / 계좌 여부</th>
                        <th>이름</th>
                        <th>카드 / 은행 명</th>
                        <th>구분</th>
                        <th>카드 / 계좌 번호</th>
                        <th>등록일자</th>
                        <th>생성일시</th>
                        <th>생성자 IP</th>
                    </tr>
                </thead>

                <tbody>
                    <Suspense
                        fallback={
                            <tr>
                                <td colSpan="8">
                                    <div className="info-content-body-loading">
                                        <Loading />
                                    </div>
                                </td>
                            </tr>
                        }
                    >
                        <PaymentContent list={list} />
                    </Suspense>
                </tbody>
            </table>
        </div>
    )
}

export default Payment
