import axios from "axios";
import React from "react";
import "css/userlog.css";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updatePagingInfo } from "actions/index";
import Paging from "component/common/paging";
import SearchTextInputSupport from "component/support/searchTextinputsupport";
import SearchSelectBox from "component/common/searchSelectBox";
function Userlog() {
  const [countLog, setcountLog] = useState();
  const [loglist, setloglist] = useState([]);
  const [loglistdown, setloglistdown] = useState([]);
  const [Selected, setSelected] = useState("");

  /**
   * REDUX
   */
  const PAGING_STATE = useSelector((state) => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();

  const handleChangeSelect = (e) => {
    setSelected(e.target.value);
  };
  const [searchOption, setSearchOption] = useState({
    text: "",
  });

  const [selectType, setSelectType] = useState("");

  const typeList = [
    { value: "", label: "구분" },
    { value: "Y", label: "성공" },
    { value: "N", label: "실패" },
  ];

  const getLogcount = useCallback(
    (selected, keyword) => {
      let ref = {
        params: {
          SUCCESS: selected,
          content: keyword,
        },
      };

      axios.get("/user/CountLog", ref).then((res) => {
        if (res.status === 200) {
          setcountLog(res.data);
          const paging_obj = {
            ROW_COUNT: PAGING_STATE.ROW_COUNT,
            PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
            TOTAL: res.data.TOTAL,
          };
          dispatch(updatePagingInfo(paging_obj));
        }
      });
    },
    [dispatch]
  );

  useEffect(() => {
    getLogcount(selectType, searchOption.text);
  }, [getLogcount]);

  const getLoglist = useCallback(
    (selected, keyword) => {
      let config = {
        params: {
          SUCCESS: selected,
          content: keyword,
          skip: PAGING_STATE.PAGE_COUNT,
          amount: PAGING_STATE.ROW_COUNT,
        },
      };
      axios.get("/user/LogList", config).then((res) => {
        if (res.status === 200) {
          setloglist(res.data);
        }
      });
    },
    [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT]
  );

  useEffect(() => {
    getLoglist(selectType, searchOption.text);
  }, [getLoglist]);

  const getLoglistdownload = useCallback((selected, keyword) => {
    let config = {
      params: {
        SUCCESS: selected,
        content: keyword,
      },
    };
    axios.get("/user/LogListDownload", config).then((res) => {
      if (res.status === 200) {
        setloglistdown(res.data);
      }
    });
  }, []);

  useEffect(() => {
    getLoglistdownload(selectType, searchOption.text);
  }, [getLoglistdownload]);

  const onClickSearch = (Selected, keyword) => {
    getLoglist(Selected, keyword);
    getLoglistdownload(Selected, keyword);
    getLogcount(Selected, keyword);
  };

  const downloadExcel = () => {
    let arr = [];

    for (let i = 0; i < loglistdown.length; i++) {
      if (loglistdown[i].USER_NM === undefined) {
        loglistdown[i].USER_NM = "-";
      }
      arr.push({
        User_Num: loglistdown[i].User_Num,
        REQUEST_ID: loglistdown[i].REQUEST_ID,
        USER_NM: loglistdown[i].USER_NM,
        SUCCESS_YN: loglistdown[i].SUCCESS_YN,
        CREATE_DT: loglistdown[i].CREATE_DT,
        CREATE_IP: loglistdown[i].CREATE_IP,
      });
    }

    const data = arr;
    const config = {
      responseType: "blob",
    };
    axios.post("/user/excel/userlog", data, config).then((res) => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "사용자접속내역.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <div className="Userlogbody">
      <section className="ct_section">
        <div className="title_big">접속현황</div>
        <div className="title_small">회원관리　&gt;&nbsp;접속현황</div>
      </section>
      <section className="ct_searchbar">
        <div
          style={{ marginLeft: "60px" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSearch(selectType, searchOption.text);
            }
          }}
        >
          <SearchTextInputSupport
            label="아이디를 입력하세요"
            type="long"
            value={searchOption.text}
            setValue={(value) =>
              setSearchOption({ ...searchOption, text: value })
            }
          />
        </div>

        <div>
          <SearchSelectBox
            label="성공여부"
            type="short"
            list={typeList}
            value={selectType}
            setValue={(value) => setSelectType(value)}
          />
        </div>

        <div
          type="button"
          className="searchbutton"
          onClick={() => onClickSearch(selectType, searchOption.text)}
        >
          <span className="searchbuttontext cursor">검색</span>
        </div>
      </section>

      <div className="ct_download">
        <div></div>
        <div className="exceldownloadbutton" onClick={() => downloadExcel()}>
          <img
            type="image"
            alt="다운로드버튼"
            className="exceldownloadbuttonimage"
            src="/img/icon\common/exceldown.png"
          ></img>
          <span className="exceldownloadbuttontext cursor">엑셀 다운로드</span>
        </div>
      </div>

      <section className="ct_table">
        <table>
          <thead>
            <tr className="tablehead">
              <th className="no">NO</th>
              <th className="usernum">사용자 번호</th>
              <th className="userid">사용자 아이디</th>
              <th className="usernickname">사용자 명</th>
              <th className="useyn">성공 여부</th>
              <th className="date">생성일시</th>
              <th className="logip">생성자IP</th>
            </tr>
          </thead>
          <tbody>
            {loglist.map((data, index) => (
              <tr key={index}>
                <td className="no" style={{ textAlign: "center" }}>
                  {PAGING_STATE.PAGE_COUNT + index + 1}
                </td>
                <td className="usernum">{data.User_Num}</td>
                <td className="userid">{data.REQUEST_ID}</td>
                <td className="usernickname">{data.USER_NM}</td>
                <td className="useyn">{data.SUCCESS_YN}</td>
                <td className="date">{data.Create_Dttm.replace("T", " ")}</td>
                <td className="logip">{data.CREATE_IP}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>

      <section className="ct_undernum">
        <div className="paylog_paging">
          <Paging />
        </div>
      </section>
    </div>
  );
}

export default Userlog;
