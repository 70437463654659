import React from 'react'
/**
 * Version : 1.0
 * 파일명 : DatePicker.js
 * 작성일자 : 2022-05-10
 * 작성자 : 권도훈
 * 설명 : DatePicker
*/
function DatePicker(props) {
    //날짜포맷
    const dateFormat = (date) => {
        let year = date.getFullYear();
        let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

        return year + "-" + month + "-" + day;
    };

    return (
        <input
            type="date"
            max={
                props.type === "START_DATE"
                    ? props.date.END_DATE
                    : dateFormat(new Date())
            }
            onChange={e => {
                if (props.type === "START_DATE") {
                    props.setDate({
                        ...props.date,
                        START_DATE: e.target.value
                    });
                } else {
                    props.setDate({
                        ...props.date,
                        END_DATE: e.target.value
                    });
                }
            }}
            defaultValue={
                props.type === "START_DATE"
                    ? props.date.START_DATE
                    : props.date.END_DATE
            }
        />
    )
}

export default DatePicker
