import React, { Suspense, useCallback, useEffect, useState } from 'react';
import 'css/info.css';
import InfoSearchArea from 'component/info/searchArea';
import InfoContentHeader from 'component/info/contentHeader';
import InfoContentNavgitor from 'component/info/contentNavgitor';
import { fetchAxiosEvent } from 'component/info/axiosEvent';
import Loading from 'component/common/loading';
import PayLog from 'component/info/payLog';
import LoginHistory from 'component/info/loginHistory';
import LicenseList from 'component/info/licenseList';
import FaqList from 'component/info/faqList';
import PasswordLog from 'component/info/passwordLog';
import SuggestList from 'component/info/suggestList';
import Payment from 'component/info/payment';
import ScheduleList from 'component/info/scheduleList';
import LeaveSurvey from 'component/info/leaveSurvey';
import EmailLog from 'component/info/emailLog';
/**
 * Version : 1.0
 * 파일명 : Info.js
 * 작성일자 : 2022-04-27
 * 작성자 : 권도훈
 */
function Info() {
    //검색
    const [refresh, setRefresh] = useState(false);
    const [search, setSearch] = useState(false);
    const [isEditUserInfo, setIsEditUserInfo] = useState(false);
    const [contentRender, setContentRender] = useState(<></>);
    //사용자정보
    const [target, setTarget] = useState({
        SEARCH_TYPE: "USER_ID",
        SEARCH_VALUE: ""
    });
    const [userInfo, setUserInfo] = useState();
    const [userNum, setUserNum] = useState("");
    //데이터
    const [type, setType] = useState("LICENSE");
    const [isChageType, setIsChangeType] = useState(false);
    //렌더링 이벤트
    const renderEvent = useCallback(() => {
        switch (type) {
            case "LICENSE": return <LicenseList userNum={userNum} refresh={refresh} setRefresh={setRefresh} />;
            case "PAYLOG": return (<PayLog userNum={userNum} />);
            case "HISTORY": return <LoginHistory userNum={userNum} />;
            case "FAQ": return <FaqList userNum={userNum} />;
            case "SUGGEST": return <SuggestList userNum={userNum} />;
            case "PAYMENT": return <Payment userNum={userNum} />;
            case "PASSWORD": return <PasswordLog userNum={userNum} />;
            case "SCHEDULE": return <ScheduleList userNum={userNum} />;
            case "DELETE": return <LeaveSurvey userNum={userNum} />;
            case "MAIL": return <EmailLog userNum={userNum} />;
            default: return <></>;
        }
    }, [refresh, type, userNum]);
    //검색
    useEffect(() => {
        if (search) {
            setUserNum("");
            const obj = {
                config: {
                    params: {
                        "SEARCH_TYPE": target.SEARCH_TYPE,
                        "SEARCH_VALUE": target.SEARCH_VALUE
                    }
                }
            }
            setUserInfo(fetchAxiosEvent("GET", "/user/admin/info", obj));
            setType("LICENSE");
            setIsChangeType(true);
            setRefresh(true);
            setSearch(false);
        }
    }, [search, target.SEARCH_TYPE, target.SEARCH_VALUE]);

    useEffect(() => {
        if (isEditUserInfo) {
            setUserInfo(
                fetchAxiosEvent(
                    "GET",
                    "/user/admin/info",
                    {
                        config: {
                            params: {
                                "SEARCH_TYPE": target.SEARCH_TYPE,
                                "SEARCH_VALUE": target.SEARCH_VALUE
                            }
                        }
                    }
                )
            );
            setIsEditUserInfo(false);
        }
    }, [isEditUserInfo, target.SEARCH_TYPE, target.SEARCH_VALUE]);

    //렌더링
    useEffect(() => {
        if (isChageType && userNum !== "") {
            setRefresh(false);
            setIsChangeType(false);
            setContentRender(renderEvent());
        }
    }, [isChageType, renderEvent, userNum]);

    return (
        <div className='info-container'>
            <section className='info-title-section'>
                <p className='info-title'>
                    회원통합정보
                </p>
            </section>

            <InfoSearchArea
                userNum={userNum}
                target={target}
                setTarget={setTarget}
                search={search}
                setSearch={setSearch}
            />

            <div className='info-content'>
                <Suspense
                    fallback={
                        <div className='info-content-header-loading'>
                            <Loading />
                        </div>
                    }
                >
                    <InfoContentHeader
                        info={userInfo}
                        userNum={userNum}
                        setUserNum={setUserNum}
                        setIsEditUserInfo={setIsEditUserInfo}
                    />
                </Suspense>

                <InfoContentNavgitor
                    type={type}
                    setType={setType}
                    setIsChangeType={setIsChangeType}
                />

                <div className='info-content-body'>
                    {
                        userNum === ""
                            ?
                            <div className='info-content-body-loading'>
                                사용자를 검색해주세요.
                            </div>
                            :
                            contentRender
                    }
                </div>
            </div>
        </div >
    )
}

export default Info
