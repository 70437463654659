import React from 'react'
import { useDispatch } from 'react-redux';
import { openAlert, closeAlert } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : PaylogDetailPaging.js
 * 작성일자 : 2022-05-17
 * 작성자 : 권도훈
 * 설명 : 결제 상세내역 페이징
 */
function PaylogDetailPaging(props) {
    const count = props.total.data.read();
    /**
     * dispatch
     */
    const dispatch = useDispatch();
    /**
     * function
     */
    //기본 페이징 이벤트
    const changePage = (type) => {
        if (type === "prev") {
            if (props.page.PAGE_COUNT - 10 < 0 || count.TOTAL <= 10) {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                알림!
                            </div>
                            <div className='info-msg'>
                                첫 페이지입니다.
                            </div>
                        </>,
                    submitEventHandler: () => dispatch(closeAlert())
                };
                dispatch(openAlert(obj));
                return;
            } else if (props.page.PAGE_COUNT >= 10) {
                let diff = props.page.PAGE_COUNT - 10;

                if (diff < 10) {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: 0,
                    });
                } else {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.page.PAGE_COUNT - 10,
                    });
                }
                props.setSearch(true);
            }
        } else if (type === "next") {
            if (props.page.PAGE_COUNT + 10 >= count.TOTAL || props.page.ROW_COUNT === count.TOTAL) {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                알림!
                            </div>
                            <div className='info-msg'>
                                마지막 페이지입니다.
                            </div>
                        </>,
                    submitEventHandler: () => dispatch(closeAlert())
                };
                dispatch(openAlert(obj));
                return;
            } else if (props.page.PAGE_COUNT < count.TOTAL) {
                let diff = props.page.PAGE_COUNT + 10;

                if (diff > count.TOTAL) {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: count.TOTAL - (count.TOTAL - props.page.PAGE_COUNT),
                    });
                } else {
                    props.setPage({
                        ...props.page,
                        PAGE_COUNT: props.page.PAGE_COUNT + 10,
                    });
                }
                props.setSearch(true);
            }
        }
    };
    //첫 페이지 이동
    const goFirstPage = () => {
        if (props.page.PAGE_COUNT - 10 < 0 || count.TOTAL <= 10) {
            const obj = {
                TEXT:
                    <>
                        <div className='info-msg err'>
                            알림!
                        </div>
                        <div className='info-msg'>
                            첫 페이지입니다.
                        </div>
                    </>,
                submitEventHandler: () => dispatch(closeAlert())
            };
            dispatch(openAlert(obj));
            return;
        } else if (props.page.PAGE_COUNT >= 10) {
            props.setPage({
                ...props.page,
                PAGE_COUNT: 0,
            });
            props.setSearch(true);
        }
    };
    //마지막 페이지 이동
    const goLastPage = () => {
        if (props.page.PAGE_COUNT + 10 >= count.TOTAL || props.page.ROW_COUNT === count.TOTAL) {
            const obj = {
                TEXT:
                    <>
                        <div className='info-msg err'>
                            알림!
                        </div>
                        <div className='info-msg'>
                            마지막 페이지입니다.
                        </div>
                    </>,
                submitEventHandler: () => dispatch(closeAlert())
            };
            dispatch(openAlert(obj));
            return;
        } else if (props.page.PAGE_COUNT < count.TOTAL) {

            const diff = count.TOTAL % 10;

            if (diff === 0) {
                props.setPage({
                    ...props.page,
                    PAGE_COUNT: count.TOTAL - 10,
                });
            } else {
                props.setPage({
                    ...props.page,
                    PAGE_COUNT: count.TOTAL - diff,
                });
            }
            props.setSearch(true);
        }
    };

    return (
        <div>
            <font>
                {
                    count.TOTAL > 0
                        ?
                        count.TOTAL + "개 중 " + (props.page.PAGE_COUNT + 1) + "-" +
                        (
                            props.page.PAGE_COUNT + 10 >= count.TOTAL
                                ? count.TOTAL
                                : props.page.PAGE_COUNT + 10
                        )
                        : "0개"
                }
            </font>

            <div>
                <img
                    className="cursor"
                    src="/img/icon/common/first.png"
                    alt=""
                    onClick={() => goFirstPage()}
                />
                <img
                    className="cursor"
                    src="/img/icon/common/prev.png"
                    alt=""
                    onClick={() => changePage("prev")}
                />
                <img
                    className="cursor"
                    src="/img/icon/common/next.png"
                    alt=""
                    onClick={() => changePage("next")}
                />
                <img
                    className="cursor"
                    src="/img/icon/common/end.png"
                    alt=""
                    onClick={() => goLastPage()}
                />
            </div>
        </div>
    )
}

export default PaylogDetailPaging
