import React, { useState } from 'react'
import "css/input.css"
/**
 * Version : 1.0
 * 파일명 : RecordSearchBox.js
 * 작성일자 : 2022-05-25
 * 작성자 : 권도훈
 */
function SearchTextInput(props) {
    /**
     * useState
     */
    //event state
    const [isFocus, setIsFocus] = useState(false);
    const [isOver, setIsOVer] = useState(false);

    return (
        <div
            className={
                props.value.length > 0 || isFocus
                    ? isOver
                        ? 'search-box focus over center ' + props.type
                        : 'search-box focus center ' + props.type
                    : isOver
                        ? 'search-box over center ' + props.type
                        : 'search-box center ' + props.type
            }
            // onClick={e => e.stopPropagation()}
            onMouseOver={() => setIsOVer(true)}
            onMouseLeave={() => setIsOVer(false)}
        >
            <span
                className={
                    props.value.length > 0 || isFocus
                        ? isOver
                            ? 'search-box-span focus over'
                            : 'search-box-span focus'
                        : 'search-box-span'
                }
            >
                {props.label}
            </span>
            <input
                className='search-input'
                type="text"
                placeholder={
                    !isFocus
                        ? props.label
                        : ""
                }
                value={props.value}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                onChange={e => props.setValue(e.target.value)}
                onKeyDown={
                    e => {
                        if (e.key === "Enter") {
                            props.setPage({
                                ...props.page,
                                PAGE_COUNT: 0
                            });
                            props.setIsSearch(true);
                        }
                    }
                }
            />
        </div>
    )
}

export default SearchTextInput
