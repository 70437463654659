
import SearchSelectBox from 'component/common/searchSelectBox';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "css/LicenseAccount.css";
import SearchTextInput from 'component/common/searchTextInput';
import axios from 'axios';
import { updatePagingInfo } from 'actions';
import Paging from 'component/common/paging';
import ReactModal from 'react-modal';
import Licenseaccountpopup from 'component/common/license/licenseaccountpopup';
import SearchTextInputSupport from 'component/support/searchTextinputsupport';
import Insertlicenseaccountpopup from 'component/common/license/insertlicenseaccountpopup';

       

function Licenseaccount() {
  /**
* REDUX
*/
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  const [init, setInit] = useState(true);
  const [selectType, setSelectType] = useState("ALL");
  const [searchOption, setSearchOption] = useState({
    text: ""
  });
  //selectbox
  const typeList = [
    { value: "ALL", label: "구분" },
    { value: 'ZOOM', label: "Zoom" },
    { value: "WEBEX", label: "Webex" },
  ];
  const [accountlist, setaccountlist] = useState([]);
  const [accountlistexcel, setaccountlistexcel] = useState([]);

  const getaccountlist = useCallback(() => {
    let config = {
      params: {
        OPTION_TYPE: selectType,
        SEARCH_TEXT: searchOption.text,
        PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
        ROW_COUNT: PAGING_STATE.ROW_COUNT,
      }
    }

    console.log("params ==>");
    console.log(config.params);

    axios.get("/license/admin/getAccountList", config).then((res) => {
      if (res.status === 200) {
        console.log("list ==>");
        console.log(res.data);
        setaccountlist(res.data);
      }
    })
      .catch(() => {
        alert("err");
        setaccountlist([]);
      })
  }, [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT, searchOption.text, selectType]);

  const getaccountlistexcel = useCallback(() => {
    let config = {
      params: {
        OPTION_TYPE: selectType,
        SEARCH_TEXT: searchOption.text,
      }
    }
    axios.get("/license/admin/getAccountExcel", config).then((res) => {
      if (res.status === 200) {
        setaccountlistexcel(res.data);
      }
    })
      .catch(() => {
        setaccountlistexcel([]);
      })
  }, [searchOption.text, selectType])



  useEffect(()=>{
    getaccountlist();
},[PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT])


  const getaccountcount = useCallback(() => {
    let config = {
      params: {
        OPTION_TYPE: selectType,
        SEARCH_TEXT: searchOption.text,
      }
    }
    axios.get("/license/admin/getAccountListTotal", config).then((res) => {
      if (res.status === 200) {
        const paging_obj = {
          ROW_COUNT: PAGING_STATE.ROW_COUNT,
          PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
          TOTAL: res.data
        };
        dispatch(updatePagingInfo(paging_obj))
      }
    })

  }, [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT, dispatch, searchOption.text, selectType])

  useEffect(() => {
    if (init) {
      getaccountlist();
      // getaccountlistexcel();
      getaccountcount();
      setInit(false);
    }
  }, [getaccountcount, getaccountlist, getaccountlistexcel, init])




  const downloadExcel = () => {
    let arr = [];

    for (let i = 0; i < accountlistexcel.length; i++) {
      if (accountlistexcel[i].WEBEX_PW === undefined) {
        accountlistexcel[i].WEBEX_PW = '-'
      }

      if (accountlistexcel[i].TYPE === 'CD0502') {
        accountlistexcel[i].TYPE = 'ZOOM'
      }
      if (accountlistexcel[i].TYPE === 'CD0501') {
        accountlistexcel[i].TYPE = 'WEBEX'
      }
      arr.push({
        SEQ: accountlistexcel[i].SEQ,
        WEBEX_ID: accountlistexcel[i].WEBEX_ID,
        WEBEX_PW: accountlistexcel[i].WEBEX_PW,
        TYPE: accountlistexcel[i].TYPE,
        USE_YN: accountlistexcel[i].USE_YN,
      })
    }

    const data = arr;

    const config = {
      responseType: "blob"
    };
    axios.post("/license/admin/Accountexcel", data, config)
      .then(res => {

        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "계정목록.xlsx");
        document.body.appendChild(link);
        link.click();
      })

  };

  const onclicksearch = (selectType, SearchOption) => {
    getaccountlist(selectType, SearchOption)
    getaccountcount(selectType, SearchOption)
    getaccountlistexcel(selectType, SearchOption)
  }

  const [isOpenDetail, setIsOpenDetail] = useState(false);

  const closeDetailPopup = (selectType, SearchOption) => {

    setIsOpenDetail(false);
    getaccountlist(selectType, SearchOption)

  };
  const [id, setid] = useState('');
  const [type, settype] = useState('');
  const [use, setuse] = useState('');
  const [num, setnum] = useState('');
  const onclickpopup = (id, use, type, num) => {
    setid(id)
    settype(use)
    setuse(type)
    setnum(num)
    setIsOpenDetail(true);
  }


  const [isOpenDetailinsert, setIsOpenDetailinsert] = useState(false);

  const closeDetailPopupinsert = (selectType, SearchOption) => {

    setIsOpenDetailinsert(false);
    getaccountlist(selectType, SearchOption)

  };



  const onclickinsertpopup = () => {
    setIsOpenDetailinsert(true);
  }



  return (

    <div style={{
      marginLeft: "60px",
      marginRight: "60px"
    }}>

      <Licenseaccountpopup
        open={isOpenDetail}
        close={closeDetailPopup}
        id={id}
        type={type}
        use={use}
        num={num}
      />

      <Insertlicenseaccountpopup
        open={isOpenDetailinsert}
        close={closeDetailPopupinsert}
      />

      <section className='licenseaccount-header drag-none'>
        <span className='licenseaccount-header-title'>
          계정관리
        </span>

        <span className='licenseaccount-header-dir'>
          계정관리<font>&gt;</font>계정관리
        </span>
      </section>


      <div className='licenseaccount-search-area'>
        <SearchTextInputSupport
          label="아이디를 입력하세요"
          type="long"
          value={searchOption.text}
          setValue={value => setSearchOption({ ...searchOption, text: value })}
        />

        <SearchSelectBox
          label="계정 종류"
          type="short"
          list={typeList}
          value={selectType}
          setValue={value => setSelectType(value)}
        />
        <button
          className='licenseaccount-search-btn btn blue cursor'
           onClick={() => onclicksearch(selectType, searchOption.text)}
        >
          검색
        </button>


      </div>

      <section className='ct_download'>
        <div className='licenseinsertbutton cursor' onClick={() => onclickinsertpopup()}>
          등록
        </div>
        <div className='licenseexceldownloadbutton cursor' onClick={() => downloadExcel()}>
          <img type='image' alt='다운로드버튼' className='exceldownloadbuttonimage' src='/img/icon/common/exceldown.png'></img>
          <span className='exceldownloadbuttontext '>엑셀 다운로드</span>
        </div>
      </section>



      <div className='licenseaccount-content-table-area'>
        <table className='licenseaccount-content-table'>
          <colgroup>
            <col width="3%" />
            <col width="12%" />
            <col width="12%" />
            <col width="12%" />
            <col width="13%" />
          </colgroup>
          <thead>
            <tr>
              <th className='licenseaccount-content-th'>
                <span>
                  NO
                </span>
              </th>
              <th className='licenseaccount-content-th'>
                <span>
                  구분
                </span>
              </th>
              <th className='licenseaccount-content-th'>
                <span>
                  아이디
                </span>
              </th>
              <th className='licenseaccount-content-th'>
                <span>
                  패스워드
                </span>
              </th>
              <th className='licenseaccount-content-th'>
                <span>
                  사용여부
                </span>
              </th>
            </tr>
          </thead>

          <tbody className='licenseaccount-content-tbody'>
            {
              accountlist.length > 0
                ?
                accountlist.map((data, index) => (
                  <tr key={index} className='licenseaccount-content-tr'>
                    <td className='licenseaccount-content-td'>
                      {PAGING_STATE.PAGE_COUNT + index + 1}
                    </td>
                    {data.TYPE === 'CD0501' ?
                      <td className='licenseaccount-content-td'> Webex </td> :
                      <td className='licenseaccount-content-td'> Zoom </td>
                    }
                    <td className='licenseaccount-content-td'>
                      {data.WEBEX_ID}
                    </td>
                    <td className='licenseaccount-content-td'>
                      {data.WEBEX_PW}
                    </td>
                    <td className='licenseaccount-content-td'>
                      <div className='cursor' onClick={() => onclickpopup(data.WEBEX_ID, data.TYPE, data.USE_YN, data.SEQ)}>{data.USE_YN}</div>
                    </td>
                  </tr>
                ))
                :
                <tr></tr>
            }

          </tbody>
        </table>
      </div>
      <div style={{
        marginTop: "27px",
        marginBottom: "46px"
      }}>
        <Paging />
      </div>



    </div>
  )
}

export default Licenseaccount