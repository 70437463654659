import Loading from 'component/common/loading';
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { fetchAxiosEvent } from './axiosEvent';
import LicenseListContent from './content/licenseListContent';
import Paging from './content/paging';
/**
 * Version : 1.0
 * 파일명 : LicenseList.js
 * 작성일자 : 2022-05-03
 * 작성자 : 권도훈
 */
function LicenseList(props) {
    const [init, setInit] = useState(props.refresh);
    const [isSearch, setIsSearch] = useState(false);
    const [typeBox, setTypeBox] = useState({
        isShow: false,
        value: "ALL",
    });
    const [useType, setUseType] = useState({
        isShow: false,
        value: "ALL"
    });
    const [unsubscribeType, setUnsubscribeType] = useState({
        isShow: false,
        value: "ALL"
    });
    const [page, setPage] = useState({
        ROW_COUNT: 10,
        PAGE_COUNT: 0
    });
    const [list, setList] = useState(
        fetchAxiosEvent(
            "GET",
            "/license/admin/list",
            {
                config: {
                    params: {
                        USER_NUM: props.userNum,
                        ROW_COUNT: page.ROW_COUNT,
                        PAGE_COUNT: page.PAGE_COUNT
                    }
                }
            }
        )
    );
    const [total, setTotal] = useState(fetchAxiosEvent(
        "GET",
        "/license/admin/list/total",
        {
            config: {
                params: {
                    USER_NUM: props.userNum
                }
            }
        }
    ));
    //토탈 조회
    const getTotal = useCallback(() => {
        const obj = {
            config: {
                params: {
                    USER_NUM: props.userNum,
                    OPTION_TYPE: typeBox.value,
                    OPTION_IS_USE: useType.value,
                    OPTION_IS_UNSUBSCRIBE: unsubscribeType.value
                }
            }
        }
        setTotal(fetchAxiosEvent("GET", "/license/admin/list/total", obj));
    }, [props.userNum, typeBox.value, unsubscribeType.value, useType.value]);
    //데이터 조회
    const getLog = useCallback(() => {
        const obj = {
            config: {
                params: {
                    USER_NUM: props.userNum,
                    ROW_COUNT: page.ROW_COUNT,
                    PAGE_COUNT: page.PAGE_COUNT,
                    OPTION_TYPE: typeBox.value,
                    OPTION_IS_USE: useType.value,
                    OPTION_IS_UNSUBSCRIBE: unsubscribeType.value
                }
            }
        }
        setList(fetchAxiosEvent("GET", "/license/admin/list", obj));
    }, [page.PAGE_COUNT, page.ROW_COUNT, props.userNum, typeBox.value, unsubscribeType.value, useType.value]);
    //검색종류 닫기 이벤트핸들러
    const closeSelectTypeBox = useCallback(() => {
        setTypeBox({
            ...typeBox,
            isShow: false
        })
    }, [typeBox]);
    //사용여부 닫기 이벤트핸들러
    const closeUseTypeBox = useCallback(() => {
        setUseType({
            ...useType,
            isShow: false
        })
    }, [useType]);
    //사용여부 닫기 이벤트핸들러
    const closeUnsubscribeBox = useCallback(() => {
        setUnsubscribeType({
            ...unsubscribeType,
            isShow: false
        })
    }, [unsubscribeType]);

    //조회
    useEffect(() => {
        if (isSearch) {
            getLog();
            getTotal();
            setIsSearch(false);
        } else if (init) {
            getLog();
            getTotal();
            setInit(false);
        }

    }, [getLog, getTotal, init, isSearch, props, props.refresh]);
    //셀렉트 박스 이벤트핸들러 관리
    useEffect(() => {
        if (typeBox.isShow) {
            window.addEventListener("click", closeSelectTypeBox);
            return () => {
                window.removeEventListener("click", closeSelectTypeBox);
            };
        } else if (useType.isShow) {
            window.addEventListener("click", closeUseTypeBox);
            return () => {
                window.removeEventListener("click", closeUseTypeBox);
            };
        } else if (unsubscribeType.isShow) {
            window.addEventListener("click", closeUnsubscribeBox);
            return () => {
                window.removeEventListener("click", closeUnsubscribeBox);
            };
        }
    }, [closeSelectTypeBox, closeUnsubscribeBox, closeUseTypeBox, typeBox.isShow, unsubscribeType.isShow, useType.isShow]);

    //그룹할당 여부 포맷
    const typeBoxFormat = (type, value) => {
        switch (value) {
            case "ALL":
                if (type === "box_display") {
                    return "전체"
                } else {
                    return ["MY_PEBBLE", "GROUP_PEBBLE"]
                }
            case "MY_PEBBLE":
                if (type === "box_display") {
                    return "마이페블"
                } else {
                    return ["ALL", "GROUP_PEBBLE"]
                }
            case "GROUP_PEBBLE":
                if (type === "box_display") {
                    return "그룹페블"
                } else {
                    return ["ALL", "MY_PEBBLE"]
                }
            default: break;
        }
    }
    //사용여부 포맷
    const useynFormat = (type, value) => {
        switch (value) {
            case "ALL":
                if (type === "box_display") {
                    return "전체"
                } else {
                    return ["Y", "N"]
                }
            case "Y":
                if (type === "box_display") {
                    return "사용"
                } else {
                    return ["ALL", "N"]
                }
            case "N":
                if (type === "box_display") {
                    return "미사용"
                } else {
                    return ["ALL", "Y"]
                }
            default: return "";
        }
    }
    //사용여부 포맷
    const subscribeFormat = (type, value) => {
        switch (value) {
            case "ALL":
                if (type === "box_display") {
                    return "전체"
                } else {
                    return ["Y", "N"]
                }
            case "Y":
                if (type === "box_display") {
                    return "구독"
                } else {
                    return ["ALL", "N"]
                }
            case "N":
                if (type === "box_display") {
                    return "구독해지"
                } else {
                    return ["ALL", "Y"]
                }
            default: return "";
        }
    }

    return (
        <div className='info-content-inner table'>
            <div>
                <div className='info-content-inner-search'>
                    <div className='info-content-inner-search-option'>
                        <font>
                            그룹여부
                        </font>

                        <div
                            className='info-content-inner-search-selectbox cursor'
                            onClick={() =>
                                setTypeBox({
                                    ...typeBox,
                                    isShow: !typeBox.isShow
                                })
                            }
                        >
                            <div>
                                {typeBoxFormat("box_display", typeBox.value)}
                            </div>

                            <img
                                src={
                                    typeBox.isShow
                                        ? "/img/icon/common/picker_up.png"
                                        : "/img/icon/common/picker_down.png"
                                }
                                alt=''
                            />

                            {
                                typeBox.isShow
                                    ?
                                    <div className='info-content-inner-search-itembox'>
                                        {
                                            typeBoxFormat("box_option", typeBox.value).map((data, idx) => (
                                                <div
                                                    key={idx}
                                                    className='info-content-inner-search-item'
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setTypeBox({
                                                            isShow: false,
                                                            value: data
                                                        });
                                                    }}
                                                >
                                                    {typeBoxFormat("box_display", data)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                    <div className='info-content-inner-search-option'>
                        <font>
                            사용여부
                        </font>

                        <div
                            className='info-content-inner-search-selectbox cursor'
                            onClick={() =>
                                setUseType({
                                    ...useType,
                                    isShow: !useType.isShow
                                })
                            }
                        >
                            <div>
                                {useynFormat("box_display", useType.value)}
                            </div>

                            <img
                                src={
                                    useType.isShow
                                        ? "/img/icon/common/picker_up.png"
                                        : "/img/icon/common/picker_down.png"
                                }
                                alt=''
                            />

                            {
                                useType.isShow
                                    ?
                                    <div className='info-content-inner-search-itembox'>
                                        {
                                            useynFormat("box_option", useType.value).map((data, idx) => (
                                                <div
                                                    key={idx}
                                                    className='info-content-inner-search-item'
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setUseType({
                                                            isShow: false,
                                                            value: data
                                                        });
                                                    }}
                                                >
                                                    {useynFormat("box_display", data)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                    <div className='info-content-inner-search-option'>
                        <font>
                            해지여부
                        </font>

                        <div
                            className='info-content-inner-search-selectbox cursor'
                            onClick={() =>
                                setUnsubscribeType({
                                    ...unsubscribeType,
                                    isShow: !unsubscribeType.isShow
                                })
                            }
                        >
                            <div>
                                {subscribeFormat("box_display", unsubscribeType.value)}
                            </div>

                            <img
                                src={
                                    unsubscribeType.isShow
                                        ? "/img/icon/common/picker_up.png"
                                        : "/img/icon/common/picker_down.png"
                                }
                                alt=''
                            />

                            {
                                unsubscribeType.isShow
                                    ?
                                    <div className='info-content-inner-search-itembox'>
                                        {
                                            subscribeFormat("box_option", unsubscribeType.value).map((data, idx) => (
                                                <div
                                                    key={idx}
                                                    className='info-content-inner-search-item'
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setUnsubscribeType({
                                                            isShow: false,
                                                            value: data
                                                        });
                                                    }}
                                                >
                                                    {subscribeFormat("box_display", data)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                    <button
                        className='info-search-btn cursor'
                        onClick={() => {
                            setPage({
                                ...page,
                                PAGE_COUNT: 0
                            });
                            setIsSearch(true);
                        }}
                    >
                        검 색
                    </button>
                </div>

                <table className='info-content-table'>
                    <colgroup>
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="10%" />
                        <col width="5%" />
                        <col width="5%" />
                        <col width="10%" />
                        <col width="20%" />
                    </colgroup>

                    <thead>
                        <tr>
                            <th>구독일</th>
                            <th>만료일</th>
                            <th>종류</th>
                            <th>회의이용권 코드</th>
                            <th>할당 그룹 명</th>
                            <th>예약회의 수</th>
                            <th>사용여부</th>
                            <th>해지여부</th>
                            <th>생성일시</th>
                            <th>생성자IP</th>
                        </tr>
                    </thead>

                    <tbody>
                        <Suspense
                            fallback={
                                <tr>
                                    <td colSpan="10">
                                        <div className="info-content-body-loading">
                                            <Loading />
                                        </div>
                                    </td>
                                </tr>
                            }
                        >
                            <LicenseListContent list={list} />
                        </Suspense>
                    </tbody>
                </table>
            </div>

            <div className='info-content-paging'>
                <Suspense fallback={<></>}>
                    <Paging
                        setIsSearch={setIsSearch}
                        page={page}
                        setPage={setPage}
                        TOTAL={total}
                    />
                </Suspense>
            </div>
        </div >
    )
}

export default LicenseList
