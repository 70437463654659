import React, { useState } from "react";
import ReactModal from "react-modal";
import "css/meetingPass.css";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";

function UserSearchPopup(props) {
  const [searchText, setSearchText] = useState("");
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectUser, setSelectUser] = useState({
    USER_NUM: "",
    USER_ID: "",
    USER_NM: "",
  });

  const searchUser = value => {
    setLoading(true);
    let url = "/user/admin/userSearch";
    let param = {
      SEARCH_TEXT: value,
    };
    axios.get(url, { params: param }).then(res => {
      if (res.status === 200) {
        setLoading(false);
        setUserList(res.data);
      } else {
        setLoading(false);
      }
    });
  };
  const checkUser = (checked, user) => {
    if (checked) {
      setSelectUser({
        ...selectUser,
        USER_NUM: user.USER_NUM,
        USER_NM: user.USER_NM,
        USER_ID: user.USER_ID,
      });
    } else {
      setSelectUser(selectUser.filter(item => item !== user));
    }
  };

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="mp-us-popup" //모달 ClassName
      overlayClassName="mp-us-popup-overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close(false);
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="mp-us-popup-bg">
        <div className="mp-us-popup-header">사용자 검색</div>
        <div className="mp-us-popup-body">
          <div className="mp-us-searchArea">
            <input
              className="mp-us-input-search"
              type="text"
              placeholder="사용자 명 또는 아이디"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              onKeyPress={e => {
                if (e.key === "Enter") {
                  searchUser(e.target.value);
                }
              }}
            />
            <button
              className="mp-us-btn-search"
              onClick={() => searchUser(searchText)}
            >
              검색
            </button>
          </div>
          <div className="mp-us-list scrollbar">
            <table className="mp-us-table">
              <colgroup>
                <col width="10%" />
                <col width="30%" />
                <col width="40%" />
                <col width="20%" />
              </colgroup>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>선택</th>
                  <th style={{ textAlign: "center" }}>사용자 번호</th>
                  <th style={{ textAlign: "center" }}>사용자 아이디</th>
                  <th style={{ textAlign: "center" }}>사용자 명</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td
                      className="mp-us-table-empty"
                      colSpan="4"
                      style={{ textAlign: "center" }}
                    >
                      <CircularProgress size={18} />
                    </td>
                  </tr>
                ) : Array.isArray(userList) && userList.length > 0 ? (
                  userList.map((data, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        <input
                          type="radio"
                          name="USER"
                          id={data.USER_NUM}
                          value={data}
                          onChange={e =>
                            checkUser(e.currentTarget.checked, data)
                          }
                          checked={
                            selectUser.USER_NUM === data.USER_NUM ? true : false
                          }
                        />
                      </td>
                      <td>{data.USER_NUM}</td>
                      <td>{data.USER_ID}</td>
                      <td>{data.USER_NM}</td>
                    </tr>
                  ))
                ) : userList.length === 0 ? (
                  <tr>
                    <td
                      className="mp-us-table-empty"
                      colSpan="4"
                      style={{ textAlign: "center" }}
                    >
                      조회된 데이터가 없습니다.
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
        <div className="mp-us-popup-footer">
          {selectUser.USER_NUM !== "" && selectUser.USER_NUM !== undefined ? (
            <button
              className="mp-us-btn-select"
              onClick={() => {
                props.setSignInfo({
                  ...props.signInfo,
                  USER_NUM: selectUser.USER_NUM,
                  USER_NM: selectUser.USER_NM,
                  USER_ID: selectUser.USER_ID,
                });
                props.close(false);
              }}
            >
              선택
            </button>
          ) : null}
          <button
            className="mp-us-btn-close"
            onClick={() => {
              props.close(false);
            }}
          >
            닫기
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default UserSearchPopup;
