import { closeAlert, openAlert } from "actions";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";

function Insertuserpopup(props) {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();

  const [insertid, setinsertid] = useState("");
  const [insertpw, setinsertpw] = useState("");
  const [insertnickname, setinsertnickname] = useState("");
  const [insertpwcheck, setinsertpwcheck] = useState("");

  const regEmail =
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;

  const handleinsertid = e => {
    setinsertid(e.target.value);
  };

  const handleinsertpw = e => {
    setinsertpw(e.target.value);
  };

  const handleinsertnickname = e => {
    setinsertnickname(e.target.value);
  };
  const handleinsertpwcheck = e => {
    setinsertpwcheck(e.target.value);
  };
  const [k, setk] = useState("");

  const getcheckid = useCallback(() => {
    let data = {
      params: {
        userID: insertid,
      },
    };
    axios.get("/user/checkID", data).then(res => {
      if (res.status === 200) {
        setk(res.data);
      }
    });
  }, [insertid]);

  useEffect(() => {
    getcheckid();
  }, [getcheckid]);

  const createAccount = () => {
    if (
      regEmail.test(insertid) === false ||
      insertid === "" ||
      insertid === null
    ) {
      const obj = {
        TEXT: "이메일 양식이 잘못되었습니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (k > 0) {
      const obj = {
        TEXT: "아이디가 중복입니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (insertnickname === "" || insertnickname === null) {
      const obj = {
        TEXT: "이름이 비어있습니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (insertpw === "" && insertpw === null) {
      const obj = {
        TEXT: "비밀번호가 비어있습니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (insertpwcheck !== insertpw) {
      const obj = {
        TEXT: "비밀번호가 일치하지 않습니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    } else if (!isValidPassword(insertpw)) {
      const obj = {
        TEXT: "잘못된 비밀번호 형식입니다.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    }

    let params = {
      Request_MAIL: insertid,
      Request_PW: insertpw,
      Request_NM: insertnickname,
    };

    axios
      .post("/user/insertUser", params)
      .then(res => {
        if (res.data) {
          const obj = {
            TEXT: "등록이 완료되었습니다.",
            submitEventHandler: () => {
              dispatch(closeAlert());props.close();
            },
          };
          dispatch(openAlert(obj));
        } else {
          const obj = {
            TEXT: "오류가 발생했습니다.",
            submitEventHandler: () => {
              dispatch(closeAlert());
            },
          };
          dispatch(openAlert(obj));
        }
      })
      .catch(error => {
        axios.post("/user/error", null, { headers: { error_info: error } });
      });
  };

  const isValidPassword = () => {
    var pattern1 = /[0-9]/;
    var pattern2 = /[a-zA-Z]/;
    var pattern3 = /[~!@\\#$%<>^&*]/; // 원하는 특수문자 추가 제거 \ 확인

    if (
      !pattern1.test(insertpw) ||
      !pattern2.test(insertpw) ||
      !pattern3.test(insertpw) ||
      insertpw.length < 8 ||
      insertpw.length > 50
    ) {
      return false;
    }

    return true;
  };
  const clickclose = () => {
    setinsertpw("");
    setinsertid("");
    setinsertnickname("");
    setinsertpwcheck("");
    props.close();
  };

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="userinputPopup" //모달 ClassName
      overlayClassName="userinputPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div>
        <div className="userInputTitle">회원 등록</div>
        <div style={{ marginTop: "20px" }}>
          <div className="ui-popup-input-div">
            <div className="ui-popup-input-label">아이디 </div>
            <input
              className="ui-popup-input"
              type="text"
              placeholder="아이디를 입력해주세요."
              onChange={handleinsertid}
            />
          </div>
          <div className="ui-popup-input-div">
            <div className="ui-popup-input-label">닉네임</div>
            <input
              className="ui-popup-input"
              type="text"
              placeholder="닉네임을 입력해주세요."
              onChange={handleinsertnickname}
            />
          </div>
          <div className="ui-popup-input-div">
            <div className="ui-popup-input-label">비밀번호</div>
            <input
              className="ui-popup-input"
              type="text"
              placeholder="비밀번호를 입력해주세요."
              onChange={handleinsertpw}
            />
          </div>
          <div className="ui-popup-input-div">
            <div className="ui-popup-input-label">비밀번호 확인</div>
            <input
              className="ui-popup-input"
              type="text"
              placeholder="비밀번호를 다시 한번 입력해 주세요."
              onChange={handleinsertpwcheck}
            />
          </div>
          <div className="ui-popup-footer">
            <button
              className="cursor ui-popup-btn-ok"
              onClick={() => createAccount()}
            >
              등록
            </button>
            <button
              className="cursor ui-popup-btn-cancel"
              onClick={() => clickclose()}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Insertuserpopup;
