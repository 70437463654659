import React, { Suspense, useCallback, useEffect, useState } from 'react'
import 'css/record.css';
import { fetchAxiosEvent } from 'component/info/axiosEvent';
import { useDispatch } from 'react-redux';
import { closeAlert, openAlert, openConfirm } from 'actions';
import Loading from 'component/common/loading';
import Paging from 'component/info/content/paging';
import axios from 'axios';
import RecordList from 'component/record/recordList';
import DatePicker from "component/record/recordDatepicker";
import { CircularProgress } from "@material-ui/core";
import SearchTextInput from 'component/record/recordSearchBox';
/**
 * Version : 1.0
 * 파일명 : Record.js
 * 작성일자 : 2022-05-23
 * 작성자 : 권도훈
 */
function Record() {
    /**
     * useDispatch
     */
    const dispatch = useDispatch();
    /**
     * useState
     */
    //초기화
    const [init, setInit] = useState(true);
    //검색
    const [isSearch, setIsSearch] = useState(false);
    const [isPaging, setIsPaging] = useState(false);
    const [page, setPage] = useState({
        ROW_COUNT: 10,
        PAGE_COUNT: 0
    });
    const [searchOption, setSearchOption] = useState({
        REC_NUM: "",
        SCHDULE_NUM: "",
        HOST_NM: "",
        REC_TITLE: "",
        GROUP_NM: ""
    });
    const [date, setDate] = useState({
        START_TIME: "",
        END_TIME: ""
    });
    //데이터
    const [record, setRecord] = useState();
    const [total, setTotal] = useState(0);
    //체크박스
    const [chkList, setChkList] = useState([]);
    //엑셀
    const [isDownload, setIsDownload] = useState(false);
    /**
     * useCallbak
     */
    //토탈 조회
    const getRecordListTotal = useCallback(() => {
        setTotal(
            fetchAxiosEvent(
                "GET",
                "/record/list/total",
                {
                    config: {
                        params: {
                            REC_NUM: searchOption.REC_NUM,
                            SCHEDULE_NUM: searchOption.SCHDULE_NUM,
                            HOST_NM: searchOption.HOST_NM,
                            REC_TITLE: searchOption.REC_TITLE,
                            GROUP_NM: searchOption.GROUP_NM,
                            START_DTTM:
                                date.START_TIME !== ""
                                    ? date.START_TIME + " 00:00:00"
                                    : "",
                            END_DTTM:
                                date.END_TIME !== ""
                                    ? date.END_TIME + " 23:59:59"
                                    : ""
                        }
                    }
                }
            )
        )
    }, [date.END_TIME, date.START_TIME, searchOption.GROUP_NM, searchOption.HOST_NM, searchOption.REC_NUM, searchOption.REC_TITLE, searchOption.SCHDULE_NUM]);
    //목록 조회
    const getRecordList = useCallback(() => {
        setRecord(
            fetchAxiosEvent(
                "GET",
                "/record/list",
                {
                    config: {
                        params: {
                            REC_NUM: searchOption.REC_NUM,
                            SCHEDULE_NUM: searchOption.SCHDULE_NUM,
                            HOST_NM: searchOption.HOST_NM,
                            REC_TITLE: searchOption.REC_TITLE,
                            GROUP_NM: searchOption.GROUP_NM,
                            ROW_COUNT: page.ROW_COUNT,
                            PAGE_COUNT: page.PAGE_COUNT,
                            START_DTTM:
                                date.START_TIME !== ""
                                    ? date.START_TIME + " 00:00:00"
                                    : "",
                            END_DTTM:
                                date.END_TIME !== ""
                                    ? date.END_TIME + " 23:59:59"
                                    : ""
                        }
                    }
                }
            )
        )
    }, [date.END_TIME, date.START_TIME, page.PAGE_COUNT, page.ROW_COUNT, searchOption.GROUP_NM, searchOption.HOST_NM, searchOption.REC_NUM, searchOption.REC_TITLE, searchOption.SCHDULE_NUM]);
    /**
     * useEffect
     */
    //초기화
    useEffect(() => {
        if (init) {
            getRecordListTotal();
            getRecordList();
            setInit(false);
        }
    }, [getRecordList, getRecordListTotal, init]);
    //검색
    useEffect(() => {
        if (isSearch) {
            if (date.START_TIME !== "" || date.END_TIME !== "") {
                if (date.START_TIME === "" || date.END_TIME === "") {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg err'>
                                    오류!
                                </div>
                                <div className='info-msg'>
                                    검색 날짜를 모두 설정해주세요!
                                </div>
                            </>,
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                    setIsSearch(false);
                    return;
                } else if (date.END_TIME < date.START_TIME) {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg err'>
                                    오류!
                                </div>
                                <div className='info-msg'>
                                    종료 일자는 시작 일자보다
                                </div>
                                <div className='info-msg'>
                                    이후의 날짜로 설정해주세요!
                                </div>
                            </>,
                        submitEventHandler: () => {
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                    setIsSearch(false);
                    return;
                }
            }
            getRecordListTotal();
            getRecordList();
            setChkList([]);
            setIsSearch(false);
        }
    }, [date.END_TIME, date.START_TIME, dispatch, getRecordList, getRecordListTotal, isSearch]);
    //페이징
    useEffect(() => {
        if (isPaging) {
            getRecordList();
            setIsPaging(false);
        }
    }, [getRecordList, isPaging]);
    /**
     * function
     */
    //checkbox click
    const clickChkEventHandler = e => {
        if (e.target.checked) {
            let recordNumList = [];

            record.data.read().forEach(el => {
                if (!chkList.includes(el.REC_NUM)) {
                    recordNumList.push(el.REC_NUM);
                }
            });

            setChkList(recordNumList);

        } else {
            if (chkList.length < total.data.read()) {
                let isCheckedAll = true;
                let recordNumList = [];

                record.data.read().forEach(el => {
                    if (!chkList.includes(el.REC_NUM)) {
                        isCheckedAll = false;
                        recordNumList.push(el.REC_NUM);
                    }
                });

                if (isCheckedAll) {
                    setChkList([]);
                } else {
                    recordNumList = recordNumList.concat(chkList);
                    setChkList(recordNumList);
                }

            } else {
                setChkList([]);
            }
        }
    }
    //excel download EventHandler
    const downloadExcelEventHandler = () => {
        setIsDownload(true);

        const data = {
            REC_LIST: chkList
        }

        const config = {
            responseType: "blob",
            params: {
                REQUEST_TYPE: chkList.length > 0 ? "CHECK_LIST" : "ALL",
                REC_NUM: searchOption.REC_NUM,
                SCHEDULE_NUM: searchOption.SCHDULE_NUM,
                HOST_NM: searchOption.HOST_NM,
                REC_TITLE: searchOption.REC_TITLE,
                GROUP_NM: searchOption.GROUP_NM,
                START_DTTM:
                    date.START_TIME !== ""
                        ? date.START_TIME + " 00:00:00"
                        : "",
                END_DTTM:
                    date.END_TIME !== ""
                        ? date.END_TIME + " 23:59:59"
                        : ""
            }
        }

        axios.post("/record/list/excel", data, config)
            .then(res => {
                const url = window.URL.createObjectURL(
                    new Blob([res.data], { type: res.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "PEBBLE_회의영상목록.xlsx");
                document.body.appendChild(link);
                link.click();
                setIsDownload(false);
            })
            .catch(() => {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                오류!
                            </div>
                            <div className='info-msg'>
                                오류가 발생했습니다.
                            </div>
                        </>,
                    submitEventHandler: () => {
                        setIsDownload(false);
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            });
    }

    return (
        <div className='rec-container'>
            <section className='rec-header drag-none'>
                <span className='rec-header-title'>
                    회의영상현황
                </span>

                <span className='rec-header-dir'>
                    회의영상관리<font>&gt;</font>회의영상현황
                </span>
            </section>

            <div className='rec-search-area'>
                <SearchTextInput
                    label="녹화 번호"
                    value={searchOption.REC_NUM}
                    setValue={value => setSearchOption({ ...searchOption, REC_NUM: value })}
                    setPage={setPage}
                    page={page}
                    setIsSearch={setIsSearch}
                />

                <SearchTextInput
                    label="예약 번호"
                    value={searchOption.SCHDULE_NUM}
                    setValue={value => setSearchOption({ ...searchOption, SCHDULE_NUM: value })}
                    setPage={setPage}
                    page={page}
                    setIsSearch={setIsSearch}
                />

                <SearchTextInput
                    label="미팅 호스트"
                    value={searchOption.HOST_NM}
                    setValue={value => setSearchOption({ ...searchOption, HOST_NM: value })}
                    setPage={setPage}
                    page={page}
                    setIsSearch={setIsSearch}
                />

                <SearchTextInput
                    label="녹화 제목"
                    value={searchOption.REC_TITLE}
                    setValue={value => setSearchOption({ ...searchOption, REC_TITLE: value })}
                    setPage={setPage}
                    page={page}
                    setIsSearch={setIsSearch}
                />

                <SearchTextInput
                    label="그룹 명"
                    value={searchOption.GROUP_NM}
                    setValue={value => setSearchOption({ ...searchOption, GROUP_NM: value })}
                    setPage={setPage}
                    page={page}
                    setIsSearch={setIsSearch}
                />

                <div className='rec-search-date center'>
                    <DatePicker
                        label="시작일자"
                        type="START"
                        date={date}
                        setDate={result => {
                            setDate({
                                ...date,
                                START_TIME: result.START
                            });
                        }}
                    />
                    <span>~</span>
                    <DatePicker
                        label="종료일자"
                        type="END"
                        date={date}
                        setDate={result => {
                            setDate({
                                ...date,
                                END_TIME: result.END
                            });
                        }}
                    />
                </div>

                <button
                    className='rec-search-btn btn blue cursor'
                    onClick={() => {
                        setPage({
                            ...page,
                            PAGE_COUNT: 0
                        });
                        setIsSearch(true);
                    }}
                >
                    검색
                </button>
            </div>

            <div className='rec-content'>
                <div className='rec-content-btn-area'>
                    <div className='rec-content-btn-area-inner'>
                        <div
                            className={
                                !isDownload
                                    ? 'rec-content-btn btn lightgrey cursor center'
                                    : 'rec-content-btn btn lightgrey drag-none center'
                            }
                            onClick={() => {
                                if (!isDownload) {
                                    const obj = {
                                        TEXT:
                                            <>
                                                <div className='info-msg success'>
                                                    알림!
                                                </div>
                                                <div className='info-msg'>
                                                    엑셀파일을 다운로드 하시겠습니까?
                                                </div>
                                            </>,
                                        submitEventHandler: () => {
                                            downloadExcelEventHandler();
                                            dispatch(closeAlert());
                                        },
                                    };
                                    dispatch(openConfirm(obj));
                                }
                            }}
                        >
                            {
                                isDownload
                                    ?
                                    <CircularProgress size={18} />
                                    :
                                    <>
                                        <img
                                            src='/img/icon/common/exceldown.png'
                                            alt=''
                                        />
                                        <span>엑셀 다운로드</span>
                                    </>
                            }
                        </div>
                    </div>
                </div>

                <div className='rec-content-table-area'>
                    <table className='rec-content-table'>
                        <colgroup>
                            <col width="3%" />
                            <col width="9%" />
                            <col width="9%" />
                            <col width="8%" />
                            <col width="12%" />
                            <col width="6%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="7%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="10%" />
                            <col width="11%" />
                        </colgroup>

                        <thead>
                            <tr>
                                <th className='rec-content-th'>
                                    <input
                                        type="checkbox"
                                        className='cursor'
                                        checked={chkList.length > 0}
                                        onChange={e => clickChkEventHandler(e)}
                                    />
                                </th>
                                <th className='rec-content-th left'>
                                    <span>
                                        녹화번호
                                    </span>
                                </th>
                                <th className='rec-content-th left'>
                                    <span>
                                        예약번호
                                    </span>
                                </th>
                                <th className='rec-content-th left'>
                                    <span>
                                        호스트 명
                                    </span>
                                </th>
                                <th className='rec-content-th left'>
                                    <span>
                                        녹화제목
                                    </span>
                                </th>
                                <th className='rec-content-th left'>
                                    <span>
                                        녹화일자
                                    </span>
                                </th>
                                <th className='rec-content-th'>
                                    시작시간
                                </th>
                                <th className='rec-content-th'>
                                    종료시간
                                </th>
                                <th className='rec-content-th'>
                                    녹화시간
                                </th>
                                <th className='rec-content-th left'>
                                    <span>
                                        그룹 명
                                    </span>
                                </th>
                                <th className='rec-content-th'>
                                    다운 횟수
                                </th>
                                <th className='rec-content-th'>
                                    삭제 여부
                                </th>
                                <th className='rec-content-th left'>
                                    <span>
                                        생성일시
                                    </span>
                                </th>
                                <th className='rec-content-th left'>
                                    <span>
                                        생성자IP
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody className='rec-content-tbody'>
                            <Suspense
                                fallback={
                                    <tr>
                                        <td colSpan='14'>
                                            <Loading />
                                        </td>
                                    </tr>
                                }
                            >
                                {
                                    !init
                                        ?
                                        <RecordList
                                            record={record}
                                            chkList={chkList}
                                            setChkList={setChkList}
                                        />
                                        :
                                        <></>
                                }
                            </Suspense>
                        </tbody>
                    </table>

                    <div className='rec-content-paging'>
                        <Suspense fallback={<></>}>
                            {
                                !init
                                    ?
                                    <Paging
                                        setIsSearch={setIsPaging}
                                        page={page}
                                        setPage={setPage}
                                        TOTAL={total}
                                    />
                                    :
                                    <></>
                            }
                        </Suspense>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Record
