import { closeAlert, openAlert } from "actions";
import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch } from "react-redux";

function Updateuserpopup(props) {
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();

  const [insertnickname, setinsertnickname] = useState(props.nickname);
  const [insertcomment, setinsertcomment] = useState(props.comment);
  //호스트 일괄 등록 권한 여부
  const [authYN, setAuthYN] = useState(props.authYN);

  const handleinsertnickname = e => {
    setinsertnickname(e.target.value);
  };
  const handleinsertcomment = e => {
    setinsertcomment(e.target.value);
  };

  const onclicksubmit = () => {
    let config = {
      params: {
        User_NM: insertnickname,
        User_Comment: insertcomment,
        User_Num: props.num,
        AUTH_YN: authYN,
      },
    };
    axios.put("/user/UserUpdate", null, config).then(res => {
      if (res.status === 200) {
        const obj = {
          TEXT: "회원정보가 수정 되었습니다.",
          submitEventHandler: () => {
            dispatch(closeAlert());
            props.close();
          },
        };
        dispatch(openAlert(obj));
      }
    });
  };

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="userinputPopup" //모달 ClassName
      overlayClassName="userinputPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div>
        <div className="userInputTitle">회원 정보 수정</div>
        <div style={{ marginTop: "20px" }}>
          <div className="ui-popup-input-div">
            <div className="ui-popup-input-label">닉네임</div>
            <input
              className="ui-popup-input"
              type="text"
              onChange={handleinsertnickname}
              value={insertnickname}
              placeholder="닉네임을 입력해 주세요"
            />
          </div>

          <div className="ui-popup-input-div">
            <div className="ui-popup-input-label">코멘트</div>
            <input
              className="ui-popup-input"
              type="text"
              onChange={handleinsertcomment}
              value={insertcomment}
              placeholder="코멘트를 입력해주세요"
            />
          </div>

          <div className="ui-popup-input-div">
            <div className="ui-popup-input-label">호스트 일괄 등록</div>
            <div className="ui-popup-radio-div">
              <input
                type="radio"
                name="AUTH_YN"
                value="Y"
                checked={authYN === "Y"}
                onChange={e => {
                  setAuthYN(e.target.value);
                }}
              />
              <span className="ui-popup-radio">권한 부여</span>
              <input
                type="radio"
                name="AUTH_YN"
                value="N"
                checked={authYN === "N"}
                onChange={e => {
                  setAuthYN(e.target.value);
                }}
              />
              <span className="ui-popup-radio">권한 미부여</span>
            </div>
          </div>

          <div className="ui-popup-footer">
            <button
              className="cursor ui-popup-btn-ok"
              onClick={() => onclicksubmit()}
            >
              수정
            </button>
            <button
              className="cursor ui-popup-btn-cancel"
              onClick={() => props.close()}
            >
              취소
            </button>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default Updateuserpopup;
