import React from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { closeAlert } from "actions/index";
/**
 * Version : 1.0
 * 파일명 : Alert.js
 * 작성일자 : 2021-10-28
 * 작성자 : 권도훈
 * 설명 : 공통 알림창
 * 수정일자 : 2022-02-03
 * 수정자 : 권도훈
 * 수정내역 : 클릭 이벤트 전파 방지 추가
 */
function Alert() {
  /**
   * REDUX
   */
  const ALERT_STATE = useSelector(state => state.alertInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * body
   */
  ReactModal.setAppElement("body");
  /**
   * 태그
   */
  return (
    <ReactModal
      isOpen={ALERT_STATE.DISPLAY} //모달 Open(Boolean)
      className="Alert" //모달 ClassName
      overlayClassName="defaultAlertOverlay" //모달오버레이 ClassName
      animationType="fade"
      transparent={true}
    >
      <div onClick={e => e.stopPropagation()}>
        <div
          className="drag-none"
          style={{
            paddingTop: "30px",
            paddingBottom: "20px",
            marginLeft: "24px",
            marginRight: "24px",
            textAlign: "center",
            fontSize: "16px",
            color: "#000000",
          }}
        >
          {ALERT_STATE.TEXT}
        </div>
        <div
          style={{
            height: "100%",
            textAlign: "right",
          }}
        >
          <button
            className="cursor inline"
            onClick={() => {
              dispatch(closeAlert());
            }}
            style={{
              display: ALERT_STATE.CANCLE_BTN ? "inline" : "none",
              width: "64px",
              height: "36px",
              fontSize: "14px",
              fontWeight: "bold",
              color: "#D7263D",
              background: "none",
              outline: "none",
              border: "none",
              marginRight: "8px",
              marginBottom: "6px",
            }}
          >
            취소
          </button>

          <button
            className="cursor inline"
            onClick={() => ALERT_STATE.submitEventHandler()}
            style={{
              display: ALERT_STATE.SUBMIT_BTN ? "inline" : "none",
              width: "64px",
              height: "36px",
              fontSize: "14px",
              fontWeight: "bold",
              color: "#2B4AC4",
              background: "none",
              outline: "none",
              border: "none",
              marginRight: "8px",
              marginBottom: "6px",
            }}
          >
            확인
          </button>
        </div>
      </div>
    </ReactModal>
  );
}

export default Alert;
