import React from 'react'
/**
 * Version : 1.0
 * 파일명 : RecurringList.js
 * 작성일자 : 2022-05-26
 * 작성자 : 권도훈
 */
function RecurringList(props) {
    const data = props.list.data.read();

    //가격 포맷
    const amountFormat = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원";
    }
    //결제수단타입 포맷
    const paymentTypeFormat = type => {
        switch (type) {
            case "CARD": return "카드";
            case "TRANSFER": return "계좌";
            default: return "";
        }
    }

    return (
        <>
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr
                            className='payment-content-tr'
                            key={idx}
                        >
                            <td
                                className='payment-content-td text-center'
                                onClick={e => e.stopPropagation()}
                            >
                                <input
                                    type='checkbox'
                                    className='cursor'
                                    checked={props.chkList.includes(item.USER_NUM + "/" + item.ORDER_NUM)}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.setChkList([...props.chkList, item.USER_NUM + "/" + item.ORDER_NUM]);
                                        } else {
                                            props.setChkList(props.chkList.filter(key => key !== item.USER_NUM + "/" + item.ORDER_NUM));
                                        }
                                    }}
                                />
                            </td>
                            <td className='payment-content-td' title={item.USER_NUM}>
                                <span>
                                    {item.USER_NUM}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.USER_ID}>
                                <span>
                                    {item.USER_ID}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.USER_NM}>
                                <span>
                                    {item.USER_NM}
                                </span>
                            </td>
                            <td className='payment-content-td text-center' title={item.PAYMENT_TYPE}>
                                {item.PAY_DT}
                            </td>
                            <td className='payment-content-td text-center' title={item.SUCCESS_YN}>
                                {item.SUCCESS_YN}
                            </td>
                            <td className='payment-content-td text-center' title={amountFormat(item.PRODUCT_AMOUNT)}>
                                {amountFormat(item.PRODUCT_AMOUNT)}
                            </td>
                            <td className='payment-content-td text-center' title={item.PRODUCT_COUNT}>
                                {item.PRODUCT_COUNT}
                            </td>
                            <td className='payment-content-td text-center' title={paymentTypeFormat(item.PAYMENT_TYPE)}>
                                {paymentTypeFormat(item.PAYMENT_TYPE)}
                            </td>
                            <td className='payment-content-td text-center' title={item.USE_YN}>
                                {item.USE_YN}
                            </td>
                            <td className='payment-content-td text-center' title={item.BILLING_YN}>
                                {item.BILLING_YN}
                            </td>
                            <td className='payment-content-td' title={item.REGISTER_DT}>
                                <span>
                                    {item.REGISTER_DT}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.CREATE_DTTM}>
                                <span>
                                    {item.CREATE_DTTM}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.CREATE_IP}>
                                <span>
                                    {item.CREATE_IP}
                                </span>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td
                            colSpan='14'
                            className='payment-content-empty text-center'
                        >
                            <span>등록된 정기결제가 없습니다.</span>
                        </td>
                    </tr>
            }
        </>
    )
}

export default RecurringList
