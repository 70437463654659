import React, { useCallback, useEffect, useState } from 'react'
import "css/selectbox.css"
/**
 * Version : 1.0
 * 파일명 : RecordSearchBox.js
 * 작성일자 : 2022-05-25
 * 작성자 : 권도훈
 */
function SearchSelectBox(props) {
    /**
     * useState
     */
    //event state
    const [isFocus, setIsFocus] = useState(false);
    const [isOver, setIsOVer] = useState(false);
    const [isClick, setIsClick] = useState(false);
    /**
     * useCallback
     */
    const closeList = useCallback(() => {
        setIsClick(false);
    }, []);
    /**
     * useEffect
     */
    //focus reset
    useEffect(() => {
        if (isClick) {
            window.addEventListener("click", closeList);
            return () => {
                window.removeEventListener("click", closeList);
            };
        }
    }, [isClick, closeList]);

    //label format
    const formatLabel = () => {
        let result = "";
        props.list.forEach(
            el => {
                if (el.value === props.value) {
                    result = el.label;
                }
            }
        )
        return result;
    }

    return (
        <div
            className={
                props.value.length > 0
                    ? isOver
                        ? 'select-box focus over center cursor ' + props.type
                        : 'select-box focus center cursor ' + props.type
                    : isOver
                        ? 'select-box over center cursor ' + props.type
                        : 'select-box center cursor ' + props.type
            }
            onClick={() => setIsClick(!isClick)}
            onMouseOver={() => setIsOVer(true)}
            onMouseLeave={() => setIsOVer(false)}
        >
            <span
                className={
                    props.value.length > 0 || isFocus
                        ? isOver
                            ? 'select-box-span focus over'
                            : 'select-box-span focus'
                        : 'select-box-span'
                }
            >
                {props.label}
            </span>

            <div
                className={
                    props.value === ""
                        ? 'select-box-value'
                        : 'select-box-value selected'
                }
            >
                <span>
                    {
                        props.value === ""
                            ? props.label
                            : formatLabel()
                        // : props.value
                    }
                </span>

                <img
                    className={
                        isClick
                            ? 'select-box-value-icon open'
                            : 'select-box-value-icon'
                    }
                    src="/img/icon/common/picker_down.png"
                    alt=''
                />
            </div>

            <div
                className={
                    isClick
                        ? 'select-box-itemlist open scrollbar'
                        : 'select-box-itemlist'
                }
                onMouseOver={e => {
                    e.stopPropagation();
                    setIsOVer(false);
                }}
                onClick={e => e.stopPropagation()}
            >
                {
                    props.list.map((item, idx) => (
                        item.value !== props.value
                            ?
                            <div
                                key={idx}
                                className='select-box-item center cursor'
                                onClick={() => {
                                    props.setValue(item.value);
                                    setIsClick(false);
                                }}
                            >
                                <span>{item.label}</span>
                            </div>
                            :
                            null
                    ))
                }
            </div>
        </div >
    )
}

export default SearchSelectBox
