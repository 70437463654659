import React, { Suspense, useCallback, useEffect, useRef, useState } from 'react'
import 'css/product.css';
import ProductList from 'component/product/productList';
import { fetchAxiosEvent } from 'component/info/axiosEvent';
import { useDispatch } from 'react-redux';
import { closeAlert, openAlert, openConfirm } from 'actions';
import Loading from 'component/common/loading';
import Paging from 'component/info/content/paging';
import ProductPopup from 'component/product/popup/productPopup';
import axios from 'axios';
import { CircularProgress } from "@material-ui/core";
/**
 * Version : 1.0
 * 파일명 : Product.js
 * 작성일자 : 2022-05-20
 * 작성자 : 권도훈
 */
function Product() {
    /**
     * useDispatch
     */
    const dispatch = useDispatch();
    /**
     * useState
     */
    //초기화
    const [init, setInit] = useState(true);
    //검색
    const [focusTarget, setFocusTarget] = useState("");
    const [overTarget, setOverTarget] = useState("");
    const [isSearch, setIsSearch] = useState(false);
    const [searchOption, setSearchOption] = useState({
        text: ""
    });
    const [page, setPage] = useState({
        ROW_COUNT: 10,
        PAGE_COUNT: 0
    });
    //데이터
    const [product, setProduct] = useState();
    const [total, setTotal] = useState(0);
    //팝업
    const [openPopup, setOpenPopup] = useState(false);
    const [popupType, setPopupType] = useState();
    const [popupData, setPopupData] = useState();
    //체크박스
    const [chkList, setChkList] = useState([]);
    //엑셀
    const [isDownload, setIsDownload] = useState(false);
    /**
     * useRef
     */
    const searchTextRef = useRef();
    /**
     * useCallbak
     */
    //토탈 조회
    const getProductListTotal = useCallback(() => {
        setTotal(
            fetchAxiosEvent(
                "GET",
                "/payment/product/total",
                {
                    config: {
                        params: {
                            SEARCH_VALUE: searchTextRef.current.value
                        }
                    }
                }
            )
        )
    }, []);
    //목록 조회
    const getProductList = useCallback(() => {
        setProduct(
            fetchAxiosEvent(
                "GET",
                "/payment/product",
                {
                    config: {
                        params: {
                            SEARCH_VALUE: searchTextRef.current.value,
                            ROW_COUNT: page.ROW_COUNT,
                            PAGE_COUNT: page.PAGE_COUNT
                        }
                    }
                }
            )
        )
    }, [page.PAGE_COUNT, page.ROW_COUNT]);
    //focus reset eventHandler
    const resetFocusTarget = useCallback(() => {
        setFocusTarget("");
    }, []);
    /**
     * useEffect
     */
    //초기화
    useEffect(() => {
        if (init) {
            getProductListTotal();
            getProductList();
            setInit(false);
        }
    }, [getProductList, getProductListTotal, init]);
    //검색
    useEffect(() => {
        if (isSearch) {
            getProductListTotal();
            getProductList();
            setChkList([]);
            setIsSearch(false);
        }
    }, [getProductList, getProductListTotal, isSearch]);
    //focus reset
    useEffect(() => {
        if (focusTarget !== "") {
            window.addEventListener("click", resetFocusTarget);
            return () => {
                window.removeEventListener("click", resetFocusTarget);
            };
        }
    }, [focusTarget, resetFocusTarget]);
    /**
     * function
     */
    //deleteEvent
    const deleteEventHandler = () => {
        const config = {
            data: {
                PRODUCT_LIST: chkList
            }
        }

        axios.delete("/payment/product", config)
            .then(res => {
                if (res.status === 200) {
                    const obj = {
                        TEXT:
                            <>
                                <div className='info-msg success'>
                                    알림!
                                </div>
                                <div className='info-msg'>
                                    삭제되었습니다.
                                </div>
                            </>,
                        submitEventHandler: () => {
                            setChkList([]);
                            setIsSearch(true);
                            dispatch(closeAlert());
                        },
                    };
                    dispatch(openAlert(obj));
                }
            })
            .catch(() => {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                오류!
                            </div>
                            <div className='info-msg'>
                                오류가 발생했습니다.
                            </div>
                        </>,
                    submitEventHandler: () => {
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            });
    }
    //checkbox click
    const clickChkEventHandler = e => {
        if (e.target.checked) {
            let codeArr = [];

            product.data.read().forEach(el => {
                if (!chkList.includes(el.PRODUCT_CODE)) {
                    codeArr.push(el.PRODUCT_CODE);
                }
            });

            setChkList(codeArr);

        } else {
            if (chkList.length < total.data.read()) {
                let isCheckedAll = true;
                let codeArr = [];

                product.data.read().forEach(el => {
                    if (!chkList.includes(el.PRODUCT_CODE)) {
                        isCheckedAll = false;
                        codeArr.push(el.PRODUCT_CODE);
                    }
                });

                if (isCheckedAll) {
                    setChkList([]);
                } else {
                    codeArr = codeArr.concat(chkList);
                    setChkList(codeArr);
                }

            } else {
                setChkList([]);
            }
        }
    }
    //excel download EventHandler
    const downloadExcelEventHandler = () => {
        setIsDownload(true);

        const data = {
            PRODUCT_LIST: chkList
        }

        const config = {
            responseType: "blob",
            params: {
                REQUEST_TYPE: chkList.length > 0 ? "CHECK_LIST" : "ALL"
            }
        }

        axios.post("/payment/product/excel", data, config)
            .then(res => {
                const url = window.URL.createObjectURL(
                    new Blob([res.data], { type: res.headers["content-type"] })
                );
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "PEBBLE_상품목록.xlsx");
                document.body.appendChild(link);
                link.click();
                setIsDownload(false);
            })
            .catch(() => {
                const obj = {
                    TEXT:
                        <>
                            <div className='info-msg err'>
                                오류!
                            </div>
                            <div className='info-msg'>
                                오류가 발생했습니다.
                            </div>
                        </>,
                    submitEventHandler: () => {
                        setIsDownload(false);
                        dispatch(closeAlert());
                    },
                };
                dispatch(openAlert(obj));
            });
    }

    return (
        <div className='pd-container'>
            <ProductPopup
                popupType={popupType}
                isOpen={openPopup}
                setIsOpen={setOpenPopup}
                setInit={setInit}
                setPage={setPage}
                page={page}
                data={popupData}
            />

            <section className='pd-header drag-none'>
                <span className='pd-header-title'>
                    상품관리
                </span>

                <span className='pd-header-dir'>
                    상품관리<font>&gt;</font>상품관리
                </span>
            </section>

            <div className='pd-search-area'>
                <div
                    className={
                        searchOption.text.length > 0 || focusTarget === "text"
                            ? overTarget === "text"
                                ? 'pd-search-box focus over center'
                                : 'pd-search-box focus center'
                            : overTarget === "text"
                                ? 'pd-search-box over center'
                                : 'pd-search-box center'
                    }
                    onClick={e => e.stopPropagation()}
                    onMouseOver={() => setOverTarget("text")}
                    onMouseLeave={() => setOverTarget("")}
                >
                    <img
                        src="/img/icon/common/search.png"
                        alt=''
                    />

                    <span
                        className={
                            searchOption.text.length > 0 || focusTarget === "text"
                                ? overTarget === "text"
                                    ? 'pd-search-box-span focus over'
                                    : 'pd-search-box-span focus'
                                : 'pd-search-box-span'
                        }
                    >
                        상품 코드 또는 상품명을 입력하세요.
                    </span>

                    <input
                        className='pd-search-input'
                        type="text"
                        placeholder={
                            focusTarget !== "text"
                                ? '상품 코드 또는 상품명을 입력하세요.'
                                : ""
                        }
                        ref={searchTextRef}
                        onFocus={() => setFocusTarget("text")}
                        onChange={
                            e => {
                                if (e.target.value === "" && searchOption.text !== "") {
                                    setSearchOption({
                                        text: ""
                                    });
                                    setPage({
                                        ...page,
                                        PAGE_COUNT: 0
                                    });
                                    setIsSearch(true);
                                }
                            }
                        }
                        onKeyDown={
                            e => {
                                if (e.key === "Enter" && e.target.value !== "") {
                                    setSearchOption({
                                        text: e.target.value
                                    });
                                    setPage({
                                        ...page,
                                        PAGE_COUNT: 0
                                    });
                                    setIsSearch(true);
                                }
                            }
                        }
                    />
                </div>

                <button
                    className='pd-search-btn btn blue cursor'
                    onClick={() => {
                        if (searchTextRef.current.value !== "") {
                            setSearchOption({
                                text: searchTextRef.current.value
                            });
                            setPage({
                                ...page,
                                PAGE_COUNT: 0
                            });
                            setIsSearch(true);
                        } else {
                            const obj = {
                                TEXT:
                                    <>
                                        <div className='info-msg err'>
                                            알림!
                                        </div>
                                        <div className='info-msg'>
                                            검색값을 입력해주세요.
                                        </div>
                                    </>,
                                submitEventHandler: () => {
                                    dispatch(closeAlert());
                                },
                            };
                            dispatch(openAlert(obj));
                        }
                    }}
                >
                    검색
                </button>
            </div>

            <div className='pd-content'>
                <div className='pd-content-btn-area symmetry'>
                    <div className='pd-content-btn-area-left symmetry'>
                        <div
                            className='pd-content-btn btn lightgrey cursor center'
                            onClick={() => {
                                setPopupType("CREATE");
                                setOpenPopup(true);
                            }}
                        >
                            <img
                                src='/img/icon/menu/product.png'
                                alt=''
                            />
                            <span>상품 등록</span>
                        </div>

                        <div
                            className='pd-content-btn btn lightgrey cursor center'
                            onClick={() => {
                                if (chkList.length > 0) {
                                    const obj = {
                                        TEXT:
                                            <>
                                                <div className='info-msg success'>
                                                    알림!
                                                </div>
                                                <div className='info-msg'>
                                                    선택하신 상품들을 삭제하시겠습니까?
                                                </div>
                                            </>,
                                        submitEventHandler: () => {
                                            deleteEventHandler();
                                            dispatch(closeAlert());
                                        },
                                    };
                                    dispatch(openConfirm(obj));
                                } else {
                                    const obj = {
                                        TEXT:
                                            <>
                                                <div className='info-msg err'>
                                                    알림!
                                                </div>
                                                <div className='info-msg'>
                                                    삭제하실 상품을 선택해주세요!
                                                </div>
                                            </>,
                                        submitEventHandler: () => {
                                            dispatch(closeAlert());
                                        },
                                    };
                                    dispatch(openAlert(obj));
                                }
                            }}
                        >
                            <img
                                src='/img/icon/common/delete_trash.png'
                                alt=''
                            />
                            <span>상품 삭제</span>
                        </div>
                    </div>

                    <div className='pd-content-btn-area-right'>
                        <div
                            className={
                                !isDownload
                                    ? 'pd-content-btn btn lightgrey cursor center'
                                    : 'pd-content-btn btn lightgrey drag-none center'
                            }
                            onClick={() => {
                                if (!isDownload) {
                                    const obj = {
                                        TEXT:
                                            <>
                                                <div className='info-msg success'>
                                                    알림!
                                                </div>
                                                <div className='info-msg'>
                                                    엑셀파일을 다운로드 하시겠습니까?
                                                </div>
                                            </>,
                                        submitEventHandler: () => {
                                            downloadExcelEventHandler();
                                            dispatch(closeAlert());
                                        },
                                    };
                                    dispatch(openConfirm(obj));
                                }
                            }}
                        >
                            {
                                isDownload
                                    ?
                                    <CircularProgress size={18} />
                                    :
                                    <>
                                        <img
                                            src='/img/icon/common/exceldown.png'
                                            alt=''
                                        />
                                        <span>엑셀 다운로드</span>
                                    </>
                            }
                        </div>
                    </div>
                </div>

                <div className='pd-content-table-area'>
                    <table className='pd-content-table'>
                        <colgroup>
                            <col width="3%" />
                            <col width="3%" />
                            <col width="8%" />
                            <col width="8%" />
                            <col width="8%" />
                            <col width="8%" />
                            <col width="9%" />
                            <col width="8%" />
                            <col width="5%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>

                        <thead>
                            <tr>
                                <th className='pd-content-th'>
                                    <input
                                        type="checkbox"
                                        className='cursor'
                                        checked={chkList.length > 0}
                                        onChange={e => clickChkEventHandler(e)}
                                    />
                                </th>
                                <th className='pd-content-th'>
                                    NO
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        상품 코드
                                    </span>
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        상품명
                                    </span>
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        상품 가격
                                    </span>
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        할인금액
                                    </span>
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        설명
                                    </span>
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        적용 기간
                                    </span>
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        사용 여부
                                    </span>
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        생성일시
                                    </span>
                                </th>
                                <th className='pd-content-th left'>
                                    <span>
                                        생성자IP
                                    </span>
                                </th>
                            </tr>
                        </thead>

                        <tbody className='pd-content-tbody'>
                            <Suspense
                                fallback={
                                    <tr>
                                        <td colSpan='11'>
                                            <Loading />
                                        </td>
                                    </tr>
                                }
                            >
                                {
                                    !init
                                        ?
                                        <ProductList
                                            product={product}
                                            setOpenPopup={setOpenPopup}
                                            setPopupType={setPopupType}
                                            setPopupData={setPopupData}
                                            chkList={chkList}
                                            setChkList={setChkList}
                                        />
                                        :
                                        <></>
                                }
                            </Suspense>
                        </tbody>
                    </table>

                    <div className='pd-content-paging'>
                        <Suspense fallback={<></>}>
                            {
                                !init
                                    ?
                                    <Paging
                                        setIsSearch={setIsSearch}
                                        page={page}
                                        setPage={setPage}
                                        TOTAL={total}
                                    />
                                    :
                                    <></>
                            }
                        </Suspense>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Product
