import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal';

function EmailPopup(props) {
    const [init, setInit] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        if (props.popup && init) {
            setData(props.content);
            setInit(false);
        }
    }, [init, props.content, props.popup]);

    const closeEventHandler = () => {
        props.setPopup(false);
        props.setContent({});
        setInit(true);
    }

    const convertHTML = (value) => {
        return (
            <div
                className='info-popup-email-mail-content scrollbar'
                dangerouslySetInnerHTML={{ __html: value }}
            />
        )
    }

    return (
        <ReactModal
            isOpen={props.popup}
            className="info-popup-email"
            overlayClassName="popupOverlay"
            animationType="fade"
            transparent={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => closeEventHandler()}
        >
            <div className='info-popup-email-inner'>
                <img
                    className='info-popup-email-close cursor'
                    src='/img/icon/common/close_black.png'
                    alt=''
                    onClick={() => closeEventHandler()}
                />

                <table className='info-popup-email-table'>
                    <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                    </colgroup>

                    <tbody>
                        <tr className='info-popup-email-tr title'>
                            <td colSpan="2">
                                <span>메일 상세보기</span>
                            </td>
                        </tr>

                        <tr className='info-popup-email-tr item'>
                            <td className='info-popup-email-td-key'>
                                <span>
                                    발송자 명
                                </span>
                            </td>
                            <td className='info-popup-email-td-value'>
                                <span>
                                    {data.EMAIL_SENDER_NM}
                                </span>
                            </td>
                        </tr>

                        <tr className='info-popup-email-tr item'>
                            <td className='info-popup-email-td-key'>
                                <span>
                                    발송자 이메일
                                </span>
                            </td>
                            <td className='info-popup-email-td-value'>
                                <span>
                                    {data.EMAIL_SENDER}
                                </span>
                            </td>
                        </tr>

                        <tr className='info-popup-email-tr item'>
                            <td className='info-popup-email-td-key'>
                                <span>
                                    메일 제목
                                </span>
                            </td>
                            <td className='info-popup-email-td-value'>
                                <span>
                                    {data.EMAIL_TITLE}
                                </span>
                            </td>
                        </tr>

                        <tr className='info-popup-email-tr content'>
                            <td className='info-popup-email-td-key'>
                                <span>
                                    메일 내용
                                </span>
                            </td>
                            <td className='info-popup-email-td-value'>
                                {convertHTML(data.EMAIL_CONTENT)}
                            </td>
                        </tr>

                        <tr className='info-popup-email-tr item'>
                            <td className='info-popup-email-td-key'>
                                <span>
                                    발송 일시
                                </span>
                            </td>
                            <td className='info-popup-email-td-value'>
                                <span>
                                    {data.SEND_DTTM}
                                </span>
                            </td>
                        </tr>

                        <tr className='info-popup-email-tr btn'>
                            <td
                                className='info-popup-email-td-btn'
                                colSpan="2"
                            >
                                <button
                                    className='blue btn cursor'
                                    onClick={() => closeEventHandler()}
                                >
                                    닫기
                                </button>
                            </td>
                        </tr>

                    </tbody>

                </table>

            </div>
        </ReactModal>
    )
}

export default EmailPopup
