import React, { useCallback, useEffect, useState } from 'react'
import { closeAlert, openAlert } from "actions/index";
import { useDispatch } from 'react-redux';
/**
 * Version : 1.0
 * 파일명 : InfoSearchArea.js
 * 작성일자 : 2022-04-27
 * 작성자 : 권도훈
 */
function InfoSearchArea(props) {
    const dispatch = useDispatch();
    const [searchValue, setSearchValue] = useState("");
    const [isSelect, setIsSelect] = useState(false);

    const closeSelectBox = useCallback(() => {
        setIsSelect(false)
    }, []);

    useEffect(() => {
        if (isSelect) {
            window.addEventListener("click", closeSelectBox);
            return () => {
                window.removeEventListener("click", closeSelectBox);
            };
        }
    }, [closeSelectBox, isSelect]);

    const typeFormat = (type) => {
        if (type === "USER_ID") {
            return "회원아이디";
        } else {
            return "회원번호"
        }
    }

    return (
        <div className='info-search-area'>
            <div
                className='info-search-selectbox cursor'
                onClick={() => setIsSelect(!isSelect)}
            >
                <div>{typeFormat(props.target.SEARCH_TYPE)}</div>
                <img
                    src={
                        isSelect
                            ? "/img/icon/common/picker_up.png"
                            : "/img/icon/common/picker_down.png"
                    }
                    alt=''
                />

                {
                    isSelect
                        ?
                        <div
                            className='info-search-selectbox-item'
                            onClick={() =>
                                props.setTarget({
                                    ...props.target,
                                    SEARCH_TYPE: props.target.SEARCH_TYPE === "USER_ID"
                                        ? "USER_NUM"
                                        : "USER_ID"
                                })
                            }
                        >
                            {typeFormat(
                                props.target.SEARCH_TYPE === "USER_ID"
                                    ? "USER_NUM"
                                    : "USER_ID"
                            )}
                        </div>
                        : <></>
                }
            </div>

            <input
                className='info-search-input'
                type="text"
                placeholder='검색값을 입력해주세요.'
                onChange={e => setSearchValue(e.target.value)}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                        if (e.target.value === "") {
                            const obj = {
                                TEXT:
                                    <>
                                        <div className='info-msg err'>
                                            오류
                                        </div>
                                        <div className='info-msg'>
                                            검색값을 입력해주세요!
                                        </div>
                                    </>,
                                submitEventHandler: () => { dispatch(closeAlert()); }
                            };
                            dispatch(openAlert(obj));
                            return;
                        } else if (e.target.value === props.target.SEARCH_VALUE) {
                            return;
                        }

                        props.setTarget({
                            ...props.target,
                            SEARCH_VALUE: e.target.value
                        });
                        props.setSearch(true);
                    }
                }}
            />

            <button
                className='info-search-btn cursor'
                onClick={() => {
                    if (searchValue === "") {
                        const obj = {
                            TEXT:
                                <>
                                    <div className='info-msg err'>
                                        오류
                                    </div>
                                    <div className='info-msg'>
                                        검색값을 입력해주세요!
                                    </div>
                                </>,
                            submitEventHandler: () => { dispatch(closeAlert()); }
                        };
                        dispatch(openAlert(obj));
                        return;
                    } else if (searchValue === props.target.SEARCH_VALUE) {
                        return;
                    }

                    props.setTarget({
                        ...props.target,
                        SEARCH_VALUE: searchValue
                    });
                    props.setSearch(true);
                }}
            >
                검 색
            </button>
        </div>
    )
}

export default InfoSearchArea
