import { CircularProgress } from "@material-ui/core";
import React from "react";
/**
 * Version : 1.0
 * 파일명 : loading.js
 * 작성일자 : 2021-11-15
 * 작성자 : 권도훈
 * 설명 : 공통 로딩 컴포넌트
 * 수정일자 :  2021-11-22
 * 수정자 : 권도훈
 * 수정내역 : 로딩 이미지 수정
 */
function Loading() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <CircularProgress size={24} />
    </div>
  );
}

export default Loading;
