import React, { useCallback, useEffect, useState } from "react";
import ReactModal from "react-modal";
import "css/writenotice.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "actions";
import Editorreact from "component/common/editorreact";

  /**
 * Version : 1.0
 * 작성일자 : 2022-04-12
 * 작성자 : 정지윤
 * 설명 : 공지사항 등록/수정 모달화면
 */
function Noticewritepopup(props) {


       /**
      * DISPATCH
      */
        const dispatch = useDispatch();
        let server_url = "";
        if (process.env.NODE_ENV === "development") {
         server_url =
           process.env.REACT_APP_SERVER_URL + process.env.REACT_APP_SERVER_PORT;
       } else {
         server_url = process.env.REACT_APP_SERVER_URL;
       }

  const [writeexpose, setwirteexpose] = useState('Y');
  const [writehold, setwirtehold] = useState('Y');
  const [writetitle, setwirtetitle] = useState('');
  const [writecontent, setwritecontent] = useState('');
  // const [NOTICE_NUM, setNoticenum] = useState(props.notice_num);


  const handlewriteexpose = (e) =>{
    setwirteexpose(e.target.value);
  }
  const handlewritehold = (e) =>{
    setwirtehold(e.target.value);
  }
  const handlewritetitle = (e) =>{
    setwirtetitle(e.target.value);
  }
  const handlewritcontent = (e) =>{
    setwritecontent (e.target.value);
  }
  
  const writedata = (content) =>{
    setwritecontent(content);
  }


const [totalFiles, setTotalFiles] = useState([]);
const [oldFiles, setOldFiles] = useState([]);
const [fileInfoList, setfileInfoList] = useState([]);

useEffect(() => {
  if (
    fileInfoList !== undefined &&
    fileInfoList !== null &&
    fileInfoList !== ""
  ) {
    let tmp = [];
    for (let i = 0; i < fileInfoList.length; i++) {
      tmp.push({
        FILE_NM: fileInfoList[i].FILE_NM,
        ORI_FILE_NM: fileInfoList[i].ORI_FILE_NM,
        type: "old",
        fileSize: fileInfoList[i].FILE_SIZE,
      });
    }
    setOldFiles(oldFiles.concat(tmp));
    setTotalFiles(totalFiles.concat(tmp));
  }
}, [fileInfoList]);

const [newFileList, setNewFileList] = useState([]);
const onChangeFile = e => {
  let tmpArr = [];
  let totalTmp = [];
  let scheduleFileTotalSize = 0;
  for (let i = 0; i < oldFiles.length; i++) {
    scheduleFileTotalSize += parseInt(oldFiles[i].fileSize);
  }
  for (let i = 0; i < newFileList.length; i++) {
    scheduleFileTotalSize += newFileList[i].size;
  }
  for (let i = 0; i < e.target.files.length; i++) {
    let sizeTotal = scheduleFileTotalSize;
    if (newFileList.find(file => file.name === e.target.files[i].name)) {
      const obj = {
        TEXT:  "이미 선택된 파일입니다.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    if (oldFiles.find(file => file.ORI_FILE_NM === e.target.files[i].name)) {
      const obj = {
        TEXT:  "이미 선택된 파일입니다.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    if (e.target.files[i].size > 10485760) {
      const obj = {
        TEXT:  "10MB 이하의 파일을 첨부해주세요.",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    if (sizeTotal + e.target.files[i].size > 10485760) {
      const obj = {
        TEXT: "파일 크기 합계가 10MB 이상입니다. 사이즈를 줄여주세요",
        submitEventHandler: () => dispatch(closeAlert()),
      };
      dispatch(openAlert(obj));
      return;
    }
    scheduleFileTotalSize += e.target.files[i].size;
    tmpArr.push(e.target.files[i]);
    totalTmp.push({ FILE_NM: e.target.files[i].name, type: "new" });
  }
  setNewFileList(newFileList.concat(tmpArr));
  setTotalFiles(totalFiles.concat(totalTmp));
  e.target.value = "";
};
const onRemoveFile = (fileNM, type) => {
  if (type === "old") {
    setOldFiles(oldFiles.filter(list => list.FILE_NM !== fileNM));
  } else if (type === "new") {
    setNewFileList(newFileList.filter(list => list.name !== fileNM));
  }
  setTotalFiles(totalFiles.filter(list => list.FILE_NM !== fileNM));
};
let opennumber = props.notice_num;

  const gettingnotice = useCallback(()=>{
    if(props.opentype === 1){
    setfileInfoList([]);
    setTotalFiles([]);
    let config = {
      params: {
        notice_num : opennumber
      }
    }
    axios.get("/support/getNotice", config).then((res)=>{
      if(res.status===200){
        setwirteexpose(res.data.Noticedata.Notice_Expose_YN)
        setwirtehold(res.data.Noticedata.Notice_Hold_YN)
        setwirtetitle(res.data.Noticedata.Notice_Title)
        setwritecontent(res.data.Noticedata.Notice_Content)
        setfileInfoList(res.data.Noticeimage)
      }

    })
  }else if(props.opentype ===0){

  }
  },[opennumber, props.opentype])

  useEffect(() =>{
    gettingnotice()
  }, [gettingnotice])

 
   const onClicksubmit = (title, content, expose, hold) =>{

    const formData = new FormData();
  
    if (Array.isArray(newFileList) && newFileList.length > 0) {
      for (let i = 0; i < newFileList.length; i++) {
        formData.append("files", newFileList[i]);
      }
  
    }
    let parameter = {
      Notice_Hold : hold,
      Notice_Expose : expose,
      Notice_Content : content,
      Notice_Title : title,
    }
    
  
    formData.append("params", JSON.stringify(parameter))
  
    
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };
  
    axios
    .post("/support/Notice", formData, config)
    .then(response =>{
      if (response.status===200){
      }
    })
    const obj = {
      TEXT: "공지사항이 등록되었습니다.",
      submitEventHandler: () => {dispatch(closeAlert()); props.close();}
    };
    dispatch(openAlert(obj));
    
    return;
  
    
  
  }
  const onClickUpdate = (title, content, expose, hold,NOTICE_NUM) =>{
    const formData = new FormData();
    let parameter = {
      Notice_Hold : hold,
      Notice_Expose : expose,
      Notice_Content : content,
      Notice_Title : title,
      NOTICE_NUM : NOTICE_NUM,
    }
  
  
    if (Array.isArray(newFileList) && newFileList.length > 0) {
      for (let i = 0; i < newFileList.length; i++) {
        formData.append("files", newFileList[i]);
      }
  
    }
    if (Array.isArray(oldFiles) && oldFiles.length > 0) {
      parameter = {
        ...parameter,
        file_info: oldFiles,
      };
    } else if (oldFiles.length === 0) {
      if (fileInfoList === undefined) {
        parameter = {
          ...parameter,
          file_info: null,
        };
      } else if (fileInfoList !== undefined && fileInfoList.length > 0) {
        parameter = {
          ...parameter,
          file_info: [],
        };
      }
    }
  
    formData.append("params", JSON.stringify(parameter))
  
    
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      }
    };
  
    axios.put("/support/updateNotice", formData, config)
    .then(response =>{
      if (response.status===200){
  
      }
    })
    const obj = {
      TEXT: "공지사항이 수정되었습니다.",
      submitEventHandler: () => {dispatch(closeAlert()); props.close();}
    };
    dispatch(openAlert(obj));
    return;
  }
  const clickclose = () =>{
    props.close();
  }

 
  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="scheduleDetailPopup" //모달 ClassName
      overlayClassName="scheduleDetailPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close();
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >

<div style={{display:"flex",flexDirection:"column"}}>

<div style={{textAlign : "right"}}>
  <img  className='cursor'
        style={{marginRight : "10px",
                marginTop : "10px"}}
        src="/img/icon/common/close_black.png"
        alt=''
        onClick={() => clickclose() }/>
    </div>
<div className='writeNotice' style={{
                      border : "2px",
                      borderColor :"black",
                    
                    }}>
                      <div style={{
                        display : "flex"
                      }}>
                        <div className="writeTitlehead">제목 :</div> <input className="writenoticeTitle" type='text' onChange={handlewritetitle} value={writetitle} /> 
                      </div>

                      <div className='exposeYN'>
                        <div >노출여부 :</div>
                        <input type='radio' className='exposeo' name='Expose_YN_CLICK' value="Y" onChange={handlewriteexpose} checked={writeexpose === 'Y'}/><label className='exposeo'>노출</label>
                        <input type='radio'className='exposeno' name='Expose_YN_CLICK' value="N" onChange={handlewriteexpose} checked={writeexpose === 'N'}/><label className='exposeno'>노출 해제</label>
                      </div>

                      <div className="holdYN">
                        <div>고정여부 : </div>
                        <input type='radio' className='holdo' name='hold_YN_CLICK' value="Y" onChange={handlewritehold} checked={writehold ==='Y'}/><label className='holdo'>고정</label>
                        <input type='radio'className='holdno' name='hold_YN_CLICK' value="N" onChange={handlewritehold} checked={writehold ==='N'}/><label className='holdno'>고정 해제</label>
                      </div>

                      <div>
                      <Editorreact content={writecontent} writedata={writedata} open = {1}/>
                    </div>

          <div>
          <div className='notice_file'>
          <div className='notice_file_head'>
            <div className='notice_fontblack'>파일목록</div> 
            </div>
            <div className='notice_input_file' >
            <div className='notice_input_fileinfo'>
            {Array.isArray(totalFiles) && totalFiles.length > 0 ? (
              <div className="notice_fileListscrollbar"
              style={{
                overflowY : "scroll",
                height : "50px",

              }}>
                  {totalFiles.map(data => (
                    <div key={data.FILE_NM} className="notice_img_test"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                                          }}>
                      <font
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          font: "normal normal normal 14px/18px Spoqa Han Sans Neo",
                          color: "#3E3E4F",
                        }}
                      >
                         {data.type === "old" ? 
                         <div className="cursor" onClick={() =>
                          window.open(
                            server_url +
                              "/file/downloadFile?filename=" +
                              data.FILE_NM +
                              "&downfilename=" +
                              data.ORI_FILE_NM,
                            "_blank"
                          )}>
                          {data.ORI_FILE_NM}
                          </div> 
                        : data.FILE_NM}
                      </font>
                      <img
                        className="cursor"
                        src="/img/icon/common/remove.png"
                        alt="remove"
                        style={{
                          verticalAlign: "top",
                          float: "right",
                          marginRight: "8px",
                        }}
                        onClick={() => onRemoveFile(data.FILE_NM, data.type )}
                      />
                    </div>
                  ))}
              
              </div>
            ) :(
              <div className="notice_inputFileplaceholder">
              10MB이내 파일만 가능합니다.
              </div>
            )
            }

              </div> 
            <div className='notice_inputfilebtn'>
            <label htmlFor="inputFile" className='noticeimagebtn cursor'>파일첨부</label>
            <input type='file' 
              id="inputFile" 
              className='noticeinputfilemain'
              placeholder='파일첨부'
              // accept="image/png, image/jpg, image/bmp, image/gif" 
              style={{display:"none"}}
              onChange={onChangeFile}
              name="file"
              multiple />
            </div>



            </div>
          </div>
     </div>


                      <div className="notice_submit_button">
                            {props.opentype ===0 ?
                            <div className="notice_submit cursor"onClick={()=>onClicksubmit(writetitle, writecontent, writeexpose, writehold)}>
                            등록버튼
                          </div>: 
                          <div className="notice_submit cursor" onClick={()=>onClickUpdate(writetitle, writecontent, writeexpose, writehold,props.notice_num)}>
                          수정버튼           
                        </div>}
                      </div>
                  </div>
      </div>
    </ReactModal>
  );
}

export default Noticewritepopup;
