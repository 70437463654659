import Loading from 'component/common/loading';
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { fetchAxiosEvent } from './axiosEvent';
import PaylogContent from './content/paylogContent';
import Paging from 'component/info/content/paging';
/**
 * Version : 1.0
 * 파일명 : PayLog.js
 * 작성일자 : 2022-05-02
 * 작성자 : 권도훈
 */
function PayLog(props) {
    /**
     * state
     */
    //검색 조건
    const [isSearch, setIsSearch] = useState(false);
    const [statusType, setStatusType] = useState({
        isShow: false,
        value: "ALL"
    });
    const [page, setPage] = useState({
        ROW_COUNT: 10,
        PAGE_COUNT: 0
    });
    //목록
    const [list, setList] = useState(
        fetchAxiosEvent(
            "GET",
            "/payment/paylog",
            {
                config: {
                    params: {
                        USER_NUM: props.userNum,
                        GROUP_YN: "N",
                        GROUP_NUM: "",
                        ROW_COUNT: page.ROW_COUNT,
                        PAGE_COUNT: page.PAGE_COUNT,
                        OPTION_STATUS: "ALL"
                    }
                }
            }
        )
    );
    //토탈
    const [total, setTotal] = useState(
        fetchAxiosEvent(
            "GET",
            "/payment/total",
            {
                config: {
                    params: {
                        USER_NUM: props.userNum,
                        OPTION_STATUS: "ALL"
                    }
                }
            }
        ));
    /**
     * useCallback
     */
    //토탈 조회
    const getTotal = useCallback(() => {
        const obj = {
            config: {
                params: {
                    USER_NUM: props.userNum,
                    OPTION_STATUS: statusType.value
                }
            }
        }
        setTotal(fetchAxiosEvent("GET", "/payment/total", obj));
    }, [props.userNum, statusType.value]);
    //목록 조회
    const getPaylog = useCallback(() => {
        const obj = {
            config: {
                params: {
                    USER_NUM: props.userNum,
                    GROUP_YN: "N",
                    GROUP_NUM: "",
                    ROW_COUNT: page.ROW_COUNT,
                    PAGE_COUNT: page.PAGE_COUNT,
                    OPTION_STATUS: statusType.value
                }
            }
        }
        setList(fetchAxiosEvent("GET", "/payment/paylog", obj));
    }, [page.PAGE_COUNT, page.ROW_COUNT, props.userNum, statusType.value]);
    //사용여부 닫기 이벤트핸들러
    const closeStatusBox = useCallback(() => {
        setStatusType({
            ...statusType,
            isShow: false
        })
    }, [statusType]);
    /**
     * useEffect
     */
    //검색
    useEffect(() => {
        if (isSearch) {
            getPaylog();
            getTotal();
            setIsSearch(false);
        }
    }, [getPaylog, getTotal, isSearch]);
    //셀렉트 박스 이벤트핸들러 관리
    useEffect(() => {
        if (statusType.isShow) {
            window.addEventListener("click", closeStatusBox);
            return () => {
                window.removeEventListener("click", closeStatusBox);
            };
        }
    }, [closeStatusBox, statusType.isShow]);
    /**
     * function
     */
    //그룹할당 여부 포맷
    const typeBoxFormat = (type, value) => {
        switch (value) {
            case "ALL":
                if (type === "box_display") {
                    return "전체"
                } else {
                    return ["DONE", "FAIL"]
                }
            case "DONE":
                if (type === "box_display") {
                    return "결제성공"
                } else {
                    return ["ALL", "FAIL"]
                }
            case "FAIL":
                if (type === "box_display") {
                    return "결제실패"
                } else {
                    return ["ALL", "DONE"]
                }
            default: break;
        }
    }

    return (
        <div className='info-content-inner table'>
            <div>
                <div className='info-content-inner-search'>
                    <div className='info-content-inner-search-option'>
                        <font>
                            결제상태
                        </font>

                        <div
                            className='info-content-inner-search-selectbox cursor'
                            onClick={() =>
                                setStatusType({
                                    ...statusType,
                                    isShow: !statusType.isShow
                                })
                            }
                        >
                            <div>
                                {typeBoxFormat("box_display", statusType.value)}
                            </div>

                            <img
                                src={
                                    statusType.isShow
                                        ? "/img/icon/common/picker_up.png"
                                        : "/img/icon/common/picker_down.png"
                                }
                                alt=''
                            />

                            {
                                statusType.isShow
                                    ?
                                    <div className='info-content-inner-search-itembox'>
                                        {
                                            typeBoxFormat("box_option", statusType.value).map((data, idx) => (
                                                <div
                                                    key={idx}
                                                    className='info-content-inner-search-item'
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        setStatusType({
                                                            isShow: false,
                                                            value: data
                                                        });
                                                    }}
                                                >
                                                    {typeBoxFormat("box_display", data)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </div>

                    <button
                        className='info-search-btn cursor'
                        onClick={() => {
                            setPage({
                                ...page,
                                PAGE_COUNT: 0
                            });
                            setIsSearch(true);
                        }}
                    >
                        검 색
                    </button>
                </div >

                <table className='info-content-table'>
                    <colgroup>
                        <col width="20%" />
                        <col width="12%" />
                        <col width="12%" />
                        <col width="10%" />
                        <col width="20%" />
                        <col width="10%" />
                        <col width="8%" />
                        <col width="8%" />
                    </colgroup>

                    <thead>
                        <tr>
                            <th>결제일</th>
                            <th>결제금액</th>
                            <th>결제방식</th>
                            <th>결제상태</th>
                            <th>결제수단 정보</th>
                            <th>결제명</th>
                            <th>전표</th>
                            <th>상세</th>
                        </tr>
                    </thead>

                    <tbody>
                        <Suspense
                            fallback={
                                <tr>
                                    <td colSpan="8">
                                        <div className="info-content-body-loading">
                                            <Loading />
                                        </div>
                                    </td>
                                </tr>
                            }
                        >
                            <PaylogContent
                                list={list}
                                userNum={props.userNum}
                                setIsSearch={setIsSearch}
                            />
                        </Suspense>
                    </tbody>
                </table>
            </div>

            <div className='info-content-paging'>
                <Suspense fallback={<></>}>
                    <Paging
                        setIsSearch={setIsSearch}
                        page={page}
                        setPage={setPage}
                        TOTAL={total}
                    />
                </Suspense>
            </div>
        </div>
    )
}

export default PayLog
