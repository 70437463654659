import React, { useState, useEffect } from "react";
import "css/main.css";
import { useHistory } from "react-router";
import axios from "axios";
import Loading from "component/common/loading";
import moment from "moment";
import SignUpChart from "component/dashboard/signUpChart";
import PaymentChart from "component/dashboard/paymentChart";
import MeetingChart from "component/dashboard/meetingChart";
/**
 * Version : 1.0
 * 작성일자 : 2022-05-26
 * 작성자 : 강연승
 * 설명 : 메인화면 (대시보드)
 * 수정일자 : 2022-05-27
 * 작성자 : 강연승
 * 설명 : 대시보드 페이지 추가
 */
function Main() {
  /**
   * history
   */
  const history = useHistory();

  /**
   * 금주의 시작, 끝 날짜
   */
  let startOfWeek = moment().startOf("week").toDate();
  let endOfWeek = moment().endOf("week").toDate();

  /**
   * STATE
   */
  const [questionCnt, setQuestionCnt] = useState(0);
  const [feedbackCnt, setFeedbackCnt] = useState(0);
  const [signUpCnt, setSignUpCnt] = useState(0);
  const [signUpCntList, setSignUpCntList] = useState([
    { day: "SUN", cnt: 0 },
    { day: "MON", cnt: 0 },
    { day: "TUE", cnt: 0 },
    { day: "WED", cnt: 0 },
    { day: "THU", cnt: 0 },
    { day: "FRI", cnt: 0 },
    { day: "SAT", cnt: 0 },
  ]);
  const [paymentCnt, setPaymentCnt] = useState(0);
  const [paymentCntList, setPaymentCntList] = useState([
    { day: "SUN", cnt: 0 },
    { day: "MON", cnt: 0 },
    { day: "TUE", cnt: 0 },
    { day: "WED", cnt: 0 },
    { day: "THU", cnt: 0 },
    { day: "FRI", cnt: 0 },
    { day: "SAT", cnt: 0 },
  ]);
  const [meetingPassCnt, setMeetingPassCnt] = useState(0);
  const [meetingPassCntList, setMeetingPassCntList] = useState([
    { day: "SUN", cnt: 0 },
    { day: "MON", cnt: 0 },
    { day: "TUE", cnt: 0 },
    { day: "WED", cnt: 0 },
    { day: "THU", cnt: 0 },
    { day: "FRI", cnt: 0 },
    { day: "SAT", cnt: 0 },
  ]);
  const [resMeetingCnt, setResMeetingCnt] = useState(0);
  const [resMeetingCntList, setResMeetingCntList] = useState([
    { day: "SUN", cnt: 0 },
    { day: "MON", cnt: 0 },
    { day: "TUE", cnt: 0 },
    { day: "WED", cnt: 0 },
    { day: "THU", cnt: 0 },
    { day: "FRI", cnt: 0 },
    { day: "SAT", cnt: 0 },
  ]);
  const [endMeetingCnt, setEndMeetingCnt] = useState(0);
  const [endMeetingCntList, setEndMeetingCntList] = useState([
    { day: "SUN", cnt: 0 },
    { day: "MON", cnt: 0 },
    { day: "TUE", cnt: 0 },
    { day: "WED", cnt: 0 },
    { day: "THU", cnt: 0 },
    { day: "FRI", cnt: 0 },
    { day: "SAT", cnt: 0 },
  ]);

  const dateFormat = dateTime => {
    let year = dateTime.getFullYear();
    let month = dateTime.getMonth() + 1;
    let date = dateTime.getDate();
    let dayNum = dateTime.getDay();
    let dayString = "";
    switch (dayNum) {
      case 0:
        dayString = "일";
        break;
      case 1:
        dayString = "월";
        break;
      case 2:
        dayString = "화";
        break;
      case 3:
        dayString = "수";
        break;
      case 4:
        dayString = "목";
        break;
      case 5:
        dayString = "금";
        break;
      case 6:
        dayString = "토";
        break;
      default:
        break;
    }
    return year + "." + month + "." + date + "(" + dayString + ")";
  };

  /**
   * 문의사항, 고객의 소리 신규 등록 건수 조회
   */
  const getSupportCnt = () => {
    let url = "/dashboard/supportCnt";
    axios.get(url).then(res => {
      if (res.status === 200) {
        setQuestionCnt(res.data.QUESTION_CNT);
        setFeedbackCnt(res.data.FEEDBACK_CNT);
      }
    });
  };
  /**
   * 신규 유저 가입 수 조회
   */
  const getSignUpUserCnt = () => {
    let url = "/dashboard/signUpUserCnt";
    axios.get(url).then(res => {
      if (res.status === 200) {
        setSignUpCnt(res.data.SIGNUP.TOTAL);
        setSignUpCntList([
          { day: "SUN", cnt: res.data.SIGNUP.SUN },
          { day: "MON", cnt: res.data.SIGNUP.MON },
          { day: "TUE", cnt: res.data.SIGNUP.TUE },
          { day: "WED", cnt: res.data.SIGNUP.WED },
          { day: "THU", cnt: res.data.SIGNUP.THU },
          { day: "FRI", cnt: res.data.SIGNUP.FRI },
          { day: "SAT", cnt: res.data.SIGNUP.SAT },
        ]);
      }
    });
  };

  /**
   * 금주 정기구독한 유저 및 회의이용권 수 조회
   */
  const getPaymentCnt = () => {
    let url = "/dashboard/paymentCnt";
    axios.get(url).then(res => {
      if (res.status === 200) {
        setPaymentCnt(res.data.PAYMENT.TOTAL);
        setPaymentCntList([
          { day: "SUN", cnt: res.data.PAYMENT.SUN },
          { day: "MON", cnt: res.data.PAYMENT.MON },
          { day: "TUE", cnt: res.data.PAYMENT.TUE },
          { day: "WED", cnt: res.data.PAYMENT.WED },
          { day: "THU", cnt: res.data.PAYMENT.THU },
          { day: "FRI", cnt: res.data.PAYMENT.FRI },
          { day: "SAT", cnt: res.data.PAYMENT.SAT },
        ]);
        setMeetingPassCnt(res.data.PASS.TOTAL);
        setMeetingPassCntList([
          { day: "SUN", cnt: res.data.PASS.SUN },
          { day: "MON", cnt: res.data.PASS.MON },
          { day: "TUE", cnt: res.data.PASS.TUE },
          { day: "WED", cnt: res.data.PASS.WED },
          { day: "THU", cnt: res.data.PASS.THU },
          { day: "FRI", cnt: res.data.PASS.FRI },
          { day: "SAT", cnt: res.data.PASS.SAT },
        ]);
      }
    });
  };

  /**
   * 예약 및 회의 완료 횟수 조회
   */
  const getMeetingCnt = () => {
    let url = "/dashboard/meetingCnt";
    axios.get(url).then(res => {
      if (res.status === 200) {
        setResMeetingCnt(res.data.RES.TOTAL);
        setResMeetingCntList([
          { day: "SUN", cnt: res.data.RES.SUN },
          { day: "MON", cnt: res.data.RES.MON },
          { day: "TUE", cnt: res.data.RES.TUE },
          { day: "WED", cnt: res.data.RES.WED },
          { day: "THU", cnt: res.data.RES.THU },
          { day: "FRI", cnt: res.data.RES.FRI },
          { day: "SAT", cnt: res.data.RES.SAT },
        ]);
        setEndMeetingCnt(res.data.END.TOTAL);
        setEndMeetingCntList([
          { day: "SUN", cnt: res.data.END.SUN },
          { day: "MON", cnt: res.data.END.MON },
          { day: "TUE", cnt: res.data.END.TUE },
          { day: "WED", cnt: res.data.END.WED },
          { day: "THU", cnt: res.data.END.THU },
          { day: "FRI", cnt: res.data.END.FRI },
          { day: "SAT", cnt: res.data.END.SAT },
        ]);
      }
    });
  };

  useEffect(() => {
    getSupportCnt();
    getSignUpUserCnt();
    getPaymentCnt();
    getMeetingCnt();
  }, []);

  return (
    <>
      {/* {loading ? (
        <div
          style={{
            display: loading ? "block" : "none",
            zIndex: "997",
            position: "absolute",
            width: "calc(100% - 285px)",
            height: "calc(100% - 91px)",
          }}
        >
          <Loading />
        </div>
      ) : null} */}
      <div className="main_section">
        <div className="main_title">
          <font className="dateRange">
            {"기간 : " +
              dateFormat(startOfWeek) +
              " ~ " +
              dateFormat(endOfWeek)}
          </font>
        </div>
        <div className="main_supportArea">
          <div
            className="main_questionContainer"
            onClick={() =>
              history.push({
                pathname: "/app/question",
              })
            }
          >
            {"신규 문의사항 : " + questionCnt + " 건"}
          </div>
          <div
            className="main_feedbackContainer"
            onClick={() =>
              history.push({
                pathname: "/app/suggestion",
              })
            }
          >
            {"신규 고객의 소리 : " + feedbackCnt + " 건"}
          </div>
        </div>
        <div className="main_dashboardContainer">
          <div className="signUpArea">
            <div className="signUpArea_title">
              <div className="signUpDiv">
                <div className="signUpBox" />
                <div className="signUpText">
                  {"회원 가입 인원 (" + signUpCnt + " 명)"}
                </div>
              </div>
            </div>
            <div className="signUpChart">
              <SignUpChart signUpCntList={signUpCntList} />
            </div>
          </div>
          <div className="paymentArea">
            <div className="paymentArea_title">
              <div className="paymentDiv">
                <div className="paymentBox" />
                <div className="paymentText">
                  {"정기 구독한 인원 (" + paymentCnt + "명)"}
                </div>
              </div>
              <div className="meetingPassDiv">
                <div className="meetingPassBox" />
                <div className="meetingPassText">
                  {"결제된 회의 이용권 (" + meetingPassCnt + "개)"}
                </div>
              </div>
            </div>
            <div className="paymentChart">
              <PaymentChart
                paymentCntList={paymentCntList}
                meetingPassCntList={meetingPassCntList}
              />
            </div>
          </div>
          <div className="meetingArea">
            <div className="meetingArea_title">
              <div className="resMeetingDiv">
                <div className="resMeetingBox" />
                <div className="resMeetingText">
                  {"예약된 회의 (" + resMeetingCnt + "건)"}
                </div>
              </div>
              <div className="endMeetingDiv">
                <div className="endMeetingBox" />
                <div className="endMeetingText">
                  {"완료된 회의 (" + endMeetingCnt + "건)"}
                </div>
              </div>
            </div>
            <div className="meetingChart">
              <MeetingChart
                resMeetingCntList={resMeetingCntList}
                endMeetingCntList={endMeetingCntList}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Main;
