import React from 'react'

function SuggestListContent(props) {
    const data = props.list.data.read();

    return (
        <>
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr key={idx}>
                            <td>{item.SUPPORT_TITLE}</td>
                            <td>{item.CREATE_DTTM}</td>
                            <td>{item.CREATE_IP}</td>
                            <td>
                                <button
                                    className='info-content-table-btn cursor'
                                    onClick={() => {
                                        props.setPopupContent(item);
                                        props.setPopup(true);
                                    }}
                                >
                                    보기
                                </button>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan="6">
                            문의 내역이 없습니다.
                        </td>
                    </tr>
            }
        </>
    )
}

export default SuggestListContent
