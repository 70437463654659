import React from "react";
import "css/user.css";
import axios from "axios";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAlert,
  openAlert,
  openConfirm,
  updatePagingInfo,
} from "actions/index";
import Paging from "component/common/paging";

import Insertuserpopup from "component/support/insertuserpopup";
import Updateuserpopup from "component/support/updateuserpopup";
import SearchTextInput from "component/common/searchTextInput";
import SearchTextInputSupport from "component/support/searchTextinputsupport";
import Insertexcelpopup from "component/support/insertexcelpopup";

/**
 * Version : 1.0
 * 작성일자 : 2022-02-16
 * 작성자 : 권도훈
 * 설명 : User
 * 수정일자 : 2022-02-21
 * 수정자 : 정지윤
 * 수정내역 : user페이지 검색기능 추가
 * 작성일자 : 2022-09-02
 * 수정자 : 강연승
 * 수정내역 : sobridge 홈페이지 링크 수정
 */
function User() {
  const [userlist, setuserlist] = useState([]);
  const [inputkeyword, setInputkeyword] = useState("");
  const [inputstartdate, setInputstartdate] = useState("");
  const [inputenddate, setInputenddate] = useState("");

  const [userlistdownload, setuserlistdownload] = useState([]);

  const [countUser, setcountUser] = useState();
  const [check, setcheck] = useState([]);

  const [Type, setType] = useState("");
  const [Way, setWay] = useState("");

  const onclickarray = type => {
    if (type === Type) {
      if (Way === "DESC") {
        setWay("ASC");
      } else if (Way === "ASC") {
        setWay("DESC");
      } else if (Way === "") {
        setWay("DESC");
      }
    } else if (type !== Type) {
      setType(type);
      setWay("DESC");
    }
  };
  // const setPagingInfo = () => {
  //   const paging_obj = {
  //     ROW_COUNT: 10,
  //     PAGE_COUNT: 0,
  //     TOTAL: 0,
  //   };
  //   console.log("작동 paginginfo")

  //   dispatch(updatePagingInfo(paging_obj));
  //   console.log(PAGING_STATE.PAGE_COUNT)
  // };

  /**
   * REDUX
   */
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();

  const [searchOption, setSearchOption] = useState({
    text: "",
  });

  const postall = userlist.length;

  const handleInputstartdate = e => {
    setInputstartdate(e.target.value);
  };
  const handleInputenddate = e => {
    setInputenddate(e.target.value);
  };

  const getuserlist = useCallback(
    (keyword, inputstartdate, inputenddate) => {
      if (
        inputstartdate !== "" &&
        inputstartdate !== null &&
        inputstartdate !== undefined &&
        inputenddate !== "" &&
        inputenddate !== null &&
        inputenddate !== undefined
      ) {
        if (inputstartdate > inputenddate) {
          return;
        } else {
          let type = "";
          let way = "";

          if (Way !== null || Way !== undefined) {
            way = Way;
          }
          if (Type !== null || Type !== undefined) {
            type = Type;
          }
          let config = {
            params: {
              skip: PAGING_STATE.PAGE_COUNT,
              amount: PAGING_STATE.ROW_COUNT,
              content: keyword,
              starttime: inputstartdate + "T00:00:00",
              endtime: inputenddate + "T23:59:59",
              WAY_TYPE: type,
              WAY: way,
            },
          };
          axios.get("/user/UserList", config).then(res => {
            if (res.status === 200) {
              setuserlist(res.data);
            }
          });
          return;
        }
      } else if (
        (inputstartdate === "" ||
          inputstartdate === null ||
          inputstartdate === undefined) &&
        (inputenddate === "" ||
          inputenddate === null ||
          inputenddate === undefined)
      ) {
        let config = {
          params: {
            skip: PAGING_STATE.PAGE_COUNT,
            amount: PAGING_STATE.ROW_COUNT,
            content: keyword,
          },
        };
        if (
          Way !== null &&
          Way !== "" &&
          Way !== undefined &&
          Type !== null &&
          Type !== "" &&
          Type !== undefined
        ) {
          config.params = {
            ...config.params,
            WAY_TYPE: Type,
            WAY: Way,
          };
        }
        axios.get("/user/UserList", config).then(res => {
          if (res.status === 200) {
            setuserlist(res.data);
          }
        });
        return;
      } else {
        const obj = {
          TEXT: "시작 일과 종료 일을 모두 지정해주세요",
          submitEventHandler: () => {
            dispatch(closeAlert());
          },
        };
        dispatch(openAlert(obj));
        return;
      }
    },
    [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT, Type, Way]
  );

  useEffect(() => {
    getuserlist(searchOption.text, inputstartdate, inputenddate);
  }, [getuserlist, Type, Way]);

  const userlistDownload = useCallback(
    (keyword, inputstartdate, inputenddate) => {
      if (
        inputstartdate !== "" &&
        inputstartdate !== null &&
        inputstartdate !== undefined &&
        inputenddate !== "" &&
        inputenddate !== null &&
        inputenddate !== undefined
      ) {
        if (inputstartdate > inputenddate) {
          return;
        } else {
          let type = "";
          let way = "";

          if (Way !== null || Way !== undefined) {
            way = Way;
          }
          if (Type !== null || Type !== undefined) {
            type = Type;
          }
          let config = {
            params: {
              skip: 0,
              amount: countUser,
              content: keyword,
              starttime: inputstartdate + "T00:00:00",
              endtime: inputenddate + "T23:59:59",
              WAY_TYPE: type,
              WAY: way,
            },
          };
          axios.get("/user/UserList", config).then(res => {
            if (res.status === 200) {
              setuserlistdownload(res.data);
            }
          });
          return;
        }
      } else if (
        (inputstartdate === "" ||
          inputstartdate === null ||
          inputstartdate === undefined) &&
        (inputenddate === "" ||
          inputenddate === null ||
          inputenddate === undefined)
      ) {
        let config = {
          params: {
            skip: 0,
            amount: countUser,
            content: keyword,
          },
        };
        if (
          Way !== null &&
          Way !== "" &&
          Way !== undefined &&
          Type !== null &&
          Type !== "" &&
          Type !== undefined
        ) {
          config.params = {
            ...config.params,
            WAY_TYPE: Type,
            WAY: Way,
          };
        }
        axios.get("/user/UserList", config).then(res => {
          if (res.status === 200) {
            setuserlistdownload(res.data);
          }
        });
        return;
      } else {
        return;
      }
    },
    [countUser, Type, Way]
  );

  useEffect(() => {
    userlistDownload(searchOption.text, inputstartdate, inputenddate);
  }, [countUser, Type, Way]);

  const UserCount = useCallback(
    (keyword, inputstartdate, inputenddate) => {
      if (
        inputstartdate !== "" &&
        inputstartdate !== null &&
        inputstartdate !== undefined &&
        inputenddate !== "" &&
        inputenddate !== null &&
        inputenddate !== undefined
      ) {
        if (inputstartdate > inputenddate) {
          return;
        } else {
          let config = {
            params: {
              content: keyword,
              starttime: inputstartdate + "T00:00:00",
              endtime: inputenddate + "T23:59:59",
            },
          };
          axios.get("/user/CountUser", config).then(res => {
            if (res.status === 200) {
              setcountUser(res.data.TOTAL);
              const paging_obj = {
                ROW_COUNT: PAGING_STATE.ROW_COUNT,
                PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
                TOTAL: res.data.TOTAL,
              };
              dispatch(updatePagingInfo(paging_obj));
            }
          });
          return;
        }
      } else if (
        (inputstartdate === "" ||
          inputstartdate === null ||
          inputstartdate === undefined) &&
        (inputenddate === "" ||
          inputenddate === null ||
          inputenddate === undefined)
      ) {
        let config = {
          params: {
            content: keyword,
          },
        };

        axios.get("/user/CountUser", config).then(res => {
          if (res.status === 200) {
            setcountUser(res.data.TOTAL);

            const paging_obj = {
              ROW_COUNT: PAGING_STATE.ROW_COUNT,
              PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
              TOTAL: res.data.TOTAL,
            };
            dispatch(updatePagingInfo(paging_obj));
          }
        });
      } else {
        return;
      }
    },
    [dispatch]
  );

  useEffect(() => {
    UserCount(searchOption.text, inputstartdate, inputenddate);
  }, [UserCount]);

  const onClickSearch = (keyword, inputstartdate, inputenddate) => {
    if (inputstartdate > inputenddate) {
      const obj = {
        TEXT: (
          <>
            <div className="info-msg err">오류!</div>
            <div className="info-msg">종료 일자는 시작 일자보다</div>
            <div className="info-msg">이후의 날짜로 설정해주세요!</div>
          </>
        ),
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    } else {
      getuserlist(keyword, inputstartdate, inputenddate);
      userlistDownload(keyword, inputstartdate, inputenddate);
      UserCount(keyword, inputstartdate, inputenddate);
      // setInputenddate ('');
      // setInputstartdate('');
    }
  };

  const onClickResetPassword = (User_Num, User_ID) => {
    let config = {
      params: {
        User_Num: User_Num,
        User_ID: User_ID,
      },
    };

    const obj = {
      TEXT: "비밀번호를 초기화 하시겠습니까 ?",
      submitEventHandler: () => {
        axios.post("/user/ResetPassword", null, config).then(res => {
          if (res.status === 200) {
            const obj = {
              TEXT: "비밀번호가 초기화 되었습니다.",
              submitEventHandler: () => {
                dispatch(closeAlert());
              },
            };
            dispatch(openAlert(obj));

            getuserlist();
          }
        });
        dispatch(closeAlert());
      },
    };
    dispatch(openConfirm(obj));
  };

  //체크박스

  const handleInputcheck = (checked, id) => {
    if (checked) {
      setcheck([...check, id]);
    } else {
      setcheck(check.filter(el => el !== id));
    }
  };

  const handlecheckall = checked => {
    if (checked) {
      const idArray = [];
      userlist.forEach(el => idArray.push(el.User_Num));
      setcheck(idArray);
    } else {
      setcheck([]);
    }
  };

  const deletecheck = () => {
    if (check.length < 1) {
      const obj = {
        TEXT: "삭제 대상을 선택해 주세요.",
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
    } else {
      let str = "";
      for (let i = 0; i < check.length; i++) {
        str = str + check[i] + ",";
      }
      const config = {
        params: {
          check: str,
        },
      };

      const obj = {
        TEXT: "삭제하시겠습니까 ?",
        submitEventHandler: () => {
          axios.post("/user/DeleteUser", null, config).then(res => {
            if (res.status === 200) {
              const obj = {
                TEXT: "회원이 삭제되었습니다.",
                submitEventHandler: () => {
                  dispatch(closeAlert());
                },
              };
              dispatch(openAlert(obj));

              getuserlist();
            }
          });
          dispatch(closeAlert());
        },
      };
      dispatch(openConfirm(obj));
    }
  };

  const downloadExcel = () => {
    let arr = [];

    for (let i = 0; i < userlistdownload.length; i++) {
      arr.push({
        User_Num: userlistdownload[i].User_Num,
        User_ID: userlistdownload[i].User_ID,
        User_Nm: userlistdownload[i].User_Nm,
        User_Type: userlistdownload[i].User_Type,
        USE_YN: userlistdownload[i].USE_YN,
        FIRST_LOGIN_YN: userlistdownload[i].FIRST_LOGIN_YN,
        UNLOCODE: userlistdownload[i].UNLOCODE,
        LANGUAGE: userlistdownload[i].LANGUAGE,
        Create_Dttm: userlistdownload[i].Create_Dttm.replace("T", " "),
        Create_IP: userlistdownload[i].Create_IP,
      });
    }

    const data = arr;
    const config = {
      responseType: "blob",
    };
    axios.post("/user/excel/user", data, config).then(res => {
      const url = window.URL.createObjectURL(
        new Blob([res.data], { type: res.headers["content-type"] })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PEBBLE_사용자내역.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  const onClickLogin = data => {
    window.open("https://www.sobridge.kr/loginadmin?data=" + data);
  };

  const [isOpenDetail, setIsOpenDetail] = useState(false);
  const [isOpenDetails, setIsOpenDetails] = useState(false);
  const [isOpenDetailexcel, setIsOpenDetailexcel] = useState(false);

  const [num, setnum] = useState();
  const [nick, setnick] = useState();
  const [comment, setcomment] = useState();
  const [authYN, setAuthYN] = useState();

  const openDetailPopup = () => {
    setIsOpenDetail(true);
  };

  const closeDetailPopup = () => {
    setIsOpenDetail(false);
    setInputenddate("");
    setInputstartdate("");
    setInputkeyword("");
    getuserlist();
    UserCount();
  };

  const openDetailPopups = (num, nick, comment, auth) => {
    setIsOpenDetails(true);
    setnum(num);
    setnick(nick);
    setAuthYN(auth);
    if (comment === null || comment === undefined || comment === "") {
      setcomment("");
    } else {
      setcomment(comment);
    }
  };

  const closeDetailPopups = () => {
    setIsOpenDetails(false);
    setInputenddate("");
    setInputstartdate("");
    setInputkeyword("");
    getuserlist();
    UserCount();
  };

  const openinsertexcel = () => {
    setIsOpenDetailexcel(true);
  };
  const closeDetailPopupexcel = () => {
    setIsOpenDetailexcel(false);
  };

  return (
    <div className="Userbody">
      {isOpenDetail ? (
        <Insertuserpopup open={isOpenDetail} close={closeDetailPopup} />
      ) : null}

      {isOpenDetails ? (
        <Updateuserpopup
          open={isOpenDetails}
          close={closeDetailPopups}
          num={num}
          nickname={nick}
          comment={comment}
          authYN={authYN}
        />
      ) : null}

      <Insertexcelpopup
        open={isOpenDetailexcel}
        close={closeDetailPopupexcel}
      />

      <section className="ct_section">
        <div className="title_big">회원관리</div>
        <div className="title_small">회원관리　&gt;&nbsp;회원관리</div>
      </section>

      <section className="ct_searchbar">
        <div
          style={{ marginLeft: "60px" }}
          onKeyDown={e => {
            if (e.key === "Enter") {
              onClickSearch(searchOption.text, inputstartdate, inputenddate);
            }
          }}
        >
          <SearchTextInputSupport
            label="아이디를 입력하세요"
            type="long"
            value={searchOption.text}
            setValue={value =>
              setSearchOption({ ...searchOption, text: value })
            }
            // setPage={setPage}
            // page={page}
            // setIsSearch={setIsSearch}
          />
        </div>

        <div>
          <input
            type="date"
            className="user_search_date"
            value={inputstartdate}
            onChange={handleInputstartdate}
          ></input>
          &nbsp; ~ &nbsp;
          <input
            type="date"
            className="user_search_date"
            value={inputenddate}
            onChange={handleInputenddate}
          ></input>
        </div>
        <div
          className="searchbutton cursor"
          onClick={() =>
            onClickSearch(searchOption.text, inputstartdate, inputenddate)
          }
        >
          <span className="searchbuttontext">검색</span>
        </div>
      </section>

      <section className="ct_joinanddelete">
        <div style={{ display: "flex" }}>
          <div className="joinbutton cursor">
            <img
              type="image"
              alt="reading"
              className="joinbuttonimage"
              src="/img/icon\common/pencil.png"
            ></img>
            <span className="joinbuttontext" onClick={openDetailPopup}>
              회원 등록
            </span>
          </div>

          <div className="deletebutton cursor" onClick={deletecheck}>
            <img
              type="image"
              alt="삭제버튼"
              className="deletebuttonimage"
              src="/img/icon\common/delete.png"
            ></img>
            <span className="deletebuttontext">회원 탈퇴</span>
          </div>

          <div className="insertexcel cursor" onClick={() => openinsertexcel()}>
            <span className="deletebuttontext">엑셀 등록</span>{" "}
          </div>
        </div>
        <div className="downloadbutton cursor" onClick={() => downloadExcel()}>
          <img
            type="image"
            alt="다운로드버튼"
            className="downloadbuttonimage"
            src="/img/icon\common/exceldown.png"
          ></img>
          <span className="downloadbuttontext">엑셀 다운로드</span>
        </div>
      </section>

      <section className="ct_table">
        <table>
          <thead>
            <tr className="tablehead">
              <th className="user_checkbox">
                <input
                  type="checkbox"
                  className="checkall"
                  onChange={e => {
                    handlecheckall(e.currentTarget.checked);
                  }}
                  checked={check.length === postall ? true : false}
                ></input>
              </th>
              <th className="user_no">NO</th>
              <th className="user_usernum">사용자 번호</th>
              <th
                className="user_userid cursor"
                onClick={() => {
                  onclickarray("USER_ID");
                }}
              >
                사용자 아이디
              </th>
              <th className="user_usernickname">사용자 명</th>
              <th
                className="user_jointype cursor"
                onClick={() => {
                  onclickarray("USER_Type");
                }}
              >
                가입 구분
              </th>
              <th
                className="user_useyn cursor"
                onClick={() => {
                  onclickarray("USE_YN");
                }}
              >
                휴면 여부
              </th>
              <th
                className="user_loginyn cursor"
                onClick={() => {
                  onclickarray("LOGIN");
                }}
              >
                첫 로그인 여부
              </th>
              <th
                className="user_timezone cursor"
                onClick={() => {
                  onclickarray("TIMEZONE");
                }}
              >
                타임 존
              </th>
              <th
                className="user_language cursor"
                onClick={() => {
                  onclickarray("LANGUAGE");
                }}
              >
                언어 설정
              </th>
              <th
                className="user_date cursor"
                onClick={() => {
                  onclickarray("DATE");
                }}
              >
                생성일시
              </th>
              <th className="user_ip">생성자 IP</th>
              <th className="user_reset">비밀번호 초기화</th>
              <th className="user_login">로그인</th>
            </tr>
          </thead>

          <tbody>
            {userlist.map((data, index) => (
              <tr key={index} className="tablebody">
                <td className="checkbox">
                  <input
                    type="checkbox"
                    className="check"
                    id={data.User_Num}
                    onChange={e => {
                      handleInputcheck(e.currentTarget.checked, data.User_Num);
                    }}
                    checked={check.includes(data.User_Num) ? true : false}
                  ></input>
                </td>
                <td className="user_no">
                  {PAGING_STATE.PAGE_COUNT + index + 1}
                </td>
                <td className="user_usernum">{data.User_Num}</td>
                <td className="user_userid">{data.User_ID}</td>
                <td
                  className="user_usernickname cursor"
                  onClick={() =>
                    openDetailPopups(
                      data.User_Num,
                      data.User_Nm,
                      data.User_Comment,
                      data.AUTH_YN
                    )
                  }
                >
                  {data.User_Nm}
                </td>
                <td className="user_jointype">{data.User_Type}</td>
                <td className="user_useyn">{data.USE_YN}</td>
                <td className="user_loginyn">{data.FIRST_LOGIN_YN}</td>
                <td className="user_timezone">{data.UNLOCODE}</td>
                <td className="user_language">{data.LANGUAGE}</td>
                <td className="user_date">
                  {data.Create_Dttm.replace("T", " ")}
                </td>
                <td className="user_ip">{data.Create_IP}</td>
                <td className="user_reset">
                  {data.User_Type === "PEBBLE" ? (
                    <button
                      type="button"
                      id={data.User_Num}
                      className="resetbutton cursor"
                      onClick={() =>
                        onClickResetPassword(data.User_Num, data.User_ID)
                      }
                    >
                      초기화
                    </button>
                  ) : (
                    <div></div>
                  )}
                </td>
                <td className="user_login">
                  <button
                    type="button"
                    id={data.User_no}
                    onClick={() => onClickLogin(data.User_IDEN)}
                  >
                    로그인
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
      <section className="ct_undernum">
        <div className="paylog_paging">
          <Paging />
        </div>
      </section>
    </div>
  );
}

export default User;
