import React from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";

/**
 * Version : 1.0
 * 작성일자 : 2022-05-31
 * 작성자 : 강연승
 * 설명 : 매출 통계 차트
 * 수정일자 : 2022-05-31
 * 작성자 : 강연승
 * 설명 : 매출 통계 차트
 */
function SalesMonthlyChart(props) {
  /**
   * Month format
   */
  const monthFormat = date => {
    let tmp = date.split("-");
    let year = tmp[0];
    let month = tmp[1];
    return year + "년 " + month + "월";
  };

  return (
    <Bar
      data={{
        labels: props.salesMonthlyChart.map(data => monthFormat(data.MONTH)),
        datasets: [
          {
            label: "매출액",
            data: props.salesMonthlyChart.map(data => data.AMOUNT_MONTH),
            backgroundColor: "#ffb423",
            stack: "Stack 0",
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: context => {
                let label = context.dataset.label + "" || "";

                return context.parsed.y !== null
                  ? label + ": " + context.parsed.y.toLocaleString() + "원"
                  : null;
              },
            },
          },
        },
        maintainAspectRatio: false,
        barThickness: "38",
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            // ticks: {
            //   stepSize: 5,
            // },
          },
        },
      }}
      height="300"
    />
  );
}

export default React.memo(SalesMonthlyChart);
