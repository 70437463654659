import React from 'react'
/**
 * Version : 1.0
 * 파일명 : PaymentList.js
 * 작성일자 : 2022-05-25
 * 작성자 : 권도훈
 */
function PaymentList(props) {
    const data = props.payment.data.read();

    return (
        <>
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr
                            className='payment-content-tr'
                            key={idx}
                        >
                            <td
                                className='payment-content-td text-center'
                                onClick={e => e.stopPropagation()}
                            >
                                <input
                                    type='checkbox'
                                    className='cursor'
                                    checked={props.chkList.includes(item.METHOD_KEY)}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.setChkList([...props.chkList, item.METHOD_KEY]);
                                        } else {
                                            props.setChkList(props.chkList.filter(key => key !== item.METHOD_KEY));
                                        }
                                    }}
                                />
                            </td>
                            <td className='payment-content-td' title={item.USER_NUM}>
                                <span>
                                    {item.USER_NUM}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.USER_ID}>
                                <span>
                                    {item.USER_ID}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.USER_NM}>
                                <span>
                                    {item.USER_NM}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.PAYMENT_TYPE}>
                                <span>
                                    {item.PAYMENT_TYPE}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.PAYMENT_NM}>
                                <span>
                                    {item.PAYMENT_NM}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.PAYMENT_ACCOUNT_NUM}>
                                <span>
                                    {item.PAYMENT_ACCOUNT_NUM}
                                </span>
                            </td>
                            <td className='payment-content-td text-center' title={item.PAYMENT_COMPANY}>
                                {item.PAYMENT_COMPANY}
                            </td>
                            <td className='payment-content-td text-center' title={item.CARD_TYPE}>
                                {item.CARD_TYPE}
                            </td>
                            <td className='payment-content-td text-center' title={item.OWNER_TYPE}>
                                {item.OWNER_TYPE}
                            </td>
                            <td className='payment-content-td text-center'>
                                {item.REGISTER_DT}
                            </td>
                            <td className='payment-content-td text-center'>
                                {item.USE_YN === "Y" ? "사용" : "미사용"}
                            </td>
                            <td className='payment-content-td' title={item.CREATE_DTTM}>
                                <span>
                                    {item.CREATE_DTTM}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.CREATE_IP}>
                                <span>
                                    {item.CREATE_IP}
                                </span>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td
                            colSpan='14'
                            className='payment-content-empty text-center'
                        >
                            <span>등록된 결제수단이 없습니다.</span>
                        </td>
                    </tr>
            }
        </>
    )
}

export default PaymentList
