import Loading from 'component/common/loading';
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { fetchAxiosEvent } from './axiosEvent';
import Paging from './content/paging';
import EmailLogContent from './content/emailLogContent';
import SearchTextInput from 'component/common/searchTextInput';
/**
 * Version : 1.0
 * 파일명 : EmailLog.js
 * 작성일자 : 2022-05-31
 * 작성자 : 권도훈
 */
function EmailLog(props) {
    const [init, setInit] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [page, setPage] = useState({
        ROW_COUNT: 10,
        PAGE_COUNT: 0
    });
    const [searchOption, setSearchOption] = useState({
        USER_TEXT: ""
    });
    const [list, setList] = useState();
    const [total, setTotal] = useState(0);
    //토탈 조회
    const getTotal = useCallback(() => {
        setTotal(
            fetchAxiosEvent(
                "GET",
                "/schedule/email/log/total",
                {
                    config: {
                        params: {
                            USER_NUM: props.userNum,
                            USER_TEXT: searchOption.USER_TEXT
                        }
                    }
                }
            )
        );
    }, [props.userNum, searchOption.USER_TEXT]);
    //목록 조회
    const getLog = useCallback(() => {
        setList(
            fetchAxiosEvent(
                "GET",
                "/schedule/email/log",
                {
                    config: {
                        params: {
                            USER_NUM: props.userNum,
                            ROW_COUNT: page.ROW_COUNT,
                            PAGE_COUNT: page.PAGE_COUNT,
                            USER_TEXT: searchOption.USER_TEXT
                        }
                    }
                }
            )
        );
    }, [page.PAGE_COUNT, page.ROW_COUNT, props.userNum, searchOption.USER_TEXT]);

    useEffect(() => {
        if (init) {
            setTotal(
                fetchAxiosEvent(
                    "GET",
                    "/schedule/email/log/total",
                    {
                        config: {
                            params: {
                                USER_NUM: props.userNum,
                                USER_TEXT: searchOption.USER_TEXT
                            }
                        }
                    }
                )
            );
            setList(
                fetchAxiosEvent(
                    "GET",
                    "/schedule/email/log",
                    {
                        config: {
                            params: {
                                USER_NUM: props.userNum,
                                ROW_COUNT: page.ROW_COUNT,
                                PAGE_COUNT: page.PAGE_COUNT,
                                USER_TEXT: searchOption.USER_TEXT
                            }
                        }
                    }
                )
            );
            setInit(false);
        } else if (isSearch) {
            getTotal();
            getLog();
            setIsSearch(false);
        }
    }, [getLog, getTotal, init, isSearch, page.PAGE_COUNT, page.ROW_COUNT, props.userNum, searchOption.USER_TEXT]);

    return (
        <div className='info-content-inner table'>
            <div>
                <div className='info-content-inner-search'>
                    <div className='info-content-inner-search-option'>
                        {/* <font>
                            예약시간(종료)
                        </font>

                        <div className='info-content-inner-search-datepicker'>
                            <DatePicker
                                setDate={setDate}
                                date={date}
                                type="END_DATE"
                                setDataSearch={setIsSearch}
                            />
                        </div> */}

                        <SearchTextInput
                            label="메일 제목"
                            type="short"
                            value={searchOption.USER_TEXT}
                            setValue={value => setSearchOption({ ...searchOption, USER_TEXT: value })}
                            setPage={setPage}
                            page={page}
                            setIsSearch={setIsSearch}
                        />
                    </div>

                    <button
                        className='info-search-btn cursor'
                        onClick={() => {
                            setPage({
                                ...page,
                                PAGE_COUNT: 0
                            });
                            setIsSearch(true);
                        }}
                    >
                        검 색
                    </button>
                </div>

                <table className='info-content-table'>
                    <colgroup>
                        <col width="5%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="15%" />
                        <col width="20%" />
                    </colgroup>

                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>메일 제목</th>
                            <th>발송자 명</th>
                            <th>발송자 이메일</th>
                            <th>발송 일시</th>
                            <th>생성일시</th>
                            <th>생성자 IP</th>
                        </tr>
                    </thead>

                    <tbody>
                        <Suspense
                            fallback={
                                <tr>
                                    <td colSpan="7">
                                        <div className="info-content-body-loading">
                                            <Loading />
                                        </div>
                                    </td>
                                </tr>
                            }
                        >
                            {
                                !init
                                    ?
                                    <EmailLogContent list={list} />
                                    :
                                    <></>
                            }
                        </Suspense>
                    </tbody>
                </table>
            </div>

            <div className='info-content-paging'>
                <Suspense fallback={<></>}>
                    {
                        !init
                            ?
                            <Paging
                                setIsSearch={setIsSearch}
                                page={page}
                                setPage={setPage}
                                TOTAL={total}
                            />
                            :
                            <></>
                    }
                </Suspense>
            </div>
        </div>
    )
}

export default EmailLog
