import React, { useState } from 'react'
import PaylogPopup from 'component/info/popup/paylogPopup';
/**
 * Version : 1.0
 * 파일명 : PaylogList.js
 * 작성일자 : 2022-05-26
 * 작성자 : 권도훈
 */
function PaylogList(props) {
    //data
    const data = props.paylog.data.read();
    /**
     * useState
     */
    //state
    const [isShow, setIsShow] = useState(false);
    const [orderNum, setOrderNum] = useState("");
    const [userNum, setUserNum] = useState("");
    /**
     * function
     */
    //가격 포맷
    const amountFormat = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원";
    }
    //수량 포맷
    const countFormat = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "개";
    }

    return (
        <>
            <PaylogPopup
                isShow={isShow}
                setIsShow={setIsShow}
                orderNum={orderNum}
                setOrderNum={setOrderNum}
                userNum={userNum}
            />

            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr
                            className='payment-content-tr'
                            key={idx}
                        >
                            <td
                                className='payment-content-td text-center'
                                onClick={e => e.stopPropagation()}
                            >
                                <input
                                    type='checkbox'
                                    className='cursor'
                                    checked={props.chkList.includes(item.ORDER_NUM)}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.setChkList([...props.chkList, item.ORDER_NUM]);
                                        } else {
                                            props.setChkList(props.chkList.filter(key => key !== item.ORDER_NUM));
                                        }
                                    }}
                                />
                            </td>
                            <td className='payment-content-td' title={item.USER_NUM}>
                                <span>
                                    {item.USER_NUM}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.USER_ID}>
                                <span>
                                    {item.USER_ID}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.USER_NM}>
                                <span>
                                    {item.USER_NM}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.APPROVED_AT}>
                                <span>
                                    {item.APPROVED_AT}
                                </span>
                            </td>
                            <td className='payment-content-td text-center' title={amountFormat(item.TOTAL_AMOUNT)}>
                                {amountFormat(item.TOTAL_AMOUNT)}
                            </td>
                            <td className='payment-content-td text-center' title={countFormat(item.PRODUCT_COUNT)}>
                                {countFormat(item.PRODUCT_COUNT)}
                            </td>
                            <td className='payment-content-td text-center' title={item.STATUS}>
                                {item.STATUS}
                            </td>
                            <td className='payment-content-td text-center' title="상세보기">
                                {
                                    item.STATUS !== "FAIL"
                                        ?
                                        <font
                                            className="cursor"
                                            onClick={() => {
                                                setUserNum(item.USER_NUM);
                                                setOrderNum(item.ORDER_NUM);
                                                setIsShow(true);
                                            }}
                                        >
                                            상세보기
                                        </font>
                                        :
                                        <></>
                                }
                            </td>
                            <td className='payment-content-td text-center' title="전표확인">
                                {
                                    item.STATUS !== "FAIL"
                                        ?
                                        <font
                                            className="cursor"
                                            onClick={() => {
                                                const url = "https://pebbling.co.kr"
                                                window.open(url + "/recipt?ordernum=" + item.ORDER_NUM)
                                            }}
                                        >
                                            전표확인
                                        </font>
                                        :
                                        <></>
                                }
                            </td>
                            <td className='payment-content-td' title={item.CREATE_DT}>
                                <span>
                                    {item.CREATE_DT}
                                </span>
                            </td>
                            <td className='payment-content-td' title={item.CREATE_IP}>
                                <span>
                                    {item.CREATE_IP}
                                </span>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td
                            colSpan='12'
                            className='payment-content-empty text-center'
                        >
                            <span>등록된 결제수단이 없습니다.</span>
                        </td>
                    </tr>
            }
        </>
    )
}

export default PaylogList
