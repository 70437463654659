import React, { forwardRef, useEffect, useState } from "react";
import Picker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import { getMonth, getYear } from "date-fns";
import "css/schedule.css";
/**
 * Version : 1.0
 * 파일명 : datePicker.js
 * 작성일자 : 2022-02-10
 * 작성자 : 강연승
 * 설명 : datePicker
 * 수정일자 :  2022-02-10
 * 수정자 : 강연승
 * 수정내역 :
 */
function DatePicker(props) {
  const [inputText, setInputText] = useState(new Date());
  /**
   * select Box
   */
  const [yearView, setYearView] = useState(false);
  const [monthView, setMonthView] = useState(false);
  const [years, setYears] = useState([]);
  const [months, setMonths] = useState([]);
  const [minDate, setMinDate] = useState(new Date());
  /**
   * DATE VALUE
   */
  useEffect(() => {
    setYears(range(getYear(new Date()), getYear(new Date()) + 1, 1));
    setMonths([
      "1월",
      "2월",
      "3월",
      "4월",
      "5월",
      "6월",
      "7월",
      "8월",
      "9월",
      "10월",
      "11월",
      "12월",
    ]);
  }, []);
  useEffect(() => {
    if (props.type === "START") {
      if (props.date.START_TIME === "") {
        setInputText("");
      } else {
        setInputText(new Date(props.date.START_TIME));
      }
    } else if (props.type === "END") {
      if (props.date.START_TIME !== "") {
        setMinDate(new Date(props.date.START_TIME));
      }
      if (props.date.END_TIME === "") {
        setInputText("");
      } else {
        setInputText(new Date(props.date.END_TIME));
      }
    }
  }, [props]);
  /**
   * Custom input
   */
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        className="schedule_datePicker"
        type="text"
        placeholder={props.type === "START" ? "시작 일자" : "종료 일자"}
        value={value}
        onClick={onClick}
        readOnly
      />
      {/* <img
        className="cursor"
        style={{
          margin: "0 4px",
        }}
        src="/img/icon/log/calendar.png"
        alt="CALENDAR"
        onClick={onClick}
        ref={ref}
      /> */}
    </div>
  ));
  /**
   * year range
   */
  const range = (sYear, eYear, step) => {
    let array = [];
    for (let i = eYear; i > sYear; --i) {
      if (!(i % step)) {
        array.push(i);
      }
    }
    return array;
  };

  return (
    <Picker
      dateFormat="yyyy년 MM월 dd일"
      selected={inputText}
      // minDate={minDate}
      onChange={date => {
        let year = date.getFullYear();
        let mon =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        let dt = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        let dateStr = year + "-" + mon + "-" + dt;
        setInputText(new Date(dateStr));
        if (props.type === "START") {
          props.setDate({
            ...props.date,
            START: dateStr,
          });
        } else {
          props.setDate({
            ...props.date,
            END: dateStr,
          });
        }
      }}
      className="pebble_datepicker"
      locale={ko}
      placeholderText="날짜 선택"
      customInput={<CustomInput />}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div
          style={{
            margin: "24px 24px 0 24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            className="cursor"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
            src="/img/icon/common/left.png"
            alt="<"
          />

          <div
            style={{
              width: "82px",
              height: "32px",
              border: "1px solid #2B4AC4",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginRight: "4px",
              marginLeft: "24px",
            }}
            className="cursor"
            onClick={() => {
              setMonthView(false);
              setYearView(!yearView);
            }}
          >
            <div
              style={{
                color: "#2B4AC4",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {getYear(date)}
            </div>
            <img
              src={
                yearView
                  ? "/img/icon/common/picker_up.png"
                  : "/img/icon/common/picker_down.png"
              }
              alt="YEAR"
            />
            <div
              className="react-datepicker-custom-selectbox scrollbar"
              style={{
                display: yearView ? "block" : "none",
                transform: "translate(70px, 58px)",
              }}
            >
              {years.map((option, idx) => (
                <div
                  className="cursor"
                  key={idx}
                  style={{
                    marginTop: idx === 0 ? "8px" : "0",
                    marginBottom: idx === years.length - 1 ? "8px" : "0",
                    borderTopRightRadius: idx === 0 ? "8px" : "0px",
                    borderTopLeftRadius: idx === 0 ? "8px" : "0px",
                    borderBottomLeftRadius:
                      idx === years.length - 1 ? "8px" : "0px",
                    borderBottomRightRadius:
                      idx === years.length - 1 ? "8px" : "0px",
                  }}
                  onClick={() => changeYear(option)}
                >
                  <div
                    style={{
                      marginLeft: "16px",
                    }}
                  >
                    {option}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            style={{
              width: "82px",
              height: "32px",
              border: "1px solid #2B4AC4",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginRight: "24px",
            }}
            className="cursor"
            onClick={() => {
              setYearView(false);
              setMonthView(!monthView);
            }}
          >
            <div
              style={{
                color: "#2B4AC4",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {months[getMonth(date)]}
            </div>
            <img
              src={
                monthView
                  ? "/img/icon/common/picker_up.png"
                  : "/img/icon/common/picker_down.png"
              }
              alt="MONTH"
            />
            <div
              className="react-datepicker-custom-selectbox scrollbar"
              style={{
                display: monthView ? "block" : "none",
                transform: "translate(145px, 58px)",
              }}
            >
              {months.map((option, idx) => (
                <div
                  className="cursor"
                  key={idx}
                  style={{
                    marginTop: idx === 0 ? "8px" : "0",
                    marginBottom: idx === months.length - 1 ? "8px" : "0",
                    borderTopRightRadius: idx === 0 ? "8px" : "0px",
                    borderTopLeftRadius: idx === 0 ? "8px" : "0px",
                    borderBottomLeftRadius:
                      idx === months.length - 1 ? "8px" : "0px",
                    borderBottomRightRadius:
                      idx === months.length - 1 ? "8px" : "0px",
                  }}
                  onClick={() => changeMonth(idx)}
                >
                  <div
                    style={{
                      marginLeft: "16px",
                    }}
                  >
                    {option}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <img
            className="cursor"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
            src="/img/icon/common/right.png"
            alt=">"
          />
        </div>
      )}
    />
  );
}

export default DatePicker;
