import React, { useState, useEffect, useCallback } from "react";
import "css/paylog.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { updatePagingInfo } from "actions/index";
import Paging from "component/common/paging";
import Loading from "component/common/loading";
import SearchSelectBox from "component/common/searchSelectBox";

/**
 * Version : 1.0
 * 작성일자 : 2022-03-10
 * 작성자 : 강연승
 * 설명 : 회의 이용권 - 구매현황
 */
function PayLog() {
  /**
   * REDUX
   */
  const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
  /**
   * DISPATCH
   */
  const dispatch = useDispatch();
  /**
   * STATE
   */
  const [loading, setLoading] = useState(false);
  const [isSelectBox, setIsSelectBox] = useState(false);
  const [selectType, setSelectType] = useState("ALL");
  //selectbox
  const typeList = [
    { value: "ALL", label: "전체" },
    { value: "MY", label: "마이 페블" },
    { value: "GROUP", label: "그룹 페블" },
  ];
  const [searchText, setSearchText] = useState("");
  const [licenseList, setLicenseList] = useState([]);
  /**
   * 조회 타입 변경
   */
  const changeSelectType = type => {
    setSelectType(type);
    setIsSelectBox(false);
  };

  /**
   * Format license Title
   */
  const licenseFormat = license => {
    if (license !== undefined) {
      const licenseArr = license.split("-");
      return licenseArr[0].replace(/(.{6})/g, "$1-");
    } else {
      return;
    }
  };

  /**
   * Set LicenseList Total
   */
  const getLicenseTotal = useCallback(
    (text, type) => {
      let param = {};
      if (type !== "ALL") {
        param = {
          ...param,
          SEARCH_TYPE: type,
        };
      }
      if (text !== "") {
        param = {
          ...param,
          SEARCH_TEXT: text,
        };
      }
      axios
        .get("/license/admin/paylogCnt", {
          params: param,
        })
        .then(res => {
          if (res.status === 200) {
            const paging_obj = {
              ROW_COUNT: PAGING_STATE.ROW_COUNT,
              PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
              TOTAL: res.data.TOTAL,
            };
            dispatch(updatePagingInfo(paging_obj));
          }
        })
        .catch(() => {});
    },
    [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT, dispatch]
  );

  /**
   * 회의 리스트 조회
   */
  const getLicenseList = useCallback(
    (text, type) => {
      setLoading(true);
      let param = {
        ROW_COUNT: PAGING_STATE.ROW_COUNT,
        PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
      };
      if (type !== "ALL") {
        param = {
          ...param,
          SEARCH_TYPE: type,
        };
      }
      if (text !== "") {
        param = {
          ...param,
          SEARCH_TEXT: text,
        };
      }
      axios
        .get("/license/admin/paylog", {
          params: param,
        })
        .then(res => {
          if (res.status === 200) {
            setLicenseList(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [PAGING_STATE.PAGE_COUNT, PAGING_STATE.ROW_COUNT]
  );

  /**
   * useEffect
   */
  useEffect(() => {
    getLicenseTotal(searchText, selectType);
    getLicenseList(searchText, selectType);
  }, [getLicenseTotal, getLicenseList]);
  /**
   * 검색 버튼
   */
  const onSearch = () => {
    getLicenseTotal(searchText, selectType);
    getLicenseList(searchText, selectType);
  };
  /**
   * 엑셀 다운로드
   */
  const downloadExcel = (text, type) => {
    let param = {};
    if (type !== "ALL") {
      param = {
        ...param,
        SEARCH_TYPE: type,
      };
    }
    if (text !== "") {
      param = {
        ...param,
        SEARCH_TEXT: text,
      };
    }
    const config = {
      params: param,
      responseType: "blob",
    };
    axios.post("/license/admin/paylog/excel", null, config).then(res => {
      if (res.status === 200) {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: res.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Pebble_회의이용권_구매현황.xlsx");
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: loading ? "block" : "none",
            zIndex: "997",
            position: "absolute",
            width: "calc(100% - 285px)",
            height: "calc(100% - 91px)",
          }}
        >
          <Loading />
        </div>
      ) : null}
      <div className="paylog_section">
        <div className="paylog_title">
          <font className="mainTitle">구매현황</font>
          <font className="subTitle">{"회의이용권 > 구매현황"}</font>
        </div>
        <div className="paylog_searchArea">
          <div className="paylog_searchDiv">
            <img
              className="paylog_searchIcon"
              src="/img/icon/common/search.png"
              alt="search"
            />
            <input
              type="text"
              className="paylog_searchInput"
              placeholder="아이디 또는 사용자 명을 입력하세요"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
          </div>

          <SearchSelectBox
            label="할당 상태"
            type="short"
            list={typeList}
            value={selectType}
            setValue={value => setSelectType(value)}
          />

          <button className="paylog_searchBtn" onClick={onSearch}>
            검색
          </button>
        </div>
        <div className="paylog_excelBtnArea">
          <div className="paylog_excelDownBtn">
            <img
              className="paylog_excelIcon"
              src="/img/icon/common/exceldown.png"
              alt="excel"
            />
            <font
              className="paylog_excelDownText"
              onClick={() => downloadExcel(searchText, selectType)}
            >
              엑셀 다운로드
            </font>
          </div>
        </div>
        <div className="paylog_list">
          <table className="paylog_table">
            <colgroup>
              <col width="40px" />
              <col width="136px" />
              <col width="217px" />
              <col width="137px" />
              <col width="68px" />
              <col width="126px" />
              <col width="93px" />
              <col width="210px" />
              <col width="85px" />
              <col width="150px" />
              <col width="240px" />
            </colgroup>
            <thead>
              <tr>
                <th>NO</th>
                <th>사용자 번호</th>
                <th>사용자 아이디</th>
                <th>사용자 명</th>
                <th>잔여기간</th>
                <th>회의이용권 코드</th>
                <th>종료일자</th>
                <th>할당 그룹 명</th>
                <th>예약 회의 수</th>
                <th>생성일시</th>
                <th>생성자IP</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(licenseList) && licenseList.length > 0 ? (
                licenseList.map((data, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      {PAGING_STATE.PAGE_COUNT + index + 1}
                    </td>
                    <td>{data.USER_NUM}</td>
                    <td>{data.USER_ID}</td>
                    <td>{data.USER_NM}</td>
                    <td>
                      {data.ENDDATE_COUNT >= 0
                        ? "D-" + data.ENDDATE_COUNT
                        : "D+" + data.ENDDATE_COUNT.toString().replace("-", "")}
                    </td>
                    <td>{licenseFormat(data.LICENSE_CODE)}</td>
                    <td>{data.EXPIRED_DT}</td>
                    <td>{data.GROUP_NM === "" ? "-" : data.GROUP_NM}</td>
                    <td style={{ textAlign: "center" }}>
                      {data.SCHEDULE_COUNT}
                    </td>
                    <td>{data.CREATE_DTTM.replace("T", " ")}</td>
                    <td>{data.CREATE_IP}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="11" style={{ textAlign: "center" }}>
                    조회된 데이터가 없습니다.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="paylog_paging">
            <Paging />
          </div>
        </div>
      </div>
    </>
  );
}

export default PayLog;
