import React from "react";
import "chart.js/auto";
import { Bar } from "react-chartjs-2";

/**
 * Version : 1.0
 * 작성일자 : 2022-05-27
 * 작성자 : 강연승
 * 설명 : 결제 차트 대시보드
 * 수정일자 : 2022-05-27
 * 작성자 : 강연승
 * 설명 : 대시보드 페이지 추가
 */
function PaymentChart(props) {
  return (
    <Bar
      data={{
        labels: props.paymentCntList.map(data => data.day),
        datasets: [
          {
            label: "정기 구독한 인원",
            data: props.paymentCntList.map(data => data.cnt),
            backgroundColor: "#ffb423",
            stack: "Stack 0",
          },
          {
            label: "구매한 회의 이용권 수",
            data: props.meetingPassCntList.map(data => data.cnt),
            backgroundColor: "#7f84fb",
            stack: "Stack 1",
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: context => {
                let label = context.dataset.label + "" || "";
                if (context.dataset.stack === "Stack 0") {
                  return context.parsed.y !== null
                    ? label + ": " + context.parsed.y + "명"
                    : null;
                } else if (context.dataset.stack === "Stack 1") {
                  return context.parsed.y !== null
                    ? label + ": " + context.parsed.y + "개"
                    : null;
                }
              },
            },
          },
        },
        maintainAspectRatio: false,
        barThickness: "38",
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            stacked: true,
            // ticks: {
            //   stepSize: 5,
            // },
          },
        },
      }}
      height="300"
    />
  );
}

export default PaymentChart;
