import { borderColor, height } from '@mui/system';
import { closeAlert, openAlert, openConfirm, updatePagingInfo } from 'actions';
import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import "css/notice.css";
import Paging from 'component/common/paging';
import Noticewritepopup from 'component/support/noticewritepopup';
import SearchTextInputSupport from 'component/support/searchTextinputsupport';
import SearchSelectBox from 'component/common/searchSelectBox';

function Notice() {

  /**
 * Version : 1.0
 * 작성일자 : 2022-04-12
 * 작성자 : 정지윤
 * 설명 : 공지사항 등록/수정 화면
 */
    /**
   * REDUX
   */
     const PAGING_STATE = useSelector(state => state.pagingInfoReducer);
     /**
      * DISPATCH
      */
     const dispatch = useDispatch();


const [selectexpose, setselectexpose] = useState('');
const [selecthold, setselecthold] = useState('');
const [inputstartdate, setinputstartdate] = useState('');
const [inputenddate, setinputenddate] = useState('');


const handleInputexpose = (e) =>{
  setselectexpose(e.target.value);
}
const handleInputhold = (e) =>{
  setselecthold(e.target.value);
}
const handleInputstartdate = (e) =>{
  setinputstartdate(e.target.value);
}

const handleInputenddate = (e) =>{
  setinputenddate(e.target.value);
}

const [searchOption, setSearchOption] = useState({
  title: "" });
  const [selectType, setSelectType] = useState("");  
  const typeList = [
      { value: "", label: "노출여부" },
      { value: 'Y', label: "YES" },
      { value: "N", label: "NO" },
    ];
    const [selectType2, setSelectType2] = useState("");  
    const typeList2 = [
      { value: "", label: "고정여부" },
      { value: 'Y', label: "YES" },
      { value: "N", label: "NO" },
      ];






const [check, setcheck] = useState([]);
  const handleInputcheck = (checked, id) =>{
    if(checked){
        setcheck([...check, id])   
}   else{
        setcheck(check.filter(el => el !== id));
}
};
  const handlecheckall = (checked) =>{
    if(checked){
        const idArray = [];
        noticelist.forEach((el) => idArray.push(el.Notice_Num));    
        setcheck(idArray); 
    }  else{
        setcheck([]);
    }
  };

/*=========================================================  */ 
const [noticetotal, setnoticetotal] = useState('');
const countNotice = useCallback((inputkeyword,selectexpose,selecthold,inputstartdate,inputenddate)=>{

  let startdate = '';
  if(inputstartdate !== undefined &&inputstartdate !== ''){
    startdate = inputstartdate+'T00:00:00'
  }
  else{
    startdate= null;
  }
  let enddate = '';
  if(inputenddate !== undefined&&inputenddate !== ''){
    enddate = inputenddate+'T23:59:59'
  }
  else{
    enddate= null;
  }


  let config ={
    params : {
      content : inputkeyword,
      expose_YN : selectexpose,
      hold_YN : selecthold,
      starttime : startdate,
      endtime : enddate
    }
  }

  axios.get("/support/countNotice",config)
  .then((res)=>
  {
    if(res.status===200){
      setnoticetotal(res.data)
      const paging_obj = {
        ROW_COUNT: PAGING_STATE.ROW_COUNT,
        PAGE_COUNT: PAGING_STATE.PAGE_COUNT,
        TOTAL: res.data
      };
      dispatch(updatePagingInfo(paging_obj));
    }
  })

},[dispatch]);

useEffect(()=>{
  countNotice(searchOption.title,selectType,selectType2,inputstartdate,inputenddate);
},[countNotice])


  const[noticelist, setnoticeList] = useState([]);
  const[noticelistexcel, setnoticelistexcel] = useState([]);
    const getnoticeList = useCallback((inputkeyword,selectexpose,selecthold,inputstartdate,inputenddate) =>{

      let startdate = '';
      if(inputstartdate !== undefined &&inputstartdate !== ''){
        startdate = inputstartdate+'T00:00:00'
      }
      else{
        startdate= null;
      }
      let enddate = '';
      if(inputenddate !== undefined&&inputenddate !== ''){
        enddate = inputenddate+'T23:59:59'
      }
      else{
        enddate= null;
      }

      let config = {
        params : {
          content : inputkeyword,
          expose_YN : selectexpose,
          hold_YN : selecthold,
          starttime : startdate,
          endtime : enddate,
          skip : PAGING_STATE.PAGE_COUNT,
          amount : PAGING_STATE.ROW_COUNT,
        }

      }
      
      axios
      .get("/support/NoticeList", config).then((res) =>
      {
        if(res.status===200){
          setnoticeList(res.data)

        }
      })

     
    },[PAGING_STATE.PAGE_COUNT,PAGING_STATE.ROW_COUNT]
    );

    const getnoticeListexcel = useCallback((inputkeyword,selectexpose,selecthold,inputstartdate,inputenddate) =>{

      let startdate = '';
      if(inputstartdate !== undefined &&inputstartdate !== ''){
        startdate = inputstartdate+'T00:00:00'
      }
      else{
        startdate= null;
      }
      let enddate = '';
      if(inputenddate !== undefined&&inputenddate !== ''){
        enddate = inputenddate+'T23:59:59'
      }
      else{
        enddate= null;
      }

      let config = {
        params : {
          content : inputkeyword,
          expose_YN : selectexpose,
          hold_YN : selecthold,
          starttime : startdate,
          endtime : enddate,
          skip : 0,
          amount : noticetotal,
        }

      }
      
      axios
      .get("/support/NoticeList", config).then((res) =>
      {
        if(res.status===200){
          setnoticelistexcel(res.data)

        }
      })

     
    },[noticetotal]
    );


    useEffect(() =>{
      getnoticeListexcel(searchOption.title,selectType,selectType2,inputstartdate,inputenddate)
    },[getnoticeListexcel])
    useEffect(() =>{
      getnoticeList(searchOption.title,selectType,selectType2,inputstartdate,inputenddate)
    },[getnoticeList])


    const postall = noticelist.length;


    const onClickSearch= (inputkeyword,selectexpose,selecthold,inputstartdate,inputenddate) =>{
      if(inputstartdate > inputenddate){
        const obj = {
          TEXT: <>
          <div className='info-msg err'>
              오류!
          </div>
          <div className='info-msg'>
              종료 일자는 시작 일자보다
          </div>
          <div className='info-msg'>
              이후의 날짜로 설정해주세요!
          </div>
      </>,
          submitEventHandler: () => {dispatch(closeAlert()); }
        };
        dispatch(openAlert(obj));
  
  
      }else{
        getnoticeList(inputkeyword,selectexpose,selecthold,inputstartdate,inputenddate)
        countNotice(inputkeyword,selectexpose,selecthold,inputstartdate,inputenddate)
      }

    }

     const [isOpenDetail, setIsOpenDetail] = useState(false);
     const [opennumber, setopennumber] = useState('');
     const [opentype, setopentype] = useState('');
     const openDetailPopup = (notice_num, number) => {
       setIsOpenDetail(true);
       setopennumber(notice_num);
       setopentype(number)
      
     };
     const closeDetailPopup = () => {

       setIsOpenDetail(false);
       getnoticeList(searchOption.title,selectType,selectType2,inputstartdate,inputenddate);
       countNotice(searchOption.title,selectType,selectType2,inputstartdate,inputenddate)
     };

     const deleteNotice = () =>{
       if (check.length <1){
         alert("삭제 대상을 선택해주세요")
       }else{
         let NOTICE_NUM = "";
         for(let i = 0; i<check.length; i++){
          NOTICE_NUM = NOTICE_NUM+ check[i] + ",";
         }
         const config = {
           params : {
             NOTICE_NUM : NOTICE_NUM
           }
         }


         const obj = {
          TEXT: "삭제하시겠습니까 ?",
          submitEventHandler: () => {
            axios.post("/support/DeleteNotice", null, config).then((res)=>{
              if(res.status === 200){
               const obj = {
                 TEXT: "삭제되었습니다.",
                 submitEventHandler: () => {dispatch(closeAlert());}
               };
               dispatch(openAlert(obj));
                getnoticeList();
                countNotice()
              }
            })
          },
        };
        dispatch(openConfirm(obj));
    
       }
       
     }

     const downloadExcel = () =>{
      let arr=[];
    
      for(let i = 0; i<noticelistexcel.length; i++){
        arr.push({
          Notice_Title : noticelistexcel[i].Notice_Title,
          Notice_Clickcount : noticelistexcel[i].Notice_Clickcount,
          SUCCESS_YN : noticelistexcel[i].SUCCESS_YN,
          Notice_Expose_YN : noticelistexcel[i].Notice_Expose_YN,
          Notice_Hold_YN : noticelistexcel[i].Notice_Hold_YN,
          Create_Dttm : noticelistexcel[i].Create_Dttm.replace("T", " "),
          Create_IP : noticelistexcel[i].Create_IP,
        })
      }
    
      const data = arr;
      const config = {
        responseType: "blob"
      };
      axios.post("/support/excel/Notice", data, config)
      .then(res => {
    
        const url = window.URL.createObjectURL(
          new Blob([res.data], {type : res.headers["content-type"]})
        );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "PEBBLE_공지사항.xlsx");
          document.body.appendChild(link);
          link.click();
      })
    
    
    }


  return (
    
    
    <div>
      {isOpenDetail ? (
        <Noticewritepopup
          open={isOpenDetail}
          close={closeDetailPopup}
          notice_num={opennumber}
          opentype = {opentype}
        />
      ) : null}
      <div className='Notice_header'>
      <div className= 'NoticeMaintitile'>공지사항</div>
      <div className= 'Noticesubtitile'>{"고객센터 > 공지사항"}</div>

      </div>


      <div className='Notice_Search' style={{display : "flex"}}>

        <div className='Notice_Searchbar' onKeyDown={e=>{
              if(e.key ==="Enter"){
              onClickSearch(searchOption.title,selectType,selectType2,inputstartdate,inputenddate)
              }
            }}>
        <SearchTextInputSupport
                    label="아이디를 입력하세요"
                    type="long"
                    value={searchOption.title}
                    setValue={value => setSearchOption({ ...searchOption, title: value })}
                />
        </div>
  
        <div className='Notice_Search_Expose' onChange={handleInputexpose} value={selectexpose}>
        <SearchSelectBox
                    label="노출여부"
                    type="short"
                    list={typeList}
                    value={selectType}
                    setValue={value => setSelectType(value)}
                  />     
        </div>

        <div className='Notice_Search_Hold'>
        <SearchSelectBox
                    label="고정여부"
                    type="short"
                    list={typeList2}
                    value={selectType2}
                    setValue={value => setSelectType2(value)}
                  />     
        </div>

        <div className='Notice_Search_startdate'>
          <input type='date' className='Notice_startdate_input' onChange={handleInputstartdate} value={inputstartdate}></input>
        </div>

        <div className='Notice_Search_enddate'>
        <input type='date' className='Notice_enddate_input' onChange={handleInputenddate} value={inputenddate}></input>
        </div>

        <div className='Notice_Search_button cursor' >
          <div className='Notice_button_tosearch cursor' onClick={()=>onClickSearch(searchOption.title,selectType,selectType2,inputstartdate,inputenddate)}>검색</div>
        </div>



      </div>

      <div className='Notice_function'>
        <div className='Notice_function2'><div className='Notice_insert cursor' onClick={()=>openDetailPopup(0,0)}>등록</div> 
        <div className='Notice_delete cursor' onClick={() =>deleteNotice()}>삭제</div></div>
        <div className='downloadbutton cursor' onClick={() => downloadExcel()}>
             <img type='image' alt='다운로드버튼' className='downloadbuttonimage' src='/img/icon\common/exceldown.png'></img>
                <span className='downloadbuttontext'>엑셀 다운로드</span>
             </div> 
        </div>
     
      <section className='ct_table noticetable'>
        <table>
              <thead>
                <tr className='noticetablehead'>
                <th className='checkboxnotice'><input type='checkbox' className='checkall'
                 onChange={e => {
                  handlecheckall(e.currentTarget.checked);
               }}
               checked ={check.length === postall ? true : false}
                ></input></th>
                  <th className='noticeno' >NO</th>
                  <th className='noticetitle'>제목</th>
                  <th className='noticeclickcount'>조회수</th>
                  <th className='noticeexpose'>노출여부</th>
                  <th className='noticehold'>고정여부</th>
                  <th className='noticewritedate'>등록일시</th>
                  <th className='writedate2'>생성일시</th>
                  <th className='noticeIP'>생성자IP</th>
                </tr>
              </thead>

              <tbody>          
                  {noticelist.map((data,index) =>(
                    <tr key={index} className='noticetablebody'>
                    <td className='checkboxnotice'>
                      <input type='checkbox' className='check' id={data.Notice_Num} 
                   
                     onChange={e => {
                      handleInputcheck(e.currentTarget.checked, data.Notice_Num);
                    }}
                   
                    checked={check.includes(data.Notice_Num) ? true : false}
                    ></input></td>
                    <td className='noticeno'>
                    {PAGING_STATE.PAGE_COUNT + index + 1}
                    </td>
                    <td className='noticetitle cursor'
                      onClick={()=>openDetailPopup(data.Notice_Num,1)}>{data.Notice_Title}</td>
                    <td className='noticeclickcount'>{data.Notice_Clickcount}</td>
                    <td className='noticeexpose'>{data.Notice_Expose_YN}</td>
                    <td className='noticehold'>{data.Notice_Hold_YN}</td>
                    <td className='noticewritedate'>{data.Create_Dttm.replace("T", " ")}</td>
                    <td className='writedate2'>{data.Create_Dttm.replace("T", " ")}</td>
                    <td className='noticeIP'>{data.Create_IP}</td>
                  </tr>  
                  )
                  )}
                
              </tbody>
            </table>
            
      
      </section>
      <div style={{
              marginTop : "27px",
              marginBottom : "46px"
            }}>
            <Paging />
            </div>

   
     
    </div>

  )
}

export default Notice