import React from "react";
// import "chart.js/auto";
import { Bar } from "react-chartjs-2";

/**
 * Version : 1.0
 * 작성일자 : 2022-05-31
 * 작성자 : 강연승
 * 설명 : 회의 통계 차트
 * 수정일자 : 2022-05-31
 * 작성자 : 강연승
 * 설명 : 회의 통계 차트
 */
function MeetingMonthlyChart(props) {
  /**
   * Month format
   */
  const monthFormat = date => {
    let tmp = date.split("-");
    let year = tmp[0];
    let month = tmp[1];
    return year + "년 " + month + "월";
  };

  return (
    <Bar
      data={{
        labels: props.meetingMonthlyChart.map(data => monthFormat(data.MONTH)),
        datasets: [
          {
            label: "완료된 회의 수",
            data: props.meetingMonthlyChart.map(data => data.MEETING_CNT),
            backgroundColor: "#ffb423",
          },
        ],
      }}
      options={{
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: context => {
                let label = context.dataset.label + "" || "";

                return context.parsed.y !== null
                  ? label + ": " + context.parsed.y + "회"
                  : null;
              },
            },
          },
        },
        maintainAspectRatio: false,
        barThickness: "38",
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
        },
      }}
      height="300"
    />
  );
}

export default React.memo(MeetingMonthlyChart);
