import React, { useEffect, useState } from 'react'
import EditUserInfo from './popup/editUserInfo';
/**
 * Version : 1.0
 * 파일명 : InfoContentHeader.js
 * 작성일자 : 2022-04-27
 * 작성자 : 권도훈
 */
function InfoContentHeader(props) {
    const data = props.info !== undefined ? props.info.data.read() : undefined;

    const [editPopup, setEditPopup] = useState(false);

    useEffect(() => {
        if (data !== undefined && data.USER_NUM !== undefined) {
            props.setUserNum(data.USER_NUM);
        } else {
            props.setUserNum("");
        }
    }, [data, props]);

    return (
        <div className='info-content-header'>
            <EditUserInfo
                data={data}
                isOpen={editPopup}
                setIsOpen={setEditPopup}
                // setSearch={props.setSearch}
                userNum={props.userNum}
                setIsEditUserInfo={props.setIsEditUserInfo}
            />

            {
                data === undefined
                    ?
                    <div className='info-content-img'>
                        NO IMAGE
                    </div>
                    : data.FILE_NUM === ""
                        ?
                        <div className='info-content-img'>
                            NO IMAGE
                        </div>
                        :
                        <img
                            className='info-content-img'
                            src={data.FILE_NUM}
                            alt=''
                        />
            }

            <div className='info-content-data'>
                <table className='info-header-data-table'>
                    <tbody>
                        <tr className='info-header-data-tr'>
                            <td className='top-left-8'>사용자 명</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ? data.USER_NM
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td>가입 방식</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ? data.USER_TYPE
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td>타임존 | 언어</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ? data.UNLOCODE
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td className='bot-left-8'>미 결제 여부</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ?
                                            data.OUTSTANDING_YN === 'Y'
                                                ?
                                                "결제 완료"
                                                :
                                                "결제 실패"
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className='info-header-data-table'>
                    <tbody>
                        <tr className='info-header-data-tr'>
                            <td>사용자 아이디</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ? data.USER_ID
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td>가입 일시</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ? data.CREATE_DTTM
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td>첫 로그인 여부</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ?
                                            data.FIRST_LOGIN_YN === 'Y'
                                                ? "미접속"
                                                : "접속 완료"
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td>휴면 여부</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ?
                                            data.USE_YN === 'Y'
                                                ? "이용 중"
                                                : "휴면"
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className='info-header-data-table'>
                    <tbody>
                        <tr className='info-header-data-tr'>
                            <td>사용자 고유번호</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ? data.USER_NUM
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td>기본 화상플랫폼</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ? data.PLATFORM_CODE
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td>이용권 팝업 읽음 여부</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ?
                                            data.EXPIRED_POPUP === 'Y'
                                                ? "미확인"
                                                : "확인 완료"
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr className='info-header-data-tr'>
                            <td>탈퇴 여부</td>
                            <td>
                                <div>
                                    {
                                        data !== undefined
                                            ?
                                            data.DEL_YN === 'N'
                                                ? "이용 중"
                                                : "탈퇴"
                                            : ""
                                    }
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='info-header-btn-area'>
                <button
                    className='cursor'
                    onClick={() => setEditPopup(true)}
                    disabled={
                        data !== undefined
                            ? false
                            : true
                    }
                >
                    정보 수정
                </button>
            </div>
        </div>
    )
}

export default InfoContentHeader
