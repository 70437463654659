import React, { useEffect, useState } from 'react'
import ReactModal from 'react-modal'
/**
 * Version : 1.0
 * 파일명 : SuggestPopup.js
 * 작성일자 : 2022-05-19
 * 작성자 : 권도훈
 */
function SuggestPopup(props) {
    const [init, setInit] = useState(true);
    const [data, setData] = useState({});
    const [showMoreFile, setShowMoreFile] = useState(false);

    useEffect(() => {
        if (init && props.popup) {
            setData(props.data);
            setInit(false);
        }
    }, [init, props.data, props.popup]);

    const convertHTML = (value) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: value }} />
        )
    }

    //close event
    const closeEventHanlder = () => {
        props.setPopup(false);
        setInit(true);
    }

    ReactModal.setAppElement("body");

    return (
        <ReactModal
            isOpen={props.popup}
            className="info-popup-suggest"
            overlayClassName="popupOverlay"
            animationType="fade"
            transparent={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => closeEventHanlder()}
        >
            <div className='info-popup-suggest-inner'>
                <img
                    className='info-popup-status-close cursor'
                    src='/img/icon/common/close_black.png'
                    alt=''
                    onClick={() => closeEventHanlder()}
                />
                <p className='info-popup-suggest-title'>고객의 소리 상세보기</p>

                <div className='info-popup-faq-line'>
                    제목 : {data.SUPPORT_TITLE}
                </div>

                <div
                    className={
                        showMoreFile
                            ? 'info-popup-faq-line file open cursor'
                            : 'info-popup-faq-line file cursor'
                    }
                    onClick={() => setShowMoreFile(!showMoreFile)}
                >
                    <div className='info-popup-faq-file-title'>
                        첨부파일(
                        <font>
                            {
                                data.FILE_INFO !== undefined
                                    ?
                                    data.FILE_INFO.length + "개"
                                    :
                                    "0개"
                            }
                        </font>
                        )

                        <img
                            className={
                                showMoreFile
                                    ? "info-popup-faq-file-title-icon open"
                                    : "info-popup-faq-file-title-icon"
                            }
                            src='/img/icon/common/picker_down.png'
                            alt=''
                        />
                    </div>

                    <div
                        className={
                            showMoreFile
                                ? 'info-popup-faq-file-area open'
                                : 'info-popup-faq-file-area'
                        }
                    >
                        {
                            data.FILE_INFO !== undefined && data.FILE_INFO.length > 0
                                ?
                                data.FILE_INFO.map((file, idx) => (
                                    <div key={idx}>
                                        <a
                                            className='info-popup-faq-file-info'
                                            href={file.FILE_URL}
                                            download={file.ORI_FILE_NM}
                                            onClick={e => e.stopPropagation()}
                                        >
                                            {file.ORI_FILE_NM}
                                        </a>
                                    </div>
                                ))
                                :
                                <></>
                        }
                    </div>
                </div>

                <div className=' info-popup-suggest-content scrollbar'>
                    <p>내용</p>
                    {convertHTML(data.SUPPORT_CONTENT)}
                </div>
            </div>
        </ReactModal >
    )
}

export default SuggestPopup
