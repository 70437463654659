
import { Alert } from "@mui/material";
import React, { useCallback, useState } from "react";
/**
 * Version : 1.0
 * 파일명 : fileuploadSingle.js
 * 작성일자 : 2021-10-25
 * 작성자 : 권도훈
 * 설명 : 싱글 파일 컴포넌트
 * 수정일자 :  2022-02-18
 * 수정자 : 강연승
 * 수정내역 : 첨부파일 용량 증가
 */
function FileuploaderSingle(props) {
  /**
   * 알림창 설정
   */
  const [alertProps, setAlertProps] = useState({
    text: "",
    isShow: false,
    cancelBtnDisplay: false,
    okBtnDisplay: true,
    okBtnEventHandler: function () {},
    cancleBtnEventHandler: function () {
      closeAlertEvent();
    },
  });
  /**
   * 알림창 닫기
   */
  const closeAlertEvent = useCallback(() => {
    setAlertProps({
      ...alertProps,
      isShow: false,
    });
  }, [alertProps]);
  /**
   * 파일 정보 출력 여부
   */
  const handleChangeFile = event => {
    event.stopPropagation();

    for (var i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i].size > props.fileSize) {
        alert("10MB 이하의 파일을 첨부해주세요.");
        return;
      }
      if (props.type === "excel") {
        if (event.target.files[i].type !== props.fileType && event.target.files[i].type !== props.fileType2 ) {
          console.log("올린 파일 형식 :",event.target.files[i].type)
          console.log(props.fileType)
          alert("올릴 수 없는 파일 형식입니다.");
          return;
        }
      } else if (props.type === "image") {
        if (event.target.files[i].type.replace(/\/.*/, "") !== "image") {
          alert("올릴 수 없는 파일 형식입니다.");
          return;
        }
      }

      if (event.target.files[i]) {
        let reader = new FileReader();

        reader.readAsDataURL(event.target.files[i]);

        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64) {
            var base64Sub = base64.toString();
            var strBase64 = base64Sub.split("base64,");

            if (props.type === "excel") {
              props.setFileInfo(event.target.files[0]);
            }

            if (props.type === "image") {
              let img = new Image();
              img.src = reader.result;

              let name = event.target.files[0].name;
              let file = strBase64[1];
              let size = event.target.files[0].size;

              img.onload = function () {
                // if (img.width < 512) {
                //   setAlertProps({
                //     ...alertProps,
                //     isShow: true,
                //     text: "길이가 512px 미만입니다.",
                //     cancelBtnDisplay: false,
                //     okBtnEventHandler: function () {
                //       closeAlertEvent();
                //     },
                //   });
                // } else if (img.height < 512) {
                //   setAlertProps({
                //     ...alertProps,
                //     isShow: true,
                //     text: "높이가 512px 미만입니다.",
                //     cancelBtnDisplay: false,
                //     okBtnEventHandler: function () {
                //       closeAlertEvent();
                //     },
                //   });
                // } else {
                props.setFileInfo([
                  {
                    name: name,
                    file: file,
                    size: size,
                  },
                ]);
                // }
              };

              // props.setFileInfo([
              //   {
              //     name: event.target.files[0].name,
              //     file: strBase64[1],
              //     size: event.target.files[0].size,
              //   },
              // ]);
            }
          }

          event.target.value = null;
        };
      }
    }
  };
  return (
    <>

      <input
        style={{
          display: "none",
        }}
        type="file"
        accept={props.fileType}
        id="fileuploaderSingle"
        onChange={handleChangeFile}
      />
    </>
  );
}

export default FileuploaderSingle;
