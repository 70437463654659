import React, { useState, useEffect } from "react";
import "css/salesStatistics.css";
import { useDispatch } from "react-redux";
import { closeAlert, openAlert } from "actions";
import axios from "axios";
import moment from "moment";
import SalesMonthlyChart from "component/statistics/salesMonthlyChart";
import SearchSelectBox from "component/common/searchSelectBox";

/**
 * Version : 1.0
 * 작성일자 : 2022-05-27
 * 작성자 : 강연승
 * 설명 : 통계관리 - 매출통계
 */
function SalesStatistics() {
  /**
   * useDispatch
   */
  const dispatch = useDispatch();
  /**
   * STATE
   */
  const [salesMonthlyChart, setSalesMonthlyChart] = useState([]);
  const [salesList, setSalesList] = useState([]);
  const [dateRange, setDateRange] = useState([
    { num: 1, value: "2022-05", label: "2022.05" },
    { num: 2, value: "2022-06", label: "2022.06" },
    { num: 3, value: "2022-07", label: "2022.07" },
    { num: 4, value: "2022-08", label: "2022.08" },
    { num: 5, value: "2022-09", label: "2022.09" },
    { num: 6, value: "2022-10", label: "2022.10" },
    { num: 7, value: "2022-11", label: "2022.11" },
    { num: 8, value: "2022-12", label: "2022.12" },
    { num: 9, value: "2023-01", label: "2023.01" },
    { num: 10, value: "2023-02", label: "2023.02" },
    { num: 11, value: "2023-03", label: "2023.03" },
    { num: 12, value: "2023-04", label: "2023.04" },
    { num: 13, value: "2023-05", label: "2023.05" },
  ]);

  const [searchDateRange, setSearchDateRange] = useState({
    START: dateRange[0].value,
    END: dateRange.find(
      i =>
        i.value ===
        new Date().getFullYear() +
          "-" +
          (new Date().getMonth() + 1 < 10
            ? "0" + (new Date().getMonth() + 1)
            : new Date().getMonth() + 1)
    ).value,
  });

  const getSalesStatistics = (start, end) => {
    if (
      dateRange.find(i => i.value === start).num >
      dateRange.find(i => i.value === end).num
    ) {
      const obj = {
        TEXT: (
          <>
            <div className="info-msg err">오류!</div>
            <div className="info-msg">
              종료 날짜가 시작 날짜보다 더 빠릅니다.
            </div>
            <div className="info-msg">조회 기간을 수정해주세요.</div>
          </>
        ),
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    }
    if (
      dateRange.find(i => i.value === end).num -
        dateRange.find(i => i.value === start).num >
      11
    ) {
      const obj = {
        TEXT: (
          <>
            <div className="info-msg err">오류!</div>
            <div className="info-msg">
              시작 날짜로부터 최대 12개월까지 조회가 가능합니다.
            </div>
            <div className="info-msg">조회 기간을 수정해주세요.</div>
          </>
        ),
        submitEventHandler: () => {
          dispatch(closeAlert());
        },
      };
      dispatch(openAlert(obj));
      return;
    }
    let url = "/statistics/sales";
    let params = {
      SEARCH_START: start + "-01T00:00:00",
      SEARCH_END: end + "-" + moment(end).daysInMonth() + "T23:59:59",
    };
    axios.get(url, { params: params }).then(res => {
      if (res.status === 200) {
        setSalesMonthlyChart(res.data.CHART);
        setSalesList(res.data.LIST);
      }
    });
  };

  useEffect(() => {
    getSalesStatistics(searchDateRange.START, searchDateRange.END);
    let startDay = new Date("2022-05-01");
    let today = new Date();
    let diff = today - startDay;
    let currDay = 24 * 60 * 60 * 1000;
    let currMonth = currDay * 30;
    let monthDiff = parseInt(diff / currMonth);
    let tmpArr = [];
    for (let i = 0; i <= monthDiff; i++) {
      let tmpObj = {
        num: i + 1,
        value: moment(
          new Date(new Date().setMonth(startDay.getMonth() + i))
        ).format("YYYY-MM"),
        label: moment(
          new Date(new Date().setMonth(startDay.getMonth() + i))
        ).format("YYYY.MM"),
      };
      tmpArr.push(tmpObj);
    }
    setDateRange(tmpArr);
  }, []);
  /**
   * 검색 버튼
   */
  const onSearch = () => {
    getSalesStatistics(searchDateRange.START, searchDateRange.END);
  };
  return (
    <>
      <div className="salesStatistics_section">
        <div className="salesStatistics_title">
          <font className="mainTitle">매출통계</font>
          <font className="subTitle">{"통계관리 > 매출통계"}</font>
        </div>
        <div className="salesStatistics_searchArea">
          <SearchSelectBox
            label="검색 년 월"
            type="long"
            list={dateRange}
            value={searchDateRange.START}
            setValue={value =>
              setSearchDateRange({ ...searchDateRange, START: value })
            }
          />

          <font className="salesStatistics_dateRange">~</font>

          <SearchSelectBox
            label="검색 년 월"
            type="long"
            list={dateRange}
            value={searchDateRange.END}
            setValue={value =>
              setSearchDateRange({ ...searchDateRange, END: value })
            }
          />
          <button className="salesStatistics_searchBtn" onClick={onSearch}>
            검색
          </button>
        </div>
        <div className="salesStatistics_chartArea">
          <SalesMonthlyChart salesMonthlyChart={salesMonthlyChart} />
        </div>
        <div className="salesStatistics_excelBtnArea">
          <div className="salesStatistics_excelDownBtn">
            <img
              className="salesStatistics_excelIcon"
              src="/img/icon/common/exceldown.png"
              alt="excel"
            />
            <font
              className="salesStatistics_excelDownText"
              // onClick={() =>
              //   downloadExcel(
              //     searchDate,
              //   )
              // }
            >
              엑셀 다운로드
            </font>
          </div>
        </div>
        <div className="salesStatistics_salesList scrollbar">
          <table className="salesList_table">
            <colgroup>
              <col width="5%" />
              <col width="15%" />
              <col width="25%" />
              <col width="20%" />
              <col width="12%" />
              <col width="8%" />
              <col width="7%" />
              <col width="8%" />
            </colgroup>
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>NO</th>
                <th>사용자 번호</th>
                <th>사용자 아이디</th>
                <th>사용자 명</th>
                <th>결제 일시</th>
                <th style={{ textAlign: "center" }}>결제 금액</th>
                <th style={{ textAlign: "center" }}>구매 수량</th>
                <th style={{ textAlign: "center" }}>합계</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(salesList) && salesList.length > 0 ? (
                salesList.map((data, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>{index + 1}</td>
                    <td>{data.USER_NUM}</td>
                    <td>{data.USER_ID}</td>
                    <td>{data.USER_NM}</td>
                    <td>{data.APPROVED_AT}</td>
                    <td style={{ textAlign: "center" }}>
                      {data.AMOUNT.toLocaleString()}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {data.PRODUCT_COUNT}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {data.TOTAL_AMOUNT.toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : salesList.length === 0 ? (
                <tr>
                  <td colSpan="8" style={{ textAlign: "center" }}>
                    조회된 데이터가 없습니다.
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default SalesStatistics;
