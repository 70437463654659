import Loading from 'component/common/loading';
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { fetchAxiosEvent } from './axiosEvent';
import Paging from './content/paging';
import ScheduleListContent from './content/scheduleListContent';
import DatePicker from './content/datepicker';
/**
 * Version : 1.0
 * 파일명 : ScheduleList.js
 * 작성일자 : 2022-05-04
 * 작성자 : 권도훈
 */
function ScheduleList(props) {
    const [init, setInit] = useState(true);
    const [isSearch, setIsSearch] = useState(false);
    const [date, setDate] = useState({});
    const [page, setPage] = useState({
        ROW_COUNT: 10,
        PAGE_COUNT: 0
    });
    const [list, setList] = useState();
    const [total, setTotal] = useState(0);
    //날짜포맷
    const dateFormat = useCallback((date) => {
        let year = date.getFullYear();
        let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
        let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

        return year + "-" + month + "-" + day;
    }, []);
    //토탈 조회
    const getTotal = useCallback(() => {
        setTotal(
            fetchAxiosEvent(
                "GET",
                "/schedule/admin/schedule/total",
                {
                    config: {
                        params: {
                            USER_NUM: props.userNum,
                            START_DATE: date.START_DATE,
                            END_DATE: date.END_DATE
                        }
                    }
                }
            )
        );
    }, [date.END_DATE, date.START_DATE, props.userNum]);
    //목록 조회
    const getLog = useCallback(() => {
        setList(
            fetchAxiosEvent(
                "GET",
                "/schedule/admin/schedule",
                {
                    config: {
                        params: {
                            USER_NUM: props.userNum,
                            ROW_COUNT: page.ROW_COUNT,
                            PAGE_COUNT: page.PAGE_COUNT,
                            START_DATE: date.START_DATE,
                            END_DATE: date.END_DATE
                        }
                    }
                }
            )
        );
    }, [date.END_DATE, date.START_DATE, page.PAGE_COUNT, page.ROW_COUNT, props.userNum]);

    useEffect(() => {
        if (init) {
            setDate({
                START_DATE: dateFormat(new Date(new Date().setMonth(new Date().getMonth() - 1))),
                END_DATE: dateFormat(new Date())
            });

            setTotal(
                fetchAxiosEvent(
                    "GET",
                    "/schedule/admin/schedule/total",
                    {
                        config: {
                            params: {
                                USER_NUM: props.userNum,
                                START_DATE: dateFormat(new Date(new Date().setMonth(new Date().getMonth() - 1))),
                                END_DATE: dateFormat(new Date())
                            }
                        }
                    }
                )
            );
            setList(
                fetchAxiosEvent(
                    "GET",
                    "/schedule/admin/schedule",
                    {
                        config: {
                            params: {
                                USER_NUM: props.userNum,
                                ROW_COUNT: page.ROW_COUNT,
                                PAGE_COUNT: page.PAGE_COUNT,
                                START_DATE: dateFormat(new Date(new Date().setMonth(new Date().getMonth() - 1))),
                                END_DATE: dateFormat(new Date())
                            }
                        }
                    }
                )
            );
            setInit(false);
        } else if (isSearch) {
            getTotal();
            getLog();
            setIsSearch(false);
        }
    }, [date.END_DATE, date.START_DATE, dateFormat, getLog, getTotal, init, isSearch, page.PAGE_COUNT, page.ROW_COUNT, props.userNum]);

    return (
        <div className='info-content-inner table'>
            <div>
                <div className='info-content-inner-search'>
                    <div className='info-content-inner-search-option'>
                        <font>
                            예약시간(시작)
                        </font>

                        <div className='info-content-inner-search-datepicker'>
                            <DatePicker
                                setDate={setDate}
                                date={date}
                                type="START_DATE"
                                setDataSearch={setIsSearch}
                            />
                        </div>
                    </div>

                    <div className='info-content-inner-search-option'>
                        <font>
                            예약시간(종료)
                        </font>

                        <div className='info-content-inner-search-datepicker'>
                            <DatePicker
                                setDate={setDate}
                                date={date}
                                type="END_DATE"
                                setDataSearch={setIsSearch}
                            />
                        </div>
                    </div>

                    <button
                        className='info-search-btn cursor'
                        onClick={() => {
                            setPage({
                                ...page,
                                PAGE_COUNT: 0
                            });
                            setIsSearch(true);
                        }}
                    >
                        검 색
                    </button>
                </div>

                <table className='info-content-table'>
                    <colgroup>
                        <col width="5%" />
                        <col width="7%" />
                        <col width="7%" />
                        <col width="7%" />
                        <col width="5%" />
                        <col width="7%" />
                        <col width="7%" />
                        <col width="5%" />
                        <col width="7%" />
                        <col width="7%" />
                        <col width="7%" />
                        <col width="7%" />
                        <col width="8%" />
                        <col width="8%" />
                        <col width="6%" />
                    </colgroup>

                    <thead>
                        <tr>
                            <th>예약 번호</th>
                            <th>예약 시작시간</th>
                            <th>예약 종료시간</th>
                            <th>시작시간</th>
                            <th>종료시간</th>
                            <th>총 회의시간</th>
                            <th>회의제목</th>
                            <th>그룹명</th>
                            <th>녹화여부</th>
                            <th>초대인원수</th>
                            <th>참여인원수</th>
                            <th>미팅키</th>
                            <th>생성일시</th>
                            <th>생성자 IP</th>
                            <th>상세</th>
                        </tr>
                    </thead>

                    <tbody>
                        <Suspense
                            fallback={
                                <tr>
                                    <td colSpan="15">
                                        <div className="info-content-body-loading">
                                            <Loading />
                                        </div>
                                    </td>
                                </tr>
                            }
                        >
                            {
                                !init
                                    ?
                                    <ScheduleListContent list={list} />
                                    :
                                    <></>
                            }
                        </Suspense>
                    </tbody>
                </table>
            </div>

            <div className='info-content-paging'>
                <Suspense fallback={<></>}>
                    {
                        !init
                            ?
                            <Paging
                                setIsSearch={setIsSearch}
                                page={page}
                                setPage={setPage}
                                TOTAL={total}
                            />
                            :
                            <></>
                    }
                </Suspense>
            </div>
        </div>
    )
}

export default ScheduleList
