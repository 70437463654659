import React from 'react'
/**
 * Version : 1.0
 * 파일명 : LeaveSurveyContent.js
 * 작성일자 : 2022-05-09
 * 작성자 : 권도훈
 */
function LeaveSurveyContent(props) {
    const data = props.list.data.read();

    return (
        <>
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr key={idx}>
                            <td>{item.SURVEY_CONTENT}</td>
                            <td>{item.CREATE_DTTM}</td>
                            <td>{item.CREATE_IP}</td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan="3">
                            탈퇴 설문조사 내역이 없습니다.
                        </td>
                    </tr>
            }
        </>
    )
}

export default LeaveSurveyContent
