import { closeAlert, openAlert } from 'actions';
import axios from 'axios';
import FileuploaderSingle from 'component/common/fileupload/fileuploaderSingle';
import React from 'react'
import { useState } from 'react';
import ReactModal from 'react-modal';
import { useDispatch } from 'react-redux';

function Insertexcelpopup(props) {

    const [excelInfo, setExcelInfo] = useState({
        name: "",
        base64: "",
        size: "",
      });
      const deleteExcelFileInfo = () => {
        setExcelInfo({
          name: "",
          base64: "",
          size: "",
        });
      };
      const clickclose = () =>{
        deleteExcelFileInfo();
        props.close();
    
      }
  

      const dispatch = useDispatch();


    const downloadExcel = () =>{
        const config = {
            responseType: "blob"
          };
        const data = "테스트"
        axios.post("/user/downloadexcel", data, config)
        .then(res => {
    
          const url = window.URL.createObjectURL(
            new Blob([res.data], {type : res.headers["content-type"]})
          );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Pebble_회원가입_양식.xlsx");
            document.body.appendChild(link);
            link.click();
        })
    }



    const uploadEcxcel = () => {
            const formData = new FormData();
            formData.append("files", excelInfo);
    
            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };
    
            axios
              .post("/user/insertExcel", formData, config)
              .then(res => {
    
                if (res.status === 200) {
                    
                }
              })
            const obj = {
                TEXT: "파일이 업로드 되었습니다.",
                submitEventHandler: () => {dispatch(closeAlert());props.close();}
              };
              dispatch(openAlert(obj));


          }

          const nofile = () =>{
            const obj = {
                TEXT: "엑셀파일을 업로드 후 진행해주세요.",
                submitEventHandler: () => {dispatch(closeAlert()); }
              };
              dispatch(openAlert(obj));
          }




  return (    

    <>

    <ReactModal
    isOpen={props.open} //모달 Open(Boolean)
    className="userinputexcelPopup" //모달 ClassName
    overlayClassName="userinputexcelPopup_Overlay" //모달오버레이 ClassName
    onRequestClose={() => {
      props.close();
      deleteExcelFileInfo()
    }}
    shouldCloseOnEsc={true}
    animationType="fade"
    transparent={true}
    closeTimeoutMS={200}
  >
    <div className='excelpopupmain'>
    <div style={{textAlign : "right"}}>
    <img  className='cursor'
          style={{marginRight : "10px",
                  marginTop : "10px"}}
          src="/img/icon/common/close_black.png"
          alt=''
          onClick={() => clickclose()} />
      </div>

        <div className='excelguide_download'>
            <div className='excelguide_download_header'>양식 다운로드</div>
            <div className='excelguide_download_file cursor' onClick={()=>downloadExcel()}>Pebble_회원가입_양식.xlsx</div>
        </div>

       
    <div className='excelfile_upload'>
            <div className='excelfile_upload_header'>양식 업로드</div>

         <div className='excelfile_upload_content'>
            {excelInfo.size > 0 ? 
            <div className="excelfile_upload_header_filein">
             <div style={{paddingTop:"8px"}}>{excelInfo.name}</div>
                <img className="cursor" src="/img/icon/address/delete_36.png" alt=""
                   onClick={() => deleteExcelFileInfo()} />
             </div>                       
                         :
                         <div>
            <label htmlFor="fileuploaderSingle" className="upload_button_label cursor">
            <div className='excelfile_upload_header_fileout'>파일업로드</div> </label>
            </div>
            }
        </div>
    </div>
    
    <div>
    {excelInfo.size > 0 ?          
     <div className="excelfile_upload_server cursor"onClick={() => uploadEcxcel()} >
           등록하기</div> :
    <div className="excelfile_upload_server cursor" onClick={()=>nofile()}>
         등록하기</div>
    }

     </div>
    </div>

  </ReactModal>
        <FileuploaderSingle
        type="excel"
        setFileInfo={setExcelInfo}
        fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        fileType2 = "application/haansoftxlsx"
        fileSize="10485760"
        />
  </>

  )
}

export default Insertexcelpopup