import { combineReducers } from "redux";
import pagingInfoReducer from "reducers/common/pagingInfo";
import alertInfoReducer from "reducers/common/alertInfo";
/**
 * Version : 1.0
 * 작성일자 : 2022-02-16
 * 작성자 : 권도훈
 * 설명 : rootReducer
 */
const rootReducer = combineReducers({
  pagingInfoReducer,
  alertInfoReducer,
});

export default rootReducer;
