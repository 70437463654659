import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import "css/group.css";
import axios from "axios";
import Loading from "component/common/loading";
// import GroupPopupPaging from "component/group/groupPopupPaging";
/**
 * Version : 1.0
 * 작성일자 : 2022-05-16
 * 작성자 : 강연승
 * 설명 : 그룹 관리 - 그룹 이용권 현황 상세 팝업
 */
function GroupMeetingPassPopup(props) {
  useEffect(() => {
    if (props.open) {
      getGroupMeetingPassTotal(props.groupInfo.groupNum);
      getGroupMeetingPass(props.groupInfo.groupNum);
    }
  }, [props.open]);

  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    ROW_COUNT: 5,
    PAGE_COUNT: 0,
    TOTAL: 0,
  });
  const [meetingPassList, setMeetingPassList] = useState([]);
  /**
   * 그룹 회의 이용권 갯수 조회
   */
  const getGroupMeetingPassTotal = groupNum => {
    let param = {
      GROUP_NUM: groupNum,
    };
    axios
      .get("/group/admin/groupMeetingPassListCnt", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          const paging_obj = {
            ...pageInfo,
            TOTAL: res.data.TOTAL,
          };
          setPageInfo(paging_obj);
        }
      })
      .catch(() => {});
  };

  /**
   * 그룹 현황 조회
   */
  const getGroupMeetingPass = groupNum => {
    setLoading(true);
    let param = {
      GROUP_NUM: groupNum,
      ROW_COUNT: pageInfo.ROW_COUNT,
      PAGE_COUNT: pageInfo.PAGE_COUNT,
    };
    axios
      .get("/group/admin/groupMeetingPassList", {
        params: param,
      })
      .then(res => {
        if (res.status === 200) {
          setMeetingPassList(res.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  /**
   * Format license Title
   */
  const licenseFormat = license => {
    if (license !== undefined) {
      const licenseArr = license.split("-");
      return licenseArr[0].replace(/(.{6})/g, "$1-");
    } else {
      return;
    }
  };

  useEffect(() => {
    getGroupMeetingPass(props.groupInfo.groupNum);
  }, [pageInfo.ROW_COUNT, pageInfo.PAGE_COUNT]);

  return (
    <ReactModal
      isOpen={props.open} //모달 Open(Boolean)
      className="groupMeetingPassPopup" //모달 ClassName
      overlayClassName="groupMeetingPassPopup_Overlay" //모달오버레이 ClassName
      onRequestClose={() => {
        props.close("PASS");
      }}
      shouldCloseOnEsc={true}
      animationType="fade"
      transparent={true}
      closeTimeoutMS={200}
    >
      <div className="popup_background">
        <div className="popup_header">
          <div className="popup_close_btn">
            <img
              src="/img/icon/common/close_black.png"
              alt="close"
              onClick={() => props.close("PASS")}
            />
          </div>
          <div className="popup_title">그룹 회의이용권 현황</div>
        </div>
        <div className="popup_body">
          <div className="groupInfo">
            <div className="label_groupNum">그룹 번호 (그룹명) : </div>
            <div className="value_groupNum">
              {props.groupInfo.groupNum + " (" + props.groupInfo.groupNm + ")"}
            </div>
          </div>
          <div className="group_list scrollbar">
            <table className="group_table">
              <colgroup>
                <col width="40px" />
                <col width="70px" />
                <col width="210px" />
                <col width="150px" />
                <col width="90px" />
                <col width="149px" />
                <col width="237px" />
              </colgroup>
              <thead>
                <tr>
                  <th>NO</th>
                  <th>잔여기간</th>
                  <th>회의이용권 코드</th>
                  <th>종료 일자</th>
                  <th style={{ textAlign: "center" }}>예약 회의 수</th>
                  <th>생성일시</th>
                  <th>생성자IP</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      <Loading />
                    </td>
                  </tr>
                ) : Array.isArray(meetingPassList) &&
                  meetingPassList.length > 0 ? (
                  meetingPassList.map((data, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        {pageInfo.PAGE_COUNT + index + 1}
                      </td>
                      <td>
                        {data.ENDDATE_COUNT >= 0
                          ? "D-" + data.ENDDATE_COUNT
                          : "D+" +
                            data.ENDDATE_COUNT.toString().replace("-", "")}
                      </td>
                      <td>{licenseFormat(data.LICENSE_CODE)}</td>
                      <td>{data.EXPIRED_DT}</td>
                      <td style={{ textAlign: "center" }}>
                        {data.SCHEDULE_COUNT}
                      </td>
                      <td>{data.CREATE_DTTM.replace("T", " ")}</td>
                      <td>{data.CREATE_IP}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" style={{ textAlign: "center" }}>
                      조회된 데이터가 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {/* {pageInfo.TOTAL === 0 ? null : (
              <div className="group_paging">
                <GroupPopupPaging
                  pageInfo={pageInfo}
                  setPageInfo={setPageInfo}
                />
              </div>
            )} */}
          </div>
          <div
            className="popup_btn_confirm"
            onClick={() => props.close("PASS")}
          >
            확인
          </div>
        </div>
      </div>
    </ReactModal>
  );
}

export default GroupMeetingPassPopup;
