import Loading from 'component/common/loading';
import React, { Suspense, useEffect, useState } from 'react'
import ReactModal from 'react-modal'
import { fetchAxiosEvent } from '../axiosEvent';
/**
 * Version : 1.0
 * 파일명 : SchedulePopup.js
 * 작성일자 : 2022-05-19
 * 작성자 : 권도훈
 */
function ScheduleSection(props) {
    const participants = props.participants.data.read();
    const data = props.data;

    return (
        <section>
            <p className='info-popup-schedule-title'>예약 히스토리 상세정보</p>

            <div className='info-popup-schedule-item'>
                <p>회의제목</p>
                <p>{data.TITLE}</p>
            </div>

            <div className='info-popup-schedule-item'>
                <p>예약번호 / 미팅키</p>
                <p>{data.SCHEDULE_NUM} / {data.MEETING_KEY}</p>
            </div>

            <div className='info-popup-schedule-item'>
                <p>그룹명</p>
                <p>{data.GROUP_NM}</p>
            </div>

            <div className='info-popup-schedule-item'>
                <p>녹화여부</p>
                <p>
                    {
                        data.REC_YN === 'Y'
                            ? "녹화 ON"
                            : "녹화 OFF"
                    }
                </p>
            </div>

            <div className='info-popup-schedule-item'>
                <p>예약 시작시간 / 예약 종료시간</p>
                <p>{data.START_TIME} / {data.END_TIME}</p>
            </div>

            <div className='info-popup-schedule-item'>
                <p>회의 시작시간 / 회의 종료시간</p>
                <p>
                    {
                        data.HISTORY_START_TIME === ""
                            ? "-"
                            : data.HISTORY_START_TIME
                    }
                    <font> / </font>
                    {
                        data.HISTORY_END_TIME === ""
                            ? "-"
                            : data.HISTORY_END_TIME
                    }
                    <font> ({data.HISTORY_DURATION}분)</font>
                </p>
            </div>

            <div className='info-popup-schedule-item'>
                <p>생설일시</p>
                <p>{data.CREATE_DT}</p>
            </div>

            <div className='info-popup-schedule-item'>
                <p>생성자 IP</p>
                <p>{data.CREATE_IP}</p>
            </div>

            <div className='info-popup-schedule-participants'>
                <p>참석자</p>

                <div className='info-popup-schedule-participants-item-area scrollbar'>
                    {
                        participants.map((item, idx) => (
                            <p
                                key={idx}
                                className="info-popup-schedule-participants-item"
                            >
                                {item.EMAIL} / {item.NAME} ({item.STATE === "ATTEND" ? "참석" : "미참석"})
                            </p>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

function SchedulePopup(props) {
    const [init, setInit] = useState(true);
    const [data, setData] = useState();
    const [participants, setParticipants] = useState([]);

    useEffect(() => {
        if (init && props.popup) {
            setParticipants(
                fetchAxiosEvent(
                    "GET",
                    "/schedule/admin/schedule/participants",
                    {
                        config: {
                            params: {
                                SCHEDULE_NUM: props.content.SCHEDULE_NUM,
                                MEETING_KEY: props.content.MEETING_KEY
                            }
                        }
                    }
                )
            )
            setData(props.content);
            setInit(false);
        }
    }, [init, props.content, props.popup]);


    const closeEventHandler = () => {
        props.setPopup(false);
        props.setContent({});
        setData({});
        setParticipants();
        setInit(true);
    }

    return (
        <ReactModal
            isOpen={props.popup}
            className="info-popup-schedule"
            overlayClassName="popupOverlay"
            animationType="fade"
            transparent={true}
            shouldCloseOnEsc={true}
            onRequestClose={() => closeEventHandler()}
        >
            <div className='info-popup-schedule-inner'>
                <img
                    className='info-popup-schedule-close cursor'
                    src='/img/icon/common/close_black.png'
                    alt=''
                    onClick={() => closeEventHandler()}
                />

                <Suspense
                    fallback={
                        <div className="info-popup-schedule-loading">
                            <Loading />
                        </div>
                    }
                >
                    {
                        !init && props.popup
                            ?
                            <ScheduleSection
                                data={data}
                                participants={participants}
                            />
                            :
                            <></>
                    }
                </Suspense>
            </div>
        </ReactModal>
    )
}

export default SchedulePopup
