import React from 'react'
/**
 * Version : 1.0
 * 파일명 : PasswordLogContent.js
 * 작성일자 : 2022-05-04
 * 작성자 : 권도훈
 */
function PasswordLogContent(props) {
    const data = props.list.data.read();

    return (
        <>
            {
                data !== undefined && data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr key={idx}>
                            <td>{item.CHANGE_DT}</td>
                            <td>{item.PASSWORD}</td>
                            <td>{item.CREATE_DT}</td>
                            <td>{item.CREATE_IP}</td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td colSpan="4">
                            변경 내역이 없습니다.
                        </td>
                    </tr>
            }
        </>
    )
}

export default PasswordLogContent
