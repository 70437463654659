
import { closeAlert, openAlert } from 'actions'
import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux'

function Insertlicenseaccountpopup(props) {


    const dispatch = useDispatch();
    
    const [insertid, setinsertid]= useState('');
    const [insertpw, setinsertpw]= useState('');
    const [insertuseyn, setinsertuseyn]= useState('');
    const [insertplatform, setinsertplatform]= useState('');

    const handleinsertid = (e) =>{
        setinsertid(e.target.value);
      }

      const handleinsertpw = (e) =>{
        setinsertpw(e.target.value);
      }

      const handleinsertuseyn = (e) =>{
        setinsertuseyn(e.target.value);
      }
      const handleinsertplatform = (e) =>{
        setinsertplatform(e.target.value);
      }

    useEffect(()=>{
      setinsertuseyn('N')
    },[]);
    const insertlicense = useCallback((id,pw,type,useyn) =>{
        let config = {
            params : {
                WEBEX_ID :   id,
                WEBEX_PW :   pw,
                PLATFORM     :   type,
                USE_YN   :   useyn
            }
        }
        axios.post("/license/admin/insertlicenseAccount",null,config).then((res) =>{
            if(res.status===200){
                console.log("입력되었습니다.")
            }
        })

    })
    const onclicksubmit = (id,pw,type,useyn) =>{
        if(useyn === ''){
            const obj = {
                TEXT: "사용여부를 선택해주세요.",
                submitEventHandler: () => {dispatch(closeAlert());}
              };
              dispatch(openAlert(obj));
        }else{

            if(type === ''){
                const obj = {
                    TEXT: "플랫폼을 선택해주세요.",
                    submitEventHandler: () => {dispatch(closeAlert());}
                  };
                  dispatch(openAlert(obj));
            }else{

              if(type === 'CD0501' && pw ===''){
                const obj = {
                  TEXT: "비밀번호를 입력해 주세요.",
                  submitEventHandler: () => {dispatch(closeAlert());}
                };
                dispatch(openAlert(obj));
              }else{
                insertlicense(id,pw,type,useyn);

                const obj = {
                    TEXT: "등록되었습니다.",
                    submitEventHandler: () => {dispatch(closeAlert());props.close();}
                  };
                  dispatch(openAlert(obj));
                  setinsertuseyn('N')
                  setinsertid('')
                  setinsertplatform('')
                  setinsertpw('')

              }

        }


    }
    }

    const clickclose = () =>{
      setinsertid('')
      setinsertplatform('')
      setinsertpw('')
      props.close();
      setinsertuseyn('N')
  
    }


  return (
    <ReactModal
    isOpen={props.open} //모달 Open(Boolean)
    
    className="insertlicenseaccountPopup" //모달 ClassName
    overlayClassName="insertlicenseaccountPopup_Overlay" //모달오버레이 ClassName
    onRequestClose={() => {
        setinsertuseyn('N')
        setinsertid('')
        setinsertplatform('')
        setinsertpw('')
            props.close();

    }}
    shouldCloseOnEsc={true}
    animationType="fade"
    transparent={true}
    closeTimeoutMS={200}
  >

    <div>
      <div style={{textAlign : "right"}}>
    <img  className='cursor'
          style={{marginRight : "10px",
                  marginTop : "10px"}}
          src="/img/icon/common/close_black.png"
          alt=''
          onClick={() => clickclose()} />
      </div>
    <div className='insertlicenseaccountbody'>

          <div  className='insertlicenseaccountID'>
        <div className='insertlicenseaccounttitle'>아이디</div>
        <div> <input type='text' onChange={handleinsertid}></input></div>
          </div>

          <div  className='insertlicenseaccountID'>
        <div className='insertlicenseaccounttitleuse'>비밀번호</div>
        <div> <input type='text' onChange={handleinsertpw}></input></div>
          </div>  

          <div className='insertlicenseaccounttype'>
        <div className='insertlicenseaccounttitle'>플랫폼</div>
        <input type='radio' className='platform' name='platform_CLICK' value="CD0501" onChange={handleinsertplatform} checked={insertplatform === 'CD0501'}/>
        <label className='exposeo'>WEBEX</label>
        <input type='radio'className='platform' name='platform_CLICK' value="CD0502" onChange={handleinsertplatform} checked={insertplatform === 'CD0502'}/>
        <label className='exposeno'>ZOOM</label>
          </div>

          <div style={{display : "flex"}}>
            <div className= 'insertlicenseaccounttitleuse'>사용여부</div>
            <input type='radio' className='useo' name='use_YN_CLICK' value="Y" onChange={handleinsertuseyn} checked={insertuseyn === 'Y'}/><label className='exposeo'>사용</label>
            <input type='radio'className='useno' name='use_YN_CLICK' value="N" onChange={handleinsertuseyn} checked={insertuseyn === 'N'}/><label className='exposeno'>미사용</label>

          </div>
      </div>
 
      <div className='insertlicenseaccountsubmit cursor' onClick={() =>onclicksubmit(insertid, insertpw, insertplatform, insertuseyn)}>
          등록
      </div>



    </div>
    </ReactModal>
  )
}

export default Insertlicenseaccountpopup