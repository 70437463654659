import React from 'react'
/**
 * Version : 1.0
 * 파일명 : ProductList.js
 * 작성일자 : 2022-05-20
 * 작성자 : 권도훈
 */
function ProductList(props) {
    const data = props.product.data.read();
    //가격 포맷
    const amountFormat = (amount) => {
        return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + "원";
    }

    return (
        <>
            {
                data.length > 0
                    ?
                    data.map((item, idx) => (
                        <tr
                            className='pd-content-tr cursor'
                            key={idx}
                            onClick={() => {
                                props.setPopupData(item);
                                props.setPopupType("UPDATE");
                                props.setOpenPopup(true);
                            }}
                        >
                            <td
                                className='pd-content-td text-center'
                                onClick={e => e.stopPropagation()}
                            >
                                <input
                                    type='checkbox'
                                    className='cursor'
                                    checked={props.chkList.includes(item.PRODUCT_CODE)}
                                    onChange={e => {
                                        if (e.target.checked) {
                                            props.setChkList([...props.chkList, item.PRODUCT_CODE]);
                                        } else {
                                            props.setChkList(props.chkList.filter(code => code !== item.PRODUCT_CODE));
                                        }
                                    }}
                                />
                            </td>
                            <td className='pd-content-td text-center'>
                                {item.PRODUCT_NUM}
                            </td>
                            <td className='pd-content-td'>
                                <span>
                                    {item.PRODUCT_CODE}
                                </span>
                            </td>
                            <td className='pd-content-td'>
                                <span>
                                    {item.PRODUCT_NAME}
                                </span>
                            </td>
                            <td className='pd-content-td'>
                                <span>
                                    {amountFormat(item.PRODUCT_PRICE)}
                                </span>
                            </td>
                            <td className='pd-content-td'>
                                <span>
                                    {amountFormat(item.PRODUCT_DISCOUNT)}
                                </span>
                            </td>
                            <td className='pd-content-td'>
                                <span>
                                    {item.PRODUCT_COMMENT}
                                </span>
                            </td>
                            <td className='pd-content-td'>
                                <span>
                                    {item.PRODUCT_DATE}
                                </span>
                            </td>
                            <td className='pd-content-td text-center'>
                                <span>
                                    {item.VIEW_YN}
                                </span>
                            </td>
                            <td className='pd-content-td'>
                                <span>
                                    {item.CREATE_DT}
                                </span>
                            </td>
                            <td className='pd-content-td'>
                                <span>
                                    {item.CREATE_IP}
                                </span>
                            </td>
                        </tr>
                    ))
                    :
                    <tr>
                        <td
                            colSpan='11'
                            rowSpan="10"
                            className='pd-content-empty text-center'
                        >
                            <span>등록된 상품이 없습니다.</span>
                        </td>
                    </tr>
            }
        </>
    )
}

export default ProductList
